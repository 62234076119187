import React from 'react';
import Grid from '../Grid';

interface Props {
  isMobile: boolean;
  content: any;
}

const Landing: React.FC<Props> = ({
  isMobile,
  content,
}: Props) => {
  return (
    <Grid mainGrid={true} rows={content.blocks.view.blocks}/>
  )
}

export default Landing;