import React, { useState, useEffect, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './reviewInformation.module.scss';
import cn from 'classnames';
import { getCurrentWidth } from '../../../../utils/custom-hooks/handleWindowResize';
import { app, i18nKeyPrefix } from '../../../../utils/constants';
import defaultImage from '../../../../assets/images/welcome.png';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import {
  setDynamicFormField,
  setReview,
  setStep,
  setValidDynamicFormField,
  startRequest,
} from '../../../../actions/form';
import immutableToJS from 'with-immutable-props-to-js';
import FormHeaderComponent from '../../FormHeader';
import FormFooterComponent from '../../FormFooter';
import { apiEndpoints } from '../../../../utils/api/endpoints';
import { State } from '../../../../reducers';
import { RequestData } from '../../../../utils/interfaces/request';
import { Action } from 'redux';
import xss from 'xss';
import {
  validateAdditionalInfo,
  validateAddressInfo,
  validateCompanyInfo,
  validateCompanyType,
  validatePersonalInfo,
} from '../../../../utils/validation';

interface Props {
  onChangeInformation?: (val: any) => void;
  sendVerificationCode?: (data: RequestData) => void;
}

interface ParamTypes {
  lang: any;
}

const ReviewInformation: React.FC<Props> = ({ sendVerificationCode }: Props) => {
  const marketData = useSelector((state: State) => state.router.marketData);
  const marketConfig = useSelector((state: State) => state.router.marketConfig);
  const size = getCurrentWidth();
  const [windowSize, setWindowSize] = useState(size);
  const dispatch = useDispatch();
  const isMobile = windowSize <= app.breakPoint;
  useEffect(() => {
    if (isMobile) {
      window.scrollTo(0, 0);
    }
    setWindowSize(size);
    dispatch(setReview(false));
  }, [isMobile]);
  const { t } = useTranslation();
  const history = useHistory();
  const companyName = useSelector((state: State) => state.form.companyName);
  const managerName = useSelector((state: State) => state.form.managerName);
  const jobTitle = useSelector((state: State) => state.form.jobTitle);
  const phone = useSelector((state: State) => state.form.phone);
  const email = useSelector((state: State) => state.form.email);
  const VATnumber = useSelector((state: State) => state.form.VATnumber);
  const saveId = useSelector((state: State) => state.form.saveId.saveId);
  const companyTypeImage = useSelector((state: State) => state.form.companyTypeImage);
  const companyTypeImageHtml = (
    <div
      style={{ backgroundImage: companyTypeImage ? `url(${companyTypeImage})` : `url(${defaultImage})` }}
      className={cn(styles.image)}
    ></div>
  );
  const selectedCategories = useSelector((state: State) => state.form.additionalData?.selectedCategories);
  const feedback = useSelector((state: State) => state.form.additionalData.feedback);
  const previousBusinessOwner = useSelector((state: State) => state.form.additionalData.previousBusinessOwner);
  const IBAN = useSelector((state: State) => state.form.additionalData.accountNumber);
  const payerVAT = useSelector((state: State) => state.form.additionalData.payerVAT);
  const norwayIBAN = useSelector((state: State) => state.form.additionalData.norwayAccountNumber);
  const dunningEmail = useSelector((state: State) => state.form.additionalData.paymentRemindersEmail);
  const receiveDunning = useSelector((state: State) => state.form.additionalData.allowPaymentReminders);
  const deliveryFrom = useSelector((state: State) => state.form.additionalData.deliveryFrom);
  const deliveryTo = useSelector((state: State) => state.form.additionalData.deliveryTo);
  const sellsAlcohol = useSelector((state: State) => state.form.additionalData.sellsAlcohol);
  const alcoholLicenseNumber = useSelector((state: State) => state.form.additionalData.alcoholLicenseNumber);
  const elmaEmail = useSelector((state: State) => state.form.additionalData.invoiceEmail);
  const ownershipDate = useSelector((state: State) => state.form.additionalData.acquisitionDate);
  const establishmentOwnership = useSelector((state: State) => state.form.additionalData.isOwnerChange);
  const selectedSeason = useSelector((state: State) => state.form.additionalData.openSeason);
  const litersToSell = useSelector((state: State) => state.form.additionalData.litersToSell);
  const openingHours = useSelector((state: State) => state.form.additionalData.openingHours);
  const invoice = useSelector((state: State) => state.form.additionalData.elmaInvoice);
  const attachments = useSelector((state: State) => state.form.attachments);
  const additionalData = useSelector((state: State) => state.form.additionalData);
  const chainBusinessName = useSelector((state: State) => state.form.additionalData.chainBusinessName);
  const chainBusiness = useSelector((state: State) => state.form.additionalData.chainBusiness);
  const previousOwner = useSelector((state: State) => state.form.additionalData.previousOwner);
  const ownershiftEstimatedDate = useSelector((state: State) => state.form.additionalData.ownershiftEstimatedDate);
  const financialEmail = useSelector((state: State) => state.form.additionalData.financialEmail);
  const communicationEmail = useSelector((state: State) => state.form.additionalData.communicationEmail);
  const paymentReminderEmail = useSelector((state: State) => state.form.additionalData.paymentReminderEmail);
  const orderNotificationEmail = useSelector((state: State) => state.form.additionalData.orderNotificationEmail);
  const sinebrychoffShopEmail = useSelector((state: State) => state.form.additionalData.sinebrychoffShopEmail);
  const loadingTruckPoint = useSelector((state: State) => state.form.additionalData.loadingTruckPoint);
  const deliveryDay = useSelector((state: State) => state.form.additionalData.deliveryDay);
  const eInvoice = useSelector((state: State) => state.form.additionalData.eInvoice);
  const eInvoiceNumber = useSelector((state: State) => state.form.additionalData.eInvoiceNumber);
  const eInvoiceOperator = useSelector((state: State) => state.form.additionalData.eInvoiceOperator);
  const eInvoiceOperatorId = useSelector((state: State) => state.form.additionalData.eInvoiceOperatorId);
  const directDebitEmail = useSelector((state: State) => state.form.additionalData.directDebitEmail);
  const emailInvoice = useSelector((state: State) => state.form.additionalData.emailInvoice);
  const form = useSelector((state: State) => state.form);
  const params = useParams<ParamTypes>();
  const prefix = i18nKeyPrefix + 'LeanSignup_Form';
  const [isValidated, setIsValidated] = useState(false);

  useEffect(() => {
    dispatch(setStep(6));
  }, []);

  useEffect(() => {
    if (form && form.saveId.saveId && marketConfig && !isValidated) {
      if (!validatePersonalInfo(dispatch, marketConfig, form)) {
        history.push(`/${params.lang}/signup/1?error=validation`);
      } else if (!validateCompanyInfo(dispatch, marketConfig, form)) {
        history.push(`/${params.lang}/signup/2?error=validation`);
      } else if (!validateAddressInfo(dispatch, marketConfig, form)) {
        history.push(`/${params.lang}/signup/3?error=validation`);
      } else if (!validateCompanyType(dispatch, marketConfig, form)) {
        history.push(`/${params.lang}/signup/4?error=validation`);
      } else {
        let validateDone = false;
        marketConfig?.form.pages.map((page: any, index: number) => {
          if (!validateDone) {
            if (!validateAdditionalInfo(form, index, setDynamicFormField, setValidDynamicFormField, t)) {
              validateDone = true;
              history.push(`/${params.lang}/signup/5/${page.pageStepName}?error=validation`);
            }
          }
        });
      }

      setIsValidated(true);
    }
  }, [marketConfig, form]);

  const changeStep = (step: any, review?: string) => {
    history.push(`/${params.lang}/signup/${step}`);
    dispatch(setStep(step));
    if (review) {
      dispatch(setReview(true));
    }
  };

  const validateStep = () => {
    sendVerificationCode({
      type: 'POST',
      endpoint: `${apiEndpoints.sendVerificationCode}/${saveId}?countryCode=${marketData.marketId.toUpperCase()}`,
    });
    window.localStorage.setItem('formData', JSON.stringify(form));
    history.push(`/${params.lang}/signup/8`);
    dispatch(setStep(8));
    return;
  };

  const getDaysValue = (days: any) => {
    const value = [];
    if (days.length > 1) {
      const splitDays = days.split(',');
      for (let i = 0; i < splitDays.length; i++) {
        const day = t(`${i18nKeyPrefix}${splitDays[i]}`);
        value.push(day);
      }
    }
    return value.join();
  };

  //we have two different styles here, onePageReview = true or false

  return (
    <React.Fragment>
      <div className={styles.formContainer}>
        <FormHeaderComponent changeStep={changeStep} />
        <div className={styles.container}>
          {!isMobile && <div className={styles.halfBackground}>{companyTypeImageHtml}</div>}
          <div className={styles.innerContainer}>
            <div className={styles.wrapper}>
              {!isMobile && (
                <div className={styles.leftScreen}>
                  <div className={styles.header} data-i18n-key={`${prefix}_ReviewInfo_title`}>
                    {t(`${prefix}_ReviewInfo_title`)}
                  </div>
                </div>
              )}
              <div
                className={cn(styles.rightScreen, {
                  [styles.noBg]: !marketConfig?.onePageReview,
                  [styles.noScroll]: marketConfig?.onePageReview,
                })}
              >
                <div className={cn(styles.infoWrapper)}>
                  {marketConfig?.onePageReview && (
                    <React.Fragment>
                      {isMobile && (
                        <div className={styles.section}>
                          <div className={styles.header} data-i18n-key={`${prefix}_ReviewInfo_title`}>
                            {t(`${prefix}_ReviewInfo_title`)}
                          </div>
                        </div>
                      )}
                      <div className={styles.section}>
                        <div className={styles.sectionHeader}>{companyName}</div>
                        <div className={styles.info}>{VATnumber}</div>
                      </div>

                      <div className={styles.section}>
                        <div className={styles.sectionHeader}>{managerName}</div>
                        {marketConfig.showJobTitle && <div className={styles.info}>{jobTitle}</div>}
                        <div className={styles.info}>{phone}</div>
                        <div className={styles.info}>{email}</div>
                      </div>

                      {marketConfig.addressList &&
                        marketConfig.addressList.primary &&
                        marketConfig.addressList.primary.map((item: any, i: any) => (
                          <div key={i + item.type} className={styles.section}>
                            <div
                              className={styles.sectionHeader}
                              data-i18n-key-combined-title={`${prefix}_Address_title${item.type}`}
                              data-i18n-key-title={`${prefix}_Address_title${item.type}`}
                            >
                              {t(`${prefix}_Address_title${item.type}`)}
                            </div>
                            <div className={styles.info}>
                              {form[`address${item.type}`]} {form[`zipCode${item.type}`]} {form[`cityName${item.type}`]}{' '}
                            </div>
                          </div>
                        ))}

                      {marketConfig.addressList &&
                        marketConfig.addressList.secondary &&
                        marketConfig.addressList.secondary.map((item: any, i: any) => (
                          <div key={i + item.type} className={styles.section}>
                            <div
                              className={styles.sectionHeader}
                              data-i18n-key-combined-title={`${prefix}_Address_title${item.type}`}
                              data-i18n-key-title={`${prefix}_Address_title${item.type}`}
                            >
                              {t(`${prefix}_Address_title${item.type}`)}
                            </div>
                            <div className={styles.info}>
                              {form[`address${item.type}`]} {form[`zipCode${item.type}`]} {form[`cityName${item.type}`]}{' '}
                            </div>
                          </div>
                        ))}
                    </React.Fragment>
                  )}
                  {marketConfig?.onePageReview == false && (
                    <div className={cn(styles.reviewWrapper)}>
                      {(email || phone || companyName || managerName || jobTitle) && (
                        <div className={cn(styles.section, { [styles.alignLeft]: isMobile })}>
                          <div className={styles.sectionHeader}>
                            <label data-i18n-key={`${prefix}_PersonalInformation_title`}>
                              {t(`${prefix}_PersonalInformation_title`)}
                            </label>
                            <a
                              onClick={() => changeStep(1, 'review')}
                              className={styles.link}
                              data-i18n-key={`${prefix}_Navigation_edit`}
                            >
                              {t(`${prefix}_Navigation_edit`)}
                            </a>
                          </div>
                          <div className={styles.title}>{companyName}</div>
                          <div className={styles.label}>{jobTitle}</div>
                          <div className={styles.label}>{managerName}</div>
                          <div className={styles.label}>{phone}</div>
                          <div className={styles.label}>{email}</div>
                          <div className={styles.lineBreak}></div>
                        </div>
                      )}

                      {VATnumber && (
                        <div className={cn(styles.section, { [styles.alignLeft]: isMobile })}>
                          <div className={styles.sectionHeader}>
                            <div data-i18n-key={`${prefix}_VAT_label`}>{t(`${prefix}_VAT_label`)}</div>
                          </div>
                          <div className={styles.title}>{VATnumber}</div>
                          <div className={styles.lineBreak}></div>
                        </div>
                      )}
                      {marketConfig.addressList &&
                        marketConfig.addressList.primary &&
                        marketConfig.addressList.primary.map((item: any, i: any) => (
                          <div key={i + item.type} className={cn(styles.section, { [styles.alignLeft]: isMobile })}>
                            <div className={styles.sectionHeader}>
                              <div data-i18n-key={`${prefix}_Address_title${item.type}`}>
                                {t(`${prefix}_Address_title${item.type}`)}
                              </div>
                              <a
                                onClick={() => changeStep(3, 'review')}
                                className={styles.link}
                                data-i18n-key={`${prefix}_Navigation_edit`}
                              >
                                {t(`${prefix}_Navigation_edit`)}
                              </a>
                            </div>
                            <div className={styles.title}>{form[`name${item.type}`]}</div>
                            <div className={styles.label}>{form[`address${item.type}`]}</div>
                            <div className={styles.label}>{form[`zipCode${item.type}`]}</div>
                            <div className={styles.label}>{form[`cityName${item.type}`]}</div>
                            <div className={styles.lineBreak}></div>
                          </div>
                        ))}

                      {marketConfig.addressList &&
                        marketConfig.addressList.secondary &&
                        marketConfig.addressList.secondary.map((item: any, i: any) => (
                          <div key={i + item.type} className={cn(styles.section, { [styles.alignLeft]: isMobile })}>
                            <div className={styles.sectionHeader}>
                              <div data-i18n-key={`${prefix}_Address_title${item.type}`}>
                                {t(`${prefix}_Address_title${item.type}`)}
                              </div>
                              <a
                                onClick={() => changeStep(3, 'review')}
                                className={styles.link}
                                data-i18n-key={`${prefix}_Navigation_edit`}
                              >
                                {t(`${prefix}_Navigation_edit`)}
                              </a>
                            </div>
                            <div className={styles.title}>{form[`name${item.type}`]}</div>
                            <div className={styles.label}>{form[`address${item.type}`]}</div>
                            <div className={styles.label}>{form[`zipCode${item.type}`]}</div>
                            <div className={styles.label}>{form[`cityName${item.type}`]}</div>
                            <div className={styles.lineBreak}></div>
                          </div>
                        ))}

                      {selectedCategories && selectedCategories.length > 0 && (
                        <div className={cn(styles.section, { [styles.alignLeft]: isMobile })}>
                          <div className={styles.sectionHeader}>
                            <div data-i18n-key={`${prefix}_CompanyType_sidebarTitle`}>
                              {t(`${prefix}_CompanyType_sidebarTitle`)}
                            </div>
                            <a
                              onClick={() => changeStep(4, 'review')}
                              className={styles.link}
                              data-i18n-key={`${prefix}_Navigation_edit`}
                            >
                              {t(`${prefix}_Navigation_edit`)}
                            </a>
                          </div>
                          {selectedCategories &&
                            selectedCategories.map((item: any, i: any) => (
                              <React.Fragment key={i}>
                                <div className={styles.title}>{item.name}</div>
                                <div className={styles.description}>
                                  {filterXSS(item.description.replace(/\u00a0/g, ' ').substring(0, 100))}...
                                </div>
                              </React.Fragment>
                            ))}
                          <div className={styles.lineBreak}></div>
                        </div>
                      )}

                      {sellsAlcohol !== undefined && (
                        <div className={cn(styles.section, { [styles.alignLeft]: isMobile })}>
                          <div className={styles.sectionHeader}>
                            <div data-i18n-key={`${prefix}_Custom_FileUpload`}>{t(`${prefix}_Custom_FileUpload`)}</div>
                            <a
                              onClick={() => changeStep(marketData.marketId === 'lt' ? '5/file-upload' : 5, 'review')}
                              className={styles.link}
                              data-i18n-key={`${prefix}_Navigation_edit`}
                            >
                              {t(`${prefix}_Navigation_edit`)}
                            </a>
                          </div>
                          <div className={styles.title} data-i18n-key={`${prefix}_FileUpload_question`}>
                            {t(`${prefix}_FileUpload_question`)}
                          </div>
                          <div className={styles.description}>
                            {sellsAlcohol ? t(`${prefix}_Yes`) : t(`${prefix}_No`)}
                          </div>
                          {alcoholLicenseNumber && <div className={styles.description}>{alcoholLicenseNumber}</div>}
                          {sellsAlcohol && attachments && attachments.length > 0 && (
                            <div
                              className={styles.title}
                              data-i18n-key={`${prefix}_Custom_FileUpload_copyTitleMessage`}
                            >
                              {t(`${prefix}_Custom_FileUpload_copyTitleMessage`)}:
                            </div>
                          )}
                          {sellsAlcohol &&
                            attachments &&
                            attachments.map((item: any, i: any) => (
                              <div className={styles.description} key={i}>
                                {item.filename?.substring(0, 100)}
                              </div>
                            ))}
                          <div className={styles.lineBreak}></div>
                        </div>
                      )}

                      {additionalData && marketConfig?.form !== null && (
                        <div className={cn(styles.section, { [styles.alignLeft]: isMobile })}>
                          <div className={styles.sectionHeader}>
                            <div data-i18n-key={`${prefix}_Custom_AdditionalInfo`}>
                              {t(`${prefix}_Custom_AdditionalInfo`)}
                            </div>
                            <a
                              onClick={() => changeStep('5/additional-data', 'review')}
                              className={styles.link}
                              data-i18n-key={`${prefix}_Navigation_edit`}
                            >
                              {t(`${prefix}_Navigation_edit`)}
                            </a>
                          </div>
                          {establishmentOwnership !== undefined && (
                            <React.Fragment>
                              <div
                                className={styles.title}
                                data-i18n-key={`${prefix}_Custom_EstablishmentOwnership_label`}
                              >
                                {t(`${prefix}_Custom_EstablishmentOwnership_label`)}
                              </div>
                              <div className={styles.description}>
                                {establishmentOwnership ? t(`${prefix}_Yes`) : t(`${prefix}_No`)}
                              </div>
                            </React.Fragment>
                          )}
                          {establishmentOwnership !== undefined && ownershipDate && (
                            <div className={styles.description}>{new Date(ownershipDate).toDateString()}</div>
                          )}
                          {establishmentOwnership !== undefined && ownershiftEstimatedDate && (
                            <div className={styles.description}>{new Date(ownershiftEstimatedDate).toDateString()}</div>
                          )}
                          {establishmentOwnership !== undefined && previousBusinessOwner && (
                            <div className={styles.description}>{previousBusinessOwner}</div>
                          )}
                          {establishmentOwnership !== undefined && previousOwner && (
                            <div className={styles.description}>{previousOwner}</div>
                          )}
                          {chainBusiness !== undefined && (
                            <React.Fragment>
                              <div className={styles.title} data-i18n-key={`${prefix}_Custom_ChainBusiness_title`}>
                                {t(`${prefix}_Custom_ChainBusiness_title`)}
                              </div>
                              <div className={styles.description}>
                                {chainBusiness ? t(`${prefix}_Yes`) : t(`${prefix}_No`)}
                              </div>
                            </React.Fragment>
                          )}
                          {chainBusiness !== undefined && chainBusinessName && (
                            <div className={styles.description}>{chainBusinessName}</div>
                          )}
                          {selectedSeason && (
                            <React.Fragment>
                              <div className={styles.title} data-i18n-key={`${prefix}_Custom_OpenSeason_Review_Title`}>
                                {t(`${prefix}_Custom_OpenSeason_Review_Title`)}
                              </div>
                              <div className={styles.description} data-i18n-key={selectedSeason?.name}>
                                {t(i18nKeyPrefix + selectedSeason?.name)}
                              </div>
                            </React.Fragment>
                          )}
                          {openingHours && (
                            <div
                              className={styles.title}
                              data-i18n-key={`${prefix}_Custom_BusinessHours_hoursList_title`}
                            >
                              {t(`${prefix}_Custom_BusinessHours_hoursList_title`)}
                            </div>
                          )}
                          {openingHours &&
                            openingHours.length &&
                            openingHours
                              .sort((a, b) => (a.day > b.day ? 1 : -1))
                              .map((item: any, index: any) => (
                                <React.Fragment key={index}>
                                  <div className={styles.description}>
                                    {getDaysValue(item.label)}{' '}
                                    {item.isClosed
                                      ? t(prefix + '_Custom_OpeningHours_closed')
                                      : item.from + '-' + item.to}
                                  </div>
                                </React.Fragment>
                              ))}
                          {deliveryFrom && deliveryTo && (
                            <React.Fragment>
                              <div className={styles.title} data-i18n-key={`${prefix}_Custom_DeliveryWindow_title`}>
                                {t(`${prefix}_Custom_DeliveryWindow_title`)}
                              </div>
                              {deliveryDay && <div className={styles.description}>{deliveryDay}</div>}
                              <div className={styles.description}>
                                {deliveryFrom} - {deliveryTo}
                              </div>
                            </React.Fragment>
                          )}
                          {loadingTruckPoint !== undefined && (
                            <React.Fragment>
                              <div className={styles.title} data-i18n-key={`${prefix}_Custom_LoadingTruckPoint_title`}>
                                {t(`${prefix}_Custom_LoadingTruckPoint_title`)}
                              </div>
                              <div className={styles.description}>
                                {loadingTruckPoint ? t(`${prefix}_Yes`) : t(`${prefix}_No`)}
                              </div>
                            </React.Fragment>
                          )}
                          {litersToSell && (
                            <React.Fragment>
                              <div className={styles.title} data-i18n-key={`${prefix}_Custom_LitersToSell_Title`}>
                                {t(`${prefix}_Custom_LitersToSell_Title`)}
                              </div>
                              <div className={styles.description} data-i18n-key={litersToSell}>
                                {litersToSell}
                              </div>
                            </React.Fragment>
                          )}
                          {eInvoice && (
                            <React.Fragment>
                              <div className={styles.title} data-i18n-key={`${prefix}_Custom_EInvoice_title`}>
                                {t(`${prefix}_Custom_EInvoice_title`)}
                              </div>
                              <div className={styles.description}>{eInvoice}</div>
                              {directDebitEmail && !emailInvoice && (
                                <div className={styles.description}>{directDebitEmail}</div>
                              )}
                              {!directDebitEmail && emailInvoice && (
                                <div className={styles.description}>{emailInvoice}</div>
                              )}
                              {!directDebitEmail && !emailInvoice && (
                                <>
                                  <div className={styles.description}>{eInvoiceNumber}</div>
                                  <div className={styles.description}>{eInvoiceOperator}</div>
                                  <div className={styles.description}>{eInvoiceOperatorId}</div>
                                </>
                              )}
                            </React.Fragment>
                          )}
                          {(IBAN || norwayIBAN) && (
                            <React.Fragment>
                              <div className={styles.title} data-i18n-key={`${prefix}_Custom_Iban_title`}>
                                {t(`${prefix}_Custom_Iban_title`)}
                              </div>
                              <div className={styles.description}>{IBAN || norwayIBAN}</div>
                            </React.Fragment>
                          )}

                          {invoice && (
                            <React.Fragment>
                              <div className={styles.title} data-i18n-key={`${prefix}_Custom_InvoiceByElma_edit_label`}>
                                {t(`${prefix}_Custom_InvoiceByElma_edit_label`)}
                              </div>
                              <div className={styles.description}>
                                {t(`${prefix}_Custom_InvoiceByElma_noEdit_copy`)}
                              </div>
                            </React.Fragment>
                          )}

                          {invoice === false && (
                            <React.Fragment>
                              <div className={styles.title} data-i18n-key={`${prefix}_Custom_InvoiceByElma_edit_label`}>
                                {t(`${prefix}_Custom_InvoiceByElma_edit_label`)}
                              </div>
                              <div className={styles.description}>{elmaEmail}</div>
                            </React.Fragment>
                          )}

                          {receiveDunning && (
                            <React.Fragment>
                              <div
                                className={styles.title}
                                data-i18n-key={`${prefix}_Custom_ReceiveDunningLetters_label`}
                              >
                                {t(`${prefix}_Custom_ReceiveDunningLetters_label`)}
                              </div>
                              <div className={styles.description}>{dunningEmail}</div>
                            </React.Fragment>
                          )}

                          {financialEmail && (
                            <React.Fragment>
                              <div
                                className={styles.title}
                                data-i18n-key={`${prefix}_Custom_FinancialEmail_edit_label`}
                              >
                                {t(`${prefix}_Custom_FinancialEmail_edit_label`)}
                              </div>
                              <div className={styles.description}>{financialEmail}</div>
                            </React.Fragment>
                          )}

                          {communicationEmail && (
                            <React.Fragment>
                              <div
                                className={styles.title}
                                data-i18n-key={`${prefix}_Custom_CommunicationEmail_edit_label`}
                              >
                                {t(`${prefix}_Custom_CommunicationEmail_edit_label`)}
                              </div>
                              <div className={styles.description}>{communicationEmail}</div>
                            </React.Fragment>
                          )}

                          {paymentReminderEmail && (
                            <React.Fragment>
                              <div
                                className={styles.title}
                                data-i18n-key={`${prefix}_Custom_PaymentReminderEmail_edit_label`}
                              >
                                {t(`${prefix}_Custom_PaymentReminderEmail_edit_label`)}
                              </div>
                              <div className={styles.description}>{paymentReminderEmail}</div>
                            </React.Fragment>
                          )}

                          {orderNotificationEmail && (
                            <React.Fragment>
                              <div
                                className={styles.title}
                                data-i18n-key={`${prefix}_Custom_OrderNotificationEmail_edit_label`}
                              >
                                {t(`${prefix}_Custom_OrderNotificationEmail_edit_label`)}
                              </div>
                              <div className={styles.description}>{orderNotificationEmail}</div>
                            </React.Fragment>
                          )}

                          {sinebrychoffShopEmail && (
                            <React.Fragment>
                              <div
                                className={styles.title}
                                data-i18n-key={`${prefix}_Custom_SinebrychoffShopEmail_edit_label`}
                              >
                                {t(`${prefix}_Custom_SinebrychoffShopEmail_edit_label`)}
                              </div>
                              <div className={styles.description}>{sinebrychoffShopEmail}</div>
                            </React.Fragment>
                          )}

                          {payerVAT && (
                            <React.Fragment>
                              <div className={styles.title} data-i18n-key={`${prefix}_Custom_PayerVat_edit_label`}>
                                {t(`${prefix}_Custom_PayerVat_edit_label`)}
                              </div>
                              <div className={styles.description}>{payerVAT}</div>
                            </React.Fragment>
                          )}

                          {feedback !== undefined && (
                            <React.Fragment>
                              <div className={styles.title} data-i18n-key={`${prefix}_Custom_Feedback`}>
                                {t(`${prefix}_Custom_Feedback`)}
                              </div>
                              <div className={styles.description}>{feedback ? feedback : '-'}</div>
                            </React.Fragment>
                          )}
                          <div className={styles.lineBreak}></div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {marketConfig?.onePageReview && (
                  <div className={styles.section}>
                    <a
                      className={styles.changeInfo}
                      onClick={() => {
                        history.push(`/${params.lang}/signup/7`);
                        dispatch(setStep(7));
                      }}
                      data-i18n-key={`${prefix}_ReviewInfo_link`}
                    >
                      {t(`${prefix}_ReviewInfo_link`)}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormFooterComponent validateStep={validateStep} />
    </React.Fragment>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    sendVerificationCode: (data: RequestData) => {
      dispatch(startRequest(data, 'verificationCode'));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(immutableToJS(ReviewInformation));
