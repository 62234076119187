import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { State } from '../../reducers';
import Radio from '../Radio';
import cn from 'classnames';

import styles from './radioQuestion.module.scss';
import { i18nKeyPrefix } from '../../utils/constants';

interface Props {
  label: string;
  action: (value: boolean) => void;
  subtitle?: string;
  items?: any;
  field?: any;
  dynamicClass?: string;
  switchItems?: boolean;
}

const RadioQuestion: React.FC<Props> = ({
  label,
  subtitle,
  action,
  items,
  field,
  dynamicClass,
  switchItems
}: Props) => {
  const { t } = useTranslation();
  const form = useSelector((state: State) => state.form);

  return (
    <div className={cn(styles.radioQuestion, {[styles[dynamicClass]] : dynamicClass})}>
      <div className={styles.label}>
        {label}
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
      {!switchItems &&
        <div className={styles.radio}>
          <div className={styles.radioItem} data-i18n-key={items[0].name}>
            <Radio
              label={t(i18nKeyPrefix + items[0].name)}
              checked={form.additionalData[field.name] === true}
              action={() => action(true)}
            />
          </div>
          <div className={styles.radioItem} data-i18n-key={items[1].name}>
            <Radio
              label={t(i18nKeyPrefix + items[1].name)}
              checked={form.additionalData[field.name] === false}
              action={() => action(false)}
            />
          </div>
        </div>
      }
      {switchItems &&
        <div className={styles.radio}>
          <div className={styles.radioItem} data-i18n-key={items[0].name}>
            <Radio
              label={t(i18nKeyPrefix + items[0].name)}
              checked={form.additionalData[field.name] === false}
              action={() => action(false)}
            />
          </div>
          <div className={styles.radioItem} data-i18n-key={items[1].name}>
            <Radio
              label={t(i18nKeyPrefix + items[1].name)}
              checked={form.additionalData[field.name] === true}
              action={() => action(true)}
            />
          </div>
        </div>
      }
    </div>
  );
}

export default RadioQuestion;
