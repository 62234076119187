import React from 'react';
import styles from './screenTitle.module.scss';
import cn from 'classnames';

interface Props {
  title?: string;
  description?: string;
  disabled?: boolean;
}

const ScreenTitle: React.FC<Props> = ({
  title,
  description,
  disabled
}: Props) => {
  return (
    <>
      <h2 className={cn(styles.header, {[styles.disabled]: disabled, [styles.bottomSpace]: !description})}>{title}</h2>
      {description && <div className={styles.bottomSpace}>{description}</div>}
    </>
  );
}

export default ScreenTitle;