import React from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../../reducers';
import styles from './images.module.scss';

interface ImagesViewModel {
  viewModel: any;
}

const Images: React.FC<ImagesViewModel> = ({
  viewModel
}: ImagesViewModel) => {

  const el = React.createRef<HTMLDivElement>();
  const configData = useSelector((state: State) => state.router.configData);

  let styleObj: any;

  return (
    <div className={styles.images} style={styleObj} ref={ el }>
      <div className={styles.headlines}>
          {viewModel?.content?.subHeadline &&
              <h2 className={styles.subHeadline}>
              {viewModel?.content?.subHeadline}
              </h2>
          }
          {viewModel?.content?.headline &&
              <h1 className={styles.headline}>
              {viewModel?.content?.headline}
              </h1>
          }
          {viewModel?.content?.action?.url &&
            <div className={styles.actionDiv}>
                <a href={viewModel?.content?.action?.url} target={viewModel?.content?.action?.target} >
                    <span className={styles.text}>{viewModel?.content?.action?.name}</span>
                </a>
            </div>
          }
      </div>
      <div className={styles.columns}>
        {viewModel?.content?.images?.map((item, index) => (
            <div key={index} className={styles.column} style={{backgroundImage: `url(${item.url})`}}></div>
        ))}
      </div>
      {viewModel?.content?.callToAction &&
        <div className={styles.underCopyDiv}>
            <p>{viewModel?.content?.callToAction}</p>
        </div>
      }
    </div>
  )
};

export default Images;
