import { call, put } from 'redux-saga/effects';
import * as Eff from 'redux-saga/effects';

import { actions as actionsConstants } from '../utils/api/constants';
import { getResponse, endRequest, handleError } from '../actions/api';
import { headlessRequest } from '../utils/api/headlessRequest';
import { RequestData } from '../utils/interfaces/request';

interface Action {
  name: string;
  data: RequestData;
  path?: string;
}

const takeEvery: any = Eff.takeEvery;

function* apiRequest(action: Action) {
  try {
    const path = action?.path?.replace(/\//, '');
    const data = yield call(() => headlessRequest(action.name, path));
    if (data?.meta?.statusCode !== 200) {
      throw "Error " + data?.meta?.statusCode;
    }
    yield put(getResponse(action.name, data));
    yield put(endRequest(action.name));
  } catch(error) {
    process.env.NODE_ENV === 'development' ? console.log(error) : null;
    yield put(handleError(error, action.name));
    yield put(endRequest(action.name));
  }
}

export function* makeCookiesRequest() {
  yield takeEvery(actionsConstants.API_COOKIES_REQUEST_INIT, apiRequest);
}

export default apiRequest;
