export enum actions {
  API_REQUEST_INIT = 'API_REQUEST_INIT',
  API_CATEGORIES_REQUEST_INIT = 'API_CATEGORIES_REQUEST_INIT',
  API_COOKIES_REQUEST_INIT = 'API_COOKIES_REQUEST_INIT',
  API_NAV_REQUEST_INIT = 'API_NAV_REQUEST_INIT',
  API_GLOBAL_REQUEST_INIT = 'API_GLOBAL_REQUEST_INIT',
  API_TERMS_REQUEST_INIT = 'API_TERMS_REQUEST_INIT',
  API_PRIVACY_REQUEST_INIT = 'API_PRIVACY_REQUEST_INIT',
  API_HEADLESS_REQUEST_INIT = 'API_HEADLESS_REQUEST_INIT',
  GET_RESPONSE = 'GET_RESPONSE',
  HTTP_ERROR = 'HTTP_ERROR',
  API_REQUEST_ENDS = 'REQUEST_ENDS'
}