import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useHistory, useParams } from "react-router-dom";
import { State } from '../../../../reducers';
import handleHeadlessLink from '../../../../utils/custom-hooks/handleHeadlessLink';

import styles from './usp.module.scss';

interface USPViewModel {
  viewModel: any;
  externalClass?: string;
}

interface ParamTypes {
  lang: any;
}

const USP: React.FC<USPViewModel> = ({
  viewModel,
  externalClass
}: USPViewModel) => {

  const history = useHistory();

  const el = React.createRef<HTMLDivElement>();
  let _links: HTMLCollection;
  // let _els: HTMLCollection;

  const params = useParams<ParamTypes>();

  useEffect(() => {
    function navigate(event: any) {
      let { target } = event;
      let i = 0;
      while (
        i < 5 &&
        !(target instanceof HTMLAnchorElement) &&
        target.parentNode
      ) {
        target = target.parentNode;
        i++;
      }
      if (!(target instanceof HTMLAnchorElement)) return;
      const href = target.getAttribute('href');

      if (href && (href[0] === '/' || href[0] === `/${params.lang}/`)) {
        event.preventDefault();
        history.push(href);
      }
    }

    _links = el?.current?.getElementsByTagName('a');
    for (let i = 0; i < _links?.length; i++) {
      const link = _links[i];
      const href = link.getAttribute('href');
      if (href && (href[0] === '/' || href[0] === `/${params.lang}/`)) {
        link.setAttribute('href', handleHeadlessLink(href));
      }
      _links[i].addEventListener('click', navigate, false);
    }

    return function removeListener() {
      _links = el?.current?.getElementsByTagName('a');

      if (_links) {
        for (let i = 0; i < _links?.length; i++) {
          _links[i].removeEventListener('click', navigate, false);
        }
      }
    }
  }, [])

  let styleObj: any;

  const parseClasses = (classNames, defaultClass) => {
    const arrClasses = defaultClass;
    let classes = [];
    
    if (classNames && classNames.length) {
      classes = classNames.split(' ');
      classes.map((className) => {
        arrClasses.push(styles[className]);
      });
    }
    
    return arrClasses;
  }

  return (
    <div className={cn(parseClasses(externalClass, [styles.usp]))} style={styleObj} ref={ el }>
      <div className={styles.headlines}>
          {viewModel?.content?.subHeadline &&
              <h2 className={styles.subHeadline}>
              {viewModel?.content?.subHeadline}
              </h2>
          }
          {viewModel?.content?.headline &&
              <h1 className={styles.headline}>
              {viewModel?.content?.headline}
              </h1>
          }
      </div>
      <div className={styles.columns}>
        {viewModel?.content?.items?.map((item, index) => (
            <div key={index} className={styles.column}>
                <div className={styles.columnTitle}>{item.title}</div>
                <div className={styles.columnContent} dangerouslySetInnerHTML={{__html: item.content ? item.content : ''}}/>
            </div>
        ))}
      </div>
      {viewModel?.content?.link?.url &&
        <div className={styles.actionDiv}>
            <a href={viewModel?.content?.link?.url} target={viewModel?.content?.link?.target} >
                <span className={styles.text}>{viewModel?.content?.link?.name}</span>
            </a>
        </div>
      }
    </div>
  )
};

export default USP;
