import React, { useState, useEffect } from 'react';
import { useLocation, useParams, Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { getCurrentWidth } from '../../../utils/custom-hooks/handleWindowResize';
import { app } from '../../../utils/constants';
import styles from './formWrapper.module.scss';
import cn from 'classnames';

import PersonalInfo from '../Screens/PersonalInfo';
import CompanyInfo from '../Screens/CompanyInfo';
import CompanyType from '../Screens/CompanyType';
import ReviewInformationComponent from '../Screens/ReviewInformation';
import VerificationCodeComponent from '../Screens/VerificationCode';
import EditInformationComponent from '../Screens/EditInformation';
import AddressComponent from '../Screens/Address';
import {
  setCompanyName,
  setManagerName,
  setJobTitle,
  setFirstName,
  setLastName,
  setPhone,
  setEmail,
  setConfirmationEmail,
  setAcceptTerms,
  setAcceptNewsletter,
  setAcceptSmsService,
  setAcceptMarketingMessage,
  setVAT,
  setDifferentAddress,
  setDynamicValue,
  setSaveId,
  setAdditionalData,
  setVATchecked,
  setAttachments,
  setSignupAdditionalForm,
  setAcceptPrivacyAndCookies,
} from '../../../actions/form';
import ContactMeComponent from '../ContactMe';
import TagManager from 'react-gtm-module';
import { State } from '../../../reducers';
import { useSelector, useDispatch } from 'react-redux';
import DynamicFormWrapper from '../Screens/DynamicFormWrapper';
import { configName, getConfig, getConfigUrl, getAWSConfig } from '../../../utils/config/getConfig';

interface Props {
  step?: number;
  props?: any;
}

interface ParamTypes {
  lang: any;
}

const pageNames = [
  { title: 'Landing Page', name: 'LandingPage' },
  { title: 'Personal Information', name: 'PersonalInfo' },
  { title: 'Company Information', name: 'CompanyInfo' },
  { title: 'Addresses', name: 'Addresses' },
  { title: 'Company Type', name: 'CompanyType' },
  { title: 'Dynamic Form', name: 'DynamicForm' },
  { title: 'Review Information', name: 'ReviewInfo' },
  { title: 'Edit Information', name: 'EditInfo' },
  { title: 'Verification Code', name: 'VerificationCode' },
  { title: 'Welcome', name: 'Welcome' },
  { title: 'Error', name: 'Error' },
];

const FormWrapper: React.FC<Props> = ({ props }: Props) => {
  const marketData = useSelector((state: State) => state.router.marketData);
  const marketConfig = useSelector((state: State) => state.router.marketConfig);
  const dynamicStepObj = useSelector((state: State) => state.form.dynamicStep);
  const step = useSelector((state: State) => state.form.step);
  const dynamicStep = dynamicStepObj === -1 ? null : dynamicStepObj;
  const location = useLocation();

  const size = getCurrentWidth();
  const { path } = useRouteMatch();

  const [windowSize, setWindowSize] = useState(size);
  const cookieConsent = window.localStorage.getItem('cookies') === 'accepted' ? 'yes' : 'no';

  useEffect(() => {
    if (marketData?.marketId) {
      const tagManagerDataLayerArgs = {
        dataLayer: {
          event: 'virtualPageView',
          pageCountry: marketData?.marketId,
          pageLanguage: localStorage['i18nextLng'],
          pageTitle:
            step < 9 && step !== 5
              ? pageNames[step].title
              : dynamicStep !== null
              ? marketConfig?.form.pages[dynamicStep].pageTitle
              : 'Contact Me',
          pageStep: step,
          pageStepName:
            step < 9 && step !== 5
              ? pageNames[step].name
              : dynamicStep !== null
              ? marketConfig?.form.pages[dynamicStep].pageStepName
              : 'ContactMe',
          pageStepDynamicvariable: dynamicStep,
          userAcceptCookies: cookieConsent,
        },
      };
      TagManager.dataLayer(tagManagerDataLayerArgs);
    }
  }, [step, dynamicStep]);

  useEffect(() => {
    setWindowSize(size);
  }, [size]);

  const isMobile = windowSize <= app.breakPoint;
  const smallDesktop = windowSize <= app.contactMeBreakPoint;
  const params = useParams<ParamTypes>();
  const loc = location.pathname.replace(`/${params.lang}`, '');
  const dispatch = useDispatch();
  const form = useSelector((state: State) => state.form);

  if (!window.localStorage.getItem('formData')) {
    localStorage.setItem('formData', JSON.stringify(form));
  }

  useEffect(() => {
    (async function () {
      const marketDataObj = await getConfig(`./${getConfigUrl(configName.market)}`, configName.market);

      if (marketDataObj) {
        if (marketDataObj?.marketId) {
          const marketConfigObj = await getAWSConfig();
          dispatch(setSignupAdditionalForm(marketConfigObj));
          console.info('LSU Market ID:', marketDataObj.marketId);
        }
      }
    })();
  }, []);

  const storage = JSON.parse(window.localStorage.getItem('formData'));

  //get data stored in localStorage
  useEffect(() => {
    if (storage) {
      dispatch(setCompanyName(storage.companyName));
      dispatch(setManagerName(storage.managerName));
      dispatch(setJobTitle(storage.jobTitle));
      dispatch(setFirstName(storage.firstName));
      dispatch(setLastName(storage.lastName));
      dispatch(setPhone(storage.phone));
      dispatch(setEmail(storage.email));
      dispatch(setConfirmationEmail(storage.confirmationEmail));
      dispatch(setAcceptTerms(storage.acceptTerms));
      dispatch(setAcceptNewsletter(storage.acceptNewsletter));
      dispatch(setAcceptSmsService(storage.acceptSmsService));
      dispatch(setAcceptMarketingMessage(storage.acceptMarketingMessage));
      dispatch(setAcceptPrivacyAndCookies(storage.acceptPrivacyAndCookies));
      dispatch(setVAT(storage.VATnumber));
      dispatch(setVATchecked(storage.VATchecked));
      dispatch(setDifferentAddress(storage.differentAddress));
      dispatch(setSaveId(storage.saveId));
      dispatch(setAdditionalData(storage.additionalData));
      dispatch(setAttachments(storage.attachments));
    }
  }, []);

  useEffect(() => {
    if (marketConfig && marketConfig.addressList && marketConfig.addressList.primary) {
      marketConfig.addressList.primary.map((item: any, i: any) => {
        dispatch(setDynamicValue(`address${item.type}`, storage[`address${item.type}`]));
        dispatch(setDynamicValue(`name${item.type}`, storage[`name${item.type}`]));
        dispatch(setDynamicValue(`cityName${item.type}`, storage[`cityName${item.type}`]));
        dispatch(setDynamicValue(`phone${item.type}`, storage[`phone${item.type}`]));
        dispatch(setDynamicValue(`email${item.type}`, storage[`email${item.type}`]));
        dispatch(setDynamicValue(`zipCode${item.type}`, storage[`zipCode${item.type}`]));
      });
    }

    if (marketConfig && marketConfig.addressList && marketConfig.addressList.secondary) {
      marketConfig.addressList.secondary.map((item: any, i: any) => {
        dispatch(setDynamicValue(`address${item.type}`, storage[`address${item.type}`]));
        dispatch(setDynamicValue(`name${item.type}`, storage[`name${item.type}`]));
        dispatch(setDynamicValue(`cityName${item.type}`, storage[`cityName${item.type}`]));
        dispatch(setDynamicValue(`phone${item.type}`, storage[`phone${item.type}`]));
        dispatch(setDynamicValue(`email${item.type}`, storage[`email${item.type}`]));
        dispatch(setDynamicValue(`zipCode${item.type}`, storage[`zipCode${item.type}`]));
      });
    }

    if (marketConfig && marketConfig.showOutletCompanyType) {
      dispatch(setDynamicValue('outletName', storage['outletName']));
      dispatch(setDynamicValue('outletZipCode', storage['outletZipCode']));
    }
  }, [marketConfig]);

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.mobile]: isMobile,
        [styles.contactMeWrap]: smallDesktop && loc === '/contact-me',
      })}
    >
      <Switch>
        <Route path={`${path}/1`} exact>
          <PersonalInfo />
        </Route>
        <Route path={`${path}/2`} exact>
          <CompanyInfo />
        </Route>
        <Route path={`${path}/3`} exact>
          <AddressComponent />
        </Route>
        <Route path={`${path}/4`} exact>
          <CompanyType />
        </Route>
        {marketConfig?.form !== null &&
          marketConfig?.form.pages.map(() => (
            <Route path={`${path}/5`} exact key={0}>
              <Redirect to={`5/${marketConfig?.form.pages[0].pageStepName}`} />
            </Route>
          ))}
        {marketConfig?.form !== null &&
          marketConfig?.form.pages.map((page: any, index: number) => (
            <Route path={`${path}/5/${page.pageStepName}`} exact key={index + 1}>
              <DynamicFormWrapper dynamicStep={index} />
            </Route>
          ))}
        <Route path={`${path}/6`} exact>
          <ReviewInformationComponent />
        </Route>
        <Route path={`${path}/7`} exact>
          <EditInformationComponent />
        </Route>
        <Route path={`${path}/8`} exact>
          <VerificationCodeComponent />
        </Route>
      </Switch>
      {loc === '/contact-me' && <ContactMeComponent />}
    </div>
  );
};

export default FormWrapper;
