import { State } from "../../../reducers";

const getApi = (state: State) => {

  return state.api;
}

const getErrorObj = (state: State, name: string) => {
  if (!getApi(state).get(name)) {

    return;
  }

  return getApi(state).get(name);
}

export const getError = (state: State, name: string) => {
  if (!getErrorObj(state, name)) {

    return;
  }

  return getErrorObj(state, name).get('error');
}
