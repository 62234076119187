import React, { ReactNode } from 'react';
import cn from 'classnames';
import styles from './button.module.scss';
import { ReactComponent as IconLoader } from '../../assets/images/icon-loader.svg';
import { ReactComponent as Loader } from '../../assets/images/loader.svg';
import loaderCb from '../../assets/images/cb-loader.png';

interface Props {
  action?: () => void;
  disabled?: boolean;
  image?: ReactNode;
  imageLeft?: ReactNode;
  loading?: boolean;
  text: ReactNode;
  type?: 'submit' | 'button' | 'reset';
  size?: string;
  style?: string;
  cbLoader?: boolean;
  color?: string;
  genericLoader?: boolean;
}

const Button: React.FC<Props> = ({
  action,
  disabled,
  image,
  imageLeft,
  loading,
  text,
  type,
  color,
  size,
  style,
  cbLoader,
  genericLoader,
}: Props) => {
  return (
    <button
      className={cn(styles.button, styles.ripple, styles.noSelect, {
        [styles.secondary]: color === 'secondary',
        [styles.grey]: color === 'grey',
        [styles.danger]: color === 'danger',
        [styles.black]: color === 'black',
        [styles.white]: color === 'white',
        [styles['black-outline']]: color === 'black-outline',
        [styles['white-outline']]: color === 'white-outline',
        [styles['grey-outline']]: color === 'grey-outline',
        [styles.small]: size === 'small',
        [styles.normal]: size === 'normal',
        [styles.medium]: size === 'medium',
        [styles.large]: size === 'large',
        [styles.loading]: loading || cbLoader,
        [styles.disabled]: disabled && !loading,
        [styles.noBorder]: style === 'noBorder',
      })}
      onClick={!disabled && !loading && !cbLoader ? action : null}
      type={type}
    >
      {loading ? (
        <IconLoader />
      ) : cbLoader ? (
        genericLoader ? (
          <Loader />
        ) : (
          <img src={loaderCb} />
        )
      ) : (
        <>
          {imageLeft && imageLeft}
          {text && text}
          {image && image}
        </>
      )}
    </button>
  );
};

export default Button;
