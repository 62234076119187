import { combineReducers } from 'redux';
import { apiReducer } from './api';
import { routerReducer } from './router';
import { modalReducer } from './modal';
import { languageReducer } from './languages';
import { formReducer } from './form';
import { landingReducer } from './landing';

export interface State {
  api: any;
  router: any;
  landing: any;
  language: any;
  modal: any;
  form: any;
}

const rootReducers = combineReducers<State>({
  api: apiReducer,
  router: routerReducer,
  landing: landingReducer,
  language: languageReducer,
  modal: modalReducer,
  form: formReducer
});

export {
  rootReducers
};