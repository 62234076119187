import React from 'react';
import styles from './backbutton.module.scss';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronLeft } from '../../assets/images/chevron-left.svg';
import { i18nKeyPrefix } from '../../utils/constants';

interface Props {
  action: () => void;
}

const BackButton: React.FC<Props> = ({
  action
}: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_Navigation';

  return (
      <button onClick={action ? action : () => history.goBack()} className={styles.back} data-i18n-key={`${prefix}_back`}>
        <ChevronLeft />
        {t(`${prefix}_back`)}
      </button>
  );
}

export default BackButton;
