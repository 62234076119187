import React, { createRef, RefObject, useState, useEffect } from 'react';
import styles from './select.module.scss';
import { getCurrentWidth } from '../../utils/custom-hooks/handleWindowResize';
import Text, { SIZES } from '../Text';
import { app, i18nKeyPrefix } from '../../utils/constants';
import cn from 'classnames';
import { ReactComponent as ChevronDown } from '../../assets/images/chevron-down.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange?: (e?: any) => void;
  label?: string;
  value?: string;
  options: any;
  disabled?: boolean;
  hideLabel?: boolean;
  valid?: boolean;
  required?: boolean;
  id?: string;
  dataId?: string;
  translateOptions?: boolean;
  size?: string;
}

const Select: React.FC<Props> = ({
  label,
  value,
  onChange,
  options,
  disabled,
  hideLabel,
  valid = true,
  required,
  id,
  dataId,
  translateOptions = false,
  size
}: Props) => {
  const screenSize = getCurrentWidth();
  const { t } = useTranslation();

  const [windowSize, setWindowSize] = useState(screenSize);
  useEffect(() => {
    setWindowSize(screenSize);
  });

  const inputRef: RefObject<HTMLSelectElement> = createRef();
  const isMobile = windowSize <= app.breakPoint;

  const renderOptions = () => {
    return options.map((option: any, index: number) => {
     return (
       <option disabled={option.disabled} className={cn(styles.option, {[styles.disabled]: option.disabled})} value={translateOptions? t(i18nKeyPrefix + (option.value || option)) : (option.value || option) } key={index}>
        {translateOptions? t(i18nKeyPrefix + (option.label || option)) : (option.label || option) }
       </option>
     )
    });
  }

  if (!value) {
    value = '';
  }

  return (
    <div className={styles.wrapper}>
      {label &&
        <label className={cn(styles.label, {[styles.disabled]: disabled})} onClick={() => inputRef.current.focus()} >
          <Text size={isMobile ? SIZES.TINY : SIZES.SMALL} bold>{!hideLabel ? label : ''}</Text>
        </label>
      }
      <div className={cn(styles.input, {[styles.disabled]: disabled, [styles.error]: required && !valid})}>
        <select data-id={dataId} className={cn(styles.select, { [styles.normal]: size === 'normal', [styles.disabled]: disabled, [styles.error]: required && !valid})}
          ref={inputRef}
          value={value}
          onChange={onChange}
          disabled={disabled}
          required={required}
          id={id}
        >
          {!value &&
            <option value='' disabled data-i18n-key={'LeanSignup_Form_Custom_general_selectOption'}>
              {t(i18nKeyPrefix + 'LeanSignup_Form_Custom_general_selectOption', { defaultValue: 'Select option' })}
            </option>
          }

          {options && options.length && renderOptions()}
        </select>
        <span className={cn(styles.icon, {[styles.disabled]: disabled})}>
          <ChevronDown />
        </span>
      </div>
    </div>
  );
};

export default Select;
