import React, { useState } from 'react';
import DatepickerContext from "../DatePickerContext";
import { useDatepicker, START_DATE } from "@datepicker-react/hooks";
import Month from '../Month';
import styles from './calendar.module.scss';
import cn from "classnames";
import { useTranslation } from 'react-i18next';
import { i18nKeyPrefix } from '../../../utils/constants';

interface Props {
  onChange: (date: Date) => void;
  title?: string;
}

const Calendar: React.FC<Props> = ({
  onChange,
  title
}: Props) => {

  const { t } = useTranslation();
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_Calendar';

  const [state, setState] = useState({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE
  });

  const handleDateChange = (data: any) => {

    if (!data.focusedInput) {
      setState({ ...data, focusedInput: START_DATE });
    } else {
      setState(data);
    }

    onChange(data.startDate);
  }

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths
  } = useDatepicker({
    startDate: state.startDate,
    endDate: state.endDate,
    exactMinBookingDays: true,
    minBookingDays: 1,
    focusedInput: state.focusedInput as any,
    minBookingDate: null,
    numberOfMonths: 1,
    onDatesChange: handleDateChange
  });

  return (
      <DatepickerContext.Provider
        value={{
          focusedDate,
          isDateFocused,
          isDateSelected,
          isDateHovered,
          isDateBlocked,
          isFirstOrLastSelectedDate,
          onDateSelect,
          onDateFocus,
          onDateHover
          }}
        >

        {title &&
        <div className={styles.header}>
          {title}
        </div>
        }

        {activeMonths.map(month => (
          <Month
            goToPreviousMonths={goToPreviousMonths}
            goToNextMonths={goToNextMonths}
            key={`${month.year}-${month.month}`}
            year={month.year}
            month={month.month}
            lastMonth={month.month - 1}
            firstDayOfWeek={firstDayOfWeek}
          />
        ))}

        <div className={styles.types}>
          <div className={cn(styles.type, styles.primary)} data-i18n-key={`${prefix}_selected`}>
            {t(`${prefix}_selected`)}
          </div>
          <div className={styles.type} data-i18n-key={`${prefix}_availableDates`}>
            {t(`${prefix}_availableDates`)}
          </div>
        </div>

      </DatepickerContext.Provider>
  );
};

export default Calendar;
