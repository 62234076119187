import { fromJS } from 'immutable';
import { Reducer } from 'redux';
import { actions } from '../constants/form';

export const initialState = {
  differentAddress: null,
  companyName: '',
  validCompanyName: true,
  managerName: '',
  validManagerName: true,
  firstName: '',
  validFirstName: true,
  lastName: '',
  validLastName: true,
  phone: '',
  validPhone: true,
  email: '',
  validEmail: true,
  confirmationEmail: '',
  validConfirmationEmail: true,
  acceptTerms: false,
  validTerms: true,
  termsConsent: '',
  acceptNewsletter: false,
  validNewsletter: true,
  newsletterConsent: '',
  acceptSmsService: false,
  validSmsService: true,
  smsServiceConsent: '',
  acceptMarketingMessage: false,
  validMarketingMessage: true,
  marketingMessageConsent: '',
  acceptPrivacyAndCookies: false,
  validPrivacyAndCookies: true,
  PrivacyAndCookiesConsent: '',
  jobTitle: '',
  validJobTitle: true,

  addressBillTo: '',
  validAddressBillTo: true,
  zipCodeBillTo: '',
  validZipCodeBillTo: true,
  cityNameBillTo: '',
  validCityNameBillTo: true,
  nameBillTo: '',
  validNameBillTo: true,
  emailBillTo: '',
  validEmailBillTo: true,
  phoneBillTo: '',
  validPhoneBillTo: true,
  addressShipTo: '',
  validAddressShipTo: true,
  zipCodeShipTo: '',
  validZipCodeShipTo: true,
  cityNameShipTo: '',
  validCityNameShipTo: true,
  nameShipTo: '',
  validNameShipTo: true,
  emailShipTo: '',
  validEmailShipTo: true,
  phoneShipTo: '',
  validPhoneShipTo: true,
  addressPayer: '',
  validAddressPayer: true,
  zipCodePayer: '',
  validZipCodePayer: true,
  cityNamePayer: '',
  validCityNamePayer: true,
  namePayer: '',
  validNamePayer: true,
  emailPayer: '',
  validEmailPayer: true,
  phonePayer: '',
  validPhonePayer: true,
  addressSoldTo: '',
  validAddressSoldTo: true,
  zipCodeSoldTo: '',
  validZipCodeSoldTo: true,
  cityNameSoldTo: '',
  validCityNameSoldTo: true,
  nameSoldTo: '',
  validNameSoldTo: true,
  emailSoldTo: '',
  validEmailSoldTo: true,
  phoneSoldTo: '',
  validPhoneSoldTo: true,
  outletName: '',
  validOutletName: true,
  outletZipCode: '',
  validOutletZipCode: true,
  VATnumber: '',
  validVAT: true,
  VATchecked: false,
  companyTypeImage: '',
  validCompanyType: true,
  verificationCode: '',
  validVerificationCode: true,
  saveId: '',
  organization: {},
  attachments: {},
  step: 1,
  dynamicStep: -1,
  sendContactInfo: false,
  error: {},
  responseCode: '',
  responseSignup: '',
  categories: {},
  brands: {},
  products: {},
  infoSent: false,
  validPreviousBusinessOwner: true,
  additionalData: {
    selectedCategories: [],
    selectedProducts: [],
    selectedBrands: [],
  },
  marketData: {},
  dynamicFormValidFields: {},
  review: false,
  edit: false,
};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_DIFFERENT_ADDRESS:
      return {
        ...state,
        differentAddress: action.differentAddress,
      };
    case actions.SET_COMPANY_NAME:
      return {
        ...state,
        companyName: action.companyName,
      };
    case actions.SET_VALID_COMPANY_NAME:
      return {
        ...state,
        validCompanyName: action.validCompanyName,
      };
    case actions.SET_MANAGER_NAME:
      return {
        ...state,
        managerName: action.managerName,
      };
    case actions.SET_VALID_MANAGER_NAME:
      return {
        ...state,
        validManagerName: action.validManagerName,
      };
    case actions.SET_JOB_TITLE:
      return {
        ...state,
        jobTitle: action.jobTitle,
      };
    case actions.SET_VALID_JOB_TITLE:
      return {
        ...state,
        validJobTitle: action.validJobTitle,
      };
    case actions.SET_FIRST_NAME:
      return {
        ...state,
        firstName: action.firstName,
      };
    case actions.SET_VALID_FIRST_NAME:
      return {
        ...state,
        validFirstName: action.validFirstName,
      };
    case actions.SET_LAST_NAME:
      return {
        ...state,
        lastName: action.lastName,
      };
    case actions.SET_VALID_LAST_NAME:
      return {
        ...state,
        validLastName: action.validLastName,
      };
    case actions.SET_PHONE:
      return {
        ...state,
        phone: action.phone,
      };
    case actions.SET_VALID_PHONE:
      return {
        ...state,
        validPhone: action.validPhone,
      };
    case actions.SET_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case actions.SET_VALID_EMAIL:
      return {
        ...state,
        validEmail: action.validEmail,
      };
    case actions.SET_CONFIRMATION_EMAIL:
      return {
        ...state,
        confirmationEmail: action.confirmationEmail,
      };
    case actions.SET_VALID_CONFIRMATION_EMAIL:
      return {
        ...state,
        validConfirmationEmail: action.validConfirmationEmail,
      };
    case actions.SET_ACCEPT_TERMS:
      return {
        ...state,
        acceptTerms: action.acceptTerms,
      };
    case actions.SET_VALID_TERMS:
      return {
        ...state,
        validTerms: action.validTerms,
      };
    case actions.SET_TERMS_CONSENT:
      return {
        ...state,
        termsConsent: action.termsConsent,
      };
    case actions.SET_VALID_NEWSLETTER:
      return {
        ...state,
        validNewsletter: action.validNewsletter,
      };
    case actions.SET_NEWSLETTER_CONSENT:
      return {
        ...state,
        newsletterConsent: action.newsletterConsent,
      };
    case actions.SET_ACCEPT_NEWSLETTER:
      return {
        ...state,
        acceptNewsletter: action.acceptNewsletter,
      };
    case actions.SET_VALID_SMS_SERVICE:
      return {
        ...state,
        validSmsService: action.validSmsService,
      };
    case actions.SET_SMS_SERVICE_CONSENT:
      return {
        ...state,
        smsServiceConsent: action.smsServiceConsent,
      };
    case actions.SET_ACCEPT_SMS_SERVICE:
      return {
        ...state,
        acceptSmsService: action.acceptSmsService,
      };
    case actions.SET_VALID_MARKETING_MESSAGE:
      return {
        ...state,
        validMarketingMessage: action.validMarketingMessage,
      };
    case actions.SET_MARKETING_MESSAGE_CONSENT:
      return {
        ...state,
        marketingMessageConsent: action.marketingMessageConsent,
      };
    case actions.SET_ACCEPT_MARKETING_MESSAGE:
      return {
        ...state,
        acceptMarketingMessage: action.acceptMarketingMessage,
      };
    case actions.SET_VALID_PRIVACY_AND_COOKIES:
      return {
        ...state,
        validPrivacyAndCookies: action.validPrivacyAndCookies,
      };
    case actions.SET_PRIVACY_AND_COOKIES_CONSENT:
      return {
        ...state,
        privacyAndCookiesConsent: action.privacyAndCookiesConsent,
      };
    case actions.SET_ACCEPT_PRIVACY_AND_COOKIES:
      return {
        ...state,
        acceptPrivacyAndCookies: action.acceptPrivacyAndCookies,
      };
    case actions.SET_VAT:
      return {
        ...state,
        VATnumber: action.VATnumber,
      };
    case actions.SET_VALID_VAT:
      return {
        ...state,
        validVAT: action.validVAT,
      };
    case actions.SET_VAT_CHECKED:
      return {
        ...state,
        VATchecked: action.VATchecked,
      };
    case actions.SET_COMPANY_TYPE:
      return {
        ...state,
        companyType: action.companyType,
      };
    case actions.SET_COMPANY_TYPE_IMAGE:
      return {
        ...state,
        companyTypeImage: action.companyTypeImage,
      };
    case actions.SET_VALID_COMPANY_TYPE:
      return {
        ...state,
        validCompanyType: action.validCompanyType,
      };
    case actions.SET_VERIFICATION_CODE:
      return {
        ...state,
        verificationCode: action.verificationCode,
      };
    case actions.SET_VALID_VERIFICATION_CODE:
      return {
        ...state,
        validVerificationCode: action.validVerificationCode,
      };
    case actions.SET_SAVE_ID:
      return {
        ...state,
        saveId: action.saveId,
      };
    case actions.SET_SELECTED_CATEGORIES:
      return {
        ...state,
        additionalData: {
          ...state.additionalData,
          selectedCategories: action.selectedCategories,
        },
      };
    case actions.SET_SELECTED_PRODUCTS:
      return {
        ...state,
        additionalData: {
          ...state.additionalData,
          selectedProducts: action.selectedProducts,
        },
      };
    case actions.SET_SELECTED_BRANDS:
      return {
        ...state,
        additionalData: {
          ...state.additionalData,
          selectedBrands: action.selectedBrands,
        },
      };
    case actions.SET_SELECTED_FIND_US:
      return {
        ...state,
        additionalData: {
          ...state.additionalData,
          findUs: action.selectedFindUs,
        },
      };
    case actions.SET_SELECTED_SEASON:
      return {
        ...state,
        additionalData: {
          ...state.additionalData,
          selectedSeason: action.selectedSeason,
        },
      };
    case actions.SET_SEASONS:
      return {
        ...state,
        seasons: action.seasons,
      };
    case actions.SET_STEP:
      return {
        ...state,
        step: action.step,
      };

    case actions.SET_DYNAMIC_STEP:
      return {
        ...state,
        dynamicStep: action.dynamicStep,
      };

    case actions.API_REQUEST_INIT:
      // state = state.set(action.name,fromJS({isLoading: true}));

      return state;
    case actions.GET_RESPONSE:
      return {
        ...state,
        [action.name]: action.payload,
      };
    case actions.SET_API_ERROR:
      return {
        ...state,
        error: action.apiError.title ? action.apiError : fromJS(action.apiError),
      };
    case actions.GET_ORGANIZATION:
      return {
        ...state.organization,
      };
    case actions.GET_ATTACHMENTS:
      return {
        ...state.attachments,
      };
    case actions.SET_ATTACHMENTS:
      return {
        ...state,
        attachments: action.attachments,
      };
    case actions.CLEAR_ORGANIZATION:
      return {
        ...state,
        organization: {},
      };
    case actions.SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case actions.SET_BRANDS:
      return {
        ...state,
        brands: action.brands,
      };
    case actions.SET_PRODUCTS:
      return {
        ...state,
        products: action.products,
      };
    case actions.SET_REVIEW:
      return {
        ...state,
        review: action.review,
      };
    case actions.SET_EDIT:
      return {
        ...state,
        edit: action.edit,
      };
    case actions.SET_AVAILABLE_DAYS:
      return {
        ...state,
        availableDays: action.availableDays,
      };
    case actions.SET_UPLOADING:
      return {
        ...state,
        uploading: action.uploading,
      };
    case actions.SET_DUNNING_EMAIL:
      return {
        ...state,
        additionalData: {
          ...state.additionalData,
          paymentRemindersEmail: action.paymentRemindersEmail,
        },
      };
    case actions.SET_ELMA_EMAIL:
      return {
        ...state,
        additionalData: {
          ...state.additionalData,
          invoiceEmail: action.elmaEmail,
        },
      };
    case actions.SET_ELMA_INVOICE:
      return {
        ...state,
        additionalData: {
          ...state.additionalData,
          elmaInvoice: action.elmaInvoice,
        },
      };
    case actions.SET_EHF_AVAILABLE:
      return {
        ...state,
        additionalData: {
          ...state.additionalData,
          ehfAvailable: action.ehfAvailable,
        },
      };
    case actions.SET_MAIN_TRADE_CHANNEL:
      return {
        ...state,
        additionalData: {
          ...state.additionalData,
          mainTradeChannel: action.mainTradeChannel,
        },
      };
    case actions.SET_ADDITIONAL_TRADE_CHANNEL:
      return {
        ...state,
        additionalData: {
          ...state.additionalData,
          additionalTradeChannel: action.additionalTradeChannel,
        },
      };
    case actions.SET_ADDITIONAL_DATA:
      return {
        ...state,
        additionalData: action.additionalData,
      };
    case actions.SET_SIGNUP_ADDITIONAL_FORM:
      return {
        ...state,
        marketData: action.formData,
      };
    case actions.SET_DYNAMIC_FORM_FIELD:
      return {
        ...state,
        additionalData: { ...state.additionalData, ...{ [action.fieldName]: action.fieldValue } },
      };
    case actions.SET_VALID_DYNAMIC_FORM_FIELD:
      return {
        ...state,
        dynamicFormValidFields: { ...state.dynamicFormValidFields, ...{ [action.fieldName]: action.validField } },
      };
    case actions.SET_DYNAMIC_FORM_FIELDS:
      return {
        ...state,
        dynamicFormValues: action.dynamicFormValues,
      };
    case actions.SET_INFO_SENT:
      return {
        ...state,
        infoSent: action.infoSent,
      };
    case actions.RESET_STATE: {
      return {
        ...initialState,
        marketData: state.marketData,
      };
    }
    case actions.SET_DYNAMIC_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case actions.SET_VALID_DYNAMIC_VALUE:
      return {
        ...state,
        [action.name]: action.valid,
      };
    default: {
      return state;
    }
  }
};

export { reducer as formReducer };
