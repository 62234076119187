import React, { ReactNode, useState, useEffect, SyntheticEvent } from "react";
import cn from 'classnames';
import styles from './modal.module.scss';
import Overlay from "../Overlay";
import { ReactComponent as CloseIcon } from '../../assets/images/nocheck.svg';
import Text, { ALIGN, SIZES, TEXTCOLOR } from "../Text";
import { app } from "../../utils/constants";
import { getCurrentWidth } from "../../utils/custom-hooks/handleWindowResize";

interface Props {
  active: boolean;
  closeModal: () => void;
  children: ReactNode;
  title?: string;
  large?: boolean;
  description?: string;
}

const Modal: React.FC<Props> = ({
  active,
  closeModal,
  description,
  children,
  large,
  title
}: Props) => {

  const size = getCurrentWidth();

  const [windowSize, setWindowSize] = useState(size);
  useEffect(() => {
    setWindowSize(size);
  });

  const isMobile = windowSize <= app.breakPoint;

  const clickModal = (event: SyntheticEvent) => {
    event.persist();
    if (event.target === event.currentTarget) {
       closeModal();
    }
 }

  return (
    <>
      <div className={cn(styles.modal,
        { [styles.isOpen]: active }
      )} onClick={closeModal ? clickModal : null}>
        <div className={cn(styles.content,
          { [styles.large]: large })}>
          {closeModal &&
            <button className={styles.close} type="button" onClick={() => closeModal()}>
              <CloseIcon />
            </button>
          }
          {title &&
            <Text align={ALIGN.CENTER} size={isMobile ? SIZES.MEDIUM_LARGE : SIZES.LARGE} bold>{title}</Text>
          }

          {description &&
          <div className={styles.description}>
            <Text color={TEXTCOLOR.GREY_DARK_MID} align={ALIGN.CENTER} size={SIZES.TINY}>{description}</Text>
          </div>
          }
          <div className={styles.children}>
            {children}
          </div>
        </div>
      </div>
      <Overlay active={active} action={closeModal} />
    </>
  );
};

export default Modal;
