import React from "react";
import cn from 'classnames';
import styles from './overlay.module.scss';

interface Props {
  active: boolean;
  action: () => void;
  transparent?: boolean;
}

const Overlay: React.FC<Props> = ({
  action,
  active,
  transparent,
}: Props) => {

  return (
    <div
      className={cn(styles.overlay, {
        [styles.open]: active,
        [styles.transparent]: transparent,
      })}
      onClick={action} />
  );
};

export default Overlay;
