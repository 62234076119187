import React, { useState, useLayoutEffect, useEffect } from 'react';
import cn from 'classnames';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { HeadlessImage } from '../../utils/interfaces/headless';
import { ReactComponent as WhiteLogoIcon } from '../../assets/images/logo-small.svg';
import { ReactComponent as BlackLogoIcon } from '../../assets/images/logo-1.svg';
import styles from './header.module.scss';
import { useTranslation } from 'react-i18next';
import Languages from '../Languages';
import { useSelector } from 'react-redux';
import { State } from '../../reducers';
import TagManager from "react-gtm-module";
import { convertLanguageFromUmbraco } from '../../utils/culture';

interface Props {
  navigation: any;
  isMobile: boolean;
  light?: boolean;
  logo?: HeadlessImage;
}

interface ParamTypes {
  lang: any;
}

const Header: React.FC<Props> = ({
  navigation,
  isMobile,
  light = false
}: Props) => {
  const marketData = useSelector((state: State) => state.router.marketData);
  const marketConfig = useSelector((state: State) => state.router.marketConfig);
  const step = useSelector((state: State) => state.form.step);

  const params = useParams<ParamTypes>();
  const { t, i18n } = useTranslation();

  const [visibleMenu, showMenu] = useState(false);

  const [scroll, setScroll] = useState(0);

  const [scrollEl, setScrollEl] = useState(document.getElementById('mainGrid'));
  const [BrandLogo, setBrandLogo] = useState('');
  const location = useLocation();
  const path = location.pathname;
  const isForm = path.includes('signup') || path.includes('contact-me');
  const history = useHistory();

  const availableLanguages = navigation.languageItems.map((val: any) => {
    let culture = val.culture;
    if (val.url.indexOf('en-lt') > 0) {
      culture = 'en-LT';
    }
    
    return {
      name: val.name,
      isoCode: culture
    }
  });

  const setLanguage = (val: any) => {
    const convertedVal = convertLanguageFromUmbraco(val.isoCode);
    const newLang = availableLanguages.find((x: any) => x.isoCode === convertedVal);
    localStorage.setItem('i18nextLng', convertedVal);
    i18n.changeLanguage(convertedVal);
    location.pathname = `/${convertedVal}`;
    if (isForm) {
      history.push(`/${convertedVal}/signup/1`);
    } else {
      history.push(`/${convertedVal}`);
    }
    window.location.reload();
  }

  const getDefaultLanguage = () => {
    const defaultLanguage = {
      name: marketData?.defaultLanguage,
      isoCode: marketData?.defaultLanguage
    }
    const selectedLanguage = {
      name: localStorage.getItem('i18nextLng'),
      isoCode: localStorage.getItem('i18nextLng')
    }

    if (selectedLanguage) {
      return selectedLanguage;
    }

    return defaultLanguage;
  }

  const handleScroll = () => {
    const el = document.getElementById('mainGrid');
    if (el) {
      setScroll(el.scrollTop);
    }
  }

  useLayoutEffect(() => {
    (async () => {
      while (document.getElementById('mainGrid') === null) { await new Promise( resolve =>  requestAnimationFrame(resolve) ) }
      if (document.getElementById('mainGrid')) {
        setScrollEl(document.getElementById('mainGrid'))
      }
    })()
  })

  useEffect(() => {
    (async () => {
      while (document.getElementById('mainGrid') === null) { await new Promise( resolve =>  requestAnimationFrame(resolve) ) }
      const el = document.getElementById('mainGrid');
      if (el) {
        setScrollEl(el)
        el.addEventListener("scroll", handleScroll);
      }
    })()

    return () => {
      if(document.getElementById('mainGrid')) {
        document.getElementById('mainGrid').removeEventListener("scroll", handleScroll)
      }
    }
  }, [scrollEl])

  useEffect(() => {
    fetch('/logo.svg')
      .then(r => r.text())
      .then(text => {
        setBrandLogo(text);
      })
      .catch(console.error.bind(console));
  }, [BrandLogo]);

  const scrollToContent = (id: string) => {
    const el = document.getElementById(id);
    el?.scrollIntoView();
  }

  const getNavItem = (navItem: any, index: number) => {
    let navItemObj: any;

    function clickLink (url) {
      if (url.indexOf('signup') >=0 ) {
        TagManager.dataLayer({
          dataLayer: {
            'event': 'clickSignUp',
            'pageSection': 'header'
          }
        });
      }
      showMenu(false); 
    }

    switch (navItem.link?.url) {
      case '/#faq':
        navItemObj = <a key={index + 1} className={styles.element} onClick={() => { showMenu(false); navItem.link?.url.includes('faq') ? scrollToContent('faq') : '' }}>{navItem.link?.name}</a>
        break;

      case '/#brands':
        navItemObj = <a key={index + 1} className={styles.element} onClick={() => { showMenu(false); navItem.link?.url.includes('brands') ? scrollToContent('brands') : '' }}>{navItem.link?.name}</a>
        break;

      case '/#contact':
        navItemObj = <a key={index + 1} className={styles.element} onClick={() => { showMenu(false); navItem.link?.url.includes('contact') ? scrollToContent('contact') : '' }}>{navItem.link?.name}</a>
        break;

      case '/#footer':
        navItemObj = <a key={index + 1} className={styles.element} onClick={() => { showMenu(false); navItem.link?.url.includes('footer') ? scrollToContent('footer') : '' }}>{navItem.link?.name}</a>
        break;

      case '/#custom1':
        navItemObj = <a key={index + 1} className={styles.element} onClick={() => { showMenu(false); navItem.link?.url.includes('custom1') ? scrollToContent('custom1') : '' }}>{navItem.link?.name}</a>
        break;

      case '/#custom2':
        navItemObj = <a key={index + 1} className={styles.element} onClick={() => { showMenu(false); navItem.link?.url.includes('custom2') ? scrollToContent('custom2') : '' }}>{navItem.link?.name}</a>
        break;

      case '/#custom3':
        navItemObj = <a key={index + 1} className={styles.element} onClick={() => { showMenu(false); navItem.link?.url.includes('custom3') ? scrollToContent('custom3') : '' }}>{navItem.link?.name}</a>
        break;
  
      default:
        navItemObj = <Link key={index + 1} className={styles.element} to={navItem.link.url} onClick={() => { clickLink(navItem.link.url); document.getElementById('mainGrid') ? document.getElementById('mainGrid').scrollTop = 0 : null }}>{navItem.link.name}</Link>
        break;
    }
    return navItemObj;
  }

  const getNavItems = () => {
    const nav: any[] = navigation.mainNavigationItems;
    const items: any[] = [];

    !isMobile && isForm ? items.push(<Link key={0} className={styles.logo} to="#" onClick={() => { false }}><BlackLogoIcon/></Link>) : null;
    !isMobile && !isForm ? items.push(<Link key={0} className={styles.logo} to={`/${params.lang}/`} onClick={() => { document.getElementById('mainGrid') ? document.getElementById('mainGrid').scrollTop = 0 : false }}><div dangerouslySetInnerHTML={{__html: BrandLogo}} ></div></Link>) : null;
    isMobile ? items.push(<a key={0} className={cn([styles.element, styles.close])} onClick={() => { showMenu(false) }}>&times;</a>) : null;

    if (!marketConfig?.formLangSelect || !isForm) {
      for (let i = 0; i < nav.length; i++) {
        const navItem = nav[i];
        items.push(getNavItem(navItem, i));
      }
    }

    return <div className={cn(styles.menu, {[styles.spaceBetween]: marketConfig?.formLangSelect})}>
      {items}
      {marketConfig?.languageSelector && availableLanguages.length > 1 && step === 1 && !isMobile &&
        <div className={cn([styles.dropdown, styles.element])}>
          <div className={cn(styles.languages)}>
            <Languages
              hasFlag={true}
              isAbsolute={false}
              handleLanguageSelection={setLanguage}
              isLogin
              defaultLanguage={getDefaultLanguage()}
              languages={availableLanguages}
            />
          </div>
        </div>
      }
    </div>
  }

  return (
    <div className={styles.headerWrapper}>
      {!isForm && !isMobile &&
        <div className={cn(styles.header, 'header')}>{getNavItems()}</div>
      }
      {isForm && marketConfig?.formLangSelect && step === 1 && !isMobile &&
        <div className={cn(styles.header, 'header')}>{getNavItems()}</div>
      }
      {!isForm && isMobile && 
        <Link key={0} className={cn([styles.logo, styles.isMobile, styles.scrolled, styles.fade])} to="#" onClick={() => { showMenu(true) }}>=</Link>
      }
      {!isForm && isMobile && scroll < 50 &&
        <React.Fragment>
          {marketConfig?.languageSelector && availableLanguages.length > 1 &&
            <div className={cn([styles.languages, styles.isMobile])}>
              <Languages
                hasFlag={true}
                isAbsolute={false}
                handleLanguageSelection={setLanguage}
                isLogin
                defaultLanguage={getDefaultLanguage()}
                languages={availableLanguages}
              />
            </div>
          }
        </React.Fragment>
      }
      {!isForm && isMobile && visibleMenu &&
        <div className={cn([styles.header, styles.isMobile], 'header')}>{getNavItems()}</div>
      }
      {isForm && isMobile && marketConfig?.hideFormNav &&
        <Link key={0} className={cn([styles.logo, styles.isMobile, styles.isForm])} to={`/${params.lang}/`}>{marketConfig.showOwnLogo ? <div dangerouslySetInnerHTML={{__html: BrandLogo}} ></div> : light ? <WhiteLogoIcon/> : <BlackLogoIcon/>}</Link>
      }
      {isForm && isMobile && marketConfig?.languageSelector && availableLanguages.length > 1 && step === 1 &&
        <div className={cn([styles.dropdown, styles.element])}>
          <div className={cn([styles.languages, styles.isFormMobile])}>
            <Languages
              hasFlag={true}
              isAbsolute={false}
              handleLanguageSelection={setLanguage}
              isLogin
              defaultLanguage={getDefaultLanguage()}
              languages={availableLanguages}
            />
          </div>
        </div>
      }
      {isForm && isMobile && !marketConfig?.hideFormNav &&
        <Link key={0} className={cn([styles.logo, styles.isMobile, styles.isForm])} to="#" onClick={() => { document.getElementById('mainGrid') ? document.getElementById('mainGrid').scrollTop = 0 : false }}>{marketConfig.showOwnLogo ? <div dangerouslySetInnerHTML={{__html: BrandLogo}} ></div> : light ? <WhiteLogoIcon/> : <BlackLogoIcon/>}</Link>
      }
      {isForm && isMobile && !marketConfig?.hideFormNav && visibleMenu &&
        <div className={cn([styles.header, styles.isMobile], 'header')}>{getNavItems()}</div>
      }
      {isForm && !isMobile && !marketConfig?.hideFormNav &&
        <div className={cn(styles.header, 'header')}>{getNavItems()}</div>
      }
      {isForm && !isMobile && marketConfig?.hideFormNav &&
        <div className={cn(styles.header, 'header')}>
          <Link key={0} className={cn([styles.logo, styles.isMobile, styles.isForm])} to={`/${params.lang}/`}>{marketConfig.showOwnLogo ? <div dangerouslySetInnerHTML={{__html: BrandLogo}} ></div> : light ? <WhiteLogoIcon/> : <BlackLogoIcon/>}</Link>
        </div>
      }
    </div>
  );
}

export default Header;
