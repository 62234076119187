import React, { useState, SyntheticEvent, Dispatch, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './companyInfo.module.scss';
import ScreenTitle from '../../ScreenTitle';
import Input from '../../../Input';
import immutableToJS from 'with-immutable-props-to-js';
import cn from 'classnames';
import FormHeaderComponent from '../../FormHeader';
import SidebarWrapper from '../../Sidebar/SidebarWrapper';
import FormFooterComponent from '../../FormFooter';
import { useDispatch, useSelector, connect } from 'react-redux';
import { 
  setStep,
  setVAT,
  setValidVAT,
  setVATchecked,
  clearOrganization,
  startRequest,
  setSaveId
} from '../../../../actions/form';
import { app, i18nKeyPrefix } from '../../../../utils/constants';
import { getCurrentWidth } from '../../../../utils/custom-hooks/handleWindowResize';
import { apiEndpoints } from '../../../../utils/api/endpoints';
import { State } from '../../../../reducers';
import { RequestData } from '../../../../utils/interfaces/request';
import { Action } from 'redux';
import { getGlobal, isGlobalLoading } from '../../../../utils/selectors/headless/selectors';
import { getLanguage } from '../../../../utils/selectors/language/selectors';
import { Stepper } from '@carlsberggroup/malty.molecules.stepper';
import { scrollToError, validateCompanyInfo } from '../../../../utils/validation';

interface Props {
  organizationRequest?: (data: RequestData) => void;
  signUpRequest?: (data: RequestData) => void;
}

interface ParamTypes {
  lang: any;
}

const CompanyInfoComponent: React.FC<Props> = ({
  organizationRequest,
  signUpRequest
}: Props) => {
  const marketData = useSelector((state: State) => state.router.marketData);
  const marketConfig = useSelector((state: State) => state.router.marketConfig);
  const vatValidation = marketConfig?.vatRegex;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const size = getCurrentWidth();

  const [windowSize, setWindowSize] = useState(size);
  const [showLoader, setLoader] = useState(false);
  const organization = useSelector((state: State) => state.form.organization);
  const organizationRef = useRef(organization);
        organizationRef.current = organization;
  const VATnumber = useSelector((state: State) => state.form.VATnumber);
  const validVAT = useSelector((state: State) => state.form.validVAT);
  const VATchecked = useSelector((state: State) => state.form.VATchecked);
  const step = useSelector((state: State) => state.form.step);
  const params = useParams<ParamTypes>();

  const saveId = useSelector((state: State) => state.form.saveId?.saveId);
  const saveIdObject = useSelector((state: State) => state.form.saveId);
  const isMobile = windowSize <= app.breakPoint;
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_VAT';
  const form = useSelector((state: State) => state.form);
  const review = useSelector((state: State) => state.form.review);
  const query = new URLSearchParams(location.search);

  const changeStep = (step: number) => {
    history.push(`/${params.lang}/signup/${step}`);
    dispatch(setStep(step));
  }

  useEffect(() => {
    dispatch(setStep(2));
  }, [])

  useEffect(() => {
    dispatch(setSaveId(saveIdObject));
    window.localStorage.setItem('formData', JSON.stringify(form));
  }, [saveIdObject]);

  useEffect(() => {
    scrollToError();
  }, [validVAT]);

  useEffect(() => {
    if (query) {
      const errorParam = query.get('error')
      if (errorParam && errorParam === 'validation') {
        scrollToError();
      }
    }
  }, [query]);

  const loadAddressData = () => {
    dispatch(setValidVAT(true));
    signUpRequest({
      type: 'PUT',
      endpoint: `${apiEndpoints.signupData}/${saveId}?countryCode=${marketData.marketId.toUpperCase()}`,
      body: {
        basicData: {
          companyId: VATnumber.replace('-', '')
        }
      }
    });
  }

  const validateVAT = (e: SyntheticEvent) => {
    e.persist();
    const formatValue = (e.target as HTMLInputElement).value;
    dispatch(setVAT(formatValue));
    const regex = new RegExp(vatValidation);
    const valid = formatValue.match(regex);
    dispatch(setValidVAT(valid ? true : false));
  }

  const validateStep = () => {
    document.getElementById('validation2').classList.remove(`${styles.black}`);
    setLoader(true);
    const valid = validateCompanyInfo(dispatch, marketConfig, form);
    if (valid) {
      dispatch(setVATchecked(true));
      organizationRequest({
        type: 'GET',
        noAuth: true,
        endpoint: `${apiEndpoints.organization}?countryCode=${marketData.marketId.toUpperCase()}&registrationNumber=${VATnumber.replace('-', '')}`,
      });
    }
    if (!valid) {
      dispatch(clearOrganization());
      dispatch(setVATchecked(false));
      scrollToError();
    }
    if(saveId) { loadAddressData() }
    if(VATchecked) {
      setLoader(false);
      //loading animation
      document.getElementById('validation1').classList.remove(`${styles.black}`);
      document.getElementById('validation2').classList.remove(`${styles.black}`);
      document.getElementById('validation3').classList.remove(`${styles.black}`);

      window.localStorage.setItem('formData', JSON.stringify(form));
      if (review) {
        history.push(`/${params.lang}/signup/6`);
        dispatch(setStep(6));
      } else {
        history.push(`/${params.lang}/signup/3`);
        dispatch(setStep(3));
      }
    } else {
      setTimeout(() => {
        document.getElementById('validation1').classList.add(`${styles.black}`);
      }, 250);
      if (VATnumber && validVAT) {
        setTimeout(() => {
          document.getElementById('validation1').classList.remove(`${styles.black}`);
          document.getElementById('validation2').classList.add(`${styles.black}`);
        }, 500);
        setTimeout(() => {
          document.getElementById('validation2').classList.remove(`${styles.black}`);
          document.getElementById('validation3').classList.add(`${styles.black}`);
          setTimeout(() => {
            setLoader(false);
            window.localStorage.setItem('formData', JSON.stringify(form));
            if(saveId && (organizationRef.current || marketConfig?.noVatCustCreation)) {
              if (review) {
                history.push(`/${params.lang}/signup/6`);
                dispatch(setStep(6));
              } else {
                history.push(`/${params.lang}/signup/3`);
                dispatch(setStep(3));
              }
            } else {
              setLoader(false);
              dispatch(setValidVAT(false));
              setVATchecked(false);
              dispatch(setVATchecked(false));
              document.getElementById('validation3').classList.remove(`${styles.black}`);
            }
          }, 500);
        }, 1000);
        return;
      } else {
        setTimeout(() => {
          document.getElementById('validation1').classList.remove(`${styles.black}`);
          document.getElementById('validation2').classList.add(`${styles.black}`);
          dispatch(setValidVAT(false));
          setLoader(false);
          return;
        }, 1000);
      }
    }
  }


  return (
    <React.Fragment>
      <div className={styles.formContainer}>
        {step!== 7 && <FormHeaderComponent changeStep={changeStep} />}
          <div className={styles.container}>
            <div className={styles.innerContainer}>
              <div className={cn(styles.screenWrapper)}>
              <div className={cn(styles.wrapper)} data-i18n-key-title={`${prefix}_title`}>
                <div className={styles.stepperContainer}>
                  <Stepper steps={marketConfig.steps} currentStep={2}/>
                </div>
                <ScreenTitle title={t(`${prefix}_title`)}></ScreenTitle>
                <div className={styles.form} data-i18n-key={`${prefix}`} data-i18n-key-error={`${prefix}_error`}>
                  <Input
                    label={t(`${prefix}`)}
                    placeholder={t(`${prefix}_label`)}
                    type="text"
                    showValidColor={true}
                    onInput={(val: any) => validateVAT(val)}
                    onChange={(val: any) => validateVAT(val)}
                    onBlur={(val: any) => validateVAT(val)}
                    required
                    valid={validVAT}
                    value={VATnumber}
                    errorMessage={t(`${prefix}_error`)}
                    disabled={step === 6 || organization?.registrationNumber || VATchecked}
                    dataId="inputVAT"
                  />
                </div>

                {step !== 7 &&
                  <React.Fragment>
                    <div className={styles.validationSteps}>
                      <div id="validation1" className={styles.validationStep} data-i18n-key={`${prefix}_step1`}>{t(`${prefix}_step1`)}</div>
                      <div id="validation2" className={styles.validationStep} data-i18n-key={`${prefix}_step2`}>{t(`${prefix}_step2`)}</div>
                      <div id="validation3" className={styles.validationStep} data-i18n-key={`${prefix}_step3`}>{t(`${prefix}_step3`)}</div>
                    </div>
                    { marketConfig?.noVatContact === true &&
                    <div className={styles.info}>
                      <div className={styles.contactMe} onClick={() => history.push(`/${params.lang}/contact-me`)} data-i18n-key={`${prefix}_unkownVAT`}>{t(`${prefix}_unkownVAT`)}</div>
                    </div>
                    }
                  </React.Fragment>
                }

            </div>
              </div>
            {!isMobile && step !== 7 &&
              <SidebarWrapper />
            }
            </div>
          </div>
      </div>
      {step !== 7 && <FormFooterComponent validateStep={validateStep} showLoader={showLoader} disabled={!validVAT}/>}
    </React.Fragment>
  );
}

const mapStateToProps = (state: State) => {
  return {
    isLoading: getGlobal(state) && isGlobalLoading(state),
    language: getLanguage(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    organizationRequest: (data: RequestData) => {
      dispatch(startRequest(data, 'organization'));
    },
    signUpRequest: (data: RequestData) => {
      dispatch(startRequest(data, 'saveId'));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(CompanyInfoComponent));
