import { RequestData } from '../../utils/interfaces/request';
import { getConfig, getConfigUrl, configName, getAWSConfig } from '../config/getConfig';

const makeRequest = async (data: RequestData) => {
  return (async function () {
    const marketData = await getConfig(`${getConfigUrl(configName.market)}`, configName.market);
    const apiConfig = await getAWSConfig(true);
    const api = apiConfig.apiBaseUrl + '/lean-signup';

    let urlString = `${api}${data?.endpoint}`;
    if (data?.endpoint?.includes('http://') || data?.endpoint?.includes('https://')) {
      urlString = data?.endpoint;
    }

    const url = new URL(urlString);

    const language = localStorage.getItem('i18nextLng') || marketData?.defaultLanguage;

    const options: any = {
      method: data.type,
      body: data.body,
      headers: {
        'Accept-Language': language,
        'Content-Type': 'application/json',
      },
    };

    if (data.params) {
      Object.keys(data.params).forEach((key) => url.searchParams.append(key, data.params[key]));
    }

    if ((data.type === 'POST' || data.type === 'PUT') && data.body) {
      options.body = JSON.stringify(data.body || {});
    }
    if (data.type === 'POST' && data.mode === 'file') {
      options.body = data.body;
      options.headers = {};
    }

    //@ts-ignore
    const response = await fetch(url, options);

    return await response.text().then((text: string) => {
      try {
        const json = JSON.parse(text);
        if (!response.ok) {
          const error = Object.assign({}, json, {
            status: response.status,
            statusText: response.statusText,
          });
          return Promise.reject(error);
        }
        return json;
      } catch (err) {
        if (!!text) {
          return null;
        }
      }
    });
  })();
};

const request = (data: RequestData) => {
  return makeRequest(data);
};

export { request };
