import React, { ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';

interface PrivateRouteProps {
  children: ReactNode;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  ...props
}: PrivateRouteProps) => {

  return (
    <Route exact
      {...props}
      render={({ location }) =>
        (
          children
        )
      }
    />
  );
};

export default PrivateRoute;