import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './formHeader.module.scss';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { State } from '../../../reducers';
import BackButton from '../../BackButton';
import { app, i18nKeyPrefix } from '../../../utils/constants';
import { getCurrentWidth } from '../../../utils/custom-hooks/handleWindowResize';
import InfoModalComponent from '../InfoModal';
import { ReactComponent as FeedbackIcon } from '../../../assets/images/feedback.svg';

interface Props {
  changeStep?: (val?: any) => void;
  removeHeaderInMobile?: boolean;
}

interface ParamTypes {
  lang: any;
}

const FormHeaderComponent: React.FC<Props> = ({
  changeStep,
  removeHeaderInMobile
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const size = getCurrentWidth();
  const [windowSize, setWindowSize] = useState(size);
  const marketConfig: any = useSelector((state: State) => state.router.marketConfig);

  const [infoOpen, setInfoOpen] = useState(false);

  const params = useParams<ParamTypes>();
  const path = location.pathname.replace(`/${params.lang}`, "");

  useEffect(() => {
    setWindowSize(size);
  });

  const isMobile = windowSize <= app.breakPoint;

  const step = useSelector((state: State) => state.form.step);
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_Navigation';
  
  return (
    <div className={cn(styles.formHeader, {[styles.hideMobile]: removeHeaderInMobile})}>
      <div className={cn(styles.buttonWrapper, {[styles.hideBack]: (!isMobile && (step > 5 && step <= 7)) || isMobile && step === 8})}>
        { ((marketConfig?.landingPage === false && step > 1) || marketConfig?.landingPage === true) &&
          <BackButton action={() =>  step === 8 ? changeStep(6) : step === 7 ? changeStep(5) : path === '/contact-me' ? history.push(`/${params.lang}/signup/2`) : step === 'file-upload' || step === 'additional-data' ? changeStep() : (marketConfig.ukSteps && step === 4) ? changeStep(1) : step > 1 ? changeStep(step - 1) : history.push(`/${params.lang}`)}/>
        }
      </div>
      { marketConfig?.kundoChat === true &&
        <a onClick={() => document.getElementById('start-kundo-chat')?.click()} className={styles.chatButton} data-i18n-key={`${prefix}_needHelp`}>
          {t(`${prefix}_needHelp`)}
          <FeedbackIcon />
        </a>
      }
      { marketConfig?.zenDeskChat === true &&
      //@ts-ignore
        <a onClick={() => ((document.getElementById("launcher"))?.contentWindow.document.getElementsByClassName("wrapper-2L6Ge")[0])?.click()} className={styles.chatButton} data-i18n-key={`${prefix}_needHelp`}>
          {t(`${prefix}_needHelp`)}
          <FeedbackIcon />
        </a>
      }
      { (marketConfig?.kundoChat !== true && marketConfig?.zenDeskChat !== true) &&
        <span data-i18n-key={`${prefix}_goBack`}>
          <InfoModalComponent action={setInfoOpen} value={infoOpen} backButtonText={t(`${prefix}_goBack`)}/>
        </span>
      }
    </div>
  );
}

export default FormHeaderComponent;