import React, { Dispatch, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Action } from "redux";
import PrivacyComponent from '../components/PrivacyPolicy';
import { getHeadlessPrivacyPolicyContent, getGlobalContent } from "../utils/selectors/headless/selectors";
import immutableToJS from 'with-immutable-props-to-js';
import { startPrivacyRequest } from "../actions/api";
import { State } from '../reducers';
import { useParams, useHistory } from "react-router-dom";

interface Props {
  global?: any;
  plain: boolean;
  privacyContent: any;
  privacyRequest: any;
}

interface ParamTypes {
  lang: any;
}

const PrivacyContainer: React.FC<Props> = ({
  global,
  plain = false,
  privacyContent,
  privacyRequest,
}: Props) => {
  const marketData = useSelector((state: State) => state.router.marketData);
  const params = useParams<ParamTypes>();
  const fallbackLanguage: string = marketData?.defaultLanguage;
  const history = useHistory();
  const loc = location.pathname;

  useEffect(() => {
    if (!privacyContent && global) {
      const pathArray: any[] = global.privacyPolicy.view.url.split('/');
      const index: number = pathArray[pathArray.length - 1] === "" ? pathArray?.length - 2 : pathArray?.length - 1;
      const secondLevelLocation: string = pathArray[index];
      privacyRequest(`/${params.lang}/${secondLevelLocation}`);
    }
  }, [global, privacyRequest, params.lang, privacyContent]);

  useEffect(() => {
    if (loc === `/privacy-policy`) {
      const lang = localStorage.getItem('i18nextLng');
      if (lang) {
        history.push(`/${lang}/privacy-policy`);
      } else {
        localStorage.setItem('i18nextLng', fallbackLanguage.toLowerCase());
        const language: string = localStorage.getItem('i18nextLng');
        history.push(`/${language}/privacy-policy`);
      }
    }
  }, [fallbackLanguage, history, loc]);

  if (privacyContent) {
    return (
      <PrivacyComponent
        plain={plain}
        privacyContent={privacyContent}
      />
    )
  } else {
    return <span>...</span>
  }
};

const mapStateToProps = (state: State) => {
  return {
    global: getGlobalContent(state),
    privacyContent: getHeadlessPrivacyPolicyContent(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    privacyRequest: (url: string) => {
      dispatch(startPrivacyRequest("PRIVACYPOLICY", url));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(PrivacyContainer));
