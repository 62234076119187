import React, { useEffect } from 'react';
import styles from './openingHours.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../reducers';
import { setAvailableDays } from '../../actions/form';
import { i18nKeyPrefix } from '../../utils/constants';
import DayHours from './dayHours';

interface Props {
  hours: any;
  days: any;
  action: any;
  field: any;
  hoursTo?: any;
}

const OpeningHours: React.FC<Props> = ({
  hours,
  days,
  action,
  field,
  hoursTo
}: Props) => {
  const selectedTimes = useSelector((state: State) => state.form.additionalData[field.name]);
  const availableDays = useSelector((state: State) => state.form.availableDays);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAvailableDays(days));
  }, []);

  const daySelection = (day) => {
    if (selectedTimes) {
      const selectedDay = selectedTimes.find((x: any) => x.label === day.label);
      return selectedDay;
    }

    return null;
  }

  const setDayTimeFrom = (day, time, timeType) => {
    const otherItems = selectedTimes && selectedTimes.filter((x: any) => x.label !== day.label);
    const currentItem = selectedTimes && selectedTimes.filter((x: any) => x.label === day.label)[0];
    if (currentItem) {
      if (timeType === 'timeFrom') {
        currentItem.from = time;
      } else if (timeType === 'timeTo') {
        currentItem.to = time;
      } else if (timeType === 'closed') {
        currentItem.isClosed = time;
        currentItem.to = null;
        currentItem.from = null;
      }

      action(otherItems.concat([currentItem]));

    } else {
      const newItem = {
        label: day.label,
        day: day.value,
        dayTranslated: t(i18nKeyPrefix + day.label),
        from: timeType === 'timeFrom' ? time : null,
        to: timeType === 'timeTo' ? time : null,
        isClosed: timeType === 'closed' ? time : false
      };

      action(otherItems ? otherItems.concat([newItem]): [newItem]);
    }
  }

  const applyToOtherDays = (day, selectedAppliedDays) => {
    let daysToApply = [];
    const otherItems = selectedTimes && selectedTimes.filter((elem) => !selectedAppliedDays.find(({ label }) => elem.label === label));

    selectedAppliedDays.map((selectedDay: any, i: any) => {
      const currentItem = selectedTimes && selectedTimes.filter((x: any) => x.label === selectedDay.label)[0];
      if (currentItem) {
        currentItem.from = day.from;
        currentItem.to = day.to;
        currentItem.isClosed = day.isClosed;

        daysToApply.push(currentItem);
      } else {
        const newItem = {
          label: selectedDay.label,
          day: selectedDay.value,
          dayTranslated: t(i18nKeyPrefix + selectedDay.label),
          from: day.from,
          to: day.to,
          isClosed: day.isClosed
        };

        daysToApply.push(newItem);
      }
    });

    action(otherItems.concat(daysToApply));
  }

  return (
    <div className={cn(styles.wrapper)}>
      <div>
        {availableDays && availableDays.map((day: any, i: any) => {
          const daySelected = daySelection(day);
          return (
            <div key={`days-${i}`}>
              <DayHours
                hours={hours}
                hoursTo={hoursTo}
                daySelected={daySelected}
                days={days}
                currentDay={day}
                currentDayIndex={i}
                field={field}
                setDayTimeFrom={setDayTimeFrom}
                applyToOtherDays={applyToOtherDays}
              />
            </div>
          )}
        )}
      </div>
    </div>
  );
}

export default OpeningHours;