import { Reducer } from 'redux';
import { actions } from '../constants/router';

export const initialState = {};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CONFIG_DATA:
      return {
        ...state,
        configData: action.configData
      }
    case actions.SET_MARKET_DATA:
      return {
        ...state,
        marketData: action.marketData
      }
    case actions.SET_MARKET_CONFIG:
      return {
        ...state,
        marketConfig: action.marketConfig
      }
    default: {
      return state;
    }
  }
};

export { reducer as routerReducer };
