import { actions } from '../constants/form';
import { RequestData } from '../utils/interfaces/request';

export const setDifferentAddress = (differentAddress: boolean) => ({
  differentAddress,
  type: actions.SET_DIFFERENT_ADDRESS,
});

export const setCompanyName = (companyName: string) => ({
  companyName,
  type: actions.SET_COMPANY_NAME,
});

export const setValidCompanyName = (validCompanyName: boolean) => ({
  validCompanyName,
  type: actions.SET_VALID_COMPANY_NAME,
});

export const setManagerName = (managerName: string) => ({
  managerName,
  type: actions.SET_MANAGER_NAME,
});

export const setValidManagerName = (validManagerName: boolean) => ({
  validManagerName,
  type: actions.SET_VALID_MANAGER_NAME,
});

export const setJobTitle = (jobTitle: string) => ({
  jobTitle,
  type: actions.SET_JOB_TITLE,
});

export const setValidJobTitle = (validJobTitle: boolean) => ({
  validJobTitle,
  type: actions.SET_VALID_JOB_TITLE,
});

export const setFirstName = (firstName: string) => ({
  firstName,
  type: actions.SET_FIRST_NAME,
});

export const setValidFirstName = (validFirstName: boolean) => ({
  validFirstName,
  type: actions.SET_VALID_FIRST_NAME,
});

export const setLastName = (lastName: string) => ({
  lastName,
  type: actions.SET_LAST_NAME,
});

export const setValidLastName = (validLastName: boolean) => ({
  validLastName,
  type: actions.SET_VALID_LAST_NAME,
});

export const setPhone = (phone: string) => ({
  phone,
  type: actions.SET_PHONE,
});

export const setValidPhone = (validPhone: boolean) => ({
  validPhone,
  type: actions.SET_VALID_PHONE,
});

export const setEmail = (email: string) => ({
  email,
  type: actions.SET_EMAIL,
});

export const setValidEmail = (validEmail: boolean) => ({
  validEmail,
  type: actions.SET_VALID_EMAIL,
});

export const setConfirmationEmail = (confirmationEmail: string) => ({
  confirmationEmail,
  type: actions.SET_CONFIRMATION_EMAIL,
});

export const setValidConfirmationEmail = (validConfirmationEmail: boolean) => ({
  validConfirmationEmail,
  type: actions.SET_VALID_CONFIRMATION_EMAIL,
});

export const setAcceptTerms = (acceptTerms: boolean) => ({
  acceptTerms,
  type: actions.SET_ACCEPT_TERMS,
});

export const setValidTerms = (validTerms: boolean) => ({
  validTerms,
  type: actions.SET_VALID_TERMS,
});

export const setTermsConsent = (termsConsent: string) => ({
  termsConsent,
  type: actions.SET_TERMS_CONSENT,
});

export const setAcceptNewsletter = (acceptNewsletter: string) => ({
  acceptNewsletter,
  type: actions.SET_ACCEPT_NEWSLETTER,
});

export const setValidNewsletter = (validNewsletter: boolean) => ({
  validNewsletter,
  type: actions.SET_VALID_NEWSLETTER,
});

export const setNewsletterConsent = (newsletterConsent: string) => ({
  newsletterConsent,
  type: actions.SET_NEWSLETTER_CONSENT,
});

export const setAcceptSmsService = (acceptSmsService: string) => ({
  acceptSmsService,
  type: actions.SET_ACCEPT_SMS_SERVICE,
});

export const setValidSmsService = (validSmsService: boolean) => ({
  validSmsService,
  type: actions.SET_VALID_SMS_SERVICE,
});

export const setSmsServiceConsent = (smsServiceConsent: string) => ({
  smsServiceConsent,
  type: actions.SET_SMS_SERVICE_CONSENT,
});

export const setAcceptMarketingMessage = (acceptMarketingMessage: string) => ({
  acceptMarketingMessage,
  type: actions.SET_ACCEPT_MARKETING_MESSAGE,
});

export const setValidMarketingMessage = (validMarketingMessage: boolean) => ({
  validMarketingMessage,
  type: actions.SET_VALID_MARKETING_MESSAGE,
});

export const setMarketingMessageConsent = (marketingMessageConsent: string) => ({
  marketingMessageConsent,
  type: actions.SET_MARKETING_MESSAGE_CONSENT,
});

export const setAcceptPrivacyAndCookies = (acceptPrivacyAndCookies: string) => ({
  acceptPrivacyAndCookies,
  type: actions.SET_ACCEPT_PRIVACY_AND_COOKIES,
});

export const setValidPrivacyAndCookies = (validPrivacyAndCookies: boolean) => ({
  validPrivacyAndCookies,
  type: actions.SET_VALID_PRIVACY_AND_COOKIES,
});

export const setPrivacyAndCookiesConsent = (privacyAndCookiesConsent: string) => ({
  privacyAndCookiesConsent,
  type: actions.SET_PRIVACY_AND_COOKIES_CONSENT,
});

export const setVAT = (VATnumber: string) => ({
  VATnumber,
  type: actions.SET_VAT,
});

export const setValidVAT = (validVAT: boolean) => ({
  validVAT,
  type: actions.SET_VALID_VAT,
});

export const setCompanyType = (companyType: string) => ({
  companyType,
  type: actions.SET_COMPANY_TYPE,
});

export const setCompanyTypeImage = (companyTypeImage: string) => ({
  companyTypeImage,
  type: actions.SET_COMPANY_TYPE_IMAGE,
});

export const setValidCompanyType = (validCompanyType: boolean) => ({
  validCompanyType,
  type: actions.SET_VALID_COMPANY_TYPE,
});

export const setVerificationCode = (verificationCode: string) => ({
  verificationCode,
  type: actions.SET_VERIFICATION_CODE,
});

export const setValidVerificationCode = (validVerificationCode: boolean) => ({
  validVerificationCode,
  type: actions.SET_VALID_VERIFICATION_CODE,
});

export const setSaveId = (saveId: object) => ({
  saveId,
  type: actions.SET_SAVE_ID,
});

export const setVATchecked = (VATchecked: boolean) => ({
  VATchecked,
  type: actions.SET_VAT_CHECKED,
});

export const setStep = (step: any) => ({
  step,
  type: actions.SET_STEP,
});

export const setDynamicStep = (dynamicStep: any) => ({
  dynamicStep,
  type: actions.SET_DYNAMIC_STEP,
});

export const startRequest = (data: RequestData, name: string) => ({
  data,
  name,
  type: actions.API_REQUEST_INIT,
});

export const getResponse = (name: string, payload: object) => ({
  name,
  payload,
  type: actions.GET_RESPONSE,
});

export const getOrganization = () => ({
  type: actions.GET_ORGANIZATION,
});

export const getAttachments = () => ({
  type: actions.GET_ATTACHMENTS,
});

export const clearOrganization = () => ({
  type: actions.CLEAR_ORGANIZATION,
});

export const setApiError = (apiError: any, name) => ({
  apiError,
  type: actions.SET_API_ERROR,
});

export const setCategories = (categories: object) => ({
  categories,
  type: actions.SET_CATEGORIES,
});

export const setBrands = (brands: object) => ({
  brands,
  type: actions.SET_BRANDS,
});

export const setProducts = (products: object) => ({
  products,
  type: actions.SET_PRODUCTS,
});

export const setSelectedCategories = (selectedCategories: any) => ({
  selectedCategories,
  type: actions.SET_SELECTED_CATEGORIES,
});

export const setSelectedProducts = (selectedProducts: any) => ({
  selectedProducts,
  type: actions.SET_SELECTED_PRODUCTS,
});

export const setSelectedBrands = (selectedBrands: any) => ({
  selectedBrands,
  type: actions.SET_SELECTED_BRANDS,
});

export const setSelectedFindUs = (selectedFindUs: any) => ({
  selectedFindUs,
  type: actions.SET_SELECTED_FIND_US,
});

export const setSelectedSeason = (selectedSeason: string) => ({
  selectedSeason,
  type: actions.SET_SELECTED_SEASON,
});

export const setSeasons = (seasons: object) => ({
  seasons,
  type: actions.SET_SEASONS,
});

export const setAvailableDays = (availableDays: Object) => ({
  availableDays,
  type: actions.SET_AVAILABLE_DAYS,
});

export const setAdditionalData = (additionalData: Object) => ({
  additionalData,
  type: actions.SET_ADDITIONAL_DATA,
});

export const setDunningEmail = (paymentRemindersEmail: string) => ({
  paymentRemindersEmail,
  type: actions.SET_DUNNING_EMAIL,
});

export const setElmaEmail = (elmaEmail: string) => ({
  elmaEmail,
  type: actions.SET_ELMA_EMAIL,
});

export const setElmaInvoice = (elmaInvoice: boolean) => ({
  elmaInvoice,
  type: actions.SET_ELMA_INVOICE,
});

export const setSignupAdditionalForm = (formData: any) => ({
  type: actions.SET_SIGNUP_ADDITIONAL_FORM,
  formData,
});

export const setDynamicFormField = (fieldName: string, fieldValue: any) => ({
  type: actions.SET_DYNAMIC_FORM_FIELD,
  fieldName,
  fieldValue,
});

export const setValidDynamicFormField = (fieldName: string, validField: boolean) => ({
  type: actions.SET_VALID_DYNAMIC_FORM_FIELD,
  fieldName,
  validField,
});

export const setSignupAdditionalData = (dynamicFormValues: any) => ({
  type: actions.SET_DYNAMIC_FORM_FIELDS,
  dynamicFormValues,
});

export const setReview = (review: boolean) => ({
  review,
  type: actions.SET_REVIEW,
});

export const setEdit = (edit: boolean) => ({
  edit,
  type: actions.SET_EDIT,
});

export const setAttachments = (attachments: boolean) => ({
  attachments,
  type: actions.SET_ATTACHMENTS,
});

export const setInfoSent = (infoSent: boolean) => ({
  infoSent,
  type: actions.SET_INFO_SENT,
});

export const setEHFAvailable = (ehfAvailable: boolean) => ({
  ehfAvailable,
  type: actions.SET_EHF_AVAILABLE,
});

export const setMainTradeChannel = (mainTradeChannel: any) => ({
  mainTradeChannel,
  type: actions.SET_MAIN_TRADE_CHANNEL,
});

export const setAdditionalTradeChannel = (additionalTradeChannel: any) => ({
  additionalTradeChannel,
  type: actions.SET_ADDITIONAL_TRADE_CHANNEL,
});

export const setUploading = (uploading: boolean) => ({
  uploading,
  type: actions.SET_UPLOADING,
});

export const resetState = () => ({
  type: actions.RESET_STATE,
});

export const setDynamicValue = (name: string, value: string) => ({
  name,
  value,
  type: actions.SET_DYNAMIC_VALUE,
});

export const setDynamicValidation = (name: string, valid: boolean) => ({
  name,
  valid,
  type: actions.SET_VALID_DYNAMIC_VALUE,
});
