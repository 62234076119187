const ANDROID = 'android';
const IOS = 'ios';
const PHONE = 'phone';
const TABLET = 'tablet';

/**
 * Read browser's required information to identify which device the user is using.
 * Based on user agent and screen information.
 */
const detectDevice = () => {
  const device = {
    platform: '',
    type: '',
  };

  if (navigator.userAgent.match(/Android/i)) {
    device.platform = ANDROID;
    device.type = window.screen.width >= 768 ? TABLET : PHONE;
  }

  if (navigator.userAgent.match(/iPhone/i)) {
    device.platform = IOS;
    device.type = PHONE;
  }

  if (navigator.userAgent.match(/iPad/i)) {
    device.platform = IOS;
    device.type = TABLET;
  }
  return device;
};

/**
 * Select the apropriate manifest for the device.
 * If it's a tablet it goes for tablet, otherwise it defaults for mobile manifest.
 */
const manifestSelector = () => {
  const device = detectDevice();
  const tag = document.getElementById('manifest-placeholder');
  if (tag) {
    if (device.type === TABLET) {
      tag.setAttribute('href', `./manifest/manifest-tablet-${device.platform}.webmanifest`);
    } else {
      tag.setAttribute('href', `./manifest/manifest-mobile-${device.platform || ANDROID}.webmanifest`);
    }
  }
};

export default manifestSelector;
