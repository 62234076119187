import React, { ReactNode } from 'react';
import cn from 'classnames';
import styles from './text.module.scss';

export enum SIZES {
  MICRO   = 'micro',
  TINY    = 'tiny',
  SMALL   = 'small',
  MEDIUM  = 'medium',
  MEDIUM_LARGE  = 'medium-large',
  LARGE   = 'large',
  BIG     = 'big',
  HUGE    = 'huge'
}


export enum TEXTCOLOR {
    DARK_GREY = 'darkgrey',
    GREY_DARK_MID = 'greydarkmid',
    GREY_MID = 'greymid',
    GREY = 'grey',
    WHITE = 'white',
    ORANGE = 'orange',
    RED = 'red',
    PRIMARY = 'primary',
    STEEL = 'steel',
    BLACK = "BLACK"
}

export enum ALIGN {
  LEFT    = 'alignLeft',
  RIGHT   = 'alignRight',
  CENTER  = 'alignCenter',
}

export interface TextProps {
  size?: SIZES;
  align?: ALIGN;
  bold?: boolean;
  color?: TEXTCOLOR;
  children: string | number | ReactNode;
  semiBold?: boolean;
  strikethrough?: boolean;
  className?: string;
}

const Text: React.FC<TextProps> = ({
  children,
  size = SIZES.MEDIUM,
  align = ALIGN.LEFT,
  color = TEXTCOLOR.DARK_GREY,
  bold = false,
  semiBold = false,
  strikethrough = false,
  className
}: TextProps) => (
  <div className={cn(
      className,
      styles.text,
      styles[size],
      styles[align],
      styles[color],
      { [styles.bold]: bold,
        [styles['semi-bold']]: semiBold,
        [styles['strikethrough']]: strikethrough
      }
    )}
  >
    { children }
  </div>
)

export default Text;
