import { useState, useEffect } from 'react';

const getCurrentWidth = () => {
  const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

   const [width, setWidth] = useState(getWidth());

   useEffect(() => {
     const resizeListener = () => {
       setWidth(getWidth())
     };

     window.addEventListener('resize', resizeListener);

     return () => {
       window.removeEventListener('resize', resizeListener);
     }
   });

   return width;
};

const getCurrentHeight = () => {
  const getHeight = () => window.innerHeight
  || document.documentElement.clientHeight
  || document.body.clientHeight;

   const [height, setHeight] = useState(getHeight());

   useEffect(() => {
     const resizeListener = () => {
       setHeight(getHeight())
     };

     window.addEventListener('resize', resizeListener);

     return () => {
       window.removeEventListener('resize', resizeListener);
     }
   });

   return height;
};

export { getCurrentWidth, getCurrentHeight }