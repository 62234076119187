import React, { Suspense } from 'react';
import { store } from './store';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import Router from './Router';
import './styles/global.scss';
import { I18nextProvider } from 'react-i18next';
import LoadingComponent from './components/Loading';

import i18n from './i18n';

const rootElement = document.getElementById('react-app');

async function App(): Promise<any> {
  render(
    <Suspense fallback={LoadingComponent}>
      <I18nextProvider i18n={ i18n }>
      <Provider store={store}>
        <Router />
      </Provider>
      </I18nextProvider>
    </Suspense>,
    rootElement
  )
}

export default App;
