import React, { Dispatch, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './errorScreen.module.scss';
import cn from 'classnames';
import Button from '../../Button';
import TagManager from "react-gtm-module";
import { connect, useSelector } from 'react-redux';
import immutableToJS from 'with-immutable-props-to-js';
import { State } from '../../../reducers';
import backupBackgroundImage from '../../../assets/images/support.png';
import { getGlobalContent } from '../../../utils/selectors/headless/selectors';
import { Action } from 'redux';
import { i18nKeyPrefix } from '../../../utils/constants';

const cookieConsent = window.localStorage.getItem('cookies') === 'accepted' ? 'yes' : 'no';

interface Props {
  global?: any;
}

const ErrorScreenComponent: React.FC<Props> = ({
  global
}: Props) => {  
  const { t } = useTranslation();
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_Error';
  const marketData = useSelector((state: State) => state.router.marketData);
  const [umbracoCopy, setUmbracoCopy] = useState(`<div><a href="mailto:customersupport@carlsberg.com">customersupport@carlsberg.com</a></div><div>Ontrade: <a href="tel:+45 33271000">+45 33271000</a></div><div>Offtrade: <a href="tel:+45 3327 2600">+45 3327 2600</a></div>`);
  const marketConfig: any = useSelector((state: State) => state.router.marketConfig);

  const tagManagerDataLayerArgs = {
    dataLayer: {
      'event': 'errorSignUp',
      'pageCountry': marketData.marketId,
      'pageLanguage': localStorage['i18nextLng'],
      'pageTitle': 'Error',
      'pageStep': 10,
      'pageStepName': 'Error',
      'userAcceptCookies': cookieConsent
    }
  }

  useLayoutEffect(() => {
    if (global) {
      if(global?.contactInfo?.text) {
        setUmbracoCopy(global?.contactInfo?.text);
      }
    }
  }, [global]);

  useEffect(() => {
    TagManager.dataLayer(tagManagerDataLayerArgs);
  }, []);

  const chatAction = () => {
    if (marketConfig?.kundoChat === true) {
      document.getElementById('start-kundo-chat')?.click();
    } else if (marketConfig?.zenDeskChat === true) {
      ((document.getElementById("launcher") as HTMLIFrameElement)?.contentWindow.document.getElementsByClassName("wrapper-AtBcr")[0] as HTMLElement)?.click();
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.fullBackground}>
        <div className={cn(styles.fullImage)} style={{backgroundImage: `url(${backupBackgroundImage})`}}></div>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.text}>
          <div className={styles.header} data-i18n-key={`${prefix}_title`}>{t(`${prefix}_title`)}</div>
          <div className={styles.info} data-i18n-key={`${prefix}_reachOut`}>{t(`${prefix}_reachOut`)}</div>
          { (marketConfig?.kundoChat !== true && marketConfig?.zenDeskChat !== true) && 
            <div className={styles.contactInfo}>
              <div className={styles.subInfo} data-i18n-key={`${prefix}_contactDetails`}>{t(`${prefix}_contactDetails`)}</div>
              <div className={styles.subInfo}>
                <span dangerouslySetInnerHTML={{__html: umbracoCopy }}></span>
              </div>
            </div>
          }
        </div>
      </div>
      { (marketConfig?.kundoChat === true || marketConfig?.zenDeskChat === true) &&
        <div className={cn(styles.contactFooter)}>
          <div className={cn(styles.contactButton)} data-i18n-key={`${prefix}_button`}>
            <Button
              text={t(`${prefix}_button`)}
              color="black"
              size="medium"
              style="noBorder"
              action={chatAction}
            />
          </div>
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state: State) => {
  return {
    global: getGlobalContent(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(ErrorScreenComponent));