import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useHistory, useParams } from "react-router-dom";
import { State } from '../../../../reducers';
import handleHeadlessLink from '../../../../utils/custom-hooks/handleHeadlessLink';
import TagManager from "react-gtm-module";
import styles from './hero.module.scss';

interface HeroViewModel {
  viewModel: any;
  externalClass: string;
}

interface ParamTypes {
  lang: any;
}

const Hero: React.FC<HeroViewModel> = ({
  viewModel,
  externalClass
}: HeroViewModel) => {

  const history = useHistory();

  const el = React.createRef<HTMLDivElement>();
  let _links: HTMLCollection;

  const params = useParams<ParamTypes>();
  const configData = useSelector((state: State) => state.router.configData);

  const scrollToContent = (id: string) => {
    const el = document.getElementById(id);
    el?.scrollIntoView();
  }

  const navigateLink = () => {
    let navItemObj: any;
    switch(viewModel?.content?.action?.url) {
      case '#faq':
      case '#brands':
      case '#contact':
      case '#footer':
      case '#custom1':
      case '#custom2':
      case '#custom3':
        navItemObj = <a onClick={()=> {scrollToContent(viewModel?.content?.action?.url.replace('#', ''))}} className={styles.action}><span className={styles.text}>{viewModel?.content?.action?.name}</span><span className={styles.arrow}>&#8595;</span></a>
        break;
      default:
        navItemObj = <a href={viewModel?.content?.action?.url} className={styles.action} target={viewModel?.content?.action?.target} ><span className={styles.text}>{viewModel?.content?.action?.name}</span><span className={styles.arrow}>&#8595;</span></a>
        break;
    }
    return navItemObj;
  }

  useEffect(() => {
    function navigate(event: any) {
      let { target } = event;
      let i = 0;
      while (
        i < 5 &&
        !(target instanceof HTMLAnchorElement) &&
        target.parentNode
      ) {
        target = target.parentNode;
        i++;
      }
      if (!(target instanceof HTMLAnchorElement)) return;
      const href = target.getAttribute('href');

      if (href && (href[0] === '/' || href[0] === `/${params.lang}/`)) {
        event.preventDefault();

        if (href.indexOf('signup') >=0 ) {
          TagManager.dataLayer({
            dataLayer: {
              'event': 'clickSignUp',
              'pageSection': 'body'
            }
          });
        }
        history.push(href);
      }
    }

    _links = el?.current?.getElementsByTagName('a');
    for (let i = 0; i < _links?.length; i++) {
      const link = _links[i];
      const href = link.getAttribute('href');
      if (href && (href[0] === '/' || href[0] === `/${params.lang}/`)) {
        link.setAttribute('href', handleHeadlessLink(href));
      }
      _links[i].addEventListener('click', navigate, false);
    }

    return function removeListener() {
      _links = el?.current?.getElementsByTagName('a');

      if (_links) {
        for (let i = 0; i < _links?.length; i++) {
          _links[i].removeEventListener('click', navigate, false);
        }
      }
    }
  }, [])

  let imagePath: string;
  let logoPath: string;
  let styleObj: any;

  const parseClasses = (classNames, defaultClass) => {
    const arrClasses = defaultClass;
    let classes = [];
    
    if (classNames && classNames.length) {
      classes = classNames.split(' ');
      classes.map((className) => {
        arrClasses.push(styles[className]);
      });
    }
    
    return arrClasses;
  }

  if(configData) {
    imagePath = `${(viewModel.content.desktopImage?.url && viewModel.content.desktopImage?.url !== '') ? viewModel.content.desktopImage?.url : ''}`;
    logoPath  = `${(viewModel.content.logo?.url && viewModel.content.logo?.url !== '') ? viewModel.content.logo?.url : ''}`;
    styleObj = {
      backgroundImage: `linear-gradient(to right,rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%), url(${imagePath})`,
      textAlign: viewModel.content.alignment
    }
  }

  return (
    <div className={cn(parseClasses(externalClass, [styles.hero]))} style={styleObj} ref={ el }>
      {viewModel?.content?.logo?.url && logoPath && logoPath !== '' &&
        <img src={logoPath} className={styles.logo}/>
      }
      <div dangerouslySetInnerHTML={{__html: !viewModel.content.enableBoxedLayout ? (viewModel?.content?.text ? viewModel?.content?.text : '') : ''}} />
      {viewModel?.content?.enableBoxedLayout &&
        <div className={cn(styles.heroBox, styles[viewModel?.content?.boxedLayoutAlignment])} dangerouslySetInnerHTML={{__html: viewModel?.content?.text ? viewModel?.content?.text : ''}} />
      }
      {viewModel?.content?.action?.url &&
        navigateLink()
      }
    </div>
  )
};

export default Hero;
