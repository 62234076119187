import React from 'react';
import Grid from '../Grid';
import cn from 'classnames';
import styles from './termsandconditions.module.scss';
import BackButton from '../BackButton';
import Footer from '../Footer';

interface Props {
  plain: boolean;
  tandcContent: any;
  modal?: boolean;
}

const TermsAndConditions: React.FC<Props> = ({
  plain,
  tandcContent,
  modal
}: Props) => {
  return (
    <div className={cn(styles.page, {[styles.modal]: modal})}>
      { !plain && <BackButton action={null} /> }
      <Grid rows={tandcContent.gridContent.rows} />
      { !plain && <Footer /> }
    </div>
  )
}

export default TermsAndConditions;