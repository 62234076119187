import { makeRequest } from "./request";
import { all } from "redux-saga/effects";
import { makeCategoriesRequest } from "./categories";
import { makeCookiesRequest } from "./cookie";
import { makeGlobalRequest } from "./global";
import { makeNavRequest } from "./nav";
import { makePrivacyRequest } from "./privacy";
import { makeTermsRequest } from "./terms";
import { makeHeadlessRequest } from "./headless";

function* rootSaga() {
  yield all([
    makeRequest(),
    makeHeadlessRequest(),
    makeCategoriesRequest(),
    makeCookiesRequest(),
    makeGlobalRequest(),
    makeNavRequest(),
    makePrivacyRequest(),
    makeTermsRequest()
  ]);
};

export {
  rootSaga
};