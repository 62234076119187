import React, { Dispatch, useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import Header from '../components/Header';
import { getNavigation, getGlobalContent } from "../utils/selectors/headless/selectors";
import immutableToJS from 'with-immutable-props-to-js';
import { startGlobalRequest } from "../actions/api";
import { State } from '../reducers';
import { HeadlessGlobal } from "../utils/interfaces/headless";

interface Props {
  isMobile: boolean;
  light?: boolean;
  global: HeadlessGlobal;
  getGlobal: () => void;
  navigation: any;
}

const HeaderContainer: React.FC<Props> = ({
  isMobile,
  light,
  global,
  getGlobal,
  navigation,
}: Props) => {
  useLayoutEffect(() => {
    if (!global) {
      getGlobal();
    }
  }, [global]);

  if (navigation && global) {
    return (
      <Header
        isMobile={isMobile}
        navigation={navigation}
        light={light}
      />
    )
  } else {
    return <React.Fragment></React.Fragment>
  }
};

const mapStateToProps = (state: State) => {
  return {
    global: getGlobalContent(state),
    navigation: getNavigation(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    getGlobal: () => {
      dispatch(startGlobalRequest("GLOBAL"));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(HeaderContainer));
