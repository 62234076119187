import React, { ReactNode } from 'react';

import styles from './dropdown.module.scss';

interface Props {
  children: ReactNode;
}

const Dropdown: React.FC<Props> = ({
  children
}: Props) => {

  return (
    <div className={styles.dropdown}>
      {children}
    </div>
  );
}

export default Dropdown;