import React, { ReactNode } from 'react';
import styles from './textField.module.scss';
import cn from 'classnames';
import Text, { SIZES, TEXTCOLOR } from '../Text';
import { useTranslation } from 'react-i18next';
import { i18nKeyPrefix } from '../../utils/constants';

interface Props {
  text: string;
  action: any;
  placeholder: string;
  required: boolean;
  info: string;
}

const TextField: React.FC<Props> = ({
  text,
  action,
  placeholder,
  required,
  info
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.textarea)}>
      <textarea className={cn(styles.textarea)} required={required} value={text} onChange={(event) => action(event)} maxLength={300} placeholder={placeholder}></textarea>
        <Text size={SIZES.TINY} color={TEXTCOLOR.BLACK}>
          <span data-i18n-key={`${info}`}>*{t(`${i18nKeyPrefix}${info}`)} <div style={{fontWeight: 700, display: 'inline'}}>{300 - (text?.length || 0)}</div></span>
        </Text>
    </div>
  );
}

export default TextField;
