import React from 'react';
import styles from './headless-image.module.scss';
import { HeadlessImage } from '../../../utils/interfaces/headless';


interface Props {
  className?: string;
  heightRatio?: number;
  image: HeadlessImage;
  imageHeight?: number;
  isBackground?: boolean;
}

const HeadlessImage: React.FC<Props> = ({
  className,
  heightRatio,
  image,
  imageHeight,
  isBackground,
}: Props) => {

  const classArray: string[] = [styles.image];

  classArray.push('lazyload lazyload-measure');

  if (className) {
    classArray.push(className)
  }

  if (isBackground) {
    classArray.push('lazyload-bg')
  }

  const dataQuery: any = {
    mode: "crop",
    quality: "80",
    center: "0.5%2C0.5"
  };

  if (imageHeight) {
    dataQuery.height = imageHeight;
  }

  if (image.focalPoint && image.focalPoint.top && image.focalPoint.left) {
    dataQuery.center = `${image.focalPoint.top}%2C${image.focalPoint.left}`;
  }

  const url = () => {
    if (!image) {
      return null;
    }
    const url = image.url;
    return url;
  }

  const attrs: any = {
    className: classArray.join(' '),
    "src": url(),
    "data-query-obj": JSON.stringify(dataQuery)
  };

  if (heightRatio != null) {
    attrs["data-height-ratio"] = heightRatio;
  }

  if (image.altText) {
    attrs.alt = image.altText;
  }

  if (image.title) {
    attrs.title = image.title;
  }

  return React.createElement('img', attrs);

  // return (
  //   <div>
  //     <img className={styles.image} src={image.url} />
  //   </div>
  // )
};


export default HeadlessImage;
