import { State } from '../../../reducers';

const getApi = (state: State) => {

  return state.api;
}

const getHeadlessObj = (state: State) => {
  if (!getApi(state).get('HEADLESS')) {

    return;
  }

  return getApi(state).get('HEADLESS');
}

export const isHeadlessLoading = (state: State) => {
  const headless = getHeadlessObj(state);

  if (!headless) {

    return true;
  }

  return headless.get('isLoading');
}

export const getHeadless = (state: State) => {
  const headless = getHeadlessObj(state);

  if (!headless || !headless.get('payload')) {

    return;
  }

  return headless.get('payload');
};

export const getHeadlessContent = (state: State) => {
  const headless = getHeadlessObj(state);

  if (!headless || !headless.get('payload')) {

    return;
  }

  return headless.getIn([ 'payload', 'route' ]);
};

export const getNavigation = (state: State) => {
  const headless = getGlobalObj(state);

  if (!headless || !headless.get('payload')) {

    return;
  }

  return headless.getIn([ 'payload', 'route', 'settings', 'navigation' ]);
};

const getHeadlessCookiePolicyObj = (state: State) => {
  if (!getApi(state).get('COOKIEPOLICY')) {

    return;
  }

  return getApi(state).get('COOKIEPOLICY');
}

export const isHeadlessCookiePolicyLoading = (state: State) => {
  const headless = getHeadlessCookiePolicyObj(state);

  if (!headless) {

    return true;
  }

  return headless.get('isLoading');
}

export const getHeadlessCookiePolicy = (state: State) => {
  const headless = getHeadlessCookiePolicyObj(state);

  if (!headless || !headless.get('payload')) {

    return;
  }

  return headless.get('payload');
};

export const getHeadlessCookiePolicyContent = (state: State) => {
  const headless = getHeadlessCookiePolicyObj(state);

  if (!headless || !headless.get('payload')) {

    return;
  }

  return headless.getIn([ 'payload', 'page', 'content' ]);
};

const getHeadlessTermsAndConditionsObj = (state: State) => {
  if (!getApi(state).get('TERMSANDCONDITIONS')) {

    return;
  }

  return getApi(state).get('TERMSANDCONDITIONS');
}

const getHeadlessCategoriesObj = (state: State) => {
  if (!getApi(state).get('CATEGORIES')) {

    return;
  }

  return getApi(state).get('CATEGORIES');
}

const getHeadlessProductsObj = (state: State) => {
  if (!getApi(state).get('PRODUCTS')) {

    return;
  }

  return getApi(state).get('PRODUCTS');
}

const getHeadlessBrandsObj = (state: State) => {
  if (!getApi(state).get('BRANDS')) {

    return;
  }

  return getApi(state).get('BRANDS');
}

export const isHeadlessTermsAndConditionsLoading = (state: State) => {
  const headless = getHeadlessTermsAndConditionsObj(state);

  if (!headless) {

    return true;
  }

  return headless.get('isLoading');
}

export const getHeadlessTermsAndConditions = (state: State) => {
  const headless = getHeadlessTermsAndConditionsObj(state);

  if (!headless || !headless.get('payload')) {

    return;
  }

  return headless.get('payload');
};

export const getHeadlessTermsAndConditionsContent = (state: State) => {
  const headless = getHeadlessTermsAndConditionsObj(state);

  if (!headless || !headless.get('payload')) {

    return;
  }

  return headless.getIn([ 'payload', 'page', 'content' ]);
};

const getHeadlessPrivacyPolicyObj = (state: State) => {
  if (!getApi(state).get('PRIVACYPOLICY')) {

    return;
  }

  return getApi(state).get('PRIVACYPOLICY');
}

export const isHeadlessPrivacyPolicyLoading = (state: State) => {
  const headless = getHeadlessPrivacyPolicyObj(state);

  if (!headless) {

    return true;
  }

  return headless.get('isLoading');
}

export const getHeadlessPrivacyPolicy = (state: State) => {
  const headless = getHeadlessPrivacyPolicyObj(state);

  if (!headless || !headless.get('payload')) {

    return;
  }

  return headless.get('payload');
};

export const getHeadlessPrivacyPolicyContent = (state: State) => {
  const headless = getHeadlessPrivacyPolicyObj(state);

  if (!headless || !headless.get('payload')) {

    return;
  }

  return headless.getIn([ 'payload', 'page', 'content' ]);
};

export const getHeadlessCompositions = (state: State) => {
  const headless = getHeadlessObj(state);

  if (!headless || !headless.get('payload')) {

    return;
  }

  return headless.getIn([ 'payload', 'page', 'compositions' ]);
};

const getGlobalObj = (state: State) => {

  if (!getApi(state).get('GLOBAL')) {

    return;
  }

  return getApi(state).get('GLOBAL');
}

export const isGlobalLoading = (state: State) => {
  const global = getGlobalObj(state);

  if (!global) {

    return true;
  }

  return global.get('isLoading');
}

export const getGlobal = (state: State) => {
  const global = getGlobalObj(state);

  if (!global || !global.get('payload')) {

    return;
  }

  return global.get('payload');
};

export const getGlobalContent = (state: State) => {
  const global = getGlobalObj(state);

  if (!global || !global.get('payload')) {

    return;
  }

  return global.getIn([ 'payload', 'route', 'settings' ]);
};

export const getFallbackImage = (state: State) => {
  const global = getGlobalObj(state);

  if (!global || !global.get('payload')) {

    return;
  }

  return global.getIn([ 'payload', 'page', 'content', 'productFallback']);
}

export const getHeadlessCategoriesContent = (state: State) => {
  const headless = getHeadlessCategoriesObj(state);

  if (!headless || !headless.get('payload')) {

    return;
  }

  return headless.getIn([ 'payload', 'route', 'categories' ]);
};

export const getHeadlessProductsContent = (state: State) => {
  const headless = getHeadlessProductsObj(state);

  if (!headless || !headless.get('payload')) {

    return;
  }

  return headless.getIn([ 'payload', 'route', 'products' ]);
};

export const getHeadlessBrandsContent = (state: State) => {
  const headless = getHeadlessBrandsObj(state);

  if (!headless || !headless.get('payload')) {

    return;
  }

  return headless.getIn([ 'payload', 'route', 'brands' ]);
};

export const getHeadlessContentByName = (state: State, name: string) => {
  const headless = getHeadlessObjByName(state, name);

  if (!headless || !headless.get('payload')) {

    return;
  }

  return headless.get('payload');
};

const getHeadlessObjByName = (state: State, name: string) => {
  if (!getApi(state).get(name)) {

    return;
  }

  return getApi(state).get(name);
}