import React, { Dispatch, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Action } from "redux";
import TermsAndConditionsComponent from '../components/TermsAndConditions';
import { getHeadlessTermsAndConditionsContent, getGlobalContent } from "../utils/selectors/headless/selectors";
import immutableToJS from 'with-immutable-props-to-js';
import { startTermsRequest } from "../actions/api";
import { State } from '../reducers';
import { useParams, useHistory } from "react-router-dom";

interface Props {
  global?: any;
  plain?: boolean;
  tandcContent: any;
  termsRequest: any;
  modal?: boolean;
}

interface ParamTypes {
  lang: any;
}

const TermsAndConditionsContainer: React.FC<Props> = ({
  global,
  plain = false,
  tandcContent,
  termsRequest,
  modal
}: Props) => {
  const marketData = useSelector((state: State) => state.router.marketData);
  const params = useParams<ParamTypes>();
  const fallbackLanguage: string = marketData?.defaultLanguage;
  const history = useHistory();
  const loc = location.pathname;

  useEffect(() => {
    if (!tandcContent && global) {
      const pathArray: any[] = global.termsAndConditions?.view?.url?.split('/');
      const index: number = pathArray ? (pathArray[pathArray?.length - 1] === "" ? pathArray?.length - 2 : pathArray?.length - 1) : null;
      const secondLevelLocation: string = pathArray ? pathArray[index] : null;
      termsRequest(`/${params.lang}/${secondLevelLocation}`);
    }
  }, [global, termsRequest, params.lang, tandcContent]);

  useEffect(() => {
    if (loc === `/terms-and-conditions`) {
      const lang = localStorage.getItem('i18nextLng');
      if (lang) {
        history.push(`/${lang}/terms-and-conditions`);
      } else {
        localStorage.setItem('i18nextLng', fallbackLanguage.toLowerCase());
        const language: string = localStorage.getItem('i18nextLng');
        history.push(`/${language}/terms-and-conditions`);
      }
    }
  }, [fallbackLanguage, history, loc]);

  if (tandcContent) {
    return (
      <TermsAndConditionsComponent
        plain={plain}
        tandcContent={tandcContent}
        modal={modal}
      />
    )
  } else {
    return <span>We encontered a problem connecting to our CMS. Please try again, or contact your System Administrator.</span>
  }
};

const mapStateToProps = (state: State) => {
  return {
    global: getGlobalContent(state),
    tandcContent: getHeadlessTermsAndConditionsContent(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    termsRequest: (url: string) => {
      dispatch(startTermsRequest("TERMSANDCONDITIONS", url));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(TermsAndConditionsContainer));
