import React from 'react';

import styles from './radio.module.scss';

interface Props {
    label: string;
    checked: boolean;
    action?: (val: any) => void;
    dataId?: string;
}

const Radio: React.FC<Props> = ({
  label,
  checked,
  action,
  dataId
}: Props) => {

  return (
    <div className={styles.radio}>
      <label>
        <input type="radio" data-id={dataId} value="label" className={styles.input} checked={checked} onChange={action}/>
          {label}
        <span className={styles.checkmark}></span>
      </label>
    </div>
  );
}

export default Radio;
