import React, { Dispatch, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Action } from "redux";
import CookiesComponent from '../components/Cookies';
import { getGlobalContent, getHeadlessCookiePolicyContent } from "../utils/selectors/headless/selectors";
import immutableToJS from 'with-immutable-props-to-js';
import { startCookiesRequest } from "../actions/api";
import { State } from '../reducers';
import { useParams, useHistory } from "react-router-dom";

interface Props {
  global?: any;
  plain: boolean;
  cookiesContent: any;
  cookiesRequest: any;
}

interface ParamTypes {
  lang: any;
}

const CookiesContainer: React.FC<Props> = ({
  global,
  plain = false,
  cookiesContent,
  cookiesRequest,
}: Props) => {
  const marketData = useSelector((state: State) => state.router.marketData);
  const params = useParams<ParamTypes>();
  const fallbackLanguage: string = marketData?.defaultLanguage;
  const history = useHistory();
  const loc = location.pathname;
  useEffect(() => {

    if (!cookiesContent && global) {
      const pathArray: any[] = global.cookies?.view?.url?.split('/');
      const index: number = pathArray[pathArray.length - 1] === "" ? pathArray?.length - 2 : pathArray?.length - 1;
      const secondLevelLocation: string = pathArray[index];
      cookiesRequest(`/${params.lang}/${secondLevelLocation}`);
    }
  }, [global, cookiesContent, cookiesRequest, params.lang]);

  useEffect(() => {
    if (loc === `/cookie-policy`) {
      const lang = localStorage.getItem('i18nextLng');
      if (lang) {
        history.push(`/${lang}/cookie-policy`);
      } else {
        localStorage.setItem('i18nextLng', fallbackLanguage.toLowerCase());
        const language: string = localStorage.getItem('i18nextLng');
        history.push(`/${language}/cookie-policy`);
      }
    }
  }, [fallbackLanguage, history, loc]);

  if (cookiesContent) {
    return (
      <CookiesComponent
        plain={plain}
        cookiesContent={cookiesContent}
      />
    )
  } else {
    return <span>We encontered a problem connecting to our CMS. Please try again, or contact your System Administrator.</span>
  }
};

const mapStateToProps = (state: State) => {
  return {
    global: getGlobalContent(state),
    cookiesContent: getHeadlessCookiePolicyContent(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    cookiesRequest: (url: string) => {
      dispatch(startCookiesRequest("COOKIEPOLICY", url));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(CookiesContainer));
