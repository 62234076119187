import React, { useState, useEffect, Dispatch, SyntheticEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import immutableToJS from 'with-immutable-props-to-js';
import styles from './address.module.scss';
import { getCurrentWidth } from '../../../../utils/custom-hooks/handleWindowResize';
import { app, i18nKeyPrefix } from '../../../../utils/constants';
import GenericAddress from '../GenericAddress';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, connect } from 'react-redux';
import { State } from '../../../../reducers';
import { apiEndpoints } from '../../../../utils/api/endpoints';
import { RequestData } from '../../../../utils/interfaces/request';
import Checkbox from '../../../Checkbox';
import Text, { TEXTCOLOR, SIZES } from '../../../Text';
import {
  setDifferentAddress,
  startRequest,
  setStep,
  setDynamicValue,
  setDynamicValidation,
} from '../../../../actions/form';
import { Action } from 'redux';
import FormHeaderComponent from '../../FormHeader';
import SidebarWrapper from '../../Sidebar/SidebarWrapper';
import FormFooterComponent from '../../FormFooter';
import { Stepper } from '@carlsberggroup/malty.molecules.stepper';
import { scrollToError, validateAddressInfo } from '../../../../utils/validation';

interface ParamTypes {
  lang: any;
}

interface Props {
  signUpRequest?: (data: RequestData) => void;
}

const AddressComponent: React.FC<Props> = ({ signUpRequest }: Props) => {
  const marketData = useSelector((state: State) => state.router.marketData);
  const marketConfig = useSelector((state: State) => state.router.marketConfig);
  const size = getCurrentWidth();
  const [windowSize, setWindowSize] = useState(size);
  useEffect(() => {
    setWindowSize(size);
  }, [size]);
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_Address';
  const { t } = useTranslation();
  const isMobile = windowSize <= app.breakPoint;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const step = useSelector((state: State) => state.form.step);
  const [validStep, setValidStep] = useState(true);
  const [differentAddressClicked, setDifferentAddressClicked] = useState(false);
  const postalCodeValidation = marketConfig.postalCodeRegex;

  const differentAddress = useSelector((state: State) => state.form.differentAddress);
  const phone = useSelector((state: State) => state.form.phone);
  const email = useSelector((state: State) => state.form.email);
  const saveId = useSelector((state: State) => state.form.saveId.saveId);
  const form = useSelector((state: State) => state.form);
  const organization = useSelector((state: State) => state.form.organization);
  const review = useSelector((state: State) => state.form.review);

  const postalCodeShipTo = useSelector((state: State) => state.form.postalCodeShipTo);
  const postalCodeBillTo = useSelector((state: State) => state.form.postalCodeBillTo);
  const postalCodePayer = useSelector((state: State) => state.form.postalCodePayer);
  const postalCodeSoldTo = useSelector((state: State) => state.form.postalCodeSoldTo);

  const validNameBillTo = useSelector((state: State) => state.form.validNameBillTo);
  const validAddressBillTo = useSelector((state: State) => state.form.validAddressBillTo);
  const validZipCodeBillTo = useSelector((state: State) => state.form.validZipCodeBillTo);
  const validCityNameBillTo = useSelector((state: State) => state.form.validCityNameBillTo);
  const validPhoneBillTo = useSelector((state: State) => state.form.validPhoneBillTo);
  const validEmailBillTo = useSelector((state: State) => state.form.validEmailBillTo);

  const validNameShipTo = useSelector((state: State) => state.form.validNameShipTo);
  const validAddressShipTo = useSelector((state: State) => state.form.validAddressShipTo);
  const validZipCodeShipTo = useSelector((state: State) => state.form.validZipCodeShipTo);
  const validCityNameShipTo = useSelector((state: State) => state.form.validCityNameShipTo);
  const validPhoneShipTo = useSelector((state: State) => state.form.validPhoneShipTo);
  const validEmailShipTo = useSelector((state: State) => state.form.validEmailShipTo);

  const validNamePayer = useSelector((state: State) => state.form.validNamePayer);
  const validAddressPayer = useSelector((state: State) => state.form.validAddressPayer);
  const validZipCodePayer = useSelector((state: State) => state.form.validZipCodePayer);
  const validCityNamePayer = useSelector((state: State) => state.form.validCityNamePayer);
  const validPhonePayer = useSelector((state: State) => state.form.validPhonePayer);
  const validEmailPayer = useSelector((state: State) => state.form.validEmailPayer);

  const validNameSoldTo = useSelector((state: State) => state.form.validNameSoldTo);
  const validAddressSoldTo = useSelector((state: State) => state.form.validAddressSoldTo);
  const validZipCodeSoldTo = useSelector((state: State) => state.form.validZipCodeSoldTo);
  const validCityNameSoldTo = useSelector((state: State) => state.form.validCityNameSoldTo);
  const validPhoneSoldTo = useSelector((state: State) => state.form.validPhoneSoldTo);
  const validEmailSoldTo = useSelector((state: State) => state.form.validEmailSoldTo);
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    if (email) {
      if (marketConfig.addressList && marketConfig.addressList.primary) {
        marketConfig.addressList.primary.map((item: any, i: any) => {
          if (item.fillCompanyData || item.inheritEmailData) {
            if (!form[`email${item.type}`].length) {
              dispatch(setDynamicValue(`email${item.type}`, email));
            }
          }
        });
      }

      if (marketConfig.addressList && marketConfig.addressList.secondary) {
        marketConfig.addressList.secondary.map((item: any, i: any) => {
          if (item.fillCompanyData || item.inheritEmailData) {
            if (!form[`email${item.type}`].length) {
              dispatch(setDynamicValue(`email${item.type}`, email));
            }
          }
        });
      }
    }
  }, [email, marketConfig]);

  useEffect(() => {
    if (phone) {
      if (marketConfig.addressList && marketConfig.addressList.primary) {
        marketConfig.addressList.primary.map((item: any, i: any) => {
          if (item.fillCompanyData || item.inheritPhoneData) {
            if (!form[`phone${item.type}`].length) {
              dispatch(setDynamicValue(`phone${item.type}`, phone));
            }
          }
        });
      }

      if (marketConfig.addressList && marketConfig.addressList.secondary) {
        marketConfig.addressList.secondary.map((item: any, i: any) => {
          if (item.fillCompanyData || item.inheritPhoneData) {
            if (!form[`phone${item.type}`].length) {
              dispatch(setDynamicValue(`phone${item.type}`, phone));
            }
          }
        });
      }
    }
  }, [phone, marketConfig]);

  useEffect(() => {
    if (organization) {
      const fillData = (item) => {
        if (item.fillCompanyData) {
          if (!form[`address${item.type}`]) {
            dispatch(setDynamicValue(`address${item.type}`, organization?.primaryAddressStreetLine1));
          }

          if (!form[`name${item.type}`]) {
            dispatch(setDynamicValue(`name${item.type}`, organization?.primaryName));
          }

          if (!form[`zipCode${item.type}`]) {
            const zipCode =
              marketData.marketId.toUpperCase() === 'LT'
                ? organization?.primaryAddressPostalCode.replace(/[^0-9]/g, '')
                : organization?.primaryAddressPostalCode;

            dispatch(setDynamicValue(`zipCode${item.type}`, zipCode));
          }

          if (!form[`cityName${item.type}`]) {
            dispatch(setDynamicValue(`cityName${item.type}`, organization?.primaryAddressLocality));
          }
        }
      };

      if (marketConfig.addressList && marketConfig.addressList.primary) {
        marketConfig.addressList.primary.map((item: any, i: any) => {
          fillData(item);
        });
      }

      if (marketConfig.addressList && marketConfig.addressList.secondary) {
        marketConfig.addressList.secondary.map((item: any, i: any) => {
          fillData(item);
        });
      }
    }
  }, [organization]);

  useEffect(() => {
    if (postalCodeBillTo) {
      dispatch(
        setDynamicValue(
          'cityNameBillTo',
          postalCodeBillTo.city.charAt(0).toUpperCase() + postalCodeBillTo.city.toLowerCase().slice(1),
        ),
      );
      dispatch(setDynamicValidation('validCityNameBillTo', true));
    }

    if (postalCodeShipTo) {
      dispatch(
        setDynamicValue(
          'cityNameShipTo',
          postalCodeShipTo.city.charAt(0).toUpperCase() + postalCodeShipTo.city.toLowerCase().slice(1),
        ),
      );
      dispatch(setDynamicValidation('validCityNameShipTo', true));
    }

    if (postalCodePayer) {
      dispatch(
        setDynamicValue(
          'cityNamePayer',
          postalCodePayer.city.charAt(0).toUpperCase() + postalCodePayer.city.toLowerCase().slice(1),
        ),
      );
      dispatch(setDynamicValidation('validCityNamePayer', true));
    }

    if (postalCodeSoldTo) {
      dispatch(
        setDynamicValue(
          'cityNameSoldTo',
          postalCodeSoldTo.city.charAt(0).toUpperCase() + postalCodeSoldTo.city.toLowerCase().slice(1),
        ),
      );
      dispatch(setDynamicValidation('validCityNameSoldTo', true));
    }
  }, [postalCodeShipTo, postalCodeBillTo, postalCodePayer, postalCodeSoldTo]);

  useEffect(() => {
    if (differentAddress === null && marketConfig.showDifferentAddress) {
      dispatch(setDifferentAddress(true));
    }
  }, [marketConfig]);

  useEffect(() => {
    if (differentAddress && differentAddressClicked) {
      const goToElement = document.getElementById('goToAddress');
      const topPos = goToElement.offsetTop;

      if (isMobile) {
        window.scrollTo(0, topPos);
      } else {
        document.getElementById('addressWrapper').scrollTop = topPos;
      }
    }
  }, [differentAddress, differentAddressClicked]);

  useEffect(() => {
    dispatch(setStep(3));
  }, []);

  useEffect(() => {
    scrollToError();
  }, [
    validNameBillTo,
    validAddressBillTo,
    validZipCodeBillTo,
    validCityNameBillTo,
    validPhoneBillTo,
    validEmailBillTo,
    validNameShipTo,
    validAddressShipTo,
    validZipCodeShipTo,
    validCityNameShipTo,
    validPhoneShipTo,
    validEmailShipTo,
    validNamePayer,
    validAddressPayer,
    validZipCodePayer,
    validCityNamePayer,
    validPhonePayer,
    validEmailPayer,
    validNameSoldTo,
    validAddressSoldTo,
    validZipCodeSoldTo,
    validCityNameSoldTo,
    validPhoneSoldTo,
    validEmailSoldTo,
  ]);

  useEffect(() => {
    if (query) {
      const errorParam = query.get('error');
      if (errorParam && errorParam === 'validation') {
        scrollToError();
      }
    }
  }, [query]);

  const changeStep = (step: number) => {
    history.push(`/${params.lang}/signup/${step}`);
    dispatch(setStep(step));
  };

  const validateInput = (e: SyntheticEvent, field: string, validField: string, type: string) => {
    const formatValue = (e.target as HTMLInputElement).value;
    if (formatValue) {
      dispatch(setDynamicValidation(`valid${validField + type}`, true));
    }

    dispatch(setDynamicValue(field + type, formatValue));
  };

  const validateZipCode = (e: SyntheticEvent, type: string) => {
    const formatValue = (e.target as HTMLInputElement).value;

    const regex = new RegExp(postalCodeValidation);
    const valid = formatValue.replace(/ /g, '').match(regex);

    if (valid) {
      dispatch(
        startRequest(
          {
            type: 'GET',
            endpoint: `${apiEndpoints.postalCode}/${formatValue.replace(
              / /g,
              '',
            )}?countryCode=${marketData.marketId.toUpperCase()}`,
          },
          `postalCode${type}`,
        ),
      );

      dispatch(setDynamicValidation(`validZipCode${type}`, true));
    } else {
      dispatch(setDynamicValidation(`validZipCode${type}`, false));
    }

    dispatch(setDynamicValue(`zipCode${type}`, formatValue));
  };

  const setPhoneInput = (val: any, type: string) => {
    if (val) {
      dispatch(setDynamicValidation(`validPhone${type}`, true));
    }
    dispatch(setDynamicValue(`phone${type}`, val));
  };

  const validateStep = () => {
    if (!validateAddressInfo(dispatch, marketConfig, form)) {
      scrollToError();
      return;
    } else {
      const body = {
        differentAddress: differentAddress || false,
      };

      if (marketConfig.addressList && marketConfig.addressList.primary) {
        marketConfig.addressList.primary.map((item: any, i: any) => {
          const addressData = {
            name: form[`name${item.type}`],
            address: form[`address${item.type}`],
            zipCode: form[`zipCode${item.type}`] && form[`zipCode${item.type}`].replace(/ /g, ''),
            town: form[`cityName${item.type}`],
            phone: form[`phone${item.type}`],
            email: form[`email${item.type}`].trim(),
          };

          if (item.type === 'BillTo') {
            body['mailingData'] = addressData;
          } else if (item.type === 'ShipTo') {
            body['deliveryData'] = addressData;
          } else if (item.type === 'Payer') {
            body['payerData'] = addressData;
          } else if (item.type === 'SoldTo') {
            body['soldToData'] = addressData;
          }
        });

        if (marketConfig.addressList.secondary) {
          const primaryAddress = marketConfig.addressList.primary[0];
          marketConfig.addressList.secondary.map((item: any, i: any) => {
            let addressData;
            if (!differentAddress && !item.disabled) {
              dispatch(setDynamicValue(`address${item.type}`, form[`address${primaryAddress.type}`]));
              dispatch(setDynamicValue(`cityName${item.type}`, form[`cityName${primaryAddress.type}`]));
              dispatch(setDynamicValue(`zipCode${item.type}`, form[`zipCode${primaryAddress.type}`]));
              dispatch(setDynamicValue(`name${item.type}`, form[`name${primaryAddress.type}`]));
              dispatch(setDynamicValue(`phone${item.type}`, form[`phone${primaryAddress.type}`]));
              dispatch(setDynamicValue(`email${item.type}`, form[`email${primaryAddress.type}`]));

              addressData = {
                name: form[`name${primaryAddress.type}`],
                address: form[`address${primaryAddress.type}`],
                zipCode:
                  form[`zipCode${primaryAddress.type}`] && form[`zipCode${primaryAddress.type}`].replace(/ /g, ''),
                town: form[`cityName${primaryAddress.type}`],
                phone: form[`phone${primaryAddress.type}`],
                email: form[`email${primaryAddress.type}`].trim(),
              };
            } else {
              addressData = {
                name: form[`name${item.type}`],
                address: form[`address${item.type}`],
                zipCode: form[`zipCode${item.type}`] && form[`zipCode${item.type}`].replace(/ /g, ''),
                town: form[`cityName${item.type}`],
                phone: form[`phone${item.type}`],
                email: form[`email${item.type}`].trim(),
              };
            }

            if (item.type === 'BillTo') {
              body['mailingData'] = addressData;
            } else if (item.type === 'ShipTo') {
              body['deliveryData'] = addressData;
            } else if (item.type === 'Payer') {
              body['payerData'] = addressData;
            } else if (item.type === 'SoldTo') {
              body['soldToData'] = addressData;
            }
          });
        }
      }

      setValidStep(true);
      signUpRequest({
        type: 'PUT',
        endpoint: `${apiEndpoints.signupData}/${saveId}?countryCode=${marketData.marketId.toUpperCase()}`,
        body: body,
      });

      window.localStorage.setItem('formData', JSON.stringify(form));

      if (review) {
        history.push(`/${params.lang}/signup/6`);
        dispatch(setStep(6));
      } else {
        history.push(`/${params.lang}/signup/4`);
        dispatch(setStep(4));
      }
    }
  };

  const clearSecondaryAddresses = () => {
    if (marketConfig.addressList && marketConfig.addressList.secondary) {
      marketConfig.addressList.secondary.map((item: any, i: any) => {
        if (item.fillCompanyData) {
          dispatch(setDynamicValue(`address${item.type}`, organization?.primaryAddressStreetLine1));
          dispatch(setDynamicValue(`name${item.type}`, organization?.primaryName));
          dispatch(setDynamicValue(`zipCode${item.type}`, organization?.primaryAddressPostalCode));
          dispatch(setDynamicValue(`cityName${item.type}`, organization?.primaryAddressLocality));
          dispatch(setDynamicValue(`email${item.type}`, email));
          dispatch(setDynamicValue(`phone${item.type}`, phone));
        } else {
          dispatch(setDynamicValue(`address${item.type}`, ''));
          dispatch(setDynamicValue(`cityName${item.type}`, ''));
          dispatch(setDynamicValue(`zipCode${item.type}`, ''));
          dispatch(setDynamicValue(`name${item.type}`, ''));
          dispatch(setDynamicValue(`phone${item.type}`, ''));
          dispatch(setDynamicValue(`email${item.type}`, ''));
        }

        dispatch(setDynamicValidation(`validAddress${item.type}`, true));
        dispatch(setDynamicValidation(`validCityName${item.type}`, true));
        dispatch(setDynamicValidation(`validZipCode${item.type}`, true));
        dispatch(setDynamicValidation(`validName${item.type}`, true));
        dispatch(setDynamicValidation(`validPhone${item.type}`, true));
        dispatch(setDynamicValidation(`validEmail${item.type}`, true));
      });
    }
  };

  const handleDifferentAddressCheckbox = (event: any) => {
    dispatch(setDifferentAddress(event));
    setDifferentAddressClicked(true);
    if (!event) {
      clearSecondaryAddresses();
    }
  };

  return (
    <React.Fragment>
      <div className={styles.formContainer}>
        {step !== 7 && <FormHeaderComponent changeStep={changeStep} />}
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <div className={styles.screenWrapper}>
              <div id="addressWrapper" className={styles.wrapper}>
                <div className={styles.stepperContainer}>
                  <Stepper steps={marketConfig.steps} currentStep={3} />
                </div>
                <div>
                  {marketConfig.addressList &&
                    marketConfig.addressList.primary &&
                    marketConfig.addressList.primary.map((item: any, i: any) => (
                      <GenericAddress
                        key={`primaryAddress${i}`}
                        onNameChange={(val: any) => validateInput(val, 'name', 'Name', item.type)}
                        onAddressChange={(val: any) => validateInput(val, 'address', 'Address', item.type)}
                        onZipCodeChange={(val: any) => validateZipCode(val, item.type)}
                        onCityChange={(val: any) => validateInput(val, 'cityName', 'CityName', item.type)}
                        onEmailChange={(val: any) => validateInput(val, 'email', 'Email', item.type)}
                        onPhoneChange={(val: any) => setPhoneInput(val, item.type)}
                        type={item.type}
                        emailExist={item.emailExist}
                        phoneNumberExist={item.phoneNumberExist}
                        nameMandatory={item.nameMandatory}
                        emailMandatory={item.emailMandatory}
                        showDescription={item.showDescription}
                        emailSubtitle={item.emailSubtitle}
                        readOnlyEstablishment={item.readOnlyEstablishment}
                        fieldsDisabled={item.disabled}
                        outletNameHide={item.outletNameHide}
                      />
                    ))}
                  {marketConfig.addressList &&
                    marketConfig.addressList.secondary &&
                    marketConfig.addressList.secondary.length && (
                      <div className={styles.checkboxContainer}>
                        <Checkbox
                          dataId="checkDifferentAddress"
                          onChange={(event) => handleDifferentAddressCheckbox(event.target.checked)}
                          checked={differentAddress || false}
                        >
                          <Text color={TEXTCOLOR.DARK_GREY} size={SIZES.SMALL}>
                            <span data-i18n-key={`${prefix}_differentAddress`}>{t(`${prefix}_differentAddress`)}</span>
                          </Text>
                        </Checkbox>
                      </div>
                    )}
                </div>
                {differentAddress && (
                  <div id="goToAddress" className={styles.secondaryAddress}>
                    {marketConfig.addressList &&
                      marketConfig.addressList.secondary &&
                      marketConfig.addressList.secondary.map((item: any, i: any) => (
                        <GenericAddress
                          key={`secondaryAddress${i}`}
                          onNameChange={(val: any) => validateInput(val, 'name', 'Name', item.type)}
                          onAddressChange={(val: any) => validateInput(val, 'address', 'Address', item.type)}
                          onZipCodeChange={(val: any) => validateZipCode(val, item.type)}
                          onCityChange={(val: any) => validateInput(val, 'cityName', 'CityName', item.type)}
                          onEmailChange={(val: any) => validateInput(val, 'email', 'Email', item.type)}
                          onPhoneChange={(val: any) => setPhoneInput(val, item.type)}
                          type={item.type}
                          emailExist={item.emailExist}
                          phoneNumberExist={item.phoneNumberExist}
                          nameMandatory={item.nameMandatory}
                          emailMandatory={item.emailMandatory}
                          showDescription={item.showDescription}
                          emailSubtitle={item.emailSubtitle}
                          readOnlyEstablishment={item.readOnlyEstablishment}
                          fieldsDisabled={item.disabled}
                          outletNameHide={item.outletNameHide}
                        />
                      ))}
                  </div>
                )}
              </div>
            </div>
            {!isMobile && step !== 7 && <SidebarWrapper />}
          </div>
        </div>
      </div>
      {step !== 7 && <FormFooterComponent validateStep={validateStep} disabled={!validStep} />}
    </React.Fragment>
  );
};
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    signUpRequest: (data: RequestData) => {
      dispatch(startRequest(data, 'saveId'));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(immutableToJS(AddressComponent));
