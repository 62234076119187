import React, { Dispatch, useState, useEffect, SyntheticEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { connect } from "react-redux";
import immutableToJS from 'with-immutable-props-to-js';
import FormWrapperComponent from '../components/SignUpForm/FormWrapper';
import { RequestData } from "../utils/interfaces/request";
import { setStep, startRequest } from '../actions/form';
import { State } from '../reducers';
import { getError } from "../utils/selectors/error/error";
import { useHistory, useParams } from "react-router-dom";

interface Props {
  error?: any;
}

interface ParamTypes {
  lang: any;
}

const FormWrapperContainer: React.FC<Props> = ({
  error
}: Props) => {

  const dispatch = useDispatch();
  const [vatError, setVatError] = useState(null);

  const step = useSelector((state: State) => state.form.step);

  useEffect(() => {
    if (error) {
      setVatError(error);
    }
  }, [error]);

  const loc = location.pathname;

  useEffect(() => {
    dispatch(setStep(parseInt(loc.split('signup/').pop().charAt(0))));
  }, [dispatch, loc]);

  const validateInput = (e: SyntheticEvent, setVal: (val: any) => void, setValid: (val: any) => void) => {
    e.persist();
    const formatValue =  (e.target as HTMLInputElement).value;
    const valid = formatValue.length >= 3;
    dispatch(setVal(formatValue));

    if (valid) {
      dispatch(setValid(true));
    } else {
      dispatch(setValid(false));
    }

    return valid;
  }

  const props = {
    validateInput
  }

  return (
    <FormWrapperComponent
      props={props}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    error: getError(state, 'organization')
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    organizationRequest: (data: RequestData) => {
      dispatch(startRequest(data, 'organization'));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(FormWrapperContainer));
