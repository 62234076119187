import React, { useEffect, useState } from 'react';
import Loadable from '@loadable/component';
import { HeadlessGrid } from '../../utils/interfaces/headless';
import styles from './grid.module.scss';
import cn from 'classnames';
import { app } from '../../utils/constants';
import { getCurrentWidth } from '../../utils/custom-hooks/handleWindowResize';

const RichTextEditor = Loadable(() =>  import('./Editors/RichText'));
const Image = Loadable(() =>  import('./Editors/Image'));
const Hero = Loadable(() =>  import('./Editors/Hero'));
const Usp = Loadable(() =>  import('./Editors/USP'));
const Images = Loadable(() =>  import('./Editors/Images'));
const Countdown = Loadable(() =>  import('./Editors/Countdown'));
const Carousel = Loadable(() =>  import('./Editors/Carousel'));
const Quote = Loadable(() =>  import('./Editors/Quote'));
const FAQ = Loadable(() =>  import('./Editors/Faq'));
const Footer = Loadable(() =>  import('../Footer'));

const GridElements: any = {
  RichTextEditor,
  Image,
  Hero,
  Countdown,
  Carousel,
  Quote,
  FAQ,
  Usp,
  Images
};

const Grid: React.FC<HeadlessGrid> = ({
  noFooter,
  mainGrid,
  rows
}: HeadlessGrid) => {
  if (!rows) {
    return null;
  }

  const size = getCurrentWidth();
  const [windowSize, setWindowSize] = useState(size);

  useEffect(() => {
    setWindowSize(size);
  });

  const isMobile = windowSize <= app.breakPoint;

  const DynamicComponent = (editor, externalClass: string) => {
    let index = 0;
    const editorAliasShort = editor.alias.replace('lsBlock', '');

    if (typeof GridElements[editorAliasShort] !== "undefined") {
      return React.createElement(GridElements[editorAliasShort], {
        viewModel: editor,
        externalClass: externalClass
      });
    }
    return React.createElement(
      () => <div key={index++}>The component {editor.alias} has not been created yet.</div>
    );
  };

  const parseClasses = (classNames, defaultClass) => {
    const arrClasses = defaultClass;
    let classes = [];
    
    if (classNames && classNames.length) {
      classes = classNames.split(' ');
      classes.map((className) => {
        arrClasses.push(styles[className]);
      });
    }
    
    return arrClasses;
  }

  return (
    <section className={cn([styles.grid, isMobile === true ? styles.isMobile : ''])} id={mainGrid ? 'mainGrid' : ''}>
      {rows.map((row: any, index: number) => (
      <div className={cn(styles.container,
        {[styles['container-fluid']]: !!(row.config && row.config.fullWidth === '1')
        })} key={index}>
        <div className={styles.row}>
          {!row.columns && 
            <div className={cn(parseClasses(row?.settings?.class, [styles['col-12']]))} key={index} id={row?.settings?.anchorId}>
              <span key={index++}>{DynamicComponent(row, row?.settings?.class)}</span>
          </div>
          }

          {row.columns && row.columns.map((column: any, index: number) => (
          <div className={cn(parseClasses(column?.settings?.class, [styles['col-' + (12 / row.columns.length)]]))} key={index} id={column?.settings?.anchorId}>
            <span key={index++}>{DynamicComponent(column, column?.settings?.class)}</span>
          </div>
          ))}
        </div>
      </div>
      ))}
      { mainGrid && !noFooter && <Footer /> }
    </section>
  )
};

export default Grid;
