import React, { createRef, RefObject, ReactNode, SyntheticEvent, ChangeEvent } from 'react';
import styles from './checkbox.module.scss';
import Text, { SIZES, TEXTCOLOR } from '../Text';
import cn from 'classnames';

export enum CHECKSIZES {
  SMALL   = 'small',
  MEDIUM  = 'medium',
}
interface Props
{
  booleanValue?: boolean;
  children?: ReactNode;
  disabled?: boolean;
  onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  required?: boolean;
  checked?: boolean;
  name?: string;
  valid?: boolean;
  errorMessage?: string;
  dataId?: string;
  size?: CHECKSIZES;
}

const Checkbox: React.FC<Props> = ({
  booleanValue,
  children,
  disabled,
  value,
  onChange,
  required,
  checked,
  name,
  valid,
  errorMessage,
  dataId,
  size
}: Props) => {
  const inputRef: RefObject<HTMLInputElement> = createRef();
  const clickHandler = (event: SyntheticEvent) => {
    event.persist();
    event.preventDefault();

    if ((event.target as HTMLElement).tagName === 'A') {
      return;
    }

    inputRef.current.click();
  }

  return (
    <div className={styles.wrapper}>
      <input className={cn(styles.checkbox, { [styles.error]: required && valid === false })}
        ref={inputRef}
        value={value}
        disabled={disabled}
        checked={checked}
        type="checkbox"
        name={name}
        required={required}
        onChange={onChange}
        data-id={dataId} />
      <label className={cn(styles.label, styles[size])} onClick={clickHandler} >
        {children}
      </label>
      {required && !valid && errorMessage && (
        <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

export default Checkbox;
