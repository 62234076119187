import React, { Dispatch, ReactNode, useEffect } from "react";
import immutableToJS from 'with-immutable-props-to-js';
import { connect } from "react-redux";
import { Action } from "redux";
import { setModalOpen } from '../actions/modal';
import ModalComponent from '../components/Modal';
import { getModalToggle } from '../utils/selectors/modal/selectors';
import { State } from '../reducers';

interface Props {
  isModalOpen: boolean;
  large?: boolean;
  title?: string;
  description?: string;
  active: boolean;
  setModalOpenFunc: (isModalOpen: boolean) => void;
  children: ReactNode;
  closeModal: () => void;
}

const ModaLContainer: React.FC<Props> = ({
  isModalOpen,
  setModalOpenFunc,
  children,
  active,
  closeModal,
  title,
  large,
  description
}: Props) => {

  useEffect(() => {
    setModalOpenFunc(active);
  }, [active]);

  useEffect(() => {
    isModalOpen ?
      document.body.style.overflow = 'hidden'
      : document.body.style.overflow = '';
  }, [isModalOpen]);

  return (
    <ModalComponent
      active={active}
      title={title}
      large={large}
      description={description}
      closeModal={closeModal}
    >{children}</ModalComponent>
  )
};

const mapStateToProps = (state: State) => {
  return {
    isModalOpen: getModalToggle(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setModalOpenFunc: (isModalOpen: boolean) => {
      dispatch(setModalOpen(isModalOpen));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(ModaLContainer));
