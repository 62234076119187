import dayjs, { Dayjs } from 'dayjs';
import { i18nKeyPrefix } from './constants';
import {
  setValidPhone,
  setValidEmail,
  setValidCompanyName,
  setValidManagerName,
  setValidJobTitle,
  setValidFirstName,
  setValidLastName,
  setValidTerms,
  setValidPrivacyAndCookies,
  setDynamicValidation,
  setValidVAT,
  setValidCompanyType,
} from '../actions/form';

export const validateDynamicString = (formatValue: any, required: boolean, regex: any) => {
  let valid = true;
  if ((required || (!required && formatValue.length)) && regex && regex.length > 0) {
    let formattedRegex;
    if (regex.length > 2 && regex.substring(regex.length - 2, regex.length - 1) === '/') {
      formattedRegex = new RegExp(regex.substring(0, regex.length - 2), regex.substring(regex.length - 1));
    } else {
      formattedRegex = new RegExp(regex);
    }
    valid = formattedRegex.test(formatValue);
  } else if (required) {
    if (formatValue.length >= 3) {
      valid = true;
    } else if (formatValue.length) {
      valid = true;
    } else {
      valid = false;
    }
  }

  return valid;
};

export const validateDynamicCheck = (checkStatus: any, required: boolean) => {
  let valid = true;
  if (required && !checkStatus) {
    valid = false;
  }

  return valid;
};

export const validateEmailInput = (e: any, setEmail: (val: string) => void, setValidEmail: (val: boolean) => void) => {
  e.persist();
  const formatValue = (e.target as HTMLInputElement).value;
  setEmail(formatValue);

  if (!formatValue) {
    setValidEmail(false);
    return;
  }

  return validateEmail(formatValue, setValidEmail);
};

export const validatePhoneInput = (
  number: any,
  setPhone: (val: string) => void,
  setValidPhone: (val: boolean) => void,
  phoneRegex: string,
) => {
  if (!number) {
    setValidPhone(false);
    return;
  }

  setPhone(number);

  return validatePhone(number, setValidPhone, phoneRegex);
};

export const validateInput = (e: any, setVal: (val: any) => void, setValid: (val: boolean) => void) => {
  e.persist();
  const formatValue = (e.target as HTMLInputElement).value;
  setVal(formatValue);

  return validateString(formatValue, setValid);
};

export const validateString = (formatValue: string, setValid: (val: boolean) => void) => {
  const valid = formatValue.length >= 3;

  if (valid) {
    setValid(true);
  } else {
    setValid(false);
  }

  return valid;
};

export const validatePhone = (number: any, setValidPhone: (val: boolean) => void, phoneRegex: string) => {
  const regex = new RegExp(phoneRegex);
  const valid = regex.test(number);

  if (valid) {
    setValidPhone(true);
  } else {
    setValidPhone(false);
  }

  return valid;
};

export const validateEmail = (formatValue: string, setValidEmail: (val: boolean) => void) => {
  const regex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}/g;
  const valid = regex.test(formatValue);

  if (valid) {
    setValidEmail(true);
  } else {
    setValidEmail(false);
  }

  return valid;
};

export const validateVAT = (
  e: any,
  setVAT: (VATnumber: string) => void,
  setValidVAT: (valid: boolean) => void,
  getOrganizationDetails: (number: string, getBy: string) => void,
  clearOrganization: () => void,
  vatValidation: string,
) => {
  e.persist();

  const regex = new RegExp(vatValidation);
  const formatValue = (e.target as HTMLInputElement).value;
  const valid = regex.test(formatValue);

  setVAT(formatValue);

  if (valid) {
    setValidVAT(true);
    getOrganizationDetails(formatValue, 'registrationNumber');
  } else {
    clearOrganization();
    setValidVAT(false);
  }

  return valid;
};

export const validateField = (
  e: any,
  field: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
  item?: any,
  regex?: string,
) => {
  if (field.type === 'input') {
    validateDynamicInput(e, field, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'checkbox') {
    validateDynamicCheckbox(e, field, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'select') {
    validateDynamicSelect(e, field, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'selectWithTextField') {
    validateDynamicSelectWithTextField(e, field, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'radio') {
    validateDynamicRadio(e, field, item, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'date') {
    validateDynamicCalendar(e, field, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'radioQuestion') {
    validateDynamicRadioQuestion(field, item, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'radioCard') {
    validateDynamicRadioCard(field, item, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'radioCardGeneric') {
    validateDynamicRadioCardGeneric(field, item, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'businessHours') {
    validateDynamicBusinessHours(e, field, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'openingHours') {
    validateDynamicOpeningHours(e, field, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'timeWindow') {
    validateDynamicTimeWindow(e, field, item, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'accountNumber') {
    return validateDynamicIban(e, field, setDynamicFormField, setValidDynamicFormField, regex);
  } else if (field.type === 'invoiceByElma') {
    validateDynamicInvoiceByElma(e, field, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'dunning') {
    validateDynamicRadioQuestion(field, item, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'textarea') {
    validateDynamicTextarea(e, field, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'isOwnerChange') {
    validateDynamicOwnership(field, item, setDynamicFormField, setValidDynamicFormField);
  } else if (field.type === 'eInvoice') {
    validateDynamicEInvoice(e, field, item, setDynamicFormField, setValidDynamicFormField);
  }

  return null;
};

const validateDynamicInput = (
  e: any,
  field: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  e.persist();
  const formatValue = (e.target as HTMLInputElement).value;
  setDynamicFormField(field.name, formatValue);
  const valid = validateDynamicString(formatValue, field.required, field.regex);
  setValidDynamicFormField(field.name, valid);
  return valid;
};

const validateDynamicCheckbox = (
  e: any,
  field: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  const checkStatus = e.target.checked;
  setDynamicFormField(field.name, checkStatus);
  const valid = validateDynamicCheck(checkStatus, field.required);
  setValidDynamicFormField(field.name, valid);

  return valid;
};

const validateDynamicSelect = (
  e: any,
  field: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  let valid = true;

  if (e === undefined) {
    setDynamicFormField(field.name, e);
    setValidDynamicFormField(field.name, false);
  } else if (!e.target) {
    setDynamicFormField(field.name, e);
    setValidDynamicFormField(field.name, valid);
  } else {
    e.persist();
    const formatValue = (e.target as HTMLInputElement).value;

    setDynamicFormField(field.name, formatValue);
    setValidDynamicFormField(field.name, valid);
  }

  return valid;
};

const validateDynamicSelectWithTextField = (
  e: any,
  field: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  let valid = true;

  if (e === undefined) {
    setDynamicFormField(field.name, e);
    setValidDynamicFormField(field.name, false);
  } else if (!e.target) {
    setDynamicFormField(field.name, e);
    setValidDynamicFormField(field.name, valid);
  } else {
    e.persist();
    let elemType = e.target.type;

    if (elemType === 'select-one' && e.target.value !== 'LeanSignup_Form_Custom_SelectWithTextField_other') {
      valid = true;
    } else if (elemType === 'select-one' && e.target.value === 'LeanSignup_Form_Custom_SelectWithTextField_other') {
      valid = false;
    } else if (elemType === 'text' && e.target.value.length > 0) {
      valid = true;
    } else {
      valid = false;
    }

    setValidDynamicFormField(field.name, valid);
  }

  return valid;
};

const validateDynamicBusinessHours = (
  option: any,
  field: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  let valid = true;
  setValidDynamicFormField(field.name, valid);

  return valid;
};

const validateDynamicOpeningHours = (
  data: any,
  field: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  let valid = true;
  setDynamicFormField(field.name, data);
  setValidDynamicFormField(field.name, valid);

  return valid;
};

const validateDynamicTimeWindow = (
  e: any,
  field: any,
  item: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  let valid = true;

  if (e === undefined) {
    setDynamicFormField(item.name, e);
    setValidDynamicFormField(item.name, false);
  } else if (!e.target) {
    setDynamicFormField(item.name, e);
    setValidDynamicFormField(item.name, valid);
  } else {
    e.persist();
    const formatValue = (e.target as HTMLInputElement).value;

    setDynamicFormField(item.name, formatValue);
    setValidDynamicFormField(item.name, valid);
  }

  return valid;
};

const validateDynamicInvoiceByElma = (
  e: any,
  field: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  e.persist();
  const formatValue = (e.target as HTMLInputElement).value;
  setDynamicFormField(field.name, formatValue);
  const valid = validateDynamicString(formatValue, field.required, field.regex);
  setValidDynamicFormField(field.name, valid);
  return valid;
};

const validateDynamicEInvoice = (
  e: any,
  field: any,
  item: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  const valid = true;
  setDynamicFormField(field.name, item);
  setValidDynamicFormField(field.name, valid);

  return valid;
};

const validateDynamicRadio = (
  e: any,
  field: any,
  item: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  const valid = true;
  setDynamicFormField(field.name, item.name);
  setValidDynamicFormField(field.name, valid);

  return valid;
};

const validateDynamicRadioQuestion = (
  field: any,
  item: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  const valid = true;
  setDynamicFormField(field.name, item);
  setValidDynamicFormField(field.name, valid);

  return valid;
};

const validateDynamicOwnership = (
  field: any,
  item: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  const valid = true;
  setDynamicFormField(field.name, item);
  setValidDynamicFormField(field.name, valid);

  return valid;
};

const validateDynamicCalendar = (
  date: Dayjs,
  field: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  const valid = true;
  setDynamicFormField(field.name, dayjs(date).format('YYYY-MM-DD'));
  setValidDynamicFormField(field.name, valid);

  return valid;
};

const validateDynamicRadioCard = (
  field: any,
  chosenType: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  setDynamicFormField(field.name, chosenType);
  setValidDynamicFormField(field.name, true);
  if (field.name === 'openSeason') {
    setDynamicFormField('season', chosenType.value);
  }
};

const validateDynamicRadioCardGeneric = (
  field: any,
  item: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  setDynamicFormField(field.name, item);
  setValidDynamicFormField(field.name, true);
};

const validateDynamicTextarea = (
  e: any,
  field: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
) => {
  e.persist();
  const formatValue = (e.target as HTMLInputElement).value;
  setDynamicFormField(field.name, formatValue);
  const valid = validateDynamicString(formatValue, field.required, field.regex);
  setValidDynamicFormField(field.name, true);
  return valid;
};

export const validateDynamicIban = (
  val: string,
  field: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
  regex: string,
) => {
  if (field.required && !val) {
    setDynamicFormField(field.name, null);
    setValidDynamicFormField(field.name, false);
    return null;
  } else if (!val) {
    setDynamicFormField(field.name, null);
    setValidDynamicFormField(field.name, true);
    return null;
  }
  setDynamicFormField(field.name, val);
  if (val) {
    const valid = val.replace(/\s/g, '').match(regex);
    if (valid) {
      setValidDynamicFormField(field.name, true);
      return true;
    } else {
      setValidDynamicFormField(field.name, false);
      return null;
    }
  }
  return true;
};

export const validatePersonalInfo = (dispatch: any, marketConfig: any, formState: any) => {
  const validPhone = formState.validPhone;
  const validEmail = formState.validEmail;
  const validCompanyName = formState.validCompanyName;
  const validManagerName = formState.validManagerName;
  const validJobTitle = formState.validJobTitle;
  const validFirstName = formState.validFirstName;
  const validLastName = formState.validLastName;
  const validTerms = formState.validTerms;
  const companyName = formState.companyName;
  const managerName = formState.managerName;
  const jobTitle = formState.jobTitle;
  const firstName = formState.firstName;
  const lastName = formState.lastName;
  const phone = formState.phone;
  const email = formState.email;
  const acceptTerms = formState.acceptTerms;
  const acceptPrivacyAndCookies = formState.acceptPrivacyAndCookies;
  const validPrivacyAndCookies = formState.validPrivacyAndCookies;

  const marketSpecificPrivacyAndCookies = marketConfig?.privacyAndCookiesCheckbox
    ? {
        name: acceptPrivacyAndCookies,
        validName: setValidPrivacyAndCookies,
        pattern: undefined,
        valid: validPrivacyAndCookies,
      }
    : {};

  const marketSpecificEstablishmentRequirement = marketConfig?.establishmentMandatory
    ? {
        name: companyName,
        validName: setValidCompanyName,
        pattern: /^.{1,35}$/,
        valid: validCompanyName,
      }
    : {};

  const marketSpecificManagerNameRequirement = !marketConfig?.hideManagerName
    ? {
        name: managerName,
        validName: setValidManagerName,
        pattern: /^.{1,35}$/,
        valid: validManagerName,
      }
    : {};

  const marketSpecificJobTitleRequirement = marketConfig?.showJobTitle
    ? {
        name: jobTitle,
        validName: setValidJobTitle,
        pattern: /^.{1,35}$/,
        valid: validJobTitle,
      }
    : {};

  const marketSpecificFirstNameRequirement = marketConfig?.showFirstLastName
    ? {
        name: firstName,
        validName: setValidFirstName,
        pattern: /^.{1,35}$/,
        valid: validFirstName,
      }
    : {};

  const marketSpecificLastNameRequirement = marketConfig?.showFirstLastName
    ? {
        name: lastName,
        validName: setValidLastName,
        pattern: /^.{1,35}$/,
        valid: validLastName,
      }
    : {};

  const requiredValues = [
    {
      name: phone,
      validName: setValidPhone,
      pattern: /^\+?[1-9]\d{4,14}$/,
      valid: validPhone,
    },
    {
      name: email,
      validName: setValidEmail,
      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/g,
      valid: validEmail,
    },
    {
      name: acceptTerms,
      validName: setValidTerms,
      pattern: undefined,
      valid: validTerms,
    },
    marketSpecificEstablishmentRequirement,
    marketSpecificManagerNameRequirement,
    marketSpecificJobTitleRequirement,
    marketSpecificFirstNameRequirement,
    marketSpecificLastNameRequirement,
    marketSpecificPrivacyAndCookies,
  ];

  const checkValid = (item: any) => {
    return item.valid;
  };

  let isValid;
  for (let i = 0; i < requiredValues.length; i++) {
    const value = requiredValues[i];
    if (value.name !== undefined) {
      if (value.name === '' || !value.name) {
        dispatch(value['validName'](false));
        isValid = false;
        value.valid = false;
      } else if (value.pattern) {
        const valid = new RegExp(value.pattern).test(value.name) && value.name.trim().length;
        if (!valid) {
          dispatch(value['validName'](false));
          value.valid = false;
          isValid = false;
        } else {
          dispatch(value['validName'](true));
          value.valid = true;
        }
      }
    } else {
      value.valid = true;
    }
  }

  if (requiredValues.every(checkValid)) {
    isValid = true;
  }

  return isValid;
};

export const validateCompanyInfo = (dispatch: any, marketConfig: any, form: any) => {
  const regex = new RegExp(marketConfig.vatRegex);
  if (!form.VATnumber.match(regex)) {
    dispatch(setValidVAT(false));
    return false;
  } else {
    return true;
  }
};

export const validateAddressInfo = (dispatch: any, marketConfig: any, form: any) => {
  let isValid = true;

  const validateField = (field: string, validField: string, regex: RegExp, type: string) => {
    if (!form[field + type] || !form[field + type].trim().length) {
      dispatch(setDynamicValidation(validField + type, false));
      isValid = false;
    } else {
      const valid = new RegExp(regex).test(form[field + type]);
      if (!valid) {
        dispatch(setDynamicValidation(validField + type, false));
        isValid = false;
      } else {
        dispatch(setDynamicValidation(validField + type, true));
      }
    }
  };

  if (marketConfig.addressList && marketConfig.addressList.primary) {
    marketConfig.addressList.primary.map((item: any, i: any) => {
      if (item.nameMandatory) {
        validateField('name', 'validName', /^.{1,35}$/, item.type);
      }

      validateField('address', 'validAddress', /^.{1,35}$/, item.type);
      validateField('zipCode', 'validZipCode', marketConfig.postalCodeRegex, item.type);
      validateField('cityName', 'validCityName', /^.{1,35}$/, item.type);

      if (item.phoneNumberExist) {
        validateField('phone', 'validPhone', /^\+?[1-9]\d{4,14}$/, item.type);
      }

      if (item.emailExist && (item.emailMandatory || form[`email${item.type}`].length)) {
        validateField('email', 'validEmail', /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/g, item.type);
      }
    });
  }

  if (form.differentAddress && marketConfig.addressList && marketConfig.addressList.secondary) {
    marketConfig.addressList.secondary.map((item: any, i: any) => {
      if (item.nameMandatory) {
        validateField('name', 'validName', /^.{1,35}$/, item.type);
      }

      validateField('address', 'validAddress', /^.{1,35}$/, item.type);
      validateField('zipCode', 'validZipCode', marketConfig.postalCodeRegex, item.type);
      validateField('cityName', 'validCityName', /^.{1,35}$/, item.type);

      if (item.phoneNumberExist) {
        validateField('phone', 'validPhone', /^\+?[1-9]\d{4,14}$/, item.type);
      }

      if (item.emailExist && (item.emailMandatory || form[`email${item.type}`].length)) {
        validateField('email', 'validEmail', /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/g, item.type);
      }
    });
  }

  return isValid;
};

export const validateOutletValidation = (dispatch: any, marketConfig: any, form: any) => {
  let isValid = true;

  const validateField = (field: string, validField: string, regex: RegExp) => {
    if (!form[field] || !form[field].trim().length) {
      dispatch(setDynamicValidation(validField, false));
      isValid = false;
    } else {
      const valid = new RegExp(regex).test(form[field]);
      if (!valid) {
        dispatch(setDynamicValidation(validField, false));
        isValid = false;
      } else {
        dispatch(setDynamicValidation(validField, true));
      }
    }
  };

  validateField('outletZipCode', 'validOutletZipCode', marketConfig.postalCodeRegex);
  validateField('outletName', 'validOutletName', /^.{1,35}$/);

  return isValid;
};

export const validateCompanyType = (dispatch: any, marketConfig: any, form: any) => {
  if (marketConfig.showOutletCompanyType && !validateOutletValidation(dispatch, marketConfig, form)) {
    return false;
  } else {
    const valid =
      (!marketConfig?.chipCategories && form.companyType) ||
      (form.additionalData.selectedCategories && form.additionalData.selectedCategories.length);

    if (!valid) {
      dispatch(setValidCompanyType(false));
    }

    return valid;
  }
};

export const validateAdditionalInfo = (
  formData: any,
  configPage: any,
  setDynamicFormField: (fieldName: string, fieldValue: any) => void,
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void,
  t: any,
) => {
  let valid = true;
  let failed;
  if (
    formData &&
    formData.marketData &&
    formData.marketData?.form &&
    formData.marketData?.form.pages &&
    formData.marketData?.form.pages.length > 0 &&
    formData.marketData?.form.pages[configPage].sections &&
    formData.marketData?.form.pages[configPage].sections.length > 0
  ) {
    const sections = formData.marketData?.form.pages[configPage].sections;
    sections.map((section: any) => {
      section.fields.map((field: any) => {
        if (formData.dynamicFormValidFields[field.name] === undefined) {
          if (formData.additionalData[field.name] !== undefined) {
            if (field.type === 'isOwnerChange' && formData.additionalData[field.name]) {
              if (
                formData.additionalData[field.subFields[0].name] &&
                formData.additionalData[field.subFields[1].name]
              ) {
                setValidDynamicFormField(field.name, true);
              } else {
                if (!formData.additionalData[field.subFields[1].name]) {
                  setValidDynamicFormField(field.name, false);
                  setValidDynamicFormField(field.subFields[1].name, false);
                }
                if (!formData.additionalData[field.subFields[0].name]) {
                  setValidDynamicFormField(field.name, false);
                  setValidDynamicFormField(field.subFields[0].name, false);
                }
                failed = true;
              }
            } else if (field.type === 'eInvoice') {
              let found = false;
              field.items.map((item: any) => {
                if (t(i18nKeyPrefix + item.name) === formData.additionalData[field.name]) {
                  found = true;

                  if (!item.subItems || !item.subItems.length) {
                    setValidDynamicFormField(field.name, true);
                  } else {
                    let subItemsCorrect = true;
                    item.subItems.map((subItem: any) => {
                      if (
                        formData.additionalData[subItem.name] &&
                        formData.dynamicFormValidFields[subItem.name] !== false
                      ) {
                        setValidDynamicFormField(subItem.name, true);
                      } else {
                        subItemsCorrect = false;
                        setValidDynamicFormField(subItem.name, false);
                      }
                    });

                    if (!subItemsCorrect) {
                      failed = true;
                      setValidDynamicFormField(field.name, false);
                    }
                  }
                } else if (item.subItems && item.subItems.length) {
                  setValidDynamicFormField(item.subItems[0].name, true);
                }
              });

              if (!found) {
                setValidDynamicFormField(field.name, false);
                failed = true;
              }
            } else if (field.type === 'timeWindow') {
              if (field.hours && field.hours.from && field.hours.to) {
                if (formData.additionalData[field.hours.to.name] || formData.additionalData[field.hours.from.name]) {
                  if (
                    parseInt(formData.additionalData[field.hours.to.name]) -
                      parseInt(formData.additionalData[field.hours.from.name]) >=
                    field.hours.minRange
                  ) {
                    if (field.day) {
                      if (formData.additionalData[field.day.name]) {
                        setValidDynamicFormField(field.name, true);
                      } else {
                        setValidDynamicFormField(field.name, false);
                        failed = true;
                      }
                    } else {
                      setValidDynamicFormField(field.name, true);
                    }
                  } else {
                    setValidDynamicFormField(field.name, false);
                    failed = true;
                  }
                } else {
                  if (field.required) {
                    setValidDynamicFormField(field.name, false);
                    failed = true;
                  } else {
                    setValidDynamicFormField(field.name, true);
                  }
                }
              }
            } else if (field.type === 'businessHours') {
              if (formData.additionalData[field.name] && formData.additionalData[field.name].length > 0) {
                setValidDynamicFormField(field.name, true);
              } else {
                setValidDynamicFormField(field.name, false);
                failed = true;
              }
            } else if (field.type === 'openingHours') {
              let allDaysFilled = true;
              const availableDays = field.dayLabels;
              const addedDays = formData.additionalData[field.name];
              if (availableDays) {
                availableDays.map((day: any, i: any) => {
                  const currentItem = addedDays && addedDays.filter((x: any) => x.day === day.value)[0];
                  if (!currentItem || !(currentItem.isClosed || (currentItem.from && currentItem.to))) {
                    allDaysFilled = false;
                    failed = true;
                  }
                });
              }

              setValidDynamicFormField(field.name, allDaysFilled);
            } else if (field.type === 'invoiceByElma') {
              if (formData.additionalData.ehfAvailable) {
                setValidDynamicFormField(field.name, true);
              } else if (!formData.additionalData.ehfAvailable && formData.additionalData.invoiceEmail) {
                if (
                  !field.regex ||
                  (field.regex &&
                    validateDynamicString(formData.additionalData[field.name], field.required, field.regex))
                ) {
                  setValidDynamicFormField(field.name, true);
                } else {
                  setValidDynamicFormField(field.name, false);
                  failed = true;
                }
              } else {
                setValidDynamicFormField(field.name, false);
                failed = true;
              }
            } else if (field.type === 'dunning') {
              if (formData.additionalData[field.name] && formData.additionalData[field.subFields[0].name]) {
                if (
                  !field.subFields[0].regex ||
                  (field.subFields[0].regex &&
                    validateDynamicString(
                      formData.additionalData[field.subFields[0].name],
                      field.subFields[0].required,
                      field.subFields[0].regex,
                    ))
                ) {
                  setValidDynamicFormField(field.name, true);
                } else {
                  setValidDynamicFormField(field.name, false);
                  failed = true;
                }
              } else if (formData.additionalData[field.name] === false) {
                setValidDynamicFormField(field.name, true);
              } else {
                setValidDynamicFormField(field.name, false);
                failed = true;
              }
            } else if (field.type === 'accountNumber') {
              if (!field.required || (field.required && formData.additionalData[field.name])) {
                setValidDynamicFormField(field.name, true);
              } else {
                setValidDynamicFormField(field.name, false);
                failed = true;
              }
            } else {
              setValidDynamicFormField(field.name, true);
            }
          } else if (field.type === 'input' || field.type === 'textarea') {
            const isDefault = field.defaultValue && field.defaultValue.length > 0;
            setDynamicFormField(field.name, isDefault ? field.defaultValue : '');
            const validString = validateDynamicString(isDefault ? field.defaultValue : '', field.required, field.regex);
            setValidDynamicFormField(field.name, validString);
            valid = valid ? validString : valid;
          } else if (field.type === 'checkbox') {
            setDynamicFormField(field.name, field.defaultStatus !== undefined ? field.defaultStatus : false);
            const validCheck = validateDynamicCheck(
              field.defaultStatus !== undefined ? field.defaultStatus : false,
              field.required,
            );
            setValidDynamicFormField(field.name, validCheck);
            valid = valid ? validCheck : valid;
          } else if (
            (field.type === 'select' || field.type === 'radio') &&
            field.defaultName &&
            field.defaultName.length > 0
          ) {
            setDynamicFormField(field.name, field.defaultName);
            setValidDynamicFormField(field.name, true);
          } else if (field.type === 'datepicker' && field.defaultValue && field.defaultValue.length > 0) {
            setDynamicFormField(field.name, field.defaultValue);
            setValidDynamicFormField(field.name, true);
          } else if (field.type === 'timeWindow') {
            if (field.hours && field.hours.from && field.hours.to) {
              if (formData.additionalData[field.hours.to.name] || formData.additionalData[field.hours.from.name]) {
                if (
                  parseInt(formData.additionalData[field.hours.to.name]) -
                    parseInt(formData.additionalData[field.hours.from.name]) >=
                  field.hours.minRange
                ) {
                  if (field.day) {
                    if (formData.additionalData[field.day.name]) {
                      setValidDynamicFormField(field.name, true);
                    } else {
                      setValidDynamicFormField(field.name, false);
                      failed = true;
                    }
                  } else {
                    setValidDynamicFormField(field.name, true);
                  }
                } else {
                  setValidDynamicFormField(field.name, false);
                  failed = true;
                }
              } else {
                if (field.required) {
                  setValidDynamicFormField(field.name, false);
                  failed = true;
                } else {
                  setValidDynamicFormField(field.name, true);
                }
              }
            }
          } else if (field.type === 'invoiceByElma') {
            if (formData.additionalData.ehfAvailable) {
              setValidDynamicFormField(field.name, true);
            } else if (!formData.additionalData.ehfAvailable && formData.additionalData.invoiceEmail) {
              if (
                !field.regex ||
                (field.regex && validateDynamicString(formData.additionalData[field.name], field.required, field.regex))
              ) {
                setValidDynamicFormField(field.name, true);
              } else {
                setValidDynamicFormField(field.name, false);
                failed = true;
              }
            } else {
              setValidDynamicFormField(field.name, false);
              failed = true;
            }
          } else if (field.type === 'dunning') {
            if (formData.additionalData[field.name] && formData.additionalData[field.subFields[0].name]) {
              if (
                !field.subFields[0].regex ||
                (field.subFields[0].regex &&
                  validateDynamicString(
                    formData.additionalData[field.subFields[0].name],
                    field.subFields[0].required,
                    field.subFields[0].regex,
                  ))
              ) {
                setValidDynamicFormField(field.name, true);
              } else {
                setValidDynamicFormField(field.name, false);
                failed = true;
              }
            } else if (formData.additionalData[field.name] === false) {
              setValidDynamicFormField(field.name, true);
            } else {
              setValidDynamicFormField(field.name, false);
              failed = true;
            }
          } else if (field.type === 'businessHours') {
            if (formData.additionalData[field.name] && formData.additionalData[field.name].length > 0) {
              setValidDynamicFormField(field.name, true);
            } else {
              setValidDynamicFormField(field.name, false);
              failed = true;
            }
          } else if (field.type === 'openingHours') {
            let allDaysFilled = true;
            const availableDays = field.dayLabels;
            const addedDays = formData.additionalData[field.name];
            if (availableDays) {
              availableDays.map((day: any, i: any) => {
                const currentItem = addedDays && addedDays.filter((x: any) => x.day === day.value)[0];
                if (!currentItem || !(currentItem.isClosed || (currentItem.from && currentItem.to))) {
                  allDaysFilled = false;
                  failed = true;
                }
              });
            }

            setValidDynamicFormField(field.name, allDaysFilled);
          } else if (field.type === 'fileUpload') {
            if (
              !field.required ||
              (formData.additionalData[field.related] != undefined && formData.additionalData[field.related] === false)
            ) {
              setValidDynamicFormField(field.name, true);
            } else if (formData.additionalData[field.related] && formData.attachments && formData.attachments.length) {
              setValidDynamicFormField(field.name, true);
            } else {
              setValidDynamicFormField(field.name, false);
              failed = true;
            }
          } else if (field.required && !formData.additionalData[field.name]) {
            setValidDynamicFormField(field.name, false);
            failed = true;
          } else {
            setValidDynamicFormField(field.name, true);
          }
        } else if (field.type === 'timeWindow' && field.hours && field.hours.from && field.hours.to) {
          if (formData.additionalData[field.hours.to.name] || formData.additionalData[field.hours.from.name]) {
            if (formData.additionalData[field.hours.from.name] && formData.additionalData[field.hours.to.name]) {
              if (
                parseInt(formData.additionalData[field.hours.to.name]) -
                  parseInt(formData.additionalData[field.hours.from.name]) >=
                field.hours.minRange
              ) {
                if (field.day) {
                  if (formData.additionalData[field.day.name]) {
                    setValidDynamicFormField(field.name, true);
                  } else {
                    setValidDynamicFormField(field.name, false);
                    failed = true;
                  }
                } else {
                  setValidDynamicFormField(field.name, true);
                }
              } else {
                setValidDynamicFormField(field.name, false);
                failed = true;
              }
            } else {
              setValidDynamicFormField(field.name, false);
              failed = true;
            }
          } else {
            if (field.required) {
              setValidDynamicFormField(field.name, false);
              failed = true;
            } else {
              setValidDynamicFormField(field.name, true);
            }
          }
        } else if (field.type === 'isOwnerChange' && formData.additionalData[field.name]) {
          if (formData.additionalData[field.subFields[0].name] && formData.additionalData[field.subFields[1].name]) {
            setValidDynamicFormField(field.name, true);
          } else {
            if (!formData.additionalData[field.subFields[1].name]) {
              setValidDynamicFormField(field.name, false);
              setValidDynamicFormField(field.subFields[1].name, false);
            }
            if (!formData.additionalData[field.subFields[0].name]) {
              setValidDynamicFormField(field.name, false);
              setValidDynamicFormField(field.subFields[0].name, false);
            }
            failed = true;
          }
        } else if (field.type === 'eInvoice') {
          let found = false;
          field.items.map((item: any) => {
            if (t(i18nKeyPrefix + item.name) === formData.additionalData[field.name]) {
              found = true;

              if (!item.subItems || !item.subItems.length) {
                setValidDynamicFormField(field.name, true);
              } else {
                let subItemsCorrect = true;
                item.subItems.map((subItem: any) => {
                  if (
                    formData.additionalData[subItem.name] &&
                    formData.dynamicFormValidFields[subItem.name] !== false
                  ) {
                    setValidDynamicFormField(subItem.name, true);
                  } else {
                    subItemsCorrect = false;
                    setValidDynamicFormField(subItem.name, false);
                  }
                });

                if (!subItemsCorrect) {
                  failed = true;
                  setValidDynamicFormField(field.name, false);
                }
              }
            } else if (item.subItems && item.subItems.length) {
              setValidDynamicFormField(item.subItems[0].name, true);
            }
          });

          if (!found) {
            setValidDynamicFormField(field.name, false);
            failed = true;
          }
        } else if (field.type === 'invoiceByElma') {
          if (formData.additionalData.ehfAvailable) {
            setValidDynamicFormField(field.name, true);
          } else if (!formData.additionalData.ehfAvailable && formData.additionalData.invoiceEmail) {
            if (
              !field.regex ||
              (field.regex && validateDynamicString(formData.additionalData[field.name], field.required, field.regex))
            ) {
              setValidDynamicFormField(field.name, true);
            } else {
              setValidDynamicFormField(field.name, false);
              failed = true;
            }
          } else {
            setValidDynamicFormField(field.name, false);
            failed = true;
          }
        } else if (field.type === 'fileUpload') {
          if (
            !field.required ||
            (formData.additionalData[field.related] != undefined && formData.additionalData[field.related] === false)
          ) {
            setValidDynamicFormField(field.name, true);
          } else if (formData.additionalData[field.related] && formData.attachments && formData.attachments.length) {
            setValidDynamicFormField(field.name, true);
          } else {
            setValidDynamicFormField(field.name, false);
            failed = true;
          }
        } else if (field.type === 'dunning') {
          if (formData.additionalData[field.name] && formData.additionalData[field.subFields[0].name]) {
            if (
              !field.subFields[0].regex ||
              (field.subFields[0].regex &&
                validateDynamicString(
                  formData.additionalData[field.subFields[0].name],
                  field.subFields[0].required,
                  field.subFields[0].regex,
                ))
            ) {
              setValidDynamicFormField(field.name, true);
            } else {
              setValidDynamicFormField(field.name, false);
              failed = true;
            }
          } else if (formData.additionalData[field.name] === false) {
            setValidDynamicFormField(field.name, true);
          } else {
            setValidDynamicFormField(field.name, false);
            failed = true;
          }
        } else if (field.type === 'businessHours') {
          if (formData.additionalData[field.name] && formData.additionalData[field.name].length > 0) {
            setValidDynamicFormField(field.name, true);
          } else {
            setValidDynamicFormField(field.name, false);
            failed = true;
          }
        } else if (field.type === 'openingHours') {
          let allDaysFilled = true;
          const availableDays = field.dayLabels;
          const addedDays = formData.additionalData[field.name];
          availableDays.map((day: any, i: any) => {
            const currentItem = addedDays && addedDays.filter((x: any) => x.day === day.value)[0];
            if (!currentItem || !(currentItem.isClosed || (currentItem.from && currentItem.to))) {
              allDaysFilled = false;
              failed = true;
            }
          });

          setValidDynamicFormField(field.name, allDaysFilled);
        } else if (field.type === 'products' || field.type === 'brands') {
          setValidDynamicFormField(field.name, true);
        } else {
          valid = formData.dynamicFormValidFields[field.name];
          if (!valid) {
            failed = true;
          }
        }
      });
    });
  }

  if (failed) {
    return false;
  } else {
    return valid;
  }
};

export const scrollToError = () => {
  const el = document.querySelector('.formError');
  if (el) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }
};
