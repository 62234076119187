import React, { ReactNode } from 'react';
import styles from './filesList.module.scss';
import cn from 'classnames';
import ListTable from '../ListTable';
import Text, { TEXTCOLOR, SIZES } from '../Text';
import { useSelector } from 'react-redux';
import { State } from '../../reducers';
import { useTranslation } from 'react-i18next';
import { apiEndpoints } from '../../utils/api/endpoints';
import { RequestData } from '../../utils/interfaces/request';
import { i18nKeyPrefix } from '../../utils/constants';

interface Props {
  field: any;
  getFilesRequest?: (data: RequestData) => void;
}

const FilesList: React.FC<Props> = ({
  field,
  getFilesRequest
}: Props) => {
  const { t } = useTranslation();
  const files = useSelector((state: State) => state.form.attachments);
  const saveId = useSelector((state: State) => state.form.saveId.saveId);

  const handleDelete = (i: any, file: any) => {
    getFilesRequest({
      type: 'DELETE',
      endpoint: `${apiEndpoints.signupData}/${saveId}/attachment/${file.aid}`
    });

    setTimeout(() => {
        getFilesRequest({
            type: 'GET',
            noAuth: true,
            endpoint: `${apiEndpoints.signupData}/${saveId}/attachment/`,
          });
    }, 2000);
  }

  return (
    <div className={cn(styles.filesList)}>
     {files && files.length > 0 &&
      <div className={styles.files}>
        <div className={styles.fileList} data-i18n-key={field.copyTitleMsg}>
          <Text size={SIZES.SMALL} bold={true} color={TEXTCOLOR.BLACK}>{t(i18nKeyPrefix + field.copyTitleMsg)}</Text>
        </div>
        <ListTable items={files} deleteAction={(i: any, file: any) => handleDelete(i, file)}/>
        {files.length === 5 && <div className={styles.infoText}>
          <Text size={SIZES.TINY} color={TEXTCOLOR.BLACK}><span data-i18n-key={field.copyLimitsMsg}>*{t(i18nKeyPrefix + field.copyLimitsMsg)}</span></Text>
        </div>
        }
      </div>
    }
    </div>
  );
}

export default FilesList;