import React, { Dispatch, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { State } from '../reducers';
import immutableToJS from 'with-immutable-props-to-js';
import SignUpFormComponent from '../components/SignUpForm';
import { isGlobalLoading, getGlobalContent, getGlobal } from "../utils/selectors/headless/selectors";
import { HeadlessGlobal } from "../utils/interfaces/headless";
import { getLanguage } from "../utils/selectors/language/selectors";
import { useTranslation } from "react-i18next";
interface Props {
  content: HeadlessGlobal;
  language: any;
}

const SignUpFormContainer: React.FC<Props> = ({
  content,
  language,
}: Props) => {

  return (
    <SignUpFormComponent />
  );
};

const mapStateToProps = (state: State) => {
  return {
    isLoading: getGlobal(state) && isGlobalLoading(state),
    content: getGlobalContent(state),
    language: getLanguage(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(SignUpFormContainer));
