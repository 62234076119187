import React, { createRef, RefObject, useState, useEffect } from 'react';
import styles from './datepicker.module.scss';
import cn from "classnames";
import { getCurrentWidth } from '../../utils/custom-hooks/handleWindowResize';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';
import Text, { SIZES } from '../Text';
import { app, i18nKeyPrefix } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import Modal from '../../containers/Modal';
import Calendar from './Calendar';
import Overlay from '../Overlay';
import { StringDecoder } from 'string_decoder';


interface Props {
  onChange?: (date?: any) => void;
  label?: string;
  type?: string;
  value?: any;
  valid?: boolean;
}

const DatePicker: React.FC<Props> = ({
  label,
  onChange,
  value,
  valid = true,
}: Props) => {
  const size = getCurrentWidth();
  const { t } = useTranslation();

  const [windowSize, setWindowSize] = useState(size);
  useEffect(() => {
    setWindowSize(size);
  });

  const buttonRef: RefObject<HTMLInputElement> = createRef();
  const isMobile = windowSize <= app.breakPoint;

  const [stateValue, setStateValue] = useState(null);

  useEffect(() => {
    if (value) {
      setStateValue(new Date(value).toDateString());
    } else {
      setStateValue(new Date().toDateString());
      onChange(new Date().toDateString());
    }
  }, []);

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const onDatePicked = (date: Date) => {
    setStateValue(date.toDateString());
    setOpenDatePicker(false);

    if (onChange) {
      // Make up for timezone difference
      const x = new Date(date);
      const hoursDiff = x.getHours() - x.getTimezoneOffset() / 60;
      const minutesDiff = (x.getHours() - x.getTimezoneOffset()) % 60;
      x.setHours(hoursDiff);
      x.setMinutes(minutesDiff);
      onChange(x);
    }
  }


  return (
    <div className={cn(styles.wrapper, { [styles.error]: !valid })}>
      {label &&
        <label className={styles.label} onClick={() => buttonRef.current.click()} >
          <Text size={isMobile ? SIZES.TINY : SIZES.SMALL}>{label}</Text>
        </label>
      }
      <button
        type="button"
        ref={buttonRef}
        className={cn(styles.input, {
          [styles.active]: openDatePicker
        })}
        data-i18n-key={'entries.datepicker.nodate'}
        onClick={() => setOpenDatePicker(true)}>

        {stateValue ? stateValue : t(i18nKeyPrefix + 'entries.datepicker.nodate', { defaultValue: 'Select date' })}
        <span className={styles.icon}>
          <CalendarIcon />
        </span>
      </button>

      {isMobile ?
        <Modal
          active={openDatePicker}
          closeModal={() => setOpenDatePicker(false)}>
          <Calendar title={label} onChange={onDatePicked} />
        </Modal>
        :
        <>
          <div
          className={cn(styles.dropdown, {
            [styles.open]: openDatePicker
          })}>
            <Calendar title={label} onChange={onDatePicked} />
          </div>
          <Overlay transparent active={openDatePicker} action={() => setOpenDatePicker(false)} />
        </>
      }

    </div>
  );
};

export default DatePicker;
