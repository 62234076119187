import React, { useState, useEffect } from 'react';
import styles from './footer.module.scss';
import { getCurrentWidth } from '../../utils/custom-hooks/handleWindowResize';
import { app, i18nKeyPrefix } from '../../utils/constants';
import { Link } from 'react-router-dom';
import { getGlobalContent } from '../../utils/selectors/headless/selectors';
import immutableToJS from 'with-immutable-props-to-js';
import { State } from '../../reducers';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface Props {
  global?: any;
}

const FooterComponent: React.FC<Props> = ({
  global
}: Props) => {
  const { t } = useTranslation();
  const prefix = i18nKeyPrefix + 'LeanSignup_Footer';
  const lang = localStorage.getItem('i18nextLng');
  const marketConfig = useSelector((state: State) => state.router.marketConfig);

  const size = getCurrentWidth();
  const [windowSize, setWindowSize] = useState(size);

  useEffect(() => {
    setWindowSize(size);
  });

  const isMobile = windowSize <= app.breakPoint;

  return (
    <div className={styles.wrapper} id="footer">
      <div className={styles.top}>
        <p>
          <span data-i18n-key={`${prefix}_Corporate_Name`}>{t(`${prefix}_Corporate_Name`)}</span>
          <span data-i18n-key={`${prefix}_Corporate_Address`}>{t(`${prefix}_Corporate_Address`)}</span>
          <span data-i18n-key={`${prefix}_Corporate_Country`}>{t(`${prefix}_Corporate_Country`)}</span>
        </p>

        <p>
          <span data-i18n-key={`${prefix}_Corporate_Phone`}>{t(`${prefix}_Corporate_Phone`)}</span>
          <a href={`mailto:` + t(`${prefix}_Corporate_Email`)} data-i18n-key={`${prefix}_Corporate_Email`}>{t(`${prefix}_Corporate_Email`)}</a>
        </p>
      </div>

      <div className={styles.content}>
        <div className={styles.left}>
          <h6 data-i18n-key={`${prefix}_Menu_Legal_Title`}>{t(`${prefix}_Menu_Legal_Title`)}</h6>
          { marketConfig?.privacyPolicy === null &&
              <Link to={`/${lang}/privacy-policy`}>
                <span data-i18n-key={`${prefix}_Menu_Legal_PrivacyPolicy`}>{t(`${prefix}_Menu_Legal_PrivacyPolicy`)}</span>
              </Link>
          }
          { marketConfig?.privacyPolicy && marketConfig?.privacyPolicy !== null &&
            <a rel="noopener noreferrer" href={`${marketConfig?.privacyPolicy}`} target="_blank" data-i18n-key={`${prefix}_Menu_Legal_PrivacyPolicy`}>
              {t(`${prefix}_Menu_Legal_PrivacyPolicy`)}
            </a>
          }
          { marketConfig?.cookiePolicy === null &&
              <Link to={`/${lang}/cookie-policy`}>
                <span  data-i18n-key={`${prefix}_Menu_Legal_CookiePolicy`}>{t(`${prefix}_Menu_Legal_CookiePolicy`)}</span>
              </Link>
          }
          { marketConfig?.cookiePolicy && marketConfig?.cookiePolicy !== null &&
            <a rel="noopener noreferrer" href={`${marketConfig?.cookiePolicy}`} target="_blank" data-i18n-key={`${prefix}_Menu_Legal_CookiePolicy`}>
              {t(`${prefix}_Menu_Legal_CookiePolicy`)}
            </a>
          }
          {/* <Link to={`/${lang}`} >Acceptable use</Link> */}
          { marketConfig?.termsAndCond === null &&
              <Link to={`/${lang}/terms-and-conditions`}>
                <span data-i18n-key={`${prefix}_Menu_Legal_TermsOfUse`}>{t(`${prefix}_Menu_Legal_TermsOfUse`)}</span>
              </Link>
          }
          { marketConfig?.termsAndCond && marketConfig?.termsAndCond !== null &&
            <a rel="noopener noreferrer" href={`${marketConfig?.termsAndCond}`} target="_blank" data-i18n-key={`${prefix}_Menu_Legal_TermsOfUse`}>
              {t(`${prefix}_Menu_Legal_TermsOfUse`)}
            </a>
          }
          <a onClick={() => {window.com_carlsberg_compliance_pack.manageCookies();}}>{t(`${i18nKeyPrefix}LeanSignup_Form_Footer_manageCookies`)}</a>
        </div>
        <div className={styles.right}>
          <h6>{t(`${prefix}_Menu_Informational_Title`)}</h6>
          <Link to={`/${lang}`}>
            <span data-i18n-key={`${prefix}_Menu_Informational_Contact`}>{t(`${prefix}_Menu_Informational_Contact`)}</span>
          </Link>
          <Link to={`/${lang}`}>
            <span data-i18n-key={`${prefix}_Menu_Informational_Subscribe`}>{t(`${prefix}_Menu_Informational_Subscribe`)}</span>
          </Link>
          <Link to={`/${lang}`}>
            <span data-i18n-key={`${prefix}_Menu_Informational_SpeakUp`}>{t(`${prefix}_Menu_Informational_SpeakUp`)}</span>
          </Link>
        </div>
      </div>

      <div className={styles.credits}>
        <span data-i18n-key={`${prefix}_Menu_PoweredBy`}>{t(`${prefix}_Menu_PoweredBy`)}</span>
        <strong data-i18n-key={`${prefix}_Menu_OrganizationName`}>
          { (global && global.footer && global.footer.copyrightLink && global.footer.copyrightLink.url) &&
            <a rel="noopener noreferrer" target="_blank" href={global.footer.copyrightLink.url}>{t(`${prefix}_Menu_OrganizationName`)}</a>
          } 
          { !(global && global.footer && global.footer.copyrightLink && global.footer.copyrightLink.url) &&
            <>{t(`${prefix}_Menu_OrganizationName`)}</>
          } 
        </strong>
      </div>
    </div>
  );
}

const mapStateToProps = (state: State) => {
  return {
    global: getGlobalContent(state)
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(FooterComponent));
