import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './genericAddress.module.scss';
import Input from '../../../Input';
import ScreenTitle from '../../ScreenTitle';
import { useSelector } from 'react-redux';
import { State } from '../../../../reducers';
import PhoneInput from '../../../PhoneInput';
import { i18nKeyPrefix } from '../../../../utils/constants';

interface Props {
  onNameChange?: (company: string) => void;
  onAddressChange?: (address: string) => void;
  onZipCodeChange?: (code: string) => void;
  onCityChange?: (city: string) => void;
  onPhoneChange?: (number: string) => void;
  onEmailChange?: (string: string) => void;
  type: string;
  emailExist?: boolean;
  phoneNumberExist?: boolean;
  nameMandatory?: boolean;
  emailMandatory?: boolean;
  showDescription?: boolean;
  readOnlyEstablishment?: boolean;
  emailSubtitle?: boolean;
  fieldsDisabled?: boolean;
  outletNameHide?: boolean;
}

const GenericAddress: React.FC<Props> = ({
  onNameChange,
  onAddressChange,
  onZipCodeChange,
  onCityChange,
  onPhoneChange,
  onEmailChange,
  emailExist = false,
  phoneNumberExist = false,
  nameMandatory = false,
  emailMandatory = false,
  showDescription = false,
  readOnlyEstablishment = false,
  emailSubtitle = false,
  fieldsDisabled = false,
  outletNameHide = false,
  type
}: Props) => {
  const { t } = useTranslation();
  const marketData = useSelector((state: State) => state.router.marketData);
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_Address';

  const validAddress = useSelector((state: State) => state.form[`validAddress${type}`]);
  const validCityName = useSelector((state: State) => state.form[`validCityName${type}`]);
  const validName = useSelector((state: State) => state.form[`validName${type}`]);
  const validZipCode = useSelector((state: State) => state.form[`validZipCode${type}`]);
  const validPhone = useSelector((state: State) => state.form[`validPhone${type}`]);
  const validEmail = useSelector((state: State) => state.form[`validEmail${type}`]);

  const address = useSelector((state: State) => state.form[`address${type}`]);
  const cityName = useSelector((state: State) => state.form[`cityName${type}`]);
  const name = useSelector((state: State) => state.form[`name${type}`]);
  const zipCode = useSelector((state: State) => state.form[`zipCode${type}`]);
  const phone = useSelector((state: State) => state.form[`phone${type}`]);
  const email = useSelector((state: State) => state.form[`email${type}`]);

  return (
    <div className={styles.wrapper} data-i18n-key-title={`${prefix}_title${type}`}>
        <ScreenTitle title={t(`${prefix}_title${type}`)} description={showDescription ? t(`${prefix}_description${type}`): null}></ScreenTitle>

        <div className={styles.form}>
          {!outletNameHide &&
            <span
              data-i18n-key={`${prefix}_name${type}`}
              data-i18n-key-error={`${prefix}_nameError`}>
              <Input
                label={t(`${prefix}_name${type}`)}
                placeholder={t(`${prefix}_name${type}`)}
                onChange={onNameChange}
                onBlur={onNameChange}
                value={name}
                maxLength={35}
                required={nameMandatory}
                valid={validName}
                disabled={readOnlyEstablishment || fieldsDisabled}
                errorMessage={t(`${prefix}_nameError`)}
                dataId={`inputName${type}`}
              />
            </span>
          }

          <span
            data-i18n-key={`${prefix}_label`}
            data-i18n-key-error={`${prefix}_error`}>
            <Input
              label={t(`${prefix}_label`)}
              placeholder={t(`${prefix}_label`)}
              onChange={onAddressChange}
              onBlur={onAddressChange}
              required
              value={address}
              maxLength={35}
              valid={validAddress}
              disabled={fieldsDisabled}
              errorMessage={t(`${prefix}_error`)}
              dataId={`inputAddress${type}`}
            />
          </span>
          <div className={styles.zipCityContainer}>
            <span
              data-i18n-key={`${prefix}_zipLabel`}
              data-i18n-key-error={`${prefix}_zipError`}>
              <Input
                label={t(`${prefix}_zipLabel`)}
                placeholder={t(`${prefix}_zipLabel`)}
                onChange={onZipCodeChange}
                onBlur={onZipCodeChange}
                value={zipCode}
                required
                valid={validZipCode}
                disabled={fieldsDisabled}
                errorMessage={t(`${prefix}_zipError`)}
                dataId={`inputZip${type}`}
              />
            </span>


            <span
              data-i18n-key={`${prefix}_cityLabel`}
              data-i18n-key-error={`${prefix}_cityError`}>
              <Input
                label={t(`${prefix}_cityLabel`)}
                placeholder={t(`${prefix}_cityLabel`)}
                onChange={onCityChange}
                onBlur={onCityChange}
                required
                valid={validCityName}
                value={cityName}
                maxLength={35}
                disabled={fieldsDisabled}
                errorMessage={t(`${prefix}_cityError`)}
                dataId={`inputCity${type}`}
              />
            </span>
          </div>

          <div className={styles.emailPhoneContainer}>
            {emailExist &&
              <span
                data-i18n-key={`${prefix}_emailLabel`}
                data-i18n-key-error={`${prefix}_emailError`}>
                <Input
                  label={t(`${prefix}_emailLabel`)}
                  placeholder={t(`${prefix}_emailLabel`)}
                  onChange={onEmailChange}
                  onBlur={onEmailChange}
                  value={email}
                  required={emailMandatory || (email ? true : false)}
                  errorMessage={t(`${prefix}_emailError`)}
                  valid={validEmail}
                  info={emailSubtitle ? t(`${prefix}_emailSubtitle${type}`) : null}
                  dataId={`inputEmail${type}`}
                  maxLength={65}
                />
              </span>

            }
            {phoneNumberExist &&
              <span
                data-i18n-key={`${prefix}_numberLabel`}
                data-i18n-key-error={`${prefix}_numberError`}>
                <PhoneInput
                  label={t(`${prefix}_numberLabel`)}
                  placeholder={t(`${prefix}_numberLabel`)}
                  required
                  defaultCountry={marketData?.marketId.toUpperCase()}
                  value={phone}
                  onChange={onPhoneChange}
                  errorMessage={t(`${prefix}_numberError`)}
                  valid={validPhone}
                  dataId={`inputPhone${type}`}
                />
              </span>
            }
          </div>
        </div>
    </div>
  );
}

export default GenericAddress;