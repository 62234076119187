import React, { useEffect, useState } from 'react';
import { getCurrentHeight } from '../../../../utils/custom-hooks/handleWindowResize';
import { getCurrentWidth } from '../../../../utils/custom-hooks/handleWindowResize';
import styles from './countdown.module.scss';
import { HeadlessGridEditorViewModel } from '../../../../utils/interfaces/headless';
import { app } from '../../../../utils/constants';

interface CountdownViewModel {
  viewModel: HeadlessGridEditorViewModel;
}

const Countdown: React.FC<CountdownViewModel> = ({
  viewModel
}: CountdownViewModel) => {

  const el = React.createRef<HTMLDivElement>();
  const scrollEl = document.getElementById('mainGrid')

  const size = getCurrentWidth();
  const [windowSize, setWindowSize] = useState(size);

  useEffect(() => {
    setWindowSize(size);
  });

  const isMobile = windowSize <= app.breakPoint;

  const [offset, setOffset] = useState(0);
  const height = getCurrentHeight();

  useEffect(() => {
    function handleScroll(event: any) {
      setOffset((document.getElementById('countdown').getBoundingClientRect().top * -1) + 200);
    }

    scrollEl?.addEventListener("scroll", handleScroll)

    return function cleanup() {
      scrollEl?.removeEventListener("scroll", handleScroll)
    }
  })

  return (
    <div className={styles.countdown} id="countdown" ref={ el }>
      {(offset > ((height / 2) * -1) && offset < (height / 8)) &&
      <div className={styles.step}
        style={{
          fontSize: `${offset > 0 ? offset * 10 : 0}px`
        }}
      >
        <h1>1</h1>
        <p>test</p>
      </div>}
      {(offset > (height / 8) && offset < height / 4.3) &&
      <div className={styles.step}
        style={{
          fontSize: `${offset > 0 ? (offset - (height / 9)) * 10 : 0}px`
        }}
      >
        <h1>2</h1>
        <p>test</p>
      </div>}
      {(offset > (height / 4.3) && offset < (height / 3) * 2) &&
      <div className={styles.step}
        style={{
          fontSize: `${offset > 0 ? (offset - (height / 6)) * (isMobile ? 2.7 : 3) : 0}px`
        }}
      >
        <h1>3</h1>
        <p>test</p>
      </div>}
    </div>
  )
};

export default Countdown;
