import React, { createRef, RefObject, useState, useEffect } from 'react';
import styles from './selectwithtextfield.module.scss';
import { getCurrentWidth } from '../../utils/custom-hooks/handleWindowResize';
import Text, { SIZES } from '../Text';
import { app, i18nKeyPrefix } from '../../utils/constants';
import cn from 'classnames';
import { ReactComponent as ChevronDown } from '../../assets/images/chevron-down.svg';
import { useTranslation } from 'react-i18next';
import { headlessRequest } from '../../utils/api/headlessRequest';

interface SelectValue {
  optionValue: string;
  otherValue: string | null;
}

interface Props {
  onChangeOption?: (e?: any) => void;
  onChangeOther?: (e?: any) => void;
  label?: string;
  value?: SelectValue;
  options?: any;
  hasUmbracoOptions?: boolean;
  hasOtherOption?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  valid?: boolean;
  required?: boolean;
  id?: string;
  dataId?: string;
  translateOptions?: boolean;
  size?: string;
  headlessRequestName?: string;
  headlessPropertyName?: string;
}

const SelectWithTextField: React.FC<Props> = ({
  label,
  value,
  onChangeOption,
  onChangeOther,
  options,
  hasOtherOption,
  hasUmbracoOptions,
  disabled,
  hideLabel,
  valid = true,
  required,
  id,
  dataId,
  translateOptions = false,
  size,
  headlessRequestName,
  headlessPropertyName,
}: Props) => {
  const screenSize = getCurrentWidth();
  const { t } = useTranslation();

  const [windowSize, setWindowSize] = useState(screenSize);
  const [umbracoOptions, setUmbracoOptions] = useState([]);
  const otherOptionSelected = value?.optionValue === 'LeanSignup_Form_Custom_SelectWithTextField_other';

  useEffect(() => {
    setWindowSize(screenSize);
  });

  useEffect(() => {
    if (hasUmbracoOptions) {
      const fetchHeadlessData = async () => {
        let headlessRequestResponse: any = await headlessRequest(headlessRequestName);
        let uOptions = headlessRequestResponse.route?.gateway?.treeView.filter(
          (item: any) => item.propertyName === headlessPropertyName,
        )[0].propertyItems;

        setUmbracoOptions(uOptions);
      };

      fetchHeadlessData().catch((error) => {
        console.error(error);
      });
    }
  }, []);

  const inputRef: RefObject<HTMLSelectElement> = createRef();
  const isMobile = windowSize <= app.breakPoint;

  const renderOptions = () => {
    return options.map((option: any, index: number) => {
      return (
        <option
          disabled={option.disabled}
          className={cn(styles.option, { [styles.disabled]: option.disabled })}
          value={translateOptions ? t(i18nKeyPrefix + (option.value || option)) : option.value || option}
          key={index}
        >
          {translateOptions ? t(i18nKeyPrefix + (option.label || option)) : option.label || option}
        </option>
      );
    });
  };

  const renderUmbracoOptions = () => {
    return umbracoOptions.map((option: any, index: number) => {
      return (
        <option
          disabled={option.disabled}
          className={cn(styles.option, { [styles.disabled]: option.disabled })}
          value={
            translateOptions ? t(i18nKeyPrefix + (option.propertyValue || option)) : option.propertyValue || option
          }
          key={index}
        >
          {translateOptions ? t(i18nKeyPrefix + (option.propertyLabel || option)) : option.propertyLabel || option}
        </option>
      );
    });
  };

  if (!value) {
    value = { optionValue: '', otherValue: null };
  }

  return (
    <div className={styles.wrapper}>
      {label && (
        <label className={cn(styles.label, { [styles.disabled]: disabled })} onClick={() => inputRef.current.focus()}>
          <Text size={isMobile ? SIZES.TINY : SIZES.SMALL} bold>
            {!hideLabel ? label : ''}
          </Text>
        </label>
      )}
      <div className={styles.selectWithTextField}>
        <select
          data-id={dataId}
          className={cn(styles.select, {
            [styles.normal]: size === 'normal',
            [styles.disabled]: disabled,
            [styles.error]: required && !valid && !value.optionValue,
          })}
          ref={inputRef}
          value={value.optionValue}
          onChange={onChangeOption}
          disabled={disabled}
          required={required}
          id={id}
        >
          <option value="" disabled data-i18n-key={'LeanSignup_Form_Custom_general_selectOption'}>
            {t(i18nKeyPrefix + 'LeanSignup_Form_Custom_general_selectOption', { defaultValue: 'Select option' })}
          </option>

          {options && options.length && renderOptions()}

          {hasUmbracoOptions && renderUmbracoOptions()}

          {hasOtherOption && (
            <option
              value={'LeanSignup_Form_Custom_SelectWithTextField_other'}
              data-i18n-key={'LeanSignup_Form_Custom_SelectWithTextField_other'}
            >
              {t(i18nKeyPrefix + 'LeanSignup_Form_Custom_SelectWithTextField_other', { defaultValue: 'Other' })}
            </option>
          )}
        </select>
        <span
          className={cn(styles.icon, {
            [styles.disabled]: disabled,
            [styles.otherOptionSelected]: otherOptionSelected,
          })}
        >
          <ChevronDown />
        </span>
        {otherOptionSelected ? (
          <input
            className={cn(styles.input, { [styles.error]: required && !valid })}
            onChange={onChangeOther}
            value={value.otherValue}
            placeholder={t(i18nKeyPrefix + 'LeanSignup_Form_Custom_SelectWithTextField_Other_Placeholder', {
              defaultValue: 'Tell us which one?',
            })}
            required={required}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SelectWithTextField;
