import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './formFooter.module.scss';
import cn from 'classnames';
import Button from '../../Button';
import { useSelector } from 'react-redux';
import { State } from '../../../reducers';
import { i18nKeyPrefix } from '../../../utils/constants';

interface Props {
  type?: string;
  form?: string;
  validateStep?: any;
  showLoader?: boolean;
  disabled?: boolean;
}

const FormFooterComponent: React.FC<Props> = ({
  type,
  form,
  validateStep,
  showLoader,
  disabled
}: Props) => {
  const { t } = useTranslation();
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_Navigation';
  const step = useSelector((state: State) => state.form.step);
  const review = useSelector((state: State) => state.form.review);
  const marketConfig = useSelector((state: State) => state.router.marketConfig);


  return (
    <div className={cn([styles.formFooter, {[styles.formMargin]: step === 6 }])}>
      <div className={cn(styles.nextButton, {[styles.reviewButton]: step === 6 || step === 8}, {[styles.editButton]: step === 7})}
        data-i18n-key-review={`${prefix}_review`}
        data-i18n-key-continue={`${prefix}_continue`}
        data-i18n-key-validate={`${prefix}_validate`}
        data-i18n-key-next={`${prefix}_next`}
      >
        <Button
          text={review ? t(`${prefix}_review`) : step === 6 ? t(`${prefix}_continue`) : (step === 8 || step === 2) ? t(`${prefix}_validate`) : t(`${prefix}_next`)}
          color="black"
          type="submit"
          size="medium"
          style="noBorder"
          disabled={disabled}
          cbLoader={showLoader}
          action={validateStep}
          genericLoader={marketConfig.genericLoader}
        />
      </div>
    </div>
  );
}

export default FormFooterComponent;