import { fromJS } from 'immutable';
import { Reducer } from 'redux';
import { actions } from '../constants/modal';

export const initialState: unknown = fromJS({});

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_MODAL_TOGGLE:
      state = state.set('isOpen', fromJS(action.isModalOpen));
      return state;
    default: {
      return state;
    }
  }
};

export { reducer as modalReducer };
