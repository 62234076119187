import React, { useEffect, useState, useRef } from 'react';
import styles from './dayHours.module.scss';
import cn from 'classnames';
import Select from '../Select';
import { ReactComponent as ArrowDown } from '../../assets/images/chevron-down.svg';
import Text, { SIZES } from '../Text';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowUp } from '../../assets/images/chevron-up.svg';
import { i18nKeyPrefix } from '../../utils/constants';
import Checkbox, { CHECKSIZES } from '../Checkbox';
import Button from '../Button';
import { Select as SelectMalty, SelectSize, SelectType } from '@carlsberggroup/malty.atoms.select';

interface Props {
  daySelected: any;
  currentDay: any;
  currentDayIndex: any;
  field: any;
  hours: any;
  hoursTo?: any;
  days: any;
  setDayTimeFrom: (day, time, type) => void;
  applyToOtherDays: (day, selectedAppliedDays) => void;
}

const DayHours: React.FC<Props> = ({
  daySelected,
  currentDay,
  currentDayIndex,
  field,
  hours,
  hoursTo,
  days,
  setDayTimeFrom,
  applyToOtherDays
}: Props) => {
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_Custom_OpeningHours';
  const [appliedDays, setAppliedDays] = useState([]);
  const [selectedAppliedDays, setSelectedAppliedDays] = useState([]);
  const { t } = useTranslation();
  const checkboxref = useRef(null);

  useEffect(() => {
    let appliedDaysArr = [];
    days.map((day: any, i: any) => {
      appliedDaysArr.push({
        label: day.label,
        value: day.value,
        name: t(i18nKeyPrefix + day.label)
      });
    });
    setAppliedDays(appliedDaysArr);
  }, []);

  const onApply = () => {
    applyToOtherDays(daySelected, selectedAppliedDays);
    setSelectedAppliedDays([]);
    checkboxref.current.checked = false;
  }

  return (
    <div className={styles.dayWrapper} data-i18n-key={currentDay.label}>
      <input className={styles.toggleCheck} id={"toggle_" + currentDayIndex} type="checkbox"></input>
      <div className={styles.dayContainer}>
        <div><Text size={SIZES.MEDIUM} bold>{t(i18nKeyPrefix + currentDay.label)}</Text></div>
        <label className={styles.buttonContainer} data-i18n-key={`${prefix}_select_close_open`} htmlFor={"toggle_" + currentDayIndex}>
          <span className={styles.buttonText}>
            <Text size={SIZES.MEDIUM}>{(daySelected && (daySelected.from || daySelected.to)) ? `${daySelected.from || ''} - ${daySelected.to || ''}` : daySelected && daySelected.isClosed ? t(`${prefix}_closed`) : t(`${prefix}_select`)}</Text>
          </span>
          <span className={cn(styles.buttonIcon, styles.iconDown)}><ArrowDown/></span>
          <span className={cn(styles.buttonIcon, styles.iconUp)}><ArrowUp/></span>
        </label>
      </div>
      <div className={styles.expandedPanel}>
        <div className={styles.deliveryStatus}>
          <Checkbox
            required={field.required}
            size={CHECKSIZES.SMALL}
            dataId={`check${field.name}`}
            onChange={(event) => {
              setDayTimeFrom(currentDay, event.target.checked, "closed")
            }}
            checked={(daySelected && daySelected.isClosed) ? true : false}
          >
            <Text size={SIZES.MEDIUM}>
              {t(`${prefix}_closed`)}
            </Text>
          </Checkbox>
        </div>
        <div className={cn(styles.deliveryInputs, {[styles.hiddenInputs] : daySelected && daySelected.isClosed})}>
          <div className={styles.deliveryInput}>
            <div><Text size={SIZES.SMALL} bold>{t(`${prefix}_hoursList_fromLabel`)}</Text></div>
            <Select size='normal' value={daySelected && daySelected.from} options={hours} onChange={(event) => setDayTimeFrom(currentDay, event.target.value, "timeFrom")}/>
          </div>
          <div className={styles.deliveryInputSeparator}>{t(`${prefix}_hoursList_separatorLabel`)}</div>
          <div className={styles.deliveryInput}>
            <div><Text size={SIZES.SMALL} bold>{t(`${prefix}_hoursList_toLabel`)}</Text></div>
            <Select size='normal' value={daySelected && daySelected.to} options={hoursTo ? hoursTo : hours} onChange={(event) => setDayTimeFrom(currentDay, event.target.value, "timeTo")}/>
          </div>
        </div>
        
        <div className={styles.applyDays}>
          <input ref={checkboxref} className={styles.toggleCheckOtherDays} id={"toggleOtherDays_" + currentDayIndex} type="checkbox"></input>
          <label className={styles.applyText} data-i18n-key={`${prefix}_apply_close_open`} htmlFor={"toggleOtherDays_" + currentDayIndex}>
            <Text size={SIZES.MEDIUM}>{t(`${prefix}_applyOthers`)}</Text>
          </label>

          <div className={styles.applyDaysInput}>
            <div><Text size={SIZES.SMALL} bold>{t(`${prefix}_applyOthers`)}</Text></div>
            <div className={styles.applyButtonContainer}>
              <div>
                <SelectMalty
                  placeholder={t(`${prefix}_othersPlaceholder`)}
                  multiple={true}
                  options={appliedDays}
                  type={SelectType.Default}
                  label={''}
                  onValueChange={(val) => {setSelectedAppliedDays(val)}}
                  value={selectedAppliedDays}
                  size={SelectSize.Medium}
                ></SelectMalty>  
              </div>
              <div>
                <label className={styles.cancelButton} htmlFor={"toggleOtherDays_" + currentDayIndex}>
                  {t(`${prefix}_cancel`)}
                </label>
              </div>
              <div><Button
                disabled={!(((daySelected && daySelected.from && daySelected.to) || (daySelected && daySelected.isClosed)) && selectedAppliedDays.length)}
                text={t(`${prefix}_apply`)}
                color='black'
                type='submit'
                size='normal'
                style='noBorder'
                action={onApply}
              /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DayHours;