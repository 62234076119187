export const formatTwoDigit = (numberToFormat: number) => {
  if (numberToFormat < 10) {
    return `0${numberToFormat}`;
  } else {
    return numberToFormat;
  }
}

export const getTodayInIsoFormat = () => {
  const today: any = new Date();

  const dd = formatTwoDigit(today.getDate());
  const mm = formatTwoDigit(today.getMonth() + 1);
  const  yyyy = today.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
}

export const formatFullDate = (dateToFormat: Date | string) => {
  const formattedDate: any = new Date(dateToFormat);

  const dd = formatTwoDigit(formattedDate.getDate());
  const mm = formatTwoDigit(formattedDate.getMonth() + 1);
  const  yyyy = formattedDate.getFullYear();
  const hh = formatTwoDigit(formattedDate.getHours());
  const mi = formatTwoDigit(formattedDate.getMinutes());
  const ss = formatTwoDigit(formattedDate.getSeconds());

  return `${yyyy}-${mm}-${dd} ${hh}:${mi}:${ss}`;
}