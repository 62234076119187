import React from 'react';
import styles from './day.module.scss';
import { useRef, useContext } from "react";
import { useDay } from "@datepicker-react/hooks";
import DatepickerContext from "../DatePickerContext";
import cn from "classnames";

interface Props {
  dayLabel: any;
  date: any;
}

const Day: React.FC<Props> = ({
  dayLabel,
  date
}: Props) => {

  const dayRef = useRef(null);

  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover
  } = useContext(DatepickerContext);

  const {
    isSelected,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef
  });

  if (!dayLabel) {
    return <div />;
  }

  return (
    <div className={styles.day}>
      <button
        onClick={onClick}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        tabIndex={tabIndex}
        type="button"
        ref={dayRef}

        className={cn(styles.button, styles.ripple, styles.noSelect, {
          [styles.selected]: isSelected,
          [styles.disabled]: disabledDate,
        })}

      >
        {dayLabel}
      </button>
    </div>
  );
};

export default Day;
