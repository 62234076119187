import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { app } from './utils/constants';
import TagManager from 'react-gtm-module';
import ErrorNotFoundComponent from './components/ErrorNotFound';
import Loadable from '@loadable/component';
import useGTM from '@elgorditosalsero/react-gtm-hook';
import { getConfig, getConfigUrl, configName, getAWSConfig } from './utils/config/getConfig';
import { setConfig, setMarket, setMarketConfig } from './actions/router';
import { State } from './reducers';
import { setSignupAdditionalForm } from './actions/form';

const PrivateRoute = Loadable(() => import('./containers/PrivateRoute'));
const Landing = Loadable(() => import('./containers/Landing'));
const Header = Loadable(() => import('./containers/Header'));
const Footer = Loadable(() => import('./containers/Footer'));
const SignUpForm = Loadable(() => import('./containers/SignUpForm'));
const Cookies = Loadable(() => import('./containers/Cookies'));
const TermsAndConditions = Loadable(() => import('./containers/TermsAndConditions'));
const Privacy = Loadable(() => import('./containers/Privacy'));
const CompliancePack = Loadable(() => import('./components/CompliancePack'));
//const CookiePopup = Loadable(() => import('./containers/CookiePopup'));

const Router: React.FC<any> = () => {
  const dispatch = useDispatch();

  const [isMobile, setMobile] = useState<boolean>(window.innerWidth <= app.breakPoint);
  const configData: any = useSelector((state: State) => state.router.configData);
  const marketConfig: any = useSelector((state: State) => state.router.marketConfig);
  const { init, UseGTMHookProvider } = useGTM();

  useLayoutEffect(() => {
    (async function () {
      const marketDataObj = await getConfig(`./${getConfigUrl(configName.market)}`, configName.market);

      if (marketDataObj) {
        const configDataObj = await getAWSConfig(true);

        if (marketDataObj?.marketId) {
          const marketConfigObj = await getAWSConfig();
          dispatch(setMarket(marketDataObj));
          dispatch(setConfig(configDataObj));
          dispatch(setMarketConfig(marketConfigObj));
          dispatch(setSignupAdditionalForm(marketConfigObj));
          console.info('LSU Market ID:', marketDataObj.marketId);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (configData?.gtm_id) {
      if (
        configData?.gtm_preview &&
        configData?.gtm_preview.length &&
        configData?.gtm_auth &&
        configData?.gtm_auth.length
      ) {
        const tagManagerInitializeArgs = {
          gtmId: configData?.gtm_id,
          auth: configData?.gtm_auth,
          preview: configData?.gtm_preview,
        };

        TagManager.initialize(tagManagerInitializeArgs);
      } else {
        init({ id: configData?.gtm_id });
      }
    }
  }, [configData]);

  useEffect(() => {
    function addScrollListener() {
      setMobile(window.innerWidth <= app.breakPoint);
    }

    window.addEventListener('resize', addScrollListener, false);

    return function removeScrollListener() {
      window.removeEventListener('resize', addScrollListener, false);
    };
  }, []);

  return (
    <UseGTMHookProvider>
      <React.Fragment>
        {marketConfig && (
          <BrowserRouter>
            <Switch>
              <PrivateRoute path="/:lang?/signup">
                <Header isMobile={isMobile} />
                <SignUpForm />
              </PrivateRoute>
              <Route path="/:lang?/contact-me" exact>
                <Header isMobile={isMobile} />
                <SignUpForm />
              </Route>
              <Route path="/:lang?/welcome" exact>
                <Header isMobile={isMobile} light={true} />
                <SignUpForm />
              </Route>
              <Route path="/:lang?/error" exact>
                <Header isMobile={isMobile} light={true} />
                <SignUpForm />
              </Route>
              <Route path="/:lang?/cookie-policy" exact>
                <Header isMobile={isMobile} />
                <Cookies plain={false} />
              </Route>
              <Route path="/:lang?/terms-and-conditions" exact>
                <Header isMobile={isMobile} />
                <TermsAndConditions plain={false} />
              </Route>
              <Route path="/:lang?/privacy-policy" exact>
                <Header isMobile={isMobile} />
                <Privacy plain={false} />
              </Route>
              {marketConfig?.landingPage && (
                <Route path="/:lang?">
                  <Header isMobile={isMobile} />
                  <Landing isMobile={isMobile} />
                </Route>
              )}
              {marketConfig?.landingPage === false && (
                <Route path="/:lang?">
                  <Redirect to="/signup" />
                </Route>
              )}
              <Route path="/:lang?/404">
                <Header isMobile={isMobile} />
                <ErrorNotFoundComponent />
                <Footer />
              </Route>
              <Route path="/404">
                <Header isMobile={isMobile} />
                <ErrorNotFoundComponent />
                <Footer />
              </Route>
              <Route>
                <Header isMobile={isMobile} />
                <ErrorNotFoundComponent />
                <Footer />
              </Route>
            </Switch>
            {/* <CookiePopup /> */}
            <CompliancePack />
          </BrowserRouter>
        )}
      </React.Fragment>
    </UseGTMHookProvider>
  );
};

export default Router;
