import React, { useEffect, useState } from 'react';
import { ReactComponent as Cloud } from '../../assets/images/cloud.svg';
import Button from '../Button';
import { Line } from 'rc-progress';
import styles from './fileInput.module.scss';
import Text, { SIZES, TEXTCOLOR } from '../Text';
import { app, i18nKeyPrefix } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { State } from '../../reducers';
import { RequestData } from '../../utils/interfaces/request';
import { apiEndpoints } from '../../utils/api/endpoints';
import FilesList from '../FilesList';

interface Props {
    action: (file: any) => void;
    getFiles: (data: RequestData) => void;
    field: any;
}

const FileInput: React.FC<Props> = ({
    action,
    getFiles,
    field
}: Props) => {

    const hiddenFileInput = React.useRef(null);
    const [uploading, setUploading] = useState(false);
    const [uploadFailed, setUploadFailed] = useState(false);
    const [percent, setPercentage] = useState(0);
    const files = useSelector((state: State) => state.form.attachments);
    const form = useSelector((state: State) => state.form);
    const apiError = useSelector((state: State) => state.form.error);
    const saveId = useSelector((state: State) => state.form.saveId.saveId);
    const sellsAlcohol = useSelector((state: State) => state.form.additionalData.sellsAlcohol);

    const handleClick = () => {
        hiddenFileInput.current.click();
    }
    const prefix = i18nKeyPrefix + 'LeanSignup_Form_FileUpload';
    const { t } = useTranslation();

    useEffect(() => {
        setPercentage(100);
        setUploading(false);
    }, [files]);

    useEffect(() => {
        getFiles({
            type: 'GET',
            noAuth: true,
            endpoint: `${apiEndpoints.signupData}/${saveId}/attachment/`,
          });
    }, []);

    useEffect(() => {
        if (apiError && (apiError.message || apiError.title)) {
            setUploading(false);
            setUploadFailed(true);
        }
    }, [apiError]);

    const handleChange = (event: any) => {
      setUploadFailed(false);
      const fileUploaded = event.target.files[0];

      if (fileUploaded) {
        setUploading(true);
        setPercentage(80);
        action(fileUploaded);
      }
    };

  return (
      <React.Fragment>
        <div className={cn(styles.file, {[styles.disabled]: (!sellsAlcohol && !files.length) || files.length === 5})}>
            {!uploading &&
                <React.Fragment>
                    <div className={styles.icon}><Cloud /></div>
                    <Text size={SIZES.MEDIUM} bold={true} color={TEXTCOLOR.BLACK}><span data-i18n-key={`${prefix}_action`}>{t(`${prefix}_action`)}</span></Text>
                    <div className={styles.fileInput}>
                        <div className={styles.button} data-i18n-key={`${prefix}_browse`}>
                            <Button text={t(`${prefix}_browse`)} color="grey-outline" size="small" style="noBorder" action={() => handleClick()} />
                        </div>
                        {/* {!isMobile && t(`${prefix}_dragAndDrop`) !== `${prefix}_dragAndDrop` &&
                            <div className={styles.buttonText} data-i18n-key={`${prefix}_dragAndDrop`}>
                                <Text size={SIZES.MEDIUM} color={TEXTCOLOR.BLACK}>{t(`${prefix}_dragAndDrop`)}*</Text>
                            </div>
                        } */}
                    </div>
                    <div className={styles.info} data-i18n-key={`${prefix}_infoText`}>
                        <Text size={SIZES.TINY} color={TEXTCOLOR.GREY}>*{t(`${prefix}_infoText`)}</Text>
                    </div>
                    <input type="file" onChange={handleChange} style={{display:'none'}} ref={hiddenFileInput}/>
                </React.Fragment>
            }
            {uploading &&
                <div className={styles.loading}>
                    <div className={styles.loadingText} data-i18n-key={`${prefix}_uploading`}>
                        <Text size={SIZES.SMALL} bold={true} color={TEXTCOLOR.BLACK}>{t(`${prefix}_uploading`)}</Text>
                        <Text size={SIZES.SMALL} bold={true} color={TEXTCOLOR.BLACK}>{percent}%</Text>
                    </div>
                    <Line percent={percent} strokeWidth={2} strokeColor="black" />
                </div>
            }
        </div>
        {uploadFailed &&
            <Text size={SIZES.SMALL} color={TEXTCOLOR.RED} className={styles['error-message']}>
                {t(i18nKeyPrefix + field.uploadErrorMsg)}
            </Text>
        }
        <FilesList field={field} getFilesRequest={getFiles}></FilesList>
        {(form.additionalData[field.related] && field.required && !(form.dynamicFormValidFields.sellsAlcohol === undefined ? true : form.dynamicFormValidFields.sellsAlcohol && !files.length ? false : true) && field.errorMsg) && (
            <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
            <span data-i18n-key={field.errorMsg}>{t(i18nKeyPrefix + field.errorMsg)}</span>
            </Text>
        )}
    </React.Fragment>
  );
}

export default FileInput;
