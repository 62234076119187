import manifestSelector from './utils/pwa/manifestSelector';
import App from './app';
import { datadogRum } from '@datadog/browser-rum';
import { startI18n } from './i18n';
import * as serviceWorker from './serviceWorker';

declare const __COMMIT_HASH__: string;

async function startApp() {
  manifestSelector();
  await startI18n();
  App();

  return __COMMIT_HASH__;
}

// Register ServiceWorker
serviceWorker.register({
  onUpdate() {
    setTimeout(() => document.location.reload(), 100);
  },
});

startApp().then((commitHash) => {
  console.info(`LSU Env.: ${process.env.NODE_ENV}`);
  console.info(`LSU Started (Commit Hash: ${commitHash.replace('\n', '')})`)
});
