import { getConfig, getConfigPath, getConfigUrl, configName, getOriginPath, getAWSConfig } from '../config/getConfig';
import { convertLanguageForUmbraco } from '../culture';

const makeHeadlessRequest = async (name: string, urlOverwrite?: string) => {
  let umbracoContent;
  let umbracoReference;

  return (async function () {
    const marketData = await getConfig(`${getConfigUrl(configName.market)}`, configName.market);
    const apiConfig = await getAWSConfig(true);

    umbracoContent = apiConfig.headlessBaseUrl;
    umbracoReference = `${getOriginPath(marketData?.marketBaseDevUrl)}`;
    const fallbackLanguage: string = marketData?.defaultLanguage;
    const lang = localStorage.getItem('i18nextLng');
    let params = `?url=${umbracoReference}${convertLanguageForUmbraco(lang).toLowerCase()}`;

    const marketConfigObj = await getAWSConfig();

    if (!lang) {
      localStorage.setItem('i18nextLng', fallbackLanguage);
    } else {
      localStorage.setItem('i18nextLng', lang);
    }

    if (urlOverwrite) {
      params = `?url=${umbracoReference}${urlOverwrite}`;
    }

    const subfix =
      name === 'HEADLESS'
        ? '/home'
        : name === 'CATEGORIES' || name === 'BRANDS' || name === 'PRODUCTS' || name === 'FIND_US'
        ? '/form'
        : '';
    const urlString = `${umbracoContent}/${params}${subfix}`;

    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': marketConfigObj.xApiKey,
      },
    };

    //@ts-ignore
    const response = await fetch(urlString, options);

    return await response.json().then((json: unknown) => {
      if (!response.ok) {
        const error = Object.assign({}, json, {
          status: response.status,
          statusText: response.statusText,
        });
        return Promise.reject(error);
      }
      return json;
    });
  })();
};

const headlessRequest = (name: string, path?: string) => {
  return makeHeadlessRequest(name, path);
};

export { headlessRequest };
