import React, { createRef, RefObject } from 'react';
import PhoneInput from 'react-phone-number-input'

import cn from 'classnames';
import styles from './phoneinput.module.scss';
import Text, { SIZES, TEXTCOLOR } from '../Text';
import { ReactComponent as ChevronDown } from '../../assets/images/chevron-down.svg';

interface Props {
  defaultCountry?: string;
  errorMessage?: string;
  label?: string;
  onBlur?: (e?: any) => void;
  onChange?: (e?: any) => void;
  onInput?: (e?: any) => void;
  onKeyDown?: (e?: any) => void;
  required?: boolean;
  valid?: boolean;
  value?: any;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  dataId?: string;
}

const Input: React.FC<Props> = ({
  defaultCountry,
  errorMessage,
  label,
  onBlur,
  onChange,
  onInput,
  onKeyDown,
  placeholder,
  required,
  valid,
  value,
  name,
  disabled,
  dataId
}: Props) => {

  return (
    <div className={styles.wrapper}>
      {label &&
        <label
          className={cn(styles.label, {[styles.error]: required && !valid && errorMessage})}>
          <Text size={SIZES.SMALL} color={TEXTCOLOR.STEEL}>{label}{required && ' *'}</Text>
        </label>
      }

      <ChevronDown />

      <PhoneInput
        className={cn(styles.input, {[styles.error]: required && !valid && errorMessage})}
        defaultCountry={defaultCountry}
        international
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onInput={onInput}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        name={name}
        disabled={disabled}
        data-id={dataId}
      />

      {(required && !valid && errorMessage) &&
        <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
          {errorMessage}
        </Text>
      }
    </div>
  );
};

export default Input;
