import React, { ReactNode, useEffect } from "react";
import { connect } from "react-redux";
import PrivateRouteComponent from '../components/PrivateRoute';
import { State } from '../reducers';
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode;
  path: string;
}

const PrivateRouteContainer: React.FC<Props> = ({
  children,
  path,
  ...props
}: Props) => {
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('currentLocation', location.pathname);
  }, []);

  return (
    <PrivateRouteComponent
      path={path}
      {...props}
    >
      {children}
    </PrivateRouteComponent>
  )
};

const mapStateToProps = (state: State) => {
  return {
  };
};

export default connect(
  mapStateToProps
)(PrivateRouteContainer);
