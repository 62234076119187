import React, { useState, useEffect, SyntheticEvent, Dispatch } from 'react';
import { useDispatch, useSelector, connect } from "react-redux";
import immutableToJS from 'with-immutable-props-to-js';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './personalInfo.module.scss';
import Text, { TEXTCOLOR, SIZES } from '../../../Text';
import Input from '../../../Input';
import Checkbox from '../../../Checkbox';
import cn from 'classnames';
import ScreenTitle from '../../ScreenTitle';
import PhoneInput from '../../../PhoneInput';
import FormHeaderComponent from '../../FormHeader';
import FormFooterComponent from '../../FormFooter';
import SidebarWrapper from '../../Sidebar/SidebarWrapper';
import { getCurrentWidth } from '../../../../utils/custom-hooks/handleWindowResize';
import TagManager from "react-gtm-module";
import { app, i18nKeyPrefix } from '../../../../utils/constants';
import { Stepper } from '@carlsberggroup/malty.molecules.stepper';
import {
  setStep,
  setValidPhone,
  setValidEmail,
  setValidConfirmationEmail,
  setValidCompanyName,
  setValidManagerName,
  setValidJobTitle,
  setValidFirstName,
  setValidLastName,
  setAcceptTerms,
  setTermsConsent,
  setValidTerms,
  setValidNewsletter,
  setAcceptNewsletter,
  setAcceptSmsService,
  setValidSmsService,
  setAcceptMarketingMessage,
  setValidMarketingMessage,
  setNewsletterConsent,
  setSmsServiceConsent,
  setMarketingMessageConsent,
  setPrivacyAndCookiesConsent,
  setPhone,
  setEmail,
  setConfirmationEmail,
  startRequest,
  setCompanyName,
  setManagerName,
  setJobTitle,
  setFirstName,
  setLastName,
  resetState,
  setAcceptPrivacyAndCookies,
  setValidPrivacyAndCookies,
  setEdit,
  setReview
} from '../../../../actions/form';
import { RequestData } from '../../../../utils/interfaces/request';
import { apiEndpoints } from '../../../../utils/api/endpoints';
import { State } from '../../../../reducers';
import { Action } from 'redux';
import { scrollToError, validatePersonalInfo } from '../../../../utils/validation';

interface Props {
  signUpRequest?: (data: RequestData) => void;
}

interface ParamTypes {
  lang: any;
}

const PersonalInfo: React.FC<Props> = ({
  signUpRequest
}: Props) => {
  const marketData = useSelector((state: State) => state.router.marketData);
  const marketConfig = useSelector((state: State) => state.router.marketConfig);
  const history = useHistory();
  const { t } = useTranslation();
  const size = getCurrentWidth();
  const [windowSize, setWindowSize] = useState(size);
  const dispatch = useDispatch();
  const [establishmentProps, setEstablishmentProps] = useState({});
  const saveId = useSelector((state: State) => state.form.saveId?.saveId);
  const isMobile = windowSize <= app.breakPoint;
  const validPhone = useSelector((state: State) => state.form.validPhone);
  const validEmail = useSelector((state: State) => state.form.validEmail);
  const validConfirmationEmail = useSelector((state: State) => state.form.validConfirmationEmail);
  const validCompanyName = useSelector((state: State) => state.form.validCompanyName);
  const validManagerName = useSelector((state: State) => state.form.validManagerName);
  const validJobTitle = useSelector((state: State) => state.form.validJobTitle);
  const validFirstName = useSelector((state: State) => state.form.validFirstName);
  const validLastName = useSelector((state: State) => state.form.validLastName);
  const validTerms = useSelector((state: State) => state.form.validTerms);
  const companyName = useSelector((state: State) => state.form.companyName);
  const managerName = useSelector((state: State) => state.form.managerName);
  const jobTitle = useSelector((state: State) => state.form.jobTitle);
  const firstName = useSelector((state: State) => state.form.firstName);
  const lastName = useSelector((state: State) => state.form.lastName);
  const phone = useSelector((state: State) => state.form.phone);
  const email = useSelector((state: State) => state.form.email);
  const confirmationEmail = useSelector((state: State) => state.form.confirmationEmail);
  const acceptTerms = useSelector((state: State) => state.form.acceptTerms);
  const termsConsent = useSelector((state: State) => state.form.termsConsent);
  const acceptNewsletter = useSelector((state: State) => state.form.acceptNewsletter);
  const newsletterConsent = useSelector((state: State) => state.form.newsletterConsent);
  const acceptSmsService = useSelector((state: State) => state.form.acceptSmsService);
  const smsServiceConsent = useSelector((state: State) => state.form.smsServiceConsent);
  const acceptMarketingMessage = useSelector((state: State) => state.form.acceptMarketingMessage);
  const marketingMessageConsent = useSelector((state: State) => state.form.marketingMessageConsent);
  const acceptPrivacyAndCookies = useSelector((state: State) => state.form.acceptPrivacyAndCookies);
  const privacyAndCookiesConsent = useSelector((state: State) => state.form.privacyAndCookiesConsent);
  const validPrivacyAndCookies = useSelector((state: State) => state.form.validPrivacyAndCookies);
  const step = useSelector((state: State) => state.form.step);
  const params = useParams<ParamTypes>();
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_PersonalInformation';
  const form = useSelector((state: State) => state.form);
  const review = useSelector((state: State) => state.form.review);
  const edit = useSelector((state: State) => state.form.edit);
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    setWindowSize(size);
  }, [size]);

  useEffect(() => {
    if (window.localStorage.getItem("formData") && step === 1 && !edit && !review) {
      dispatch(resetState());
      localStorage.removeItem("formData");
    }

    dispatch(setStep(1));
  }, []);

  useEffect(() => {
    dispatch(setTermsConsent(t(`${prefix}_terms1`) + ' ' + t(`${prefix}_termsConditions`) + ' ' + t(`${prefix}_terms2`)));
    dispatch(setNewsletterConsent(t(`${prefix}_newsLetter`)));
    dispatch(setSmsServiceConsent(t(`${prefix}_smsService`)));
    dispatch(setMarketingMessageConsent(t(`${prefix}_marketingMessage`)));
    dispatch(setPrivacyAndCookiesConsent(t(`${prefix}_privacyCookies1`) + ' ' + t(`${prefix}_privacyPolicy`) + ' ' + t(`${prefix}_privacyCookies2`) + ' ' + t(`${prefix}_cookiePolicy`) + ' ' + t(`${prefix}_privacyCookies3`)));
  }, []);

  useEffect(() => {
    if (marketConfig?.establishmentMandatory) {
      setEstablishmentProps({label: t(`${prefix}_establishmentLabel`)});
    }
  }, []);

  useEffect(() => {
    scrollToError();
  }, [validPhone, validEmail, validConfirmationEmail, validCompanyName, validManagerName, validFirstName, validLastName,
    validTerms, validPrivacyAndCookies, validJobTitle]);

  useEffect(() => {
    if (query) {
      const errorParam = query.get('error')
      if (errorParam && errorParam === 'validation') {
        scrollToError();
      }
    }
  }, [query]);

  const changeStep = (step: number) => {
    history.push(`/${params.lang}/signup/${step}`);
    dispatch(setStep(step));
  }

  const handleNewsletterCheckbox = (event: any) => {
    dispatch(setAcceptNewsletter(event));
    dispatch(setValidNewsletter(event));
  }

  const handleSmsServiceCheckbox = (event: any) => {
    dispatch(setAcceptSmsService(event));
    dispatch(setValidSmsService(event));
  }

  const handleMarketingMessageCheckbox = (event: any) => {
    dispatch(setAcceptMarketingMessage(event));
    dispatch(setValidMarketingMessage(event));
  }

  const handleTermsCheckbox = (event: any) => {
    dispatch(setAcceptTerms(event));
    dispatch(setValidTerms(event));
  }

  const handlePrivacyAndCookiesCheckbox = (event: any) => {
    dispatch(setAcceptPrivacyAndCookies(event));
    dispatch(setValidPrivacyAndCookies(event));
  }

  const setInput = (e: SyntheticEvent, setVal: (val: any) => void, setValidation: (val: any) => void) => {
    const formatValue =  (e.target as HTMLInputElement).value;
    if (formatValue) {
      dispatch(setValidation(true));
    }
    dispatch(setVal(formatValue));
  }

  const validateConfirmationEmail = () =>{
    let isValid = false;
    if (email === confirmationEmail) {
      dispatch(setValidConfirmationEmail(true));
      isValid = true;
    } else {
      dispatch(setValidConfirmationEmail(false));
    }

    return isValid;
  }

  const setPhoneInput = (val: any, setVal: (val: any) => void) => {
    if (val) {
      dispatch(setValidPhone(true));
    }
    dispatch(setVal(val));
  }

  const handleValidation = () => {
    let isValid = validatePersonalInfo(dispatch, marketConfig, form);

    if (marketConfig?.confirmationEmail) {
      const isConfirmationValid = validateConfirmationEmail();
      if (isValid) {
        isValid = isConfirmationValid;
      }
    }

    return isValid;
  }

  const validateStep = () => {
    if (!handleValidation()) {
      scrollToError();
      return;
    } else {
      const body = {
        contactData: {
          contactPhone: phone,
          contactEmail: email.trim()
        }
      }

      if (marketConfig.consentsInAdditionalData) {
        body["additionalData"] = {
          tandcConsent : acceptTerms
        };
      } else {
        body['tandcConsent'] = {
          consentStatement: termsConsent,
          consentGiven: acceptTerms
        };
      }

      if (!marketConfig.hideEstablishment) {
        body["basicData"]= {
          companyName : companyName
        }
      }

      if (!marketConfig.hideManagerName) {
        body.contactData["contactName"] = managerName;
      }

      if (marketConfig.showJobTitle) {
        body.contactData["jobTitle"] = jobTitle;
      }

      if (marketConfig.showFirstLastName) {
        body.contactData["contactName"] = `${firstName} ${lastName}`;
      }

      if (marketConfig.newsletterCheckbox) {
        body['newsletterSubscriptionConsent'] = {
          consentStatement: newsletterConsent,
          consentGiven: acceptNewsletter
        };

        if (acceptNewsletter) {
          TagManager.dataLayer({
            dataLayer: {
              'event': 'acceptNewsletter'
            }
          });
        }
      }

      if (marketConfig.smsServiceCheckbox) {
        body['smsServiceConsent'] = {
          consentStatement: smsServiceConsent,
          consentGiven: acceptSmsService
        };

        if (acceptSmsService) {
          TagManager.dataLayer({
            dataLayer: {
              'event': 'acceptSmsService'
            }
          });
        }
      }

      if (marketConfig.marketingMessageCheckbox) {
        body['marketingMessageConsent'] = {
          consentStatement: marketingMessageConsent,
          consentGiven: acceptMarketingMessage
        };

        if (acceptMarketingMessage) {
          TagManager.dataLayer({
            dataLayer: {
              'event': 'acceptMarketingMessage'
            }
          });
        }
      }

      if (marketConfig.privacyAndCookiesCheckbox) {
        body['privacyAndCookiesConsent'] = {
          consentStatement: privacyAndCookiesConsent,
          consentGiven: acceptPrivacyAndCookies
        };
      }

      if (saveId) {
        signUpRequest({
          type: 'PUT',
          endpoint: `${apiEndpoints.signupData}/${saveId}?countryCode=${marketData.marketId.toUpperCase()}`,
          body: body
        });
      } else {
        signUpRequest({
          type: 'POST',
          endpoint: `${apiEndpoints.signupData}?countryCode=${marketData.marketId.toUpperCase()}`,
          body: body
        });
      }

      window.localStorage.setItem('formData', JSON.stringify(form));
      dispatch(setEdit(false));
      if (review) {
        dispatch(setReview(false));
        history.push(`/${params.lang}/signup/6`);
        dispatch(setStep(6));
      } else if (marketConfig.ukSteps) {
        history.push(`/${params.lang}/signup/4`);
        dispatch(setStep(4));
      }else {
        history.push(`/${params.lang}/signup/2`);
        dispatch(setStep(2));
      }
    }
  }

  const navigateToLegal = (url: string) => {
    window.open(url, "_blank")
  }

  const managerBlock = () => {
    return (
      <span key="name-block">
        { !marketConfig.hideManagerName &&
          <span
            key="manager-block"
            data-i18n-key-manager={`${prefix}_manager`}
            data-i18n-key-manager-placeholder={`${prefix}_managerPlaceholder`}
            data-i18n-key-manager-error={`${prefix}_managerError`}>
            <Input
              label={t(`${prefix}_manager`)}
              placeholder={t(`${prefix}_managerPlaceholder`)}
              onChange={(val: any) =>  setInput(val, setManagerName, setValidManagerName)}
              onBlur={(val: any) =>  setInput(val, setManagerName, setValidManagerName)}
              required
              maxLength={35}
              value={managerName}
              errorMessage={t(`${prefix}_managerError`)}
              valid={validManagerName}
              dataId="inputManagerName"
            />
          </span>
        }
        { marketConfig.showFirstLastName && 
          <div className={styles.firstLastNameContainer}>
            <span
              key="firstname-block"
              data-i18n-key-manager={`${prefix}_firstName`}
              data-i18n-key-manager-placeholder={`${prefix}_firstNamePlaceholder`}
              data-i18n-key-manager-error={`${prefix}_firstNameError`}>
              <Input
                label={t(`${prefix}_firstName`)}
                placeholder={t(`${prefix}_firstNamePlaceholder`)}
                onChange={(val: any) =>  setInput(val, setFirstName, setValidFirstName)}
                onBlur={(val: any) =>  setInput(val, setFirstName, setValidFirstName)}
                required
                maxLength={35}
                value={firstName}
                errorMessage={t(`${prefix}_firstNameError`)}
                valid={validFirstName}
                dataId="inputFirstName"
              />
            </span>
            <span
              key="lastname-block"
              data-i18n-key-manager={`${prefix}_lastName`}
              data-i18n-key-manager-placeholder={`${prefix}_lastNamePlaceholder`}
              data-i18n-key-manager-error={`${prefix}_lastNameError`}>
              <Input
                label={t(`${prefix}_lastName`)}
                placeholder={t(`${prefix}_lastNamePlaceholder`)}
                onChange={(val: any) =>  setInput(val, setLastName, setValidLastName)}
                onBlur={(val: any) =>  setInput(val, setLastName, setValidLastName)}
                required
                maxLength={35}
                value={lastName}
                errorMessage={t(`${prefix}_lastNameError`)}
                valid={validLastName}
                dataId="inputLastName"
              />
            </span>
          </div>
        }
      </span>
    )
  }

  const phoneBlock = () => {
    return (
      <span
        key="phone-block"
        data-i18n-key-number-label={`${prefix}_numberLabel`}
        data-i18n-key-number-error={`${prefix}_numberError`}>
        <PhoneInput
          label={t(`${prefix}_numberLabel`)}
          placeholder={t(`${prefix}_numberLabel`)}
          required
          defaultCountry={marketData?.marketId.toUpperCase()}
          value={phone}
          onChange={(val: any) =>  setPhoneInput(val, setPhone)}
          errorMessage={t(`${prefix}_numberError`)}
          valid={validPhone}
          dataId="inputPhone"
        />
      </span>
    )
  }

  const termsBlock = () => {
    return (
      <div className={styles.checks}>
      <Checkbox dataId="checkTermsConditions" onChange={(event) => handleTermsCheckbox(event.target.checked)} checked={acceptTerms} required valid={validTerms}>
        <Text color={TEXTCOLOR.DARK_GREY} size={SIZES.SMALL}>
        <span data-i18n-key={`${prefix}_terms1`}>{t(`${prefix}_terms1`)}{''}</span>
        <a className={styles.link} onClick={() => navigateToLegal(marketConfig?.termsConditionsPolicy === null ? `/${params.lang}/privacy-policy` : marketConfig?.termsConditionsPolicy)} target="_blank" data-i18n-key={`${prefix}_termsConditions`}>
          {t(`${prefix}_termsConditions`)}
        </a>
        {' '}<span data-i18n-key={`${prefix}_terms2`}>{t(`${prefix}_terms2`)}</span>
        </Text>
      </Checkbox>
        {!acceptTerms && !validTerms &&
          <div className={styles.errorMessage}>
            <Text className={'formError'} color={TEXTCOLOR.RED} size={SIZES.TINY}>
              <span data-i18n-key={`${prefix}_termsError`}>{t(`${prefix}_termsError`)}</span>
            </Text>
          </div>
        }
    </div>
    )
  }

  return (
    <React.Fragment>
      <div className={styles.formContainer}>
        {step !== 7 && <FormHeaderComponent changeStep={changeStep} />}
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <div className={cn(styles.screenWrapper)}>
              <div className={cn(styles.wrapper)} data-i18n-key-title={`${prefix}_title`}>
                <div className={styles.stepperContainer}>
                  <Stepper steps={marketConfig.steps} currentStep={1}/>
                </div>
                <ScreenTitle title={t(`${prefix}_title`)}></ScreenTitle>
                <form>
                  <div className={styles.form}>
                    { !marketConfig.hideManagerName && 
                      <>
                        { !marketConfig?.establishmentMandatory &&
                          <label className={styles.headerInfo}>
                            <span style={{fontWeight: 700}} data-i18n-key-label={`${prefix}_establishmentLabel`} data-i18n-key-info={`${prefix}_establishmentInfo`}>
                              {t(`${prefix}_establishmentLabel`)}
                            </span> - {t(`${prefix}_establishmentInfo`)}
                          </label>
                        }
                        <span
                          data-i18n-key-placeholder={`${prefix}_establishment`}
                          data-i18n-key-error={`${prefix}_establishmentError`}>
                          <Input
                            {...establishmentProps}
                            placeholder={t(`${prefix}_establishment`)}
                            onChange={(val: any) => setInput(val, setCompanyName, setValidCompanyName)}
                            onBlur={(val: any) =>  setInput(val, setCompanyName, setValidCompanyName)}
                            value={companyName}
                            maxLength={35}
                            required={marketConfig?.establishmentMandatory}
                            errorMessage={t(`${prefix}_establishmentError`)}
                            valid={validCompanyName}
                            dataId="inputCompanyName"
                          />
                        </span>
                      </>
                    }
                    { marketConfig.showJobTitle &&
                      <span
                        key="jobtitle-block"
                        data-i18n-key-manager={`${prefix}jobTitle`}
                        data-i18n-key-manager-placeholder={`${prefix}_jobTitlePlaceholder`}
                        data-i18n-key-manager-error={`${prefix}_jobTitleError`}>
                        <Input
                          label={t(`${prefix}_jobTitle`)}
                          placeholder={t(`${prefix}_jobTitlePlaceholder`)}
                          onChange={(val: any) =>  setInput(val, setJobTitle, setValidJobTitle)}
                          onBlur={(val: any) =>  setInput(val, setJobTitle, setValidJobTitle)}
                          required
                          maxLength={35}
                          value={jobTitle}
                          errorMessage={t(`${prefix}_jobTitleError`)}
                          valid={validJobTitle}
                          dataId="inputJobTitle"
                        />
                      </span>
                    }

                    { !marketConfig?.phoneBeforeContactName &&
                      [managerBlock(), phoneBlock()]
                    }

                    { marketConfig?.phoneBeforeContactName &&
                      [phoneBlock(), managerBlock()]
                    }

                    <span
                      data-i18n-key-email-label={`${prefix}_emailLabel`}
                      data-i18n-key-email-error={`${prefix}_emailError`}>
                      <Input
                        label={t(`${prefix}_emailLabel`)}
                        placeholder={t(`${prefix}_emailLabel`)}
                        onChange={(val: any) =>  setInput(val, setEmail, setValidEmail)}
                        onBlur={(val: any) =>  setInput(val, setEmail, setValidEmail)}
                        required
                        value={email}
                        errorMessage={t(`${prefix}_emailError`)}
                        valid={validEmail}
                        dataId="inputEmail"
                        maxLength={65}
                      />
                    </span>

                    { marketConfig?.confirmationEmail &&
                      <>
                        <span
                          data-i18n-key-email-label={`${prefix}_emailConfirmationLabel`}
                          data-i18n-key-email-error={`${prefix}_emailConfirmationError`}>
                          <Input
                            label={t(`${prefix}_emailConfirmationLabel`)}
                            placeholder={t(`${prefix}_emailConfirmationPlaceholder`)}
                            onChange={(val: any) =>  setInput(val, setConfirmationEmail, setValidConfirmationEmail)}
                            onBlur={(val: any) =>  validateConfirmationEmail()}
                            required
                            value={confirmationEmail}
                            errorMessage={t(`${prefix}_emailConfirmationError`)}
                            valid={validConfirmationEmail}
                            dataId="inputConfirmationEmail"
                            maxLength={65}
                          />
                        </span>
                      </>
                    }

                  {step === 1 &&
                    <span>
                      { !marketConfig?.termsLastPlace && termsBlock() }

                      { marketConfig?.newsletterCheckbox &&
                        <div className={styles.checks}>
                          <Checkbox dataId="checkNewsletter" onChange={(event) => handleNewsletterCheckbox(event.target.checked)} checked={acceptNewsletter}>
                            <Text color={TEXTCOLOR.DARK_GREY} size={SIZES.SMALL}>
                              <span data-i18n-key={`${prefix}_newsLetter`}>{t(`${prefix}_newsLetter`)}</span>
                            </Text>
                          </Checkbox>
                        </div>
                      }

                      { marketConfig?.smsServiceCheckbox &&
                        <div className={styles.checks}>
                          <Checkbox dataId="checkSmsService" onChange={(event) => handleSmsServiceCheckbox(event.target.checked)} checked={acceptSmsService}>
                            <Text color={TEXTCOLOR.DARK_GREY} size={SIZES.SMALL}>
                              <span data-i18n-key={`${prefix}_smsService`}>{t(`${prefix}_smsService`)}</span>
                            </Text>
                          </Checkbox>
                        </div>
                      }

                      { marketConfig?.marketingMessageCheckbox &&
                        <div className={styles.checks}>
                          <Checkbox dataId="checkMarketingMessage" onChange={(event) => handleMarketingMessageCheckbox(event.target.checked)} checked={acceptMarketingMessage}>
                            <Text color={TEXTCOLOR.DARK_GREY} size={SIZES.SMALL}>
                              <span data-i18n-key={`${prefix}_marketingMessage`}>{t(`${prefix}_marketingMessage`)}</span>
                            </Text>
                          </Checkbox>
                        </div>
                      }

                      { marketConfig?.privacyAndCookiesCheckbox &&
                        <div className={styles.checks}>
                          <Checkbox dataId="checkPrivacyCookies" onChange={(event) => handlePrivacyAndCookiesCheckbox(event.target.checked)} checked={acceptPrivacyAndCookies} required valid={validPrivacyAndCookies}>
                            <Text color={TEXTCOLOR.DARK_GREY} size={SIZES.SMALL}>
                            <span data-i18n-key={`${prefix}_privacyCookies1`}>{t(`${prefix}_privacyCookies1`)}</span>
                            <a className={styles.link} onClick={() => navigateToLegal(marketConfig?.privacyPolicy === null ? `/${params.lang}/privacy-policy` : marketConfig?.privacyPolicy)} target="_blank" data-i18n-key={`${prefix}_privacyPolicy`}>
                              {t(`${prefix}_privacyPolicy`)}
                            </a>
                            {' '}
                            <span data-i18n-key={`${prefix}_privacyCookies2`}>{t(`${prefix}_privacyCookies2`)}</span>
                            <a className={styles.link} onClick={() => navigateToLegal(marketConfig?.cookiePolicy === null ? `/${params.lang}/cookie-policy` : marketConfig?.cookiePolicy)} target="_blank" data-i18n-key={`${prefix}_cookiePolicy`}>
                              {t(`${prefix}_cookiePolicy`)}
                            </a>
                            {' '}
                            <span data-i18n-key={`${prefix}_privacyCookies3`}>{t(`${prefix}_privacyCookies3`)}</span>
                            </Text>
                          </Checkbox>
                            {!acceptPrivacyAndCookies && !validPrivacyAndCookies &&
                              <div className={styles.errorMessage}>
                                <Text className="formError" color={TEXTCOLOR.RED} size={SIZES.TINY}>
                                  <span data-i18n-key={`${prefix}_privacyCookiesError`}>{t(`${prefix}_privacyCookiesError`)}</span>
                                </Text>
                              </div>
                            }
                        </div>
                      }
                      { marketConfig?.termsLastPlace && termsBlock() }
                    </span>
                  }
                </div>
              </form>
              </div>
            </div>
            {!isMobile && step !== 7 &&
              <SidebarWrapper />
            }
          </div>
        </div>
      </div>
      {step !== 7 && <FormFooterComponent validateStep={validateStep} />}
    </React.Fragment>
  );
}
const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    signUpRequest: (data: RequestData) => {
      dispatch(startRequest(data, 'saveId'));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(PersonalInfo));