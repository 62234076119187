import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './errorNotFound.module.scss';
import { getCurrentWidth } from '../../utils/custom-hooks/handleWindowResize';
import { app, i18nKeyPrefix } from '../../utils/constants';
import { useHistory } from "react-router-dom";
import cn from 'classnames';
import Button from '../Button';

interface ParamTypes {
  lang: any;
}

const ErrorNotFoundComponent: React.FC = ({
  // footerLinks,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const size = getCurrentWidth();
  const history = useHistory();
  const [windowSize, setWindowSize] = useState(size);
  useEffect(() => {
    setWindowSize(size);
  });
  const isMobile = windowSize <= app.breakPoint;
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_404';
  const params = useParams<ParamTypes>();

  return (
    <div className={cn(styles.wrapper, {[styles.mobile]: isMobile})}>
      <div className={styles.backgroundImage}></div>
        <div className={styles.content}>
          <div className={styles.message}>Hmm...<br></br>something went
            <div className={styles.flippedWord}>
              <span className={styles.flippedW}>w</span>
              <span className={styles.flippedR}>r</span>
              <span className={styles.notFlipped}>on</span>
              <span className={styles.flippedG}>g</span>
              .
            </div>
          </div>
            <div className={styles.button} data-i18n-key={`${prefix}_button`}>
              <Button
                text={t(`${prefix}_button`)}
                color="white"
                size="medium"
                action={() => history.push(`/${params.lang}/`)}
              />
            </div>
        </div>
    </div>
  );
}

export default ErrorNotFoundComponent;