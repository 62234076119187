import { actions } from '../utils/api/constants';
import { RequestData } from '../utils/interfaces/request';

export const startRequest = (
  data: RequestData,
  name: string,
) => ({
  data,
  name,
  type: actions.API_REQUEST_INIT
});

export const startHeadlessRequest = (
  name: string,
  path?: string,
) => ({
  name,
  path,
  type: actions.API_HEADLESS_REQUEST_INIT
});

export const startCategoriesRequest = (
  name: string,
  path?: string,
) => ({
  name,
  path,
  type: actions.API_CATEGORIES_REQUEST_INIT
});

export const startCookiesRequest = (
  name: string,
  path?: string,
) => ({
  name,
  path,
  type: actions.API_COOKIES_REQUEST_INIT
});

export const startGlobalRequest = (
  name: string,
  path?: string,
) => ({
  name,
  path,
  type: actions.API_GLOBAL_REQUEST_INIT
});

export const startNavRequest = (
  name: string,
  path?: string,
) => ({
  name,
  path,
  type: actions.API_NAV_REQUEST_INIT
});

export const startPrivacyRequest = (
  name: string,
  path?: string,
) => ({
  name,
  path,
  type: actions.API_PRIVACY_REQUEST_INIT
});

export const startTermsRequest = (
  name: string,
  path?: string,
) => ({
  name,
  path,
  type: actions.API_TERMS_REQUEST_INIT
});

export const getResponse = (
  name: string,
  payload: object,
) => ({
  name,
  payload,
  type: actions.GET_RESPONSE
});

export const endRequest = (name: string) => ({
  name,
  isLoading: false,
  type: actions.API_REQUEST_ENDS
});

export const handleError = (error: unknown, name: string) => ({
  error,
  name,
  type: actions.HTTP_ERROR
});
