import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { State } from '../../reducers';

const CompliancePack = () => {
  // const cookiePolicyUrl = 'https://compliance.carlsberggroup.com/en-hq/cookies?id=ca873d93-747d-48b1-9a97-3e55bc6de560';
  // const privacyPolicyUrl = 'https://compliance.carlsberggroup.com/en-hq/?id=ca873d93-747d-48b1-9a97-3e55bc6de560';
  const language = localStorage.getItem('i18nextLng') || navigator.language;
  const marketConfig = useSelector((state: State) => state.router.marketConfig);

  const convertDataCulture = (lang) => {
    switch (lang) {
      case 'no':
        return 'nb';
      default:
        return lang;
    }
  };

  const convertDataMarket = (lang) => {
    switch (lang.toLowerCase()) {
      case 'sv':
        return 'se';
      case 'en-us':
        return 'no';
      default:
        return lang.substring(lang.length - 2);
    }
  };

  return (
    <Helmet>
      <script
        async
        id="cbgCompPack"
        src="https://compliance-pack.carlsberggroup.com/cbgCompPack.js"
        data-gtm-id="GTM-KKZG2D8"
        data-market={convertDataMarket(language)}
        data-culture={convertDataCulture(language)}
        data-prj-id={marketConfig.projectId}
        data-policy-page={marketConfig.compliancePackPrivacy}
        data-cookies-page={marketConfig.compliancePackCookie}
      ></script>
    </Helmet>
  );
};

export default CompliancePack;
