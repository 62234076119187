import React from 'react';
import styles from './contactMe.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { State } from '../../../reducers';
import ContactMeFooterComponent from './ContactMeFooter';
import FormHeaderComponent from '../FormHeader';
import { useHistory, useParams } from 'react-router-dom';
import { i18nKeyPrefix } from '../../../utils/constants';

interface ParamTypes {
  lang: any;
}
const ContactMe: React.FC = ({
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const managerName = useSelector((state: State) => state.form.managerName);
  const phone = useSelector((state: State) => state.form.phone);
  const email = useSelector((state: State) => state.form.email);
  const infoSent = useSelector((state: State) => state.form.infoSent);
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_ContactMe';
  const params = useParams<ParamTypes>();

  const changeStep = () => {
    history.push(`${2}`);
  }

  return (
    <React.Fragment>
      <div className={styles.formContainer}>
        <FormHeaderComponent changeStep={changeStep} removeHeaderInMobile={true} />
          <div className={styles.container}>
            <div className={styles.innerContainer}>
            <div className={(styles.wrapper)}>
              <div className={styles.image}></div>
              <div className={(styles.textWrapper)}>
                <div className={(styles.header)} data-i18n-key-confirm={`${prefix}_confirm`} data-i18n-key-desc={`${prefix}_description`}>
                  {!infoSent && t(`${prefix}_confirm`)}
                  {infoSent && t(`${prefix}_description`)}
                </div>
                <div className={(styles.contactInformation)}>
                  <div>{managerName}</div>
                  <div>{phone}</div>
                  <div>{email}</div>

                  <a className={(styles.contactMe)} onClick={() => history.push(`/${params.lang}/signup/1`)} data-i18n-key={`${prefix}_link`}>{t(`${prefix}_link`)}</a>
                </div>
              </div>
            </div>
            </div>
          </div>
      </div>
      <ContactMeFooterComponent/>
    </React.Fragment>
  );
}

export default ContactMe;
