import React from 'react';
import styles from './loading.module.scss';
import cn from 'classnames';
import IconLoader from '../../assets/images/cb-loader.png';
import { ReactComponent as Loader } from '../../assets/images/loader.svg';
import { useSelector } from 'react-redux';
import { State } from '../../reducers';

interface Props {
  fullScreen?: boolean;
  hideText?: boolean;
  small?: boolean;
}

const Loading: React.FC<Props> = ({
  fullScreen,
  hideText,
  small
}: Props) => {
  const marketConfig = useSelector((state: State) => state.router.marketConfig);

  return (
      <div className={cn(styles.loading, {[styles.small]: small}, {[styles.full]: fullScreen})}>
          <div className={styles.wrapper}>
            { marketConfig.genericLoader ? <Loader/> : <img src={IconLoader} /> }
            {!hideText && !small && <span>'Loading...'</span> }
          </div>
      </div>
  );
}

export default Loading;
