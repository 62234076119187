import React, { useState, useEffect, Dispatch } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './editInformation.module.scss';
import cn from 'classnames';
import immutableToJS from 'with-immutable-props-to-js';
import PersonalInfo from '../../Screens/PersonalInfo';
import CompanyInfo from '../../Screens/CompanyInfo';
import CompanyType from '../../Screens/CompanyType';
import AddressComponent from '../Address';
import FormHeaderComponent from '../../FormHeader';
import SidebarWrapper from '../../Sidebar/SidebarWrapper';
import FormFooterComponent from '../../FormFooter';
import { useSelector, useDispatch, connect } from 'react-redux';
import { State } from '../../../../reducers';
import { apiEndpoints } from '../../../../utils/api/endpoints';
import {
  setValidCompanyType,
  setValidVAT,
  setStep,
  setValidPhone,
  setValidEmail,
  setValidManagerName,
  setValidTerms,
  setValidPrivacyAndCookies,
  startRequest,
  setValidCompanyName,
  setDynamicValidation,
  setDynamicValue
} from '../../../../actions/form';
import { getCurrentWidth } from '../../../../utils/custom-hooks/handleWindowResize';
import { app } from '../../../../utils/constants';
import { RequestData } from '../../../../utils/interfaces/request';
import { Action } from 'redux';

interface ParamTypes {
  lang: any;
}

interface Props {
  signUpRequest?: (data: RequestData) => void;
}

const EditInformation: React.FC<Props> = ({
  signUpRequest
}: Props) => {
  const marketData = useSelector((state: State) => state.router.marketData);
  const marketConfig = useSelector((state: State) => state.router.marketConfig);

  const history = useHistory();
  const size = getCurrentWidth();
  const [windowSize, setWindowSize] = useState(size);
  const dispatch = useDispatch();

  useEffect(() => {
    setWindowSize(size);
  }, [size]);

  const isMobile = windowSize <= app.breakPoint;
  const differentAddress = useSelector((state: State) => state.form.differentAddress);
  const companyName = useSelector((state: State) => state.form.companyName);
  const managerName = useSelector((state: State) => state.form.managerName);
  const phone = useSelector((state: State) => state.form.phone);
  const email = useSelector((state: State) => state.form.email);
  const confirmationEmail = useSelector((state: State) => state.form.confirmationEmail);
  const acceptTerms = useSelector((state: State) => state.form.acceptTerms);
  const termsConsent = useSelector((state: State) => state.form.termsConsent);
  const acceptNewsletter = useSelector((state: State) => state.form.acceptNewsletter);
  const newsletterConsent = useSelector((state: State) => state.form.newsletterConsent);
  const acceptSmsService = useSelector((state: State) => state.form.acceptSmsService);
  const smsServiceConsent = useSelector((state: State) => state.form.smsServiceConsent);
  const acceptMarketingMessage = useSelector((state: State) => state.form.acceptMarketingMessage);
  const marketingMessageConsent = useSelector((state: State) => state.form.marketingMessageConsent);
  const acceptPrivacyAndCookies = useSelector((state: State) => state.form.acceptPrivacyAndCookies);
  const privacyAndCookiesConsent = useSelector((state: State) => state.form.privacyAndCookiesConsent);
  const additionalData = useSelector((state: State) => state.form.additionalData);

  const VATnumber = useSelector((state: State) => state.form.VATnumber);
  const companyType = useSelector((state: State) => state.form.companyType);
  const selectedCategories = useSelector((state: State) => state.form.additionalData?.selectedCategories);

  const saveId = useSelector((state: State) => state.form.saveId.saveId);
  const params = useParams<ParamTypes>();

  const validPhone = useSelector((state: State) => state.form.validPhone);
  const validEmail = useSelector((state: State) => state.form.validEmail);
  const validCompanyName = useSelector((state: State) => state.form.validCompanyName);
  const validManagerName = useSelector((state: State) => state.form.validManagerName);
  const validTerms = useSelector((state: State) => state.form.validTerms);
  const validPrivacyAndCookies = useSelector((state: State) => state.form.validPrivacyAndCookies);
  const validVAT = useSelector((state: State) => state.form.validVAT);
  const form = useSelector((state: State) => state.form);

  useEffect(() => {
    dispatch(setStep(7));
  }, [])

  const changeStep = (step: number) => {
    history.push(`/${params.lang}/signup/${step}`);
    dispatch(setStep(step));
  }

  const handleValidation = () => {
    const requiredValues: any[] = [
      {
        name: managerName,
        validName: setValidManagerName,
        pattern: /^.{3,64}$/,
        valid: validManagerName
      },
      {
        name: phone,
        validName: setValidPhone,
        pattern: /^\+?[1-9]\d{4,14}$/,
        valid: validPhone
      },
      {
        name: email,
        validName: setValidEmail,
        pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g,
        valid: validEmail
      },
      {
        name: acceptTerms,
        validName: setValidTerms,
        valid: validTerms
      },
      {
        name: VATnumber,
        validName: setValidVAT,
        valid: validVAT
      }
    ];

    if (marketConfig?.establishmentMandatory) {
      requiredValues.push({
        name: companyName,
        validName: setValidCompanyName,
        pattern: /^.{3,64}$/,
        valid: validCompanyName
      });
    }

    if (marketConfig?.privacyAndCookiesCheckbox) {
      requiredValues.push({
        name: acceptPrivacyAndCookies,
        validName: setValidPrivacyAndCookies,
        pattern: undefined,
        valid: validPrivacyAndCookies
      });
    }

    let isValid = true;
    for (let i = 0; i < requiredValues.length; i++) {
      const value = requiredValues[i];
      if (value.name === '' || !value.name) {
        dispatch(value['validName'](false));
        isValid = false;
      } else if (value.pattern) {
        const valid = new RegExp(value.pattern).test(value.name);
        if (!valid) {
          dispatch(value['validName'](false));
          isValid = false;
        } else {
          dispatch(value['validName'](true));
        }
      }
    }

    if ((marketConfig?.chipCategories && selectedCategories.length > 0) || (!marketConfig?.chipCategories && companyType)) {
      dispatch(setValidCompanyType(true));
    } else {
      isValid = false;
      dispatch(setValidCompanyType(false));
    }

    if (marketConfig?.confirmationEmail) {
      const isConfirmationValid = email === confirmationEmail;
      if (isValid) {
        isValid = isConfirmationValid;
      }
    }

    const validateField = (field: string, validField: string, regex: RegExp, type: string) => {
      if (!form[field + type] || !form[field + type].length) {
        dispatch(setDynamicValidation(validField + type, false));
        isValid = false;
      } else {
        const valid = new RegExp(regex).test(form[field + type]);
        if (!valid) {
          dispatch(setDynamicValidation(validField + type, false));
          isValid = false;
        } else {
          dispatch(setDynamicValidation(validField + type, true));
        }
      }
    }

    if (marketConfig.addressList && marketConfig.addressList.primary) {
      marketConfig.addressList.primary.map((item: any, i:any) => {
        if (item.nameMandatory) {
          validateField('name', 'validName', /^.{3,64}$/, item.type);
        }
        
        validateField('address', 'validAddress', /^.{3,64}$/, item.type);
        validateField('zipCode', 'validZipCode', /([0-9a-zA-Z]{3,64})+/g, item.type);
        validateField('cityName', 'validCityName', /^.{3,64}$/, item.type);

        if (item.phoneNumberExist) {
          validateField('phone', 'validPhone', /^\+?[1-9]\d{4,14}$/, item.type);
        }

        if (item.emailExist && (item.emailMandatory || form[`email${item.type}`].length)) {
          validateField('email', 'validEmail', /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g, item.type);
        }
      });
    }

    if (differentAddress && marketConfig.addressList && marketConfig.addressList.secondary) {
      marketConfig.addressList.secondary.map((item: any, i:any) => {
        if (item.nameMandatory) {
          validateField('name', 'validName', /^.{3,64}$/, item.type);
        }
        
        validateField('address', 'validAddress', /^.{3,64}$/, item.type);
        validateField('zipCode', 'validZipCode', /([0-9a-zA-Z]{3,64})+/g, item.type);
        validateField('cityName', 'validCityName', /^.{3,64}$/, item.type);

        if (item.phoneNumberExist) {
          validateField('phone', 'validPhone', /^\+?[1-9]\d{4,14}$/, item.type);
        }

        if (item.emailExist && (item.emailMandatory || form[`email${item.type}`].length)) {
          validateField('email', 'validEmail', /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g, item.type);
        }
      });
    }

    return isValid;
  }

  const validateStep = () => {
    if (!handleValidation()) {
      return;
    } else {
      const body = {
        basicData: {
          companyId: VATnumber.replace('-', ''),
          companyName: companyName
        },
        contactData: {
          contactName: managerName,
          contactPhone: phone,
          contactEmail: email
        },
        tandcConsent: {
          consentStatement: termsConsent,
          consentGiven: true
        }
      }

      if (marketConfig.newsletterCheckbox) {
        body['newsletterSubscriptionConsent'] = {
          consentStatement: newsletterConsent,
          consentGiven: acceptNewsletter
        };
      }

      if (marketConfig.smsServiceCheckbox) {
        body['smsServiceConsent'] = {
          consentStatement: smsServiceConsent,
          consentGiven: acceptSmsService
        };
      }

      if (marketConfig.marketingMessageCheckbox) {
        body['marketingMessageConsent'] = {
          consentStatement: marketingMessageConsent,
          consentGiven: acceptMarketingMessage
        };
      }

      if (marketConfig.privacyAndCookiesCheckbox) {
        body['privacyAndCookiesConsent'] = {
          consentStatement: privacyAndCookiesConsent,
          consentGiven: acceptPrivacyAndCookies
        };
      }

      if (marketConfig.addressList && marketConfig.addressList.primary) {
        marketConfig.addressList.primary.map((item: any, i:any) => { 
          const addressData = {
            name: form[`name${item.type}`],
            address: form[`address${item.type}`],
            zipCode: form[`zipCode${item.type}`] && form[`zipCode${item.type}`].replace(/ /g, ''),
            town: form[`cityName${item.type}`],
            phone: form[`phone${item.type}`],
            email: form[`email${item.type}`]
          };

          if (item.type === 'BillTo') {
            body['mailingData'] = addressData;
          } else if (item.type === 'ShipTo') {
            body['deliveryData'] = addressData;
          } else if (item.type === 'Payer') {
            body['payerData'] = addressData;
          } else if (item.type === 'SoldTo') {
            body['soldToData'] = addressData;
          }
        });

        if (marketConfig.addressList.secondary) {
          const primaryAddress = marketConfig.addressList.primary[0];
          marketConfig.addressList.secondary.map((item: any, i:any) => {
            let addressData;
            if (!differentAddress) {
              dispatch(setDynamicValue(`address${item.type}`, form[`address${primaryAddress.type}`]));
              dispatch(setDynamicValue(`cityName${item.type}`, form[`cityName${primaryAddress.type}`]));
              dispatch(setDynamicValue(`zipCode${item.type}`, form[`zipCode${primaryAddress.type}`]));
              dispatch(setDynamicValue(`name${item.type}`, form[`name${primaryAddress.type}`]));
              dispatch(setDynamicValue(`phone${item.type}`, form[`phone${primaryAddress.type}`]));
              dispatch(setDynamicValue(`email${item.type}`, form[`email${primaryAddress.type}`]));

              addressData = {
                name: form[`name${primaryAddress.type}`],
                address: form[`address${primaryAddress.type}`],
                zipCode: form[`zipCode${primaryAddress.type}`] && form[`zipCode${primaryAddress.type}`].replace(/ /g, ''),
                town: form[`cityName${primaryAddress.type}`],
                phone: form[`phone${primaryAddress.type}`],
                email: form[`email${primaryAddress.type}`]
              };
            } else {
              addressData = {
                name: form[`name${item.type}`],
                address: form[`address${item.type}`],
                zipCode: form[`zipCode${item.type}`] && form[`zipCode${item.type}`].replace(/ /g, ''),
                town: form[`cityName${item.type}`],
                phone: form[`phone${item.type}`],
                email: form[`email${item.type}`]
              };
            }
  
            if (item.type === 'BillTo') {
              body['mailingData'] = addressData;
            } else if (item.type === 'ShipTo') {
              body['deliveryData'] = addressData;
            } else if (item.type === 'Payer') {
              body['payerData'] = addressData;
            } else if (item.type === 'SoldTo') {
              body['soldToData'] = addressData;
            }
          });
        }
      }

      if (selectedCategories && selectedCategories.length) {
        const mainCategory = selectedCategories.find((x: any) => x.isMain === true);
        const additionalCategories = selectedCategories.filter((x: any) => x.isMain !== true);
        const additionalDataUpdated = { ...additionalData, mainTradeChannel: mainCategory, additionalTradeChannel: additionalCategories }

        body['additionalData'] = additionalDataUpdated;
      }

      signUpRequest({
        type: 'PUT',
        endpoint: `${apiEndpoints.signupData}/${saveId}?countryCode=${marketData.marketId.toUpperCase()}`,
        body: body
      });
      window.localStorage.setItem('formData', JSON.stringify(form));

      history.push(`/${params.lang}/signup/6`);
      dispatch(setStep(6));
    }
  }

  return (
    <React.Fragment>
      <div className={styles.formContainer}>
        <FormHeaderComponent changeStep={changeStep} />
          <div className={styles.container}>
            <div className={styles.innerContainer}>
              <div className={cn(styles.wrapper)}>
                <div className={cn(styles.screenWrapper)}>
                  <PersonalInfo/>
                </div>
                <div className={cn(styles.screenWrapper)}>
                  <CompanyInfo/>
                </div>
                <div className={cn(styles.screenWrapper, styles.smallMargin)}>
                  <AddressComponent/>
                </div>
                <div className={cn(styles.screenWrapper, styles.smallMargin)}>
                  <CompanyType />
                </div>
              </div>
              {!isMobile &&
                <SidebarWrapper />
              }
            </div>
          </div>
      </div>
      <FormFooterComponent validateStep={validateStep} />
    </React.Fragment>
  );
}

const mapStateToProps = (state: State) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    signUpRequest: (data: RequestData) => {
      dispatch(startRequest(data, 'saveId'));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(EditInformation));