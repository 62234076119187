import React from 'react';
import styles from './month.module.scss';
import { useMonth } from "@datepicker-react/hooks";
import Day from '../Day';
import { ReactComponent as ChevronLeft } from '../../../assets/images/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../../assets/images/chevron-right.svg';
import { useTranslation } from 'react-i18next';
import { i18nKeyPrefix } from '../../../utils/constants';


interface Props {
  firstDayOfWeek: any;
  lastMonth: any;
  month: any;
  year: any;
  goToPreviousMonths: any;
  goToNextMonths: any;
}

const Month: React.FC<Props> = ({
  firstDayOfWeek,
  lastMonth,
  month,
  year,
  goToPreviousMonths,
  goToNextMonths
}: Props) => {

  const { t } = useTranslation();
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_Calendar';

  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek
  });


  // Calculate the last days of last month:
  let lastYear: any = year;
  let lastRealMonth: any = month -1;
  if (lastMonth === -1) {
    lastYear = year - 1;
    lastRealMonth = 11;
  }
  const lastMonthObj = useMonth({
    year: lastYear,
    month: lastRealMonth,
    firstDayOfWeek
  });
  const emptyDays = days.filter((day) => {
    return typeof day !== "object";
  });
  const lastDays = lastMonthObj.days.slice(lastMonthObj.days.length - emptyDays.length, lastMonthObj.days.length);

  const monthName = monthLabel.split(' ')[0];
  const yearName = monthLabel.split(' ')[1];

  return (
    <div className={styles.month}>
      <div className={styles.header}>
        <strong data-i18n-key={`${prefix}_months_${monthName.toLowerCase()}`}>
          {t(`${prefix}_months_${monthName.toLowerCase()}`)}
          {yearName !== new Date().getFullYear().toString() &&
            <span className={styles.year}>{yearName}</span>
          }
        </strong>
        <div>
          <button className={styles.navigate} onClick={goToPreviousMonths}>
            <ChevronLeft />
          </button>
          <button className={styles.navigate} onClick={goToNextMonths}>
            <ChevronRight />
          </button>
        </div>
      </div>

      <div className={styles.grid}>
        {weekdayLabels.map((dayLabel: string) => (
          <div className={styles.label} key={dayLabel} data-i18n-key={`${prefix}_days_${dayLabel.toLowerCase()}`}>
            {t(`${prefix}_days_${dayLabel.toLowerCase()}`)}
          </div>
        ))}
      </div>

      <div className={styles.grid}>
        {days.map((day, index) => {
          if (typeof day === "object") {
            return (
              <Day
                date={day.date}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
              />
            );
          }

          const lastDay = lastDays[index];

          if (typeof lastDay === "object") {
            return (
              <div key={index} className={styles.lastday}>
                {lastDay.dayLabel}
              </div>
            );
          }

          return (<div key={index} />)
        })}
      </div>
    </div>
  );
};

export default Month;
