import React, { useState, useEffect, Dispatch } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './verificationCode.module.scss';
import cn from 'classnames';
import CodeVerify from '../../../CodeVerify';
import { getCurrentWidth } from '../../../../utils/custom-hooks/handleWindowResize';
import { app, i18nKeyPrefix } from '../../../../utils/constants';
import defaultImage from '../../../../assets/images/welcome.png';
// import defaultImage from '../../../../assets/images/bar.png';
import { useSelector, connect, useDispatch } from 'react-redux';
import { State } from '../../../../reducers';
import { apiEndpoints } from '../../../../utils/api/endpoints';
import immutableToJS from 'with-immutable-props-to-js';
import { startRequest, setStep, setValidVerificationCode, setVerificationCode, setDynamicFormField, setValidDynamicFormField } from '../../../../actions/form';
import { RequestData } from '../../../../utils/interfaces/request';
import { Action } from 'redux';
import { ReactComponent as LargeLogo } from '../../../../assets/images/grey-logo.svg';
import FormFooterComponent from '../../FormFooter';
import FormHeaderComponent from '../../FormHeader';
import { validateAdditionalInfo, validateAddressInfo, validateCompanyInfo, validateCompanyType, validatePersonalInfo } from '../../../../utils/validation';

interface ParamTypes {
  lang: any;
}

interface Props {
  sendVerificationCode?: (data: RequestData) => void;
  checkVerificationCode?: (data: RequestData) => void;
  completeSignup?: (data: RequestData) => void;
}

const VerificationCode: React.FC<Props> = ({
  checkVerificationCode,
  sendVerificationCode,
  completeSignup
}: Props) => {
  const marketData = useSelector((state: State) => state.router.marketData);
  const marketConfig = useSelector((state: State) => state.router.marketConfig);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const size = getCurrentWidth();
  const history = useHistory();
  const [windowSize, setWindowSize] = useState(size);
  useEffect(() => {
    setWindowSize(size);
  });
  const params = useParams<ParamTypes>();
  const isMobile = windowSize <= app.breakPoint;
  const [validStep, setValidStep] = useState(false);

  const changeStep = (step: number) => {
    history.push(`/${params.lang}/signup/${step}`);
    dispatch(setStep(step));
  }

  const saveId = useSelector((state: State) => state.form.saveId.saveId);
  const form = useSelector((state: State) => state.form);
  const [showLoader, setLoader] = useState(false);
  const verificationCode = useSelector((state: State) => state.form.verificationCode);
  const responseCode = useSelector((state: State) => state.form.responseCode);
  const responseSignup = useSelector((state: State) => state.form.responseSignup);
  const validVerificationCode = useSelector((state: State) => state.form.validVerificationCode);
  const phone = useSelector((state: State) => state.form.phone);
  const step = useSelector((state: State) => state.form.step);
  const companyTypeImage = useSelector((state: State) => state.form.companyTypeImage);
  const [counter, setCounter] = useState(0);
  const [isValidated, setIsValidated] = useState(false);

  const prefix = i18nKeyPrefix + 'LeanSignup_Form_CodeVerify';

  useEffect(() => {
    if (verificationCode && validVerificationCode && responseCode.success && responseSignup) {
      setLoader(false);
      if (responseSignup.success) {
        history.push(`/${params.lang}/welcome`);
      } else {
        history.push(`/${params.lang}/error`);
      }
    }
  }, [responseSignup])

  // useEffect(() => {
  //   const listener = event => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       event.preventDefault();
  //       validateStep();
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, []);

  useEffect(() => {
    if (form && form.saveId.saveId && marketConfig && !isValidated) {
      if (!validatePersonalInfo(dispatch, marketConfig, form)) {
        history.push(`/${params.lang}/signup/1?error=validation`);
      } else if (!validateCompanyInfo(dispatch, marketConfig, form)) {
        history.push(`/${params.lang}/signup/2?error=validation`);
      } else if (!validateAddressInfo(dispatch, marketConfig, form)) {
        history.push(`/${params.lang}/signup/3?error=validation`);
      } else if (!validateCompanyType(dispatch, marketConfig, form)) {
        history.push(`/${params.lang}/signup/4?error=validation`);
      } else {
        let validateDone = false;
        marketConfig?.form.pages.map((page: any, index: number) => {
          if (!validateDone) {
            if(!validateAdditionalInfo(form, index, setDynamicFormField, setValidDynamicFormField, t)) {
              validateDone = true;
              history.push(`/${params.lang}/signup/5/${page.pageStepName}?error=validation`);
            }
          }
        });
      }

      setIsValidated(true);
    }
  }, [marketConfig, form]);

  useEffect(() => {
    dispatch(setStep(8));
  }, [])

  const resendCode = () => {
    if (!phone || counter > 0) return;
    sendVerificationCode({
      type: 'POST',
      endpoint: `${apiEndpoints.sendVerificationCode}/${saveId}?countryCode=${marketData.marketId.toUpperCase()}`
    });

    let time = 20;
    setCounter(time);
    const counterInterval = setInterval(() => {
      if (time <= 1) {
        clearInterval(counterInterval);
      }

      time = time - 1;
      setCounter(time);
    }, 1000);
  }

  const validateCode = (val: string) => {
    if (!val) {
      dispatch(setValidVerificationCode(false));
      return null;
    }
    if (val && val.length === 4 && saveId) {
        checkVerificationCode({
          type: 'POST',
          endpoint: `${apiEndpoints.verificationCode}?id=${saveId}&code=${val}&countryCode=${marketData.marketId.toUpperCase()}`,
        });
        dispatch(setValidVerificationCode(true));
        return null;
    } else if (val) {
      dispatch(setVerificationCode(val));
      dispatch(setValidVerificationCode(true));
    };

    return true;
  }

  const validateStep = () => {
    setLoader(true);
    if (verificationCode && validVerificationCode && responseCode.success) {
        setTimeout(() => {
        dispatch(setValidVerificationCode(true));
        setValidStep(true);
        completeSignup({
          type: 'POST',
          endpoint: `${apiEndpoints.signup}/submit/${saveId}?countryCode=${marketData.marketId.toUpperCase()}`,
        });
        return;
        }, 3000);
    } else {
        setTimeout(() => {
        dispatch(setValidVerificationCode(false));
        setValidStep(false);
        setLoader(false);
        return;
        }, 3000);
    }
}

  return (
    <React.Fragment>
      <div className={styles.formContainer}>
        {(!isMobile && !marketConfig.hideVerificationLogo) && <LargeLogo style={{position: 'absolute', top: 30, right: 30}}/>}
        <FormHeaderComponent changeStep={changeStep} />
        <div className={styles.container}>
          {!isMobile && <div className={styles.halfBackground}><div style={{ backgroundImage: companyTypeImage ? `url(${companyTypeImage})` : `url(${defaultImage})`}} className={cn(styles.image)}></div></div>}
          <div className={styles.innerContainer}>
            <div className={styles.wrapper}>
              <div className={styles.leftScreen}>
              <div className={styles.header} data-i18n-key={`${prefix}_title`}>{t(`${prefix}_title`)}</div>
            </div>
            <div className={styles.rightScreen}>
            <div className={styles.infoWrapper}>
              <div className={styles.section}>
                <p className={styles.sectionHeader} data-i18n-key={`${prefix}_title2`}>{t(`${prefix}_title2`)}</p>
                <p className={styles.info} data-i18n-key={`${prefix}_description`}>{t(`${prefix}_description`)} {isMobile && <span className={styles.info} style={{fontWeight: 700}}>{phone}</span>}</p>
                {!isMobile && <p className={styles.info} style={{fontWeight: 700}}>{phone}</p>}
              </div>
            </div>

            <div className={styles.bottomWrapper}>
              <div className={styles.section} data-i18n-key={`${prefix}_label`}>
                <CodeVerify
                  onChange={validateCode}
                  label={t(`${prefix}_label`)}
                  required
                  valid={validVerificationCode}
                />
              </div>
              <p className={cn(styles.resentCode, {[styles.disabled] : (counter > 0)})} onClick={() => resendCode()} data-i18n-key={`${prefix}_resendCode`}>{t(`${prefix}_resendCode`)} {(counter > 0) && <span>({counter})</span>}</p>
              {!validVerificationCode && <div className={styles.codeMissing} data-i18n-key={`${prefix}_error`}>{t(`${prefix}_error`)}</div>}
            </div>
          </div>
        </div>
      </div>
        </div>
      </div>
      <FormFooterComponent validateStep={validateStep} showLoader={showLoader} />
    </React.Fragment>
  );
}

const mapStateToProps = (state: State) => {
    return {
    };
  };

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        sendVerificationCode: (data: RequestData) => {
          dispatch(startRequest(data, 'verificationCode'));
        },
        checkVerificationCode: (data: RequestData) => {
          dispatch(startRequest(data, 'responseCode'));
        },
        completeSignup: (data: RequestData) => {
          dispatch(startRequest(data, 'responseSignup'));
        }
    };
  };

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(immutableToJS(VerificationCode));