import React, { ChangeEventHandler, createRef, RefObject, SyntheticEvent, useState } from 'react';

import cn from 'classnames';
import styles from './input.module.scss';
import InputMask from 'react-input-mask';
import Text, { SIZES, TEXTCOLOR } from '../Text';
export type InputChangeEventHandler = ChangeEventHandler<HTMLInputElement>;

interface Props {
  disabled?: boolean;
  onBlur?: (e?: any) => void;
  onChange?: (e?: any) => void;
  onInput?: (e?: any) => void;
  required?: boolean;
  errorMessage?: string;
  label?: string;
  mask?: string;
  valid?: boolean;
  value?: string;
  type?: string;
  placeholder?: string;
  defaultValue?: string;
  name?: string;
  hideLabel?: boolean;
  info?: string;
  showValidColor?: boolean;
  maxLength?: number;
  dataId?: string;
  labelBold?: boolean;
}

const Input: React.FC<Props> = ({
  disabled,
  errorMessage,
  label,
  mask,
  type,
  onBlur,
  onChange,
  onInput,
  placeholder,
  required,
  valid,
  value,
  defaultValue,
  name,
  hideLabel,
  info,
  showValidColor = false,
  maxLength,
  dataId,
  labelBold = false
}: Props) => {
  const inputRef: RefObject<HTMLInputElement> = createRef();
  const textareaRef: RefObject<HTMLTextAreaElement> = createRef();
  const isTextarea = type && type === "textarea";


  const clickLabel = () => {
    if (isTextarea) {
      textareaRef.current.focus();
      return;
    }
    inputRef.current.focus()
  }


  return (
    <div className={styles.wrapper}>
      {label &&
        <label
          className={cn(styles.label, {[styles.labelBold]: labelBold, [styles.error]: required && !valid})}
          onClick={() => clickLabel} >
          <Text size={SIZES.SMALL} color={labelBold ? TEXTCOLOR.DARK_GREY : TEXTCOLOR.STEEL}>{!hideLabel ? label : ''}{required && ' *'}</Text>
        </label>
      }
      {isTextarea ?
        <textarea
          className={cn(styles.input, styles.textarea, {[styles.error]: required && !valid})}
          disabled={disabled}
          ref={textareaRef}
          defaultValue={value}
          placeholder={placeholder}
          onBlur={onBlur}
          onInput={onInput}
          onChange={onChange}
          data-id={dataId}>
        </textarea>
      :
        mask ?
        <InputMask
          disabled={disabled}
          maskChar=" "
          mask={mask}
          defaultValue={value}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          onInput={onInput}>
          {(inputProps: any) =>
            <input
              {...inputProps}
              className={cn(styles.input, {[styles.error]: required && !valid})}
              ref={inputRef}
              placeholder={placeholder}
              type={type ? type : "text"}
            />
          }
        </InputMask>
        :
        <input
          className={cn(styles.input, {[styles.error]: required && !valid, [styles.validInput] : showValidColor && valid && value.length})}
          ref={inputRef}
          disabled={disabled}
          placeholder={placeholder}
          type={type ? type : "text"}
          onInput={onInput}
          onBlur={onBlur}
          onChange={onChange}
          name={name}
          value={value || defaultValue || ''}
          maxLength={maxLength}
          data-id={dataId}
        />
      }

      {info && 
          <Text size={SIZES.TINY} color={TEXTCOLOR.BLACK} className={styles['error-message']}>
            <span data-i18n-key={info}>{info}</span>
          </Text>
        }

      {(required && !valid && errorMessage) &&
        <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
          {errorMessage}
          {valid}
        </Text>
      }
    </div>
  );
};

export default Input;
