import React, { useEffect, ReactHTMLElement, useState } from 'react';
import cn from 'classnames';

import RichText from './../../Editors/RichText';
import Image from './../../Editors/Image';
import Hero from './../../Editors/Hero';
import Quote from './../../Editors/Quote';
import Faq from './../../Editors/Faq';

import styles from './carousel.module.scss';
import gridStyles from './../../grid.module.scss';
import { HeadlessGridEditor, HeadlessGridEditorViewModel } from './../../../../utils/interfaces/headless';

interface CarouselViewModel {
  viewModel: HeadlessGridEditorViewModel;
};

const GridElements: any = {
  RichText,
  Image,
  Hero,
  Quote,
  Faq
};

const CarouselWrapper: React.FC<CarouselViewModel> = ({
  viewModel
}: CarouselViewModel) => {

  const el = React.createRef<HTMLDivElement>();

  const [slides] = useState(viewModel.slides);

  const DynamicComponent = (editor: HeadlessGridEditor) => {
    const editorAliasShort = editor.alias.replace('carlsberg.grideditor.leansignup.', '');
    const editorAlias = editorAliasShort.charAt(0).toUpperCase() + editorAliasShort.slice(1);
    if (typeof GridElements[editorAlias] !== "undefined") {
      return React.createElement(GridElements[editorAlias], {
        viewModel: editor.viewModel,
      })
    }
    return React.createElement(
      () => <div>The component {editor.alias} has not been created yet.</div>
    )
  }

  const getSlideContent = (vModel: any, index: number) => {
    const slide = vModel?.gridContent?.rows;
    const rowArray: any[] = [];
    for (let i = 0; i < slide.length; i++) {
      const row = slide[i];
      const colArray: any[] = [];
      for (let j = 0; j < row.columns.length; j++) {
        const col = row.columns[j];
        const editorArray: any[] = [];
        for (let k = 0; k < col.editors.length; k++) {
          const editor = col.editors[k];
          const editorObj = <div key={ 'editor-' + k } >{DynamicComponent(editor)}</div>;
          editorArray.push(editorObj);
        };
        const colObj = <div key={ 'col-' + j } className={ gridStyles['col-' + col.size] } >{editorArray}</div>;
        colArray.push(colObj);
      };
      const rowObj =  <div key={ 'row-' + i }>
                      <div className={cn(gridStyles.row, styles.bottleRow)} >
                        <Image viewModel={vModel}/>
                      </div>
                      <div className={cn(gridStyles.row, styles.contentRow)} >{colArray}</div>
                    </div>;
      rowArray.push(rowObj);
    }

    return  <div key={ 'slide-' + index } className={cn(styles.container, styles['slide--' + (index + 1)], {[styles['container-fluid']]: !!(slide.config && slide.config.fullWidth === '1')})}>
              {rowArray}
            </div>;
  }

  const getSlides = (slides: any) => {
    const slidesArray: any[] = [];
    const slidesObj: any = slides;
    for (let i = 0; i < slidesObj.length; i++) {
      const slide = getSlideContent(slidesObj[i], i);
      slidesArray.push(slide);
    }
    return  <div>
              {slidesArray}
            </div>;
  }

  return (
    <div className={styles.carousel} ref={ el } id="brands">
      <h1>{viewModel.headline}</h1>
      {slides && getSlides(slides)}
    </div>
  )
};

export default CarouselWrapper;
