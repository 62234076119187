import React, { Dispatch, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import LandingComponent from '../components/Landing';
import { getHeadlessContent, isGlobalLoading, isHeadlessLoading, getGlobalContent } from "../utils/selectors/headless/selectors";
import immutableToJS from 'with-immutable-props-to-js';
import TagManager from "react-gtm-module";
import { startHeadlessRequest } from "../actions/api";
import { State } from '../reducers';
import Loading from "../components/Loading";
import { useHistory } from "react-router-dom";
import { configName, getConfig, getConfigUrl } from "../utils/config/getConfig";
import { resetState } from "../actions/form";

interface Props {
  isMobile: boolean;
  loading: boolean;
  url: string;
  content: any;
  headlessRequest: any;
}

const cookieConsent = window.localStorage.getItem('cookies') === 'accepted' ? 'yes' : 'no';

const LandingContainer: React.FC<Props> = ({
  isMobile,
  loading = true,
  url,
  content,
  headlessRequest,
}: Props) => {
  const [marketData, setMarketData] = useState((state: State) => state?.router?.marketData);

  if(!marketData) {
    (async () => {
      let marketDataObj = await getConfig(`${getConfigUrl(configName.market)}`, configName.market);
      setMarketData(marketDataObj);
    })()
  }
  const loc = location.pathname;
  const history = useHistory();
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (!content) {
      headlessRequest(url);
    }
  }, [content, url]);

  useEffect(() => {
    if (window.localStorage.getItem("formData")) {
      dispatch(resetState());
      localStorage.removeItem("formData");
    }
  }, []);
  
  useEffect(() => {
    if (loc === `/` && marketData?.defaultLanguage) {
      const lang = localStorage.getItem('i18nextLng');
      if (lang) {
        history.push(`/${lang}/`);
      } else {
        const language: string = marketData?.defaultLanguage.toLowerCase();
        localStorage.setItem('i18nextLng', language);
        history.push(`/${language}/`);
      }
    }

    if(marketData?.marketId) {
      const tagManagerDataLayerArgs = {
        dataLayer: {
          'event': 'virtualPageView',
          'pageCountry': marketData?.marketId,
          'pageLanguage': localStorage['i18nextLng'],
          'pageTitle': 'Lean Signup',
          'pageStep': 0,
          'pageStepName': 'LandingPage',
          'userAcceptCookies': cookieConsent
        }
      };
      TagManager.dataLayer(tagManagerDataLayerArgs)
    }
  }, [loc, marketData]);

  if (content) {
    return (
      <LandingComponent
        isMobile={isMobile}
        content={content}
      />
    )
  } else if (loading) {
    return <Loading fullScreen hideText />
  } else {
    return <span>We encontered a problem connecting to our CMS. Please try again, or contact your System Administrator.</span>
  }
};

const mapStateToProps = (state: State) => {
  return {
    url: getGlobalContent(state)?.get('frontPage')?.get('url'),
    loading: isGlobalLoading(state) || isHeadlessLoading(state),
    content: getHeadlessContent(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    headlessRequest: (url: string) => {
      dispatch(startHeadlessRequest("HEADLESS", url));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(LandingContainer));
