import  { actions } from '../constants/router';

export const setConfig = (
    configData: any
) => ({
    type: actions.SET_CONFIG_DATA,
    configData
});

export const setMarket = (
    marketData: any
) => ({
    type: actions.SET_MARKET_DATA,
    marketData
});

export const setMarketConfig = (
    marketConfig: any
) => ({
    type: actions.SET_MARKET_CONFIG,
    marketConfig
});
