export enum actions {
    SET_DIFFERENT_ADDRESS = 'SET_DIFFERENT_ADDRESS',
    SET_COMPANY_NAME = 'SET_COMPANY_NAME',
    SET_VALID_COMPANY_NAME = 'SET_VALID_COMPANY_NAME',
    SET_MANAGER_NAME = 'SET_MANAGER_NAME',
    SET_VALID_MANAGER_NAME = 'SET_VALID_MANAGER_NAME',
    SET_JOB_TITLE = 'SET_JOB_TITLE',
    SET_VALID_JOB_TITLE = 'SET_VALID_JOB_TITLE',
    SET_FIRST_NAME = 'SET_FIRST_NAME',
    SET_VALID_FIRST_NAME = 'SET_VALID_FIRST_NAME',
    SET_LAST_NAME = 'SET_LAST_NAME',
    SET_VALID_LAST_NAME = 'SET_VALID_LAST_NAME',
    SET_PHONE = 'SET_PHONE',
    SET_VALID_PHONE = 'SET_VALID_PHONE',
    SET_EMAIL = 'SET_EMAIL',
    SET_VALID_EMAIL = 'SET_VALID_EMAIL',
    SET_CONFIRMATION_EMAIL = 'SET_CONFIRMATION_EMAIL',
    SET_VALID_CONFIRMATION_EMAIL = 'SET_VALID_CONFIRMATION_EMAIL',
    SET_ACCEPT_TERMS = 'SET_ACCEPT_TERMS',
    SET_VALID_TERMS = 'SET_VALID_TERMS',
    SET_TERMS_CONSENT = 'SET_TERMS_CONSENT',
    SET_ACCEPT_NEWSLETTER = 'SET_ACCEPT_NEWSLETTER',
    SET_VALID_NEWSLETTER = 'SET_VALID_NEWSLETTER',
    SET_NEWSLETTER_CONSENT = 'SET_NEWSLETTER_CONSENT',
    SET_ACCEPT_SMS_SERVICE = 'SET_ACCEPT_SMS_SERVICE',
    SET_VALID_SMS_SERVICE = 'SET_VALID_SMS_SERVICE',
    SET_SMS_SERVICE_CONSENT = 'SET_SMS_SERVICE_CONSENT',
    SET_ACCEPT_MARKETING_MESSAGE = 'SET_ACCEPT_MARKETING_MESSAGE',
    SET_VALID_MARKETING_MESSAGE = 'SET_VALID_MARKETING_MESSAGE',
    SET_MARKETING_MESSAGE_CONSENT = 'SET_MARKETING_MESSAGE_CONSENT',
    SET_ACCEPT_PRIVACY_AND_COOKIES = 'SET_ACCEPT_PRIVACY_AND_COOKIES', 
    SET_VALID_PRIVACY_AND_COOKIES = 'SET_VALID_PRIVACY_AND_COOKIES',
    SET_PRIVACY_AND_COOKIES_CONSENT = 'SET_PRIVACY_AND_COOKIES_CONSENT', 
    SET_VAT = 'SET_VAT',
    SET_VALID_VAT = 'SET_VALID_VAT',
    SET_COMPANY_TYPE = 'SET_COMPANY_TYPE',
    SET_COMPANY_TYPE_IMAGE = 'SET_COMPANY_TYPE_IMAGE',
    SET_VALID_COMPANY_TYPE = 'SET_VALID_COMPANY_TYPE',
    SET_VERIFICATION_CODE = 'SET_VERIFICATION_CODE',
    SET_VALID_VERIFICATION_CODE = 'SET_VALID_VERIFICATION_CODE',
    SET_SAVE_ID = 'SET_SAVE_ID',
    SET_VAT_CHECKED = 'SET_VAT_CHECKED',
    API_REQUEST_INIT = 'API_REQUEST_INIT',
    GET_RESPONSE = 'GET_RESPONSE',
    SET_STEP = 'SET_STEP',
    SET_DYNAMIC_STEP = 'SET_DYNAMIC_STEP',
    SET_API_ERROR = 'SET_API_ERROR',
    GET_ORGANIZATION = 'GET_ORGANIZATION',
    CLEAR_ORGANIZATION = 'CLEAR_ORGANIZATION',
    SET_CATEGORIES = 'SET_CATEGORIES',
    SET_BRANDS = 'SET_BRANDS',
    SET_PRODUCTS = 'SET_PRODUCTS',
    SET_SELECTED_CATEGORIES = 'SET_SELECTED_CATEGORIES',
    SET_SELECTED_PRODUCTS = 'SET_SELECTED_PRODUCTS',
    SET_SELECTED_BRANDS = 'SET_SELECTED_BRANDS',
    SET_SELECTED_FIND_US = 'SET_SELECTED_FIND_US',
    GET_ATTACHMENTS = 'GET_ATTACHMENTS',
    SET_SEASONS = 'SET_SEASONS',
    SET_SELECTED_SEASON = 'SET_SELECTED_SEASON',
    SET_VALID_IBAN = 'SET_VALID_IBAN',
    SET_AVAILABLE_DAYS = 'SET_AVAILABLE_DAYS',
    SET_ADDITIONAL_DATA = 'SET_ADDITIONAL_DATA',
    SET_DUNNING_EMAIL = 'SET_DUNNING_EMAIL',
    SET_ELMA_EMAIL = 'SET_ELMA_EMAIL',
    SET_ELMA_INVOICE = 'SET_ELMA_INVOICE',
    SET_SIGNUP_ADDITIONAL_FORM = 'SET_SIGNUP_ADDITIONAL_FORM',
    SET_DYNAMIC_FORM_FIELD = 'SET_DYNAMIC_FORM_FIELD',
    SET_VALID_DYNAMIC_FORM_FIELD = 'SET_VALID_DYNAMIC_FORM_FIELD',
    SET_DYNAMIC_FORM_FIELDS = 'SET_DYNAMIC_FORM_FIELDS',
    SET_FIELD_VALUE = 'SET_FIELD_VALUE',
    SET_VALID_FIELD_VALUE = 'SET_VALID_FIELD_VALUE',
    SET_REVIEW = 'SET_REVIEW',
    SET_EDIT = 'SET_EDIT',
    SET_ATTACHMENTS = 'SET_ATTACHMENTS',
    SET_INFO_SENT = 'SET_INFO_SENT',
    SET_EHF_AVAILABLE = 'SET_EHF_AVAILABLE',
    SET_MAIN_TRADE_CHANNEL = 'SET_MAIN_TRADE_CHANNEL',
    SET_ADDITIONAL_TRADE_CHANNEL = 'SET_ADDITIONAL_TRADE_CHANNEL',
    SET_UPLOADING = 'SET_UPLOADING',
    RESET_STATE = 'RESET_STATE',
    SET_DYNAMIC_VALUE = 'SET_DYNAMIC_VALUE',
    SET_VALID_DYNAMIC_VALUE = 'SET_VALID_DYNAMIC_VALUE'
 }