import React, { Dispatch, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import immutableToJS from 'with-immutable-props-to-js';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Modal from '../../Modal';
import Button from '../../Button';
import { State } from '../../../reducers';
import { getGlobalContent } from '../../../utils/selectors/headless/selectors';
import styles from './infoModal.module.scss';
import InfoIcon from '../../InfoIcon';
import backupBackgroundImage from '../../../assets/images/support.png';
import { i18nKeyPrefix } from '../../../utils/constants';

interface Props {
    global?: any;
    value?: boolean;
    action?: (val: any) => void;
    backButtonText?: string;
}

const InfoModalComponent: React.FC<Props> = ({
    global,
    action,
    value,
    backButtonText
}: Props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const prefix = i18nKeyPrefix + 'LeanSignup_Form';

  const [umbracoBackgroundImage, setUmbracoBackgroundImage] = useState(backupBackgroundImage);
  const [umbracoCopy, setUmbracoCopy] = useState(`<div><a href="mailto:customersupport@carlsberg.com">customersupport@carlsberg.com</a></div><div>Ontrade: <a href="tel:+45 33271000">+45 33271000</a></div><div>Offtrade: <a href="tel:+45 3327 2600">+45 3327 2600</a></div>`);

  useLayoutEffect(() => {
    if (global) {
      if(global?.contactInfo?.image?.url) {
        setUmbracoBackgroundImage(global?.contactInfo?.image?.url);
      }
      if(global?.contactInfo?.text) {
        setUmbracoCopy(global?.contactInfo?.text);
      }
    }
  }, [global]);

  return (
    <div className={styles.infoIcon}>
      <InfoIcon color="black" action={() => action(true)}/>
      <Modal
        active={value}
        closeModal={() => action(false)}
        title={''}
      >
        <div className={styles.terms}>
          <img src={umbracoBackgroundImage} className={styles.image}></img>
          <div className={styles.info}>
            <div className={styles.infoHeader} data-i18n-key={`${prefix}_Info_title`}>{t(`${prefix}_Info_title`)}</div>
            <span dangerouslySetInnerHTML={{__html: umbracoCopy }}></span>
          </div>
        </div>
        <span data-i18n-key={`${prefix}_Navigation_close`}>
          <Button
            color="black"
            text={backButtonText || t(`${prefix}_Navigation_close`)}
            action={() => action(false)}
          />
        </span>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state: State) => {
  return {
    global: getGlobalContent(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(InfoModalComponent));