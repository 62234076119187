import React, { useEffect, useState } from 'react';
import styles from './dynamicFormPage.module.scss';
import Input from '../Input';
import Checkbox from '../Checkbox';
import Text, { TEXTCOLOR, SIZES } from '../Text';
import Select from '../Select';
import Radio from '../Radio';
import DatePicker from '../DatePicker';
import dayjs from 'dayjs';
import RadioQuestion from '../RadioQuestion';
import IbanInput from '../IbanInput';
import TextField from '../TextField';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../reducers';
import RadioCard from '../RadioCard';
import CheckLabel from '../CheckLabel';
import DaysAndHours from '../DaysAndHours';
import OpeningHours from '../OpeningHours';
import ScreenTitle from '../SignUpForm/ScreenTitle';
import { useTranslation } from 'react-i18next';
import FileInput from '../FileInput';
import { RequestData } from '../../utils/interfaces/request';
import { apiEndpoints } from '../../utils/api/endpoints';
import cn from 'classnames';
import Chip from '../Chip';
import { setProducts, setSelectedProducts, setBrands, setSelectedBrands, setSelectedFindUs } from '../../actions/form';
import { i18nKeyPrefix } from '../../utils/constants';
import SelectWithTextField from '../SelectWithTextField';

interface DynamicFormPageProps {
  configPage: number;
  validateField: (e: any, field: any, item?: any, regex?: string, form?: any) => boolean;
  uploadRequest?: (data: RequestData) => void;
  getFilesRequest?: (data: RequestData) => void;
  checkAccountNumberRequest?: (data: RequestData) => void;
}

const DynamicFormPage: React.FC<DynamicFormPageProps> = ({
  configPage,
  validateField,
  uploadRequest,
  getFilesRequest,
  checkAccountNumberRequest,
}) => {
  const [formHtml, setFormHtml] = useState([]);
  const invoice = useSelector((state: State) => state.form.additionalData.elmaInvoice);
  const saveId = useSelector((state: State) => state.form.saveId.saveId);
  const files = useSelector((state: State) => state.form.attachments);
  const form = useSelector((state: State) => state.form);
  const { t } = useTranslation();
  const marketConfig: any = useSelector((state: State) => state.router.marketConfig);
  const ibanRegex = marketConfig?.ibanRegex;
  const brandTiles = useSelector((state: State) => state.form.brands);
  const productTiles = useSelector((state: State) => state.form.products);
  const selectedProducts = useSelector((state: State) => state.form.additionalData.selectedProducts || []);
  const selectedBrands = useSelector((state: State) => state.form.additionalData.selectedBrands || []);
  const selectedFindUs = useSelector((state: State) => state.form.additionalData.findUs || null);
  const dispatch = useDispatch();

  useEffect(() => {
    let isCallDone = true;
    if (marketConfig.makeDynamicProductsApiCall && !productTiles) {
      isCallDone = false;
    }

    if (marketConfig.makeDynamicBrandsApiCall && !brandTiles) {
      isCallDone = false;
    }

    if (!form.marketData) {
      isCallDone = false;
    }

    if (isCallDone) {
      renderForm(form.marketData);
    }
  }, [form.marketData, form.dynamicFormValidFields, brandTiles, productTiles, selectedProducts, selectedBrands]);

  const renderForm = (formData: any) => {
    setFormHtml([]);
    if (
      formData &&
      formData.form &&
      formData.form.pages &&
      formData.form.pages.length > 0 &&
      formData.form.pages[configPage].sections &&
      formData.form.pages[configPage].sections.length > 0
    ) {
      //no pagination or section needed so directly render fields
      const sections = formData.form.pages[configPage].sections;

      sections.map((section: any, index: any) => {
        renderField(section, section.fields, index);
      });
    }
  };

  const renderField = (section: any, fields: any, index: any) => {
    fields.map((field: any) => {
      if (field.type === 'input') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderInput(field, section)]);
      } else if (field.type === 'checkbox') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderCheckbox(field, section)]);
      } else if (field.type === 'select') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderSelect(field, section)]);
      } else if (field.type === 'selectWithTextField') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderSelectWithTextField(field, section)]);
      } else if (field.type === 'radio') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderRadio(field, section)]);
      } else if (field.type === 'date') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderCalendar(field, section)]);
      } else if (field.type === 'radioQuestion') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderRadioQuestion(field, section)]);
      } else if (field.type === 'isOwnerChange') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderEstablishmentOwnership(field, section)]);
      } else if (field.type === 'radioCard') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderRadioCard(field, section)]);
      } else if (field.type === 'radioCardGeneric') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderRadioCardGeneric(field, section)]);
      } else if (field.type === 'businessHours') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderDaysAndHours(field, section)]);
      } else if (field.type === 'openingHours') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderOpeningHours(field, section)]);
      } else if (field.type === 'timeWindow') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderTimeWindow(field, section)]);
      } else if (field.type === 'accountNumber') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderIban(field, section, ibanRegex)]);
      } else if (field.type === 'invoiceByElma') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderInvoiceCheck(field, section)]);
      } else if (field.type === 'textarea') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderTextField(field, section)]);
      } else if (field.type === 'dunning') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderDunning(field, section)]);
      } else if (field.type === 'fileUpload') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderFileUpload(field, section)]);
      } else if (field.type === 'eInvoice') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderEInvoice(field, section)]);
      } else if (field.type === 'brands') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderBrands(field, section)]);
      } else if (field.type === 'products') {
        setFormHtml((oldFormHtml) => [...oldFormHtml, renderProducts(field, section)]);
      }
    });
  };

  const renderInput = (field: any, section: any) => {
    return (
      <div key={field.name} className={cn({ [styles[field.class]]: field.class })}>
        {(!field.hideRelated || (field.hideRelated && field.related && form.additionalData[field.related])) && (
          <div
            className={styles.itemWrapper}
            data-i18n-key-title={section.sectionTitle}
            data-i18n-key-label={field.label}
            data-i18n-key-placeholder={field.placeholder}
            data-i18n-key-error={field.errorMsg}
          >
            {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
            <Input
              label={t(i18nKeyPrefix + field.label)}
              labelBold={field.labelBold}
              placeholder={t(i18nKeyPrefix + field.placeholder)}
              dataId={`input${field.name}`}
              onChange={(e: any) => {
                validateField(e, field);
              }}
              required={field.required}
              value={
                form.additionalData[field.name] === undefined
                  ? field.defaultValue
                    ? field.defaultValue
                    : ''
                  : form.additionalData[field.name]
              }
              valid={
                form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name]
              }
              info={field.infoMsg ? t(i18nKeyPrefix + field.infoMsg) : null}
              maxLength={field.maxLength}
            />
            {!(form.dynamicFormValidFields[field.name] === undefined
              ? true
              : form.dynamicFormValidFields[field.name]) &&
              field.errorMsg &&
              (field.required || form.additionalData[field.name].length) && (
                <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
                  {t(i18nKeyPrefix + field.errorMsg)}
                </Text>
              )}
          </div>
        )}
      </div>
    );
  };

  const renderSelect = (field: any, section: any) => {
    const valid =
      form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];

    return (
      <div
        data-i18n-key-title={section.sectionTitle}
        data-i18n-key-label={field.label}
        data-i18n-key-error={field.errorMsg}
        className={cn(styles.itemWrapper, { [styles[field.class]]: field.class })}
        key={field.name}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        <label className={styles.label}>
          <Text size={SIZES.SMALL} bold>
            {t(i18nKeyPrefix + field.label)}
            {field.required && ' *'}
          </Text>
        </label>
        <Select
          value={form.additionalData[field.name]}
          options={field.options}
          translateOptions={true}
          dataId={`select${field.name}`}
          onChange={(e) => {
            validateField(e, field);
          }}
          required={field.required}
        />
        {field.required && !valid && field.errorMsg && (
          <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
            {t(i18nKeyPrefix + field.errorMsg)}
          </Text>
        )}
      </div>
    );
  };

  const renderSelectWithTextField = (field: any, section: any) => {
    const valid =
      form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];

    return (
      <div
        data-i18n-key-title={section.sectionTitle}
        data-i18n-key-label={field.label}
        data-i18n-key-error={field.errorMsg}
        className={cn(styles.itemWrapper, { [styles[field.class]]: field.class })}
        key={field.name}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        <label className={styles.label}>
          <Text size={SIZES.SMALL} bold>
            {t(i18nKeyPrefix + field.label)}
            {field.required && ' *'}
          </Text>
        </label>
        <SelectWithTextField
          value={selectedFindUs}
          options={field.options}
          hasUmbracoOptions={field.hasUmbracoOptions}
          hasOtherOption={field.hasOtherOption}
          translateOptions={true}
          dataId={`select${field.name}`}
          onChangeOption={(e) => {
            dispatch(setSelectedFindUs({ optionValue: e.target.value, otherValue: undefined }));
            validateField(e, field);
          }}
          onChangeOther={(e) => {
            dispatch(setSelectedFindUs({ optionValue: selectedFindUs.optionValue, otherValue: e.target.value }));
            validateField(e, field);
          }}
          required={field.required}
          headlessRequestName={field.headlessRequestName}
          headlessPropertyName={field.headlessPropertyName}
          valid={valid}
        />
        {field.required && !valid && field.errorMsg && (
          <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
            {t(i18nKeyPrefix + field.errorMsg)}
          </Text>
        )}
      </div>
    );
  };

  const renderCheckbox = (field: any, section: any) => {
    const checked =
      form.additionalData[field.name] === undefined
        ? field.defaultStatus
          ? field.defaultStatus
          : false
        : form.additionalData[field.name] == true || form.additionalData[field.name] == 'true';

    return (
      <div
        data-i18n-key-title={section.sectionTitle}
        data-i18n-key-label={field.label}
        data-i18n-key-error={field.errorMsg}
        className={styles.itemWrapper}
        key={field.name}
      >
        {section.sectionTitle && !field.showNoTitle && (
          <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>
        )}
        {field.infoMsg && <div className={styles.infoMessage}>{t(i18nKeyPrefix + field.infoMsg)}</div>}
        <Checkbox
          required={field.required}
          dataId={`check${field.name}`}
          onChange={(e) => {
            validateField(e, field);
          }}
          checked={checked}
          valid={form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name]}
          errorMessage={t(i18nKeyPrefix + field.errorMsg)}
        >
          <Text color={TEXTCOLOR.DARK_GREY} size={SIZES.SMALL}>
            {t(i18nKeyPrefix + field.label)}
          </Text>
        </Checkbox>
      </div>
    );
  };

  const renderRadio = (field: any, section: any) => {
    const valid =
      form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];
    return (
      <div
        data-i18n-key-title={section.sectionTitle}
        data-i18n-key-label={field.label}
        data-i18n-key-error={field.errorMsg}
        className={styles.itemWrapper}
        key={field.name}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        <label className={styles.label}>
          <Text size={SIZES.TINY} color={TEXTCOLOR.STEEL}>
            {field.label}
            {field.required && ' *'}
          </Text>
        </label>
        {field.items.map((item: any) => (
          <Radio
            key={item.name}
            dataId={`radio${field.name}`}
            action={(e: any) => {
              validateField(e, field, item);
            }}
            label={item.value}
            checked={
              form.additionalData[field.name] === undefined
                ? field.defaultName === item.name
                : form.additionalData[field.name] === item.name
            }
          />
        ))}
        {field.required && !valid && field.errorMsg && (
          <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
            {t(i18nKeyPrefix + field.errorMsg)}
          </Text>
        )}
      </div>
    );
  };

  const renderCalendar = (field: any, section: any) => {
    const valid =
      form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];
    return (
      <div
        data-i18n-key-title={section.sectionTitle}
        data-i18n-key-label={field.label}
        data-i18n-key-error={field.errorMsg}
        className={styles.itemWrapper}
        key={field.name}
        data-id={`section${field.name}`}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        <label className={styles.label}>
          <Text size={SIZES.TINY} color={TEXTCOLOR.STEEL}>
            {field.label}
            {field.required && ' *'}
          </Text>
        </label>
        <DatePicker
          valid={(field.required && valid) || !field.required}
          onChange={(e) => {
            validateField(e, field);
          }}
          value={dayjs(
            form.additionalData[field.name] === undefined
              ? field.defaultValue
                ? field.defaultValue
                : new Date()
              : form.additionalData[field.name],
          )}
        />
        {field.required && !valid && field.errorMsg && (
          <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
            {t(i18nKeyPrefix + field.errorMsg)}
          </Text>
        )}
      </div>
    );
  };

  const renderRadioQuestion = (field: any, section: any) => {
    const valid =
      form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];
    const newArray = field.items;
    return (
      <div
        data-i18n-key-title={section.sectionTitle}
        data-i18n-key-label={field.label}
        data-i18n-key-error={field.errorMsg}
        className={styles.itemWrapper}
        key={field.name}
        data-id={`section${field.name}`}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        <RadioQuestion
          label={t(i18nKeyPrefix + field.label)}
          items={newArray}
          action={(item: any) => validateField(null, field, item)}
          field={field}
          dynamicClass={field.class}
        ></RadioQuestion>
        {field.required && !valid && field.errorMsg && (
          <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
            {t(i18nKeyPrefix + field.errorMsg)}
          </Text>
        )}
      </div>
    );
  };

  const renderIban = (field: any, section: any, ibanRegex: string) => {
    const valid =
      form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];
    if (!form.additionalData[field.name]) {
      form.additionalData[field.name] = null;
    }

    const onChangeIban = (val: string) => {
      const valid = validateField(val, field, null, ibanRegex);

      if (valid && field.withAPIValidation) {
        checkAccountNumberRequest({
          type: 'GET',
          endpoint: `${apiEndpoints.signup}/iban/validate/${val}`,
        });
      }
    };

    return (
      <div
        data-i18n-key-title={section.sectionTitle}
        data-i18n-key-label={field.label}
        data-i18n-key-error={field.errorMsg}
        className={styles.itemWrapper}
        key={field.name}
        data-id={`section${field.name}`}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        <IbanInput
          onChange={onChangeIban}
          label={t(i18nKeyPrefix + field.label)}
          required={field.required}
          defaultValue={form.additionalData[field.name]}
          valid={valid}
          info={t(i18nKeyPrefix + field.infoMsg)}
        />
        {!valid && field.errorMsg && (
          <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
            {t(i18nKeyPrefix + field.errorMsg)}
          </Text>
        )}
      </div>
    );
  };

  const renderTextField = (field: any, section: any) => {
    const valid =
      form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];
    return (
      <div
        data-i18n-key-title={section.sectionTitle}
        data-i18n-key-info={field.infoMsg}
        data-i18n-key-placeholder={field.placeholder}
        data-i18n-key-error={field.errorMsg}
        className={styles.itemWrapper}
        key={field.name}
        data-id={`section${field.name}`}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        <TextField
          text={form.additionalData[field.name]}
          action={(e: any) => validateField(e, field)}
          placeholder={t(i18nKeyPrefix + field.placeholder)}
          required={field.required}
          info={field.infoMsg}
        />
        {field.required && !valid && field.errorMsg && (
          <Text
            size={SIZES.TINY}
            color={TEXTCOLOR.RED}
            className={cn('formError', styles['error-message'], styles['margin-top'])}
          >
            {t(i18nKeyPrefix + field.errorMsg)}
          </Text>
        )}
      </div>
    );
  };

  const renderRadioCardGeneric = (field: any, section: any) => {
    const valid =
      form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];

    const checkItem = (item) => {
      if (form.additionalData[field.name] && form.additionalData[field.name] === t(i18nKeyPrefix + item.value)) {
        return true;
      }

      return false;
    };

    return (
      <div
        data-i18n-key-title={section.sectionTitle}
        data-i18n-key-error={field.errorMsg}
        className={styles.itemWrapper}
        key={field.name}
        data-id={`section${field.name}`}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        {field.infoMsg && <div className={styles.infoMessage}>{t(i18nKeyPrefix + field.infoMsg)}</div>}
        <div className={cn(styles.cards, { [styles[field.class]]: field.class })}>
          {field.items &&
            field.items.length > 0 &&
            field.items.map((item: any, i: any) => (
              <span data-i18n-key-name={item.name} key={`${field.name}-${i}`}>
                <RadioCard
                  key={i}
                  label={t(i18nKeyPrefix + item.name)}
                  icon={item.icon}
                  checked={checkItem(item)}
                  action={() => validateField(null, field, t(i18nKeyPrefix + item.value))}
                  genericClass={field.class}
                ></RadioCard>
              </span>
            ))}
        </div>
        {field.required && !valid && field.errorMsg && (
          <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
            {t(i18nKeyPrefix + field.errorMsg)}
          </Text>
        )}
      </div>
    );
  };

  const renderRadioCard = (field: any, section: any) => {
    const valid =
      form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];

    const checkSeason = (season) => {
      if (form.additionalData[field.name] && form.additionalData[field.name]?.value === season.value) {
        return true;
      }

      return false;
    };

    return (
      <div
        data-i18n-key-title={section.sectionTitle}
        data-i18n-key-error={field.errorMsg}
        className={styles.itemWrapper}
        key={field.name}
        data-id={`section${field.name}`}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        <div className={styles.cards}>
          {field.items &&
            field.items.length > 0 &&
            field.items.map((season: any, i: any) => (
              <span data-i18n-key-name={season.name} key={`${field.name}-${i}`}>
                <RadioCard
                  key={i}
                  label={t(i18nKeyPrefix + season.name)}
                  icon={season.icon}
                  checked={checkSeason(season)}
                  action={() => validateField(null, field, season)}
                ></RadioCard>
              </span>
            ))}
        </div>
        {field.required && !valid && field.errorMsg && (
          <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
            {t(i18nKeyPrefix + field.errorMsg)}
          </Text>
        )}
      </div>
    );
  };

  const renderInvoiceCheck = (field: any, section: any) => {
    const valid =
      form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];
    return (
      <div
        data-i18n-key-title={section.sectionTitle}
        data-i18n-key-name={field.name}
        data-i18n-key-error={field.errorMsg}
        className={styles.itemWrapper}
        key={field.name}
        data-id={`section${field.name}`}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        <CheckLabel label={invoice ? t(i18nKeyPrefix + field.label) : t(i18nKeyPrefix + field.editLabel)} />
        {invoice && (
          <div className={styles.invoiceLabel}>
            <Text size={SIZES.MEDIUM} bold={true} color={TEXTCOLOR.BLACK}>
              {t(i18nKeyPrefix + field.noEditCopy)}
            </Text>
          </div>
        )}
        {!invoice && (
          <div className={styles.invoicing}>
            <div className={styles.invoiceLabel}>
              <Text size={SIZES.SMALL} color={TEXTCOLOR.BLACK}>
                {t(i18nKeyPrefix + field.editCopy)}
              </Text>
            </div>
            <Input
              label={t(i18nKeyPrefix + field.emailLabel)}
              placeholder={t(i18nKeyPrefix + field.placeholder)}
              onChange={(val: any) => validateField(val, field)}
              required={field.required}
              value={form.additionalData.invoiceEmail}
              errorMessage={t(i18nKeyPrefix + field.errorMsg)}
              valid={valid}
            />
          </div>
        )}
      </div>
    );
  };

  const renderTimeWindow = (field: any, section: any) => {
    let valid = form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];

    if (form.dynamicFormValidFields[field.hours.from.name] && form.dynamicFormValidFields[field.hours.to.name]) {
      if (field.day) {
        if (form.dynamicFormValidFields[field.day.name]) {
          valid = true;
          form.dynamicFormValidFields[field.name] = true;
        }
      } else {
        valid = true;
        form.dynamicFormValidFields[field.name] = true;
      }
    }

    const checkTime = (e: any) => {
      e.persist();
      const formatValue = (e.target as HTMLInputElement).value;
      const select = document.getElementById(t(i18nKeyPrefix + field.hours.to.label)) as HTMLSelectElement;
      const hourMin = formatValue.split(':');
      const fromTimeWithRage = new Date(1970, 0, 1, parseInt(hourMin[0]) + field.hours.minRange, parseInt(hourMin[1]));
      if (formatValue !== form.additionalData[field.hours.from.name]) {
        for (let i = 0; i < select.options.length; i++) {
          const time = select.options[i].value;
          const timeHourMin = time.split(':');
          const option = select.options[i];
          option.disabled =
            new Date(1970, 0, 1, parseInt(timeHourMin[0]), parseInt(timeHourMin[1])) < fromTimeWithRage ? true : false;
        }

        if (select.value) {
          const selectedHourMin = select.value.split(':');
          const isRangeFine =
            new Date(1970, 0, 1, parseInt(selectedHourMin[0]), parseInt(selectedHourMin[1])) < fromTimeWithRage
              ? false
              : true;
          if (!isRangeFine) {
            validateField(undefined, field, field.hours.to);
          }
        }
      }
    };

    const translateOptions = (options) => {
      const translatedOptions = [];
      options.map((option: any, index: number) => {
        translatedOptions.push(t(i18nKeyPrefix + option));
      });

      return translatedOptions;
    };

    return (
      <div
        className={styles.itemWrapper}
        data-id={`section${field.name}`}
        key={field.name}
        data-i18n-key-title={section.sectionTitle}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        {field.infoMsg && <div className={styles.infoMessage}>{t(i18nKeyPrefix + field.infoMsg)}</div>}
        <div className={styles.delivery}>
          {field.day && (
            <div className={styles.deliveryDay}>
              <Select
                label={t(i18nKeyPrefix + field.day.label)}
                value={form.additionalData[field.day.name]}
                options={translateOptions(field.day.options)}
                onChange={(event) => {
                  validateField(event, field, field.day);
                }}
                required={field.required}
              />
            </div>
          )}
          <div className={styles.deliveryInputs}>
            <div className={styles.deliveryInput} data-i18n-key={field.hours.from.label}>
              <Select
                label={t(i18nKeyPrefix + field.hours.from.label)}
                value={form.additionalData[field.hours.from.name]}
                options={field.hours.openingTimesDefault}
                onChange={(event) => {
                  validateField(event, field, field.hours.from);
                  checkTime(event);
                }}
                required={field.required}
              />
            </div>
            <div className={styles.deliveryInput} data-i18n-key={field.hours.to.label}>
              <Select
                label={t(i18nKeyPrefix + field.hours.to.label)}
                id={t(i18nKeyPrefix + field.hours.to.label)}
                value={form.additionalData[field.hours.to.name]}
                options={field.hours.closingTimesDefault}
                onChange={(event) => {
                  validateField(event, field, field.hours.to);
                }}
                required={field.required}
                disabled={!form.additionalData[field.hours.from.name]}
              />
            </div>
          </div>
          <Text size={SIZES.TINY} color={TEXTCOLOR.BLACK}>
            <span data-i18n-key={field.minRangeCopy}>*{t(i18nKeyPrefix + field.minRangeCopy)}</span>
          </Text>
        </div>
        {!valid && field.errorMsg && (
          <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
            <span data-i18n-key={field.errorMsg}>{t(i18nKeyPrefix + field.errorMsg)}</span>
          </Text>
        )}
      </div>
    );
  };

  const renderDaysAndHours = (field: any, section: any) => {
    const valid =
      form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];

    return (
      <div
        className={styles.itemWrapper}
        data-id={`section${field.name}`}
        key={field.name}
        data-i18n-key-title={section.sectionTitle}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        {field.infoMsg && <div className={styles.infoMessage}>{t(i18nKeyPrefix + field.infoMsg)}</div>}
        <DaysAndHours
          defaultHourFrom={field.defaultHourFrom}
          defaultHourTo={field.defaultHourTo}
          field={field}
          days={field.dayLabels}
          hours={field.hours.default}
          hoursTo={field.hours.to}
          action={(data: any) => validateField(data, field)}
        ></DaysAndHours>
        {field.required && !valid && field.errorMsg && (
          <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
            <span data-i18n-key={field.errorMsg}>{t(i18nKeyPrefix + field.errorMsg)}</span>
          </Text>
        )}
      </div>
    );
  };

  const renderOpeningHours = (field: any, section: any) => {
    const valid =
      form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];

    return (
      <div
        className={styles.itemWrapper}
        data-id={`section${field.name}`}
        key={field.name}
        data-i18n-key-title={section.sectionTitle}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        {field.infoMsg && <div className={styles.infoMessage}>{t(i18nKeyPrefix + field.infoMsg)}</div>}
        <OpeningHours
          field={field}
          days={field.dayLabels}
          hours={field.hours.default}
          hoursTo={field.hours.to}
          action={(data: any) => validateField(data, field)}
        ></OpeningHours>
        {field.required && !valid && field.errorMsg && (
          <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
            <span data-i18n-key={field.errorMsg}>{t(i18nKeyPrefix + field.errorMsg)}</span>
          </Text>
        )}
      </div>
    );
  };

  const renderEstablishmentOwnership = (field: any, section: any) => {
    const valid =
      form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];
    const validName =
      form.dynamicFormValidFields[field.subFields[1].name] === undefined
        ? true
        : form.dynamicFormValidFields[field.subFields[1].name];
    if (form.additionalData[field.name] === false) {
      form.additionalData[field.subFields[1].name] = null;
      form.additionalData[field.subFields[0].name] = null;
    }
    return (
      <div
        className={cn(styles.itemWrapper, { [styles[field.class]]: field.class })}
        data-id={`section${field.name}`}
        key={field.name}
        data-i18n-key-title={section.sectionTitle}
        data-i18n-key-radio-label={field.label}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        <RadioQuestion
          label={t(i18nKeyPrefix + field.label)}
          action={(item: any) => validateField(null, field, item)}
          items={field.items}
          field={field}
          dynamicClass={field.class}
          switchItems={field.switchItems}
        ></RadioQuestion>
        {form.additionalData[field.name] && field.subFields && (
          <div className={styles.ownershipInputs}>
            <div className={styles.datepicker} data-i18n-key-label={field.subFields[0].label}>
              <DatePicker
                onChange={(value) => validateField(value.toString(), field.subFields[0])}
                label={t(i18nKeyPrefix + field.subFields[0].label)}
                value={form.additionalData[field.subFields[0].name]}
              />
            </div>
            <div className={styles.datepicker} data-i18n-key-label={field.subFields[1].label}>
              <Input
                label={t(i18nKeyPrefix + field.subFields[1].label)}
                placeholder={t(i18nKeyPrefix + field.subFields[1].placeholder)}
                onChange={(e: any) => validateField(e, field.subFields[1])}
                value={form.additionalData[field.subFields[1].name]}
                required={field.required}
                errorMessage={t(i18nKeyPrefix + field.subFields[1].errorMsg)}
                valid={validName}
              />
            </div>
          </div>
        )}
        {field.required && !valid && field.errorMsg && (
          <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
            <span data-i18n-key={field.errorMsg}>{t(i18nKeyPrefix + field.errorMsg)}</span>
          </Text>
        )}
      </div>
    );
  };

  const renderFileUpload = (field: any, section: any) => {
    const handleUpload = (file: any) => {
      if (file) {
        const formData = new FormData();
        formData.append('attachment', file);

        uploadRequest({
          type: 'POST',
          endpoint: `${apiEndpoints.signupData}/${saveId}/attachment/`,
          mode: 'file',
          body: formData,
        });
      }
    };

    return (
      <div key={field.name} data-id={`section${field.name}`}>
        {(!field.hideRelated || (field.hideRelated && field.related && form.additionalData[field.related])) && (
          <div className={styles.itemWrapper} data-i18n-key-title={section.sectionTitle}>
            {section.sectionTitle && (
              <ScreenTitle
                title={t(i18nKeyPrefix + section.sectionTitle)}
                disabled={!form.additionalData[field.related] && !files.length}
              ></ScreenTitle>
            )}
            {field.label && (
              <label className={cn(styles.label)}>
                <Text size={SIZES.SMALL} color={TEXTCOLOR.STEEL}>
                  {t(i18nKeyPrefix + field.label)}
                  {field.required && ' *'}
                </Text>
              </label>
            )}
            <div className={styles.fileUpload}>
              <FileInput getFiles={getFilesRequest} action={(file: any) => handleUpload(file)} field={field} />
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderDunning = (field: any, section: any) => {
    let valid = form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];
    const validEmail =
      form.dynamicFormValidFields[field.subFields[0]]?.name === undefined
        ? true
        : form.dynamicFormValidFields[field.subFields[0]].name;
    if (form.additionalData[field.name] === false) {
      form.additionalData[field.subFields[0].name] = null;
    }
    if (validEmail && form.dynamicFormValidFields[field.name]) {
      valid = true;
    }
    return (
      <div
        className={styles.itemWrapper}
        data-id={`section${field.name}`}
        key={field.name}
        data-i18n-key-title={section.sectionTitle}
        data-i18n-key-label={field.subFields[0].label}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        <RadioQuestion
          label={t(i18nKeyPrefix + field.label)}
          action={(item: any) => validateField(null, field, item)}
          items={field.items}
          field={field}
          dynamicClass={field.class}
        ></RadioQuestion>
        {form.additionalData[field.name] && (
          <Input
            label={t(i18nKeyPrefix + field.subFields[0].label)}
            placeholder={t(i18nKeyPrefix + (field.subFields[0].placeholder || field.subFields[0].label))}
            onChange={(e: any) => validateField(e, field.subFields[0])}
            required={field.required}
            value={form.additionalData[field.subFields[0].name]}
            errorMessage={t(i18nKeyPrefix + field.subFields[0].errorMessage)}
            valid={validEmail}
          />
        )}
        {field.required && !valid && field.errorMsg && (
          <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
            <span data-i18n-key={field.errorMsg}>{t(i18nKeyPrefix + field.errorMsg)}</span>
          </Text>
        )}
      </div>
    );
  };

  const renderEInvoice = (field: any, section: any) => {
    const valid =
      form.dynamicFormValidFields[field.name] === undefined ? true : form.dynamicFormValidFields[field.name];

    const changeSelection = (e: any, selectedItem: any) => {
      field.items.map((item: any) => {
        if (!(item.name === selectedItem.name || item.apiFill) && item.subItems) {
          item.subItems.map((subItem: any) => {
            form.additionalData[subItem.name] = null;
          });
        }
      });

      validateField(e, field, t(i18nKeyPrefix + selectedItem.name));
    };

    return (
      <div
        className={cn(styles.itemWrapper, { [styles[field.class]]: field.class })}
        data-id={`section${field.name}`}
        key={field.name}
        data-i18n-key-title={section.sectionTitle}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        {field.items.map((item: any) => (
          <div key={item.name}>
            {(!item.apiFill || (item.apiFill && form.additionalData[item.subItems[0].name])) && (
              <div>
                <div className={styles.eInvoiceSelection}>
                  <Radio
                    key={item.name}
                    action={(e: any) => {
                      changeSelection(e, item);
                    }}
                    label={t(i18nKeyPrefix + item.name)}
                    checked={
                      form.additionalData[field.name] === undefined
                        ? field.defaultName === t(i18nKeyPrefix + item.name)
                        : form.additionalData[field.name] === t(i18nKeyPrefix + item.name)
                    }
                  />
                </div>
                {form.additionalData[field.name] === t(i18nKeyPrefix + item.name) && item.subItems && (
                  <div className={styles.eInvoiceInputContainer}>
                    {form.additionalData[field.name] === t(i18nKeyPrefix + item.name) &&
                      item.subItems &&
                      item.subItems.map((subItem: any) => (
                        <div key={subItem.name} className={cn(styles.eInvoiceInputs, styles[subItem.class])}>
                          <Input
                            label={t(i18nKeyPrefix + subItem.label)}
                            placeholder={t(i18nKeyPrefix + subItem.placeholder)}
                            onChange={(e: any) => validateField(e, subItem)}
                            value={form.additionalData[subItem.name]}
                            required={subItem.required}
                            errorMessage={t(i18nKeyPrefix + subItem.errorMsg)}
                            valid={
                              form.dynamicFormValidFields[subItem.name] === undefined
                                ? true
                                : form.dynamicFormValidFields[subItem.name]
                            }
                            disabled={subItem.readOnly}
                            info={subItem.infoMsg ? t(i18nKeyPrefix + subItem.infoMsg) : null}
                          />
                        </div>
                      ))}
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
        {field.required && !valid && field.errorMsg && (
          <Text size={SIZES.TINY} color={TEXTCOLOR.RED} className={cn('formError', styles['error-message'])}>
            <span data-i18n-key={field.errorMsg}>{t(i18nKeyPrefix + field.errorMsg)}</span>
          </Text>
        )}
      </div>
    );
  };

  const renderBrands = (field: any, section: any) => {
    const selectBrand = (id: any, value: boolean, isDisabled: boolean) => {
      const tile = brandTiles.find((x: any) => x.name === id);
      const newArray = [...selectedBrands];
      if (field.maxSelectedBrands === 1 && selectedBrands.length > 0 && !tile.isSelected) {
        const selectedTile = brandTiles.find((x: any) => x.isSelected);
        selectedTile.isSelected = false;

        const index = newArray.indexOf(selectedTile);
        newArray.splice(index, 1);

        tile.isSelected = true;
        newArray.push(tile);
      } else if (tile && tile.isSelected && newArray) {
        tile.isSelected = !value;
        const index = newArray.indexOf(tile);
        newArray.splice(index, 1);
      } else if (!isDisabled) {
        tile.isSelected = value;
        newArray.push(tile);
      }
      dispatch(setSelectedBrands(newArray));
      dispatch(setBrands(brandTiles));
    };

    return (
      <div
        className={styles.itemWrapper}
        data-id={`section${field.name}`}
        key={field.name}
        data-i18n-key-title={section.sectionTitle}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        <div id="tileContainer" className={styles.brandsContainer}>
          {brandTiles &&
            brandTiles?.length > 0 &&
            brandTiles?.map((tile: any, i: any) => (
              <div
                key={tile.name}
                data-id={`chip${tile.name}`}
                onClick={() =>
                  selectBrand(
                    tile.name,
                    true,
                    brandTiles.filter((x: any) => x.isSelected).length >= field.maxSelectedBrands,
                  )
                }
                className={cn(styles.cardImage, {
                  [styles.selected]: tile.isSelected,
                  [styles.disabled]:
                    brandTiles.filter((x: any) => x.isSelected).length >= marketConfig?.maxSelectedBrands,
                })}
                style={{ backgroundImage: `url(${tile.image.url}` }}
              ></div>
            ))}
        </div>
      </div>
    );
  };

  const renderProducts = (field: any, section: any) => {
    const selectProduct = (id: any, value: boolean) => {
      const tile = productTiles.find((x: any) => x.name === id);
      const newArray = [...selectedProducts];
      if (field.maxSelectedProducts === 1 && selectedProducts.length > 0 && !tile.isSelected) {
        const selectedTile = productTiles.find((x: any) => x.isSelected);
        selectedTile.isSelected = false;

        const index = newArray.indexOf(selectedTile);
        newArray.splice(index, 1);

        tile.isSelected = true;
        newArray.push(tile);
      } else if (tile && tile.isSelected && newArray) {
        tile.isSelected = !value;
        const index = newArray.indexOf(tile);
        newArray.splice(index, 1);
      } else {
        tile.isSelected = value;
        newArray.push(tile);
      }
      dispatch(setSelectedProducts(newArray));
      dispatch(setProducts(productTiles));
    };

    return (
      <div
        className={styles.itemWrapper}
        data-id={`section${field.name}`}
        key={field.name}
        data-i18n-key-title={section.sectionTitle}
      >
        {section.sectionTitle && <ScreenTitle title={t(i18nKeyPrefix + section.sectionTitle)}></ScreenTitle>}
        <div id="tileContainer" className={styles.tilesContainer}>
          {productTiles &&
            productTiles?.length > 0 &&
            productTiles?.map((tile: any, i: any) => (
              <Chip
                label={tile.name}
                selected={tile.isSelected}
                key={tile.name}
                action={() => selectProduct(tile.name, true)}
                multiSelection={true}
                data-id={`chip${tile.name}`}
                disabled={productTiles.filter((x: any) => x.isSelected).length >= field.maxSelectedProducts}
              />
            ))}
        </div>
      </div>
    );
  };

  return <>{formHtml}</>;
};

export default DynamicFormPage;
