import { fromJS, List } from 'immutable';
import { Reducer } from 'redux';
import { actions } from '../utils/api/constants';

export const initialState: unknown = fromJS({});

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.API_REQUEST_INIT:
      state = state.set(
        action.name,
          fromJS({
          isLoading: true
        })
      );

      return state;
    case actions.GET_RESPONSE:
      state = state.setIn([action.name, "payload"], fromJS(action.payload));

      return state;
    case actions.API_REQUEST_ENDS:
      state = state.setIn([action.name, "isLoading"], fromJS(action.isLoading));

      return state;
    case actions.HTTP_ERROR:
      state = state.setIn([action.name, "error"], fromJS(action.error));

      state = state.setIn([action.name, "isLoading"], fromJS(action.isLoading));

      return state;
    default: {
      return state;
    }
  }
};

export { reducer as apiReducer };
