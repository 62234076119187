import React, { ChangeEvent, useState, createRef, RefObject, SyntheticEvent, useEffect } from 'react';
import styles from './ibanInput.module.scss';
import Text, { SIZES, TEXTCOLOR } from '../Text';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { getCurrentWidth } from '../../utils/custom-hooks/handleWindowResize';
import { app } from '../../utils/constants';
import IBAN, { format} from '@tammo/react-iban';

interface Props {
  onChange: (val: string) => void;
  label?: string;
  required?: boolean;
  valid?: boolean;
  style?: string;
  defaultValue?: string;
  info?: string;
}

const IbanInput: React.FC<Props> = ({
  onChange,
  label,
  required,
  valid,
  style,
  defaultValue,
  info
}: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultValue || '');
  const size = getCurrentWidth();
  const [windowSize, setWindowSize] = useState(size);

  useEffect(() => {
    setWindowSize(size);
  });

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const isMobile = windowSize <= app.breakPoint;

  const inputRef: RefObject<HTMLInputElement> = createRef();

  return (
      <React.Fragment>
        {label &&
          <label
            className={cn(styles.label, {[styles.error]: required && !valid})}
          >
            <Text size={SIZES.SMALL} color={TEXTCOLOR.STEEL}>{label}{required && ' *'}</Text>
          </label>
        }
        <div className={styles.formWrap}>
          <IBAN
            initialValue={defaultValue}
            onChange={onChange}
            style={{
              width: '100%',
              fontSize: '16px',
              padding: '15px 20px',
              lineHeight: '21px',
              }}
            className={cn(styles.input, {[styles.error]: !valid})}
          />
        </div>
        {info && 
          <Text size={SIZES.TINY} color={TEXTCOLOR.BLACK} className={styles['error-message']}>
            <span data-i18n-key={info}>{info}</span>
          </Text>
        }
    </React.Fragment>
  );
}

export default IbanInput;
