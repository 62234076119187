import React, { useEffect, useState } from 'react';
import styles from './faq.module.scss';
import { ReactComponent as ArrowDown } from '../../../../assets/images/chevron-down.svg';
import { ReactComponent as ArrowUp } from '../../../../assets/images/chevron-up.svg';

interface FaqViewModel {
  viewModel: {
    headline: string;
    questions: Faq[];
  };
}

interface Faq {
  question: string;
  answer: string;
  open: boolean;
}

const Faq: React.FC<FaqViewModel> = ({
  viewModel
}: FaqViewModel) => {
  const el = React.createRef<HTMLDivElement>();
  const [ faqs, setFaqs ] = useState(viewModel?.questions);

  useEffect(() => {
    if(faqs.length === 0) {
      let faqsArray = viewModel?.questions;
      for (let i = 0; i < faqsArray.length; i++) {
        const faq = faqsArray[i];
        faq['open'] = false;
      }
      setFaqs([...faqsArray])
    }
  }, [faqs]);

  const getQuestions = () => {
    let questions:any = [];
    for (let i = 0; i < faqs.length; i++) {
      const faq:Faq = faqs[i];
      questions.push(
        <li key={i}>
          <div className={styles.question}>
            <a
              className={faqs[i].open ? styles.active : ''} 
              onClick={(e) => {
                faqs[i].open = faqs[i].open ? false : true;
                setFaqs([...faqs])
              }}>
                {faq?.question}
                <span className={styles.arrowDown}><ArrowDown/></span>
                <span className={styles.arrowUp}><ArrowUp/></span>
            </a>
            
          </div>
          {faq?.open === true && faq?.answer && 
          <div className={styles.answer} dangerouslySetInnerHTML={{__html: faq?.answer }} />}
        </li>
      )
    }

    return questions;
  }

  return (
    <div className={styles.faq} ref={ el }>
      <h1>{viewModel?.headline}</h1>
      <ul>{faqs && getQuestions()}</ul>
    </div>
  )
};

export default Faq;
