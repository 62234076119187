import React, { ReactNode } from 'react';
import { ReactComponent as Check } from '../../assets/images/check.svg';
import styles from './checkLabel.module.scss';
import cn from 'classnames';

interface Props {
  label: string;
}

const CheckLabel: React.FC<Props> = ({
  label,
}: Props) => {
  return (
    <div className={cn(styles.checkLabel)}>
        <div className={styles.icon}><Check /></div>
        {label}
    </div>
  );
}

export default CheckLabel;