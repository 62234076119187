import React from 'react';
import Grid from '../Grid';

import styles from './cookies.module.scss';
import BackButton from '../BackButton';
import Footer from '../Footer';

interface Props {
  plain: boolean;
  cookiesContent: any;
}

const Cookies: React.FC<Props> = ({
  plain,
  cookiesContent,
}: Props) => {
  return (
    <div className={styles.page}>
      { !plain && <BackButton action={null} /> }
      <Grid rows={cookiesContent.gridContent.rows} />
      { !plain && <Footer /> }
    </div>
  )
}

export default Cookies;