import React, { ReactNode } from 'react';
import { ReactComponent as Check } from '../../assets/images/check.svg';
import { ReactComponent as Plus } from '../../assets/images/plus-line.svg';
import styles from './chip.module.scss';
import cn from 'classnames';

interface Props {
  selected: boolean;
  label: string;
  action?: () => void;
  disabled?: boolean;
  multiSelection?: boolean;
}

const Chip: React.FC<Props> = ({
  selected,
  label,
  action,
  disabled,
  multiSelection
}: Props) => {
  return (
    <div className={cn(styles.categoryCard, {[styles.selected]: selected, [styles.disabled]: disabled && multiSelection && !selected})} onClick={action}>
      {!selected &&
        <div className={styles.plus}>
          <Plus/>
        </div>
      }
      {selected &&
        <div className={styles.check}>
          <Check/>
        </div>
      }
      {label}
    </div>
  );
}

export default Chip;