
import { fromJS } from 'immutable';
import { Reducer } from 'redux';
import { actions } from '../constants/languages';

export const initialState: unknown = fromJS({});

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LANGUAGE:
      state = state.set('language', fromJS(action.language));

      return state;
    default: {
      return state;
    }
  }
};

export { reducer as languageReducer };
