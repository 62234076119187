import React from 'react';
import Sidebar from './index';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../../reducers';
import { setStep, setEdit } from '../../../actions/form';
import { useHistory, useParams } from 'react-router-dom';

interface Props {
  props?: any;
  step?: number;
}

interface ParamTypes {
  lang: any;
}

const SidebarWrapper: React.FC<Props> = ({
  props,
  step,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const companyName = useSelector((state: State) => state.form.companyName);
  const managerName = useSelector((state: State) => state.form.managerName);
  const jobTitle = useSelector((state: State) => state.form.jobTitle);
  const firstName = useSelector((state: State) => state.form.firstName);
  const lastName = useSelector((state: State) => state.form.lastName);
  const phone = useSelector((state: State) => state.form.phone);
  const email = useSelector((state: State) => state.form.email);
  const VATnumber = useSelector((state: State) => state.form.VATnumber);

  const params = useParams<ParamTypes>();

  const changeStep = (step: number) => {
    if (step === 1) {
      dispatch(setEdit(true));
    }
    history.push(`/${params.lang}/signup/${step}`);
    dispatch(setStep(step));
  }

  return (
    <React.Fragment>
      <Sidebar
        phone={phone}
        email={email}
        companyName={companyName}
        managerName={managerName}
        jobTitle={jobTitle}
        firstName={firstName}
        lastName={lastName}
        vatNumber={VATnumber}
        step={step}
        onEditInfo={(val: number) => changeStep(val)}
      />
    </React.Fragment>
  );
}

export default SidebarWrapper;