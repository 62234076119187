import React from 'react';
import styles from './infoIcon.module.scss';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ReactComponent as InformationIcon } from '../../assets/images/information.svg';

interface Props {
  action: () => void;
  color: string;
}

const InfoIcon: React.FC<Props> = ({
  action,
  color
}: Props) => {
  const { t } = useTranslation();

  return (
      <div onClick={action} className={cn(styles.icon, [styles[color]])}>
        <InformationIcon />
      </div>
  );
}

export default InfoIcon;
