export enum apiEndpoints {
    signup = '/api/signup',
    postalCode = '/api/signup/postalCode',
    signupData = '/api/signup/signupData',
    organization = '/api/signup/organizationDetails',
    verificationCode = '/api/signup/checkVerificationCode',
    sendVerificationCode = '/api/signup/sendPhoneVerificationCode',
    sendContactInfo = '/api/signup/sendContactEmail',
    locales = '/api/system/locales',
    user = '/api/user'
}
