import React from 'react';
import { HeadlessImage } from '../../../../utils/interfaces/headless';
import HeadlessImageComponent from '../../../Headless/HeadlessImage';

interface Props {
  viewModel: {
    image: HeadlessImage;
  };
}

const Image: React.FC<Props> = ({
  viewModel
}: Props) => {

  if (!viewModel || !viewModel.image) {
    return null;
  }

  return (
    <HeadlessImageComponent image={viewModel.image} />
  )
};

export default Image;
