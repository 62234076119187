import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './signUpForm.module.scss';
import { getCurrentWidth } from '../../utils/custom-hooks/handleWindowResize';
import { app, i18nKeyPrefix } from '../../utils/constants';
import FormWrapper from '../../containers/FormWrapper';
import cn from 'classnames';
import WelcomeScreenComponent from './WelcomeScreen';
import ErrorScreenComponent from './ErrorScreen';
import LoadingComponent from '../Loading';
import { useSelector } from 'react-redux';
import { State } from '../../reducers';
import backupBackgroundImage from '../../assets/images/Bitmap.png';
import immutableToJS from 'with-immutable-props-to-js';
import { getGlobalContent } from "../../utils/selectors/headless/selectors";
import { connect } from "react-redux";

interface ParamTypes {
  lang: any;
}

interface Props {
  global?: any;
}

const SignUpForm: React.FC<Props> = ({ global
}) => {
  const marketData = useSelector((state: State) => state.router.marketData);
  const marketConfig = useSelector((state: State) => state.router.marketConfig);
  const fallbackLanguage: string = marketData?.defaultLanguage;
  const location = useLocation();
  const params = useParams<ParamTypes>();
  const path = location.pathname.replace(`/${params.lang}`, "");
  const loc = location.pathname;
  const history = useHistory();
  let lang = localStorage.getItem('i18nextLng');
  const [umbracoBackgroundImage, setUmbracoBackgroundImage] = useState(backupBackgroundImage);

  if (!lang && fallbackLanguage) {
    localStorage.setItem('i18nextLng', fallbackLanguage.toLowerCase());
    lang = localStorage.getItem('i18nextLng');
  }
  
  useLayoutEffect(() => {
    if (loc === `/signup` || loc === `/signup/1`) {
      history.push(`/${lang}/signup/1`);
    } else if (loc === `/${params.lang}/signup`) {
      history.push(`/${params.lang}/signup/1`);
    }
  }, [history, lang, loc, params.lang]);

  useLayoutEffect(() => {
    if (global) {
      if(global?.loginPageBackgroundImage?.url) {
        setUmbracoBackgroundImage(global?.loginPageBackgroundImage?.url)
      }
    }
  }, [global]);

  const size = getCurrentWidth();
  const { ready, t } = useTranslation();
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_Footer';
  const [windowSize, setWindowSize] = useState(size);

  useEffect(() => {
    setWindowSize(size);
  }, [size]);
  
  const isMobile = windowSize <= app.breakPoint;

  return (
    <React.Fragment>
      {!ready && <LoadingComponent fullScreen />}
      {ready &&
        <div className={cn(styles.wrapper, {[styles.mobile]: isMobile})}>
          <div className={styles.backgroundImage} style={{backgroundImage: `url(${umbracoBackgroundImage})`}}>
            <div className={styles.backgroundCover}></div>
            {(path !== '/welcome' && path !== '/error') &&
              <FormWrapper />
            }
            {path === '/welcome' &&
              <WelcomeScreenComponent />
            }
            {path === '/error' &&
              <ErrorScreenComponent />
            }
          </div>
          <div className={styles.footerContainer}>
            <div className={styles.linksContainer}>
              <a onClick={() => {window.com_carlsberg_compliance_pack.manageCookies();}}>{t(`${prefix}_manageCookies`)}</a>
              {global && global.footer && global.footer.links && global.footer.links.map((link: any, index: number) => (
                <a key={`footerLink_${index}`} rel="noopener noreferrer" target="_blank" href={link.url}>{link.name}</a>
              ))}
            </div>
            <div className={styles.copyright}>{t(`${prefix}_copyright`)}</div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

const mapStateToProps = (state: State) => {
  return {
    global: getGlobalContent(state)
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(SignUpForm));