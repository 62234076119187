import React, { Dispatch } from "react";
import { connect } from "react-redux";
import { State } from '../reducers';
import immutableToJS from 'with-immutable-props-to-js';
import FooterComponent from '../components/Footer';

const FooterContainer: React.FC = () => {

  return (
    <FooterComponent />
  );
};

const mapStateToProps = (state: State) => {
  return {  }
};

const mapDispatchToProps = () => {
  return {  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(FooterContainer));
