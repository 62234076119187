import React, { useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import handleHeadlessLink from '../../../../utils/custom-hooks/handleHeadlessLink';

import styles from './rich-text.module.scss';

interface RichTextViewModel {
  viewModel: any;
}

interface ParamTypes {
  lang: any;
}

const RichText: React.FC<RichTextViewModel> = ({
  viewModel
}: RichTextViewModel) => {

  const history = useHistory();

  const el = React.createRef<HTMLDivElement>();
  let _links: HTMLCollection;
  const params = useParams<ParamTypes>();

  const navigate = (event: any) => {
    let { target } = event;
    let i = 0;
    // Go throught 5 parents max to find a tag
    while (
      i < 5 &&
      !(target instanceof HTMLAnchorElement) &&
      target.parentNode
    ) {
      target = target.parentNode;
      i++;
    }
    // If target is still not a link, ignore
    if (!(target instanceof HTMLAnchorElement)) return;
    const href = target.getAttribute('href');
    // Get link target, if local link, navigate with router link
    if (href && (href[0] === '/' || href[0] === `/${params.lang}/`)) {
      event.preventDefault();
      history.push(href);
    }
  }

  const addListeners =  () => {
    _links = el.current.getElementsByTagName('a');
    for (let i = 0; i < _links.length; i++) {
      const link = _links[i];
      const href = link.getAttribute('href');
      if (href && (href[0] === '/' || href[0] === `/${params.lang}/`)) {
        link.setAttribute('href', handleHeadlessLink(href));
      }
      _links[i].addEventListener('click', navigate, false);
    }
  }

  useEffect(() => {
    addListeners();
  });

  return (
    <div className={styles.rte} dangerouslySetInnerHTML={{__html: viewModel.content.text}} ref={ el } />
  )
};

export default RichText;
