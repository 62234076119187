import React, { ReactNode } from 'react';
import { ReactComponent as NoCheck } from '../../assets/images/nocheck.svg';
import styles from './listTable.module.scss';
import { formatBytes } from '../../utils/getReadableSize';

interface Props {
  items: any;
  deleteAction: (index: any, item: any) => void;
}

const ListTable: React.FC<Props> = ({
    items,
    deleteAction
}: Props) => {

  return (
    <div className={styles.list}>
      {items.map((item: any, index:any) =>
        <div className={styles.listItem} key={index}>
            <div className={styles.fileName}>
                {item.filename}
            </div>
            <div className={styles.fileSize}>
                {formatBytes(item.fileSize)}
            </div>
            <div className={styles.deleteIcon}>
              <NoCheck onClick={() => deleteAction(index, item)} />
            </div>
        </div>
      )}
    </div>
  );
}

export default ListTable;
