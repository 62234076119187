import React from 'react';

import styles from './quote.module.scss';

interface QuoteViewModel {
  viewModel: {
    content: {
      description: string;
      headline: string;
      name: string;
      preHeadline: string;
      quote: string;
    }
  };
}

const Quote: React.FC<QuoteViewModel> = ({
  viewModel
}: QuoteViewModel) => {

  const el = React.createRef<HTMLDivElement>();

  return (
    <div className={styles.quoteWrapper} ref={ el }>
      <div className={styles.quote}>
      {viewModel?.content?.preHeadline &&
        <small className={styles.preHeadline} >
          {viewModel?.content?.preHeadline}
        </small>
      }

      {viewModel?.content?.headline &&
        <h1 className={styles.headline}>
          {viewModel?.content?.headline}
        </h1>
      }

      {viewModel?.content?.quote &&
        <blockquote className={styles.quote}>
          {viewModel?.content?.quote}
        </blockquote>
      }

      {viewModel?.content?.name &&
        <cite className={styles.name}>
          {viewModel?.content?.name}
        </cite>
      }

      {viewModel?.content?.description &&
        <cite className={styles.description}>
          {viewModel?.content?.description}
        </cite>
      }
      </div>
    </div>
  )
};

export default Quote;
