import { State } from '../../../reducers';

const getModal = (state: State) => {
  return state.modal;
}

export const getModalToggle = (state: State) => {
  const modal = getModal(state);

  if (!modal) {
    return;
  }

  return modal.get('isOpen');
};
