import { State } from '../../../reducers';

export const getLanguageObj = (state: State) => {
  return state.language;
}

export const getLanguage = (state: State) => {
  const languageObj = getLanguageObj(state);

  if (!languageObj) {

    return;
  }

  return languageObj.get('language');
}
/*
** API locales (LOGIN)
*/
const getApi = (state: State) => {

  return state.api;
}

const getLocalesObj = (state: State) => {
  if (!getApi(state).get('LOCALES')) {

    return;
  }

  return getApi(state).get('LOCALES');
}

export const isLocalesLoading = (state: State) => {
  const locales = getLocalesObj(state);

  if (!locales) {

    return true;
  }

  return locales.get('isLoading');
}

export const getLocales = (state: State) => {
  const locales = getLocalesObj(state);

  if (!locales || !locales.get('payload')) {

    return;
  }

  return locales.get('payload');
};