import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './sidebar.module.scss';
import { ReactComponent as Logo } from '../../../assets/images/logo-large.svg';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { State } from '../../../reducers';
import { i18nKeyPrefix } from '../../../utils/constants';

interface Props {
    step?: number;
    phone?: string;
    email?: string;
    companyName?: string;
    managerName?: string;
    jobTitle?: string;
    firstName?: string;
    lastName?: string;
    vatNumber?: string;
    onEditInfo?: (val: number) => void;
  }

const Sidebar: React.FC<Props> = ({
    phone,
    email,
    companyName,
    managerName,
    jobTitle,
    firstName,
    lastName,
    vatNumber,
    onEditInfo
}: Props) => {
  const marketConfig = useSelector((state: State) => state.router.marketConfig);
  const form = useSelector((state: State) => state.form);
  const { t } = useTranslation();
  const prefix = i18nKeyPrefix + 'LeanSignup_Form';
  const step = useSelector((state: State) => state.form.step);

  const selectedCategories = useSelector((state: State) => state.form.additionalData?.selectedCategories);
  const outletZipCode = useSelector((state: State) => state.form.outletZipCode);
  const outletName = useSelector((state: State) => state.form.outletName);

  return (
    <div className={cn(styles.wrapper, {[styles.fullHeight]: step === 7})}>
        { !marketConfig.hideSidebarLogo && <Logo className={styles.logo} /> }
        { (step === 1 && !marketConfig?.fullSizeTextRightPanel) &&
            <div className={styles.section}>
                <div className={styles.sectionHeader}>
                    <label data-i18n-key={`${prefix}_PersonalInformation_sidebarInfo`}>{t(`${prefix}_PersonalInformation_sidebarInfo`)}</label>
                </div>
                <div className={styles.lineBreak}></div>
            </div>
        }
        { (step === 1 && marketConfig?.fullSizeTextRightPanel && !(email || managerName || jobTitle || firstName || lastName || phone || companyName)) &&
            <div className={styles.dynamicContent}>
                {marketConfig?.rightPanelContent && marketConfig?.rightPanelContent.map((row: any, i: any) =>
                    <div key={`content-${i}`} data-i18n-key={`${prefix}_${row.text}`} className={styles[row.class]}>{t(`${prefix}_${row.text}`)}</div>
                )}
            </div>
        }
        {(email || managerName || jobTitle || firstName || lastName || phone || companyName) &&
            <div className={styles.section}>
                <div className={styles.sectionHeader}>
                    <label data-i18n-key={`${prefix}_PersonalInformation_title`}>{t(`${prefix}_PersonalInformation_title`)}</label>
                   {(marketConfig?.onePageReview && step !== 1 && step !== 2) && <a onClick={() => onEditInfo(1)} className={styles.link} data-i18n-key={`${prefix}_Navigation_edit`}>{t(`${prefix}_Navigation_edit`)}</a>}
                </div>
                
                {companyName &&<div className={styles.title}>{companyName}</div>}
                {jobTitle && <div className={styles.label}>{jobTitle}</div>}
                {managerName && <div className={styles.label}>{managerName}</div>}
                {(firstName || lastName) && <div className={styles.label}>{firstName} {lastName}</div>}
                <div className={styles.label}>{phone}</div>
                <div className={styles.label}>{email}</div>
                <div className={styles.lineBreak}></div>
            </div>
        }

        { vatNumber &&
            <div className={styles.section}>
                <div className={styles.sectionHeader}>
                    <div data-i18n-key={`${prefix}_VAT_label`}>{t(`${prefix}_VAT_label`)}</div>
                </div>
                <div className={styles.title}>{vatNumber}</div>
                <div className={styles.lineBreak}></div>
            </div>
        }
        
        { marketConfig.addressList && marketConfig.addressList.primary && marketConfig.addressList.primary.map((item: any, i: any) =>
            <div key={i + item.type}>
                { form[`address${item.type}`] &&
                    <div className={styles.section}>
                        <div className={styles.sectionHeader}>
                            <div data-i18n-key={`${prefix}_Address_title${item.type}`}>{t(`${prefix}_Address_title${item.type}`)}</div>
                            {marketConfig?.onePageReview && <a onClick={() => onEditInfo(3)} className={styles.link} data-i18n-key={`${prefix}_Navigation_edit`}>{t(`${prefix}_Navigation_edit`)}</a>}
                        </div>
                        <div className={styles.title}>{form[`name${item.type}`]}</div>
                        <div className={styles.label}>{form[`address${item.type}`]}</div>
                        <div className={styles.label}>{form[`zipCode${item.type}`]}</div>
                        <div className={styles.label}>{form[`cityName${item.type}`]}</div>
                        { form[`email${item.type}`] && <div className={styles.label}>{form[`email${item.type}`]}</div>}
                        { form[`phone${item.type}`] && <div className={styles.label}>{form[`phone${item.type}`]}</div>}
                        <div className={styles.lineBreak}></div>
                    </div>
                }
            </div>
        )}

        { marketConfig.addressList && marketConfig.addressList.secondary && marketConfig.addressList.secondary.map((item: any, i: any) =>
            <div key={i + item.type}>
                { form[`address${item.type}`] &&
                    <div className={styles.section}>
                        <div className={styles.sectionHeader}>
                            <div data-i18n-key={`${prefix}_Address_title${item.type}`}>{t(`${prefix}_Address_title${item.type}`)}</div>
                            {marketConfig?.onePageReview && <a onClick={() => onEditInfo(3)} className={styles.link} data-i18n-key={`${prefix}_Navigation_edit`}>{t(`${prefix}_Navigation_edit`)}</a>}
                        </div>
                        <div className={styles.title}>{form[`name${item.type}`]}</div>
                        <div className={styles.label}>{form[`address${item.type}`]}</div>
                        <div className={styles.label}>{form[`zipCode${item.type}`]}</div>
                        <div className={styles.label}>{form[`cityName${item.type}`]}</div>
                        { form[`email${item.type}`] && <div className={styles.label}>{form[`email${item.type}`]}</div>}
                        { form[`phone${item.type}`] && <div className={styles.label}>{form[`phone${item.type}`]}</div>}
                        <div className={styles.lineBreak}></div>
                    </div>
                }
            </div>
        )}
        { (marketConfig.showOutletCompanyType && (outletName || outletZipCode)) &&
            <div className={styles.section}>
                <div className={styles.sectionHeader}>
                    <div data-i18n-key={`${prefix}_CompanyType_sidebarOutletTitle`}>{t(`${prefix}_CompanyType_sidebarOutletTitle`)}</div>
                    {marketConfig?.onePageReview && <a onClick={() => onEditInfo(4)} className={styles.link} data-i18n-key={`${prefix}_Navigation_edit`}>{t(`${prefix}_Navigation_edit`)}</a>}
                </div>
                <div className={styles.title}>{outletName}</div>
                <div className={styles.label}>{outletZipCode}</div>
                <div className={styles.lineBreak}></div>
            </div>
        }
        { selectedCategories && selectedCategories.length > 0 &&
            <div className={styles.section}>
                <div className={styles.sectionHeader}>
                    <div data-i18n-key={`${prefix}_CompanyType_sidebarTitle`}>{t(`${prefix}_CompanyType_sidebarTitle`)}</div>
                    {marketConfig?.onePageReview && <a onClick={() => onEditInfo(4)} className={styles.link} data-i18n-key={`${prefix}_Navigation_edit`}>{t(`${prefix}_Navigation_edit`)}</a>}
                </div>
                {selectedCategories && selectedCategories.map((item: any, i: any) =>
                    <React.Fragment key={i}>
                        <div className={styles.title}>{item.name}</div>
                    </React.Fragment>
                )}
                <div className={styles.lineBreak}></div>
            </div>
        }
    </div>
  );
}

export default Sidebar;