import React, { ReactNode } from 'react';

import styles from './card.module.scss';

interface Props {
  children: ReactNode;
}

const Card: React.FC<Props> = ({
  children
}: Props) => {

  return (
    <div className={styles.card}>
      {children}
    </div>
  );
}

export default Card;
