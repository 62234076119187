import React, { Dispatch, useEffect, useState } from 'react';
import FormFooterComponent from '../../FormFooter';
import FormHeaderComponent from '../../FormHeader';
import SidebarWrapper from '../../Sidebar/SidebarWrapper';
import cn from 'classnames';
import immutableToJS from 'with-immutable-props-to-js';
import styles from './dynamicFormWrapper.module.scss';
import { connect, useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../reducers';
import { useHistory, useParams } from 'react-router-dom';
import { getCurrentWidth } from '../../../../utils/custom-hooks/handleWindowResize';
import { app, i18nKeyPrefix } from '../../../../utils/constants';
import { validateAdditionalInfo, validateField, scrollToError } from '../../../../utils/validation';
import { useTranslation } from 'react-i18next';
import { getHeadlessBrandsContent, getHeadlessProductsContent } from '../../../../utils/selectors/headless/selectors';
import { startCategoriesRequest } from '../../../../actions/api';
import {
  setStep,
  setDynamicStep,
  startRequest,
  setElmaEmail,
  setDunningEmail,
  setValidDynamicFormField,
  setDynamicFormField,
  setSelectedSeason,
  setSeasons,
  setElmaInvoice,
  setEHFAvailable,
  setBrands,
  setProducts,
} from '../../../../actions/form';
import { Action } from 'redux';
import { RequestData } from '../../../../utils/interfaces/request';
import { apiEndpoints } from '../../../../utils/api/endpoints';
import DynamicFormPage from '../../../DynamicFormPage';
import { Stepper } from '@carlsberggroup/malty.molecules.stepper';

interface Props {
  dynamicStep?: number;
  signUpRequest?: (data: RequestData) => void;
  elmaCheck?: (data: RequestData) => void;
  uploadRequest?: (data: RequestData) => void;
  getFilesRequest?: (data: RequestData) => void;
  checkAccountNumberRequest?: (data: RequestData) => void;
  setDynamicFormField: (fieldName: string, fieldValue: any) => void;
  setValidDynamicFormField: (fieldName: string, validField: boolean) => void;
  brandsRequest?: () => void;
  brands?: any;
  productsRequest?: () => void;
  products?: any;
  completeSignup?: (data: RequestData) => void;
}

interface ParamTypes {
  lang: any;
}

const DynamicFormWrapper: React.FC<Props> = ({
  dynamicStep,
  signUpRequest,
  uploadRequest,
  getFilesRequest,
  checkAccountNumberRequest,
  elmaCheck,
  setDynamicFormField,
  setValidDynamicFormField,
  brandsRequest,
  brands,
  productsRequest,
  products,
  completeSignup,
}: Props) => {
  const marketData = useSelector((state: State) => state.router.marketData);
  const marketConfig = useSelector((state: State) => state.router.marketConfig);
  const history = useHistory();
  const size = getCurrentWidth();
  const [windowSize, setWindowSize] = useState(size);
  const [doneStep, setDoneStep] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setWindowSize(size);
  }, []);

  const isMobile = windowSize <= app.higherBreakPoint;
  const saveId = useSelector((state: State) => state.form.saveId.saveId);
  const params = useParams<ParamTypes>();
  const email = useSelector((state: State) => state.form.email);
  const VATnumber = useSelector((state: State) => state.form.VATnumber);
  const seasons = useSelector((state: State) => state.form.seasons);
  const selectedSeason = useSelector((state: State) => state.form.additionalData.openSeason);
  const elmaEmail = useSelector((state: State) => state.form.additionalData.invoiceEmail);
  const invoice = useSelector((state: State) => state.form.additionalData.elmaInvoice);
  const formInvoice = useSelector((state: State) => state.form.elmaInvoice);
  const additionalData = useSelector((state: State) => state.form.additionalData);
  const dunningEmail = useSelector((state: State) => state.form.additionalData.paymentRemindersEmail);
  const receiveDunning = useSelector((state: State) => state.form.additionalData.allowPaymentReminders);
  const financialEmail = useSelector((state: State) => state.form.additionalData.financialEmail);
  const communicationEmail = useSelector((state: State) => state.form.additionalData.communicationEmail);
  const eInvoiceResp = useSelector((state: State) => state.form.eInvoiceResp);
  const eInvoiceNumber = useSelector((state: State) => state.form.additionalData.eInvoiceNumber);
  const productTiles = useSelector((state: State) => state.form.products);
  const selectedProducts = useSelector((state: State) => state.form.additionalData.selectedProducts || []);
  const brandTiles = useSelector((state: State) => state.form.brands);
  const selectedBrands = useSelector((state: State) => state.form.additionalData.selectedBrands || []);
  const accountNumberAPIValidation = useSelector((state: State) => state.form.accountNumberAPIValidation);
  const form = useSelector((state: State) => state.form);
  const review = useSelector((state: State) => state.form.review);
  const query = new URLSearchParams(location.search);
  const [isValidated, setIsValidated] = useState(false);
  const responseSignup = useSelector((state: State) => state.form.responseSignup);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (doneStep && marketConfig.ukSteps && responseSignup) {
      setShowLoader(false);
      if (responseSignup.success) {
        history.push(`/${params.lang}/welcome`);
      } else {
        history.push(`/${params.lang}/error`);
      }
    }
  }, [responseSignup]);

  useEffect(() => {
    if (accountNumberAPIValidation) {
      if (accountNumberAPIValidation.valid) {
        setValidDynamicFormField('accountNumber', true);
      } else {
        setValidDynamicFormField('accountNumber', false);
      }
    }
  }, [accountNumberAPIValidation]);

  useEffect(() => {
    if (marketConfig.makeDynamicBrandsApiCall) {
      if (!brands) {
        brandsRequest();
      }
      dispatch(setBrands(brands));
    }
  }, [brands, brandsRequest]);

  useEffect(() => {
    if (marketConfig.makeDynamicProductsApiCall) {
      if (!products) {
        productsRequest();
      }
      dispatch(setProducts(products));
    }
  }, [products, productsRequest]);

  useEffect(() => {
    if (selectedProducts && selectedProducts.length && productTiles) {
      for (let i = 0; i < selectedProducts.length; i++) {
        const product = selectedProducts[i];
        if (product.isSelected && productTiles && productTiles.length > 0) {
          const tile = productTiles.find((x: any) => x.name === product.name);
          tile.isSelected = true;
        }
      }
      dispatch(setProducts(productTiles));
    }
  }, [selectedProducts, productTiles]);

  useEffect(() => {
    if (selectedBrands && selectedBrands.length && brandTiles) {
      for (let i = 0; i < selectedBrands.length; i++) {
        const brand = selectedBrands[i];
        if (brand.isSelected && brandTiles && brandTiles.length > 0) {
          const tile = brandTiles.find((x: any) => x.name === brand.name);
          tile.isSelected = true;
        }
      }
      dispatch(setBrands(brandTiles));
    }
  }, [selectedBrands, brandTiles]);

  const checkElma = () => {
    elmaCheck({
      type: 'GET',
      endpoint: `${
        apiEndpoints.signup
      }/elmaInfo?countryCode=${marketData?.marketId.toUpperCase()}&registrationNumber=${VATnumber}`,
    });
  };

  const checkEInvoice = () => {
    dispatch(
      startRequest(
        {
          type: 'GET',
          endpoint: `${
            apiEndpoints.signup
          }/einvoice?countryCode=${marketData?.marketId.toUpperCase()}&registrationNumber=${VATnumber.replace(
            '-',
            '',
          )}`,
        },
        'eInvoiceResp',
      ),
    );
  };

  useEffect(() => {
    if (VATnumber && marketConfig.form.pages[1] && marketConfig.elmaActive) {
      checkElma();
    }
  }, [VATnumber, marketConfig]);

  useEffect(() => {
    if (VATnumber && marketConfig.form.pages[1] && marketConfig.eInvoiceActive) {
      checkEInvoice();
    }
  }, [VATnumber, marketConfig]);

  useEffect(() => {
    if (marketConfig.elmaActive) {
      if (formInvoice) {
        dispatch(setElmaInvoice(formInvoice?.ehfAvailable));
        dispatch(setEHFAvailable(formInvoice?.ehfAvailable));
        dispatch(setElmaEmail(null));
      } else {
        dispatch(setEHFAvailable(false));
        dispatch(setElmaInvoice(false));
      }
    }
  }, [formInvoice, marketConfig]);

  useEffect(() => {
    if (marketConfig.elmaActive && !elmaEmail && invoice !== true && email) {
      dispatch(setElmaEmail(email));
    }
  }, [invoice, email, marketConfig]);

  useEffect(() => {
    if (marketConfig.eInvoiceActive) {
      if (!eInvoiceNumber && eInvoiceResp && eInvoiceResp.receive && eInvoiceResp.eInvoiceAddress) {
        const formData = form.marketData;
        if (
          formData &&
          formData.form &&
          formData.form.pages &&
          formData.form.pages.length > 0 &&
          formData.form.pages[dynamicStep].sections &&
          formData.form.pages[dynamicStep].sections.length > 0
        ) {
          const sections = formData.form.pages[dynamicStep].sections;

          sections.map((section: any) => {
            section.fields.map((field: any) => {
              if (field.type === 'eInvoice') {
                field.items.map((item: any) => {
                  if (item.apiFill) {
                    setDynamicFormField(item.subItems[0].name, eInvoiceResp.eInvoiceAddress);
                    setDynamicFormField(item.subItems[1].name, eInvoiceResp.operatorName);
                    setDynamicFormField(item.subItems[2].name, eInvoiceResp.operatorId);
                    if (form.additionalData[field.name] === undefined) {
                      setDynamicFormField(field.name, t(i18nKeyPrefix + item.name));
                    }
                  }
                });
              }
            });
          });
        }
      }
    }
  }, [eInvoiceResp, marketConfig]);

  useEffect(() => {
    const formData = form.marketData;
    if (
      formData &&
      formData.form &&
      formData.form.pages &&
      formData.form.pages.length > 0 &&
      formData.form.pages[dynamicStep].sections &&
      formData.form.pages[dynamicStep].sections.length > 0
    ) {
      const sections = formData.form.pages[dynamicStep].sections;

      sections.map((section: any, index: any) => {
        section.fields.map((field: any) => {
          if (field.type === 'dunning' && field.name === 'allowPaymentReminders') {
            if (!dunningEmail && !receiveDunning) {
              dispatch(setDunningEmail(email));
            }
          }
          if (field.name === 'financialEmail') {
            if (!financialEmail) {
              setDynamicFormField('financialEmail', email);
              setValidDynamicFormField('financialEmail', true);
            }
          }

          if (field.name === 'communicationEmail') {
            if (!communicationEmail) {
              setDynamicFormField('communicationEmail', email);
              setValidDynamicFormField('communicationEmail', true);
            }
          }
        });
      });
    }
  }, [form.marketData, email]);

  useEffect(() => {
    dispatch(setStep(5));
  }, []);

  const changeStep = () => {
    if (dynamicStep === 0) {
      history.push(`/${params.lang}/signup/4`);
      dispatch(setStep(4));
      dispatch(setDynamicStep(null));
    } else {
      history.push(`/${params.lang}/signup/5/${marketConfig?.form.pages[dynamicStep - 1].pageStepName}`);
      dispatch(setStep(5));
      dispatch(setDynamicStep(dynamicStep - 1));
    }
  };

  useEffect(() => {
    if (selectedSeason && seasons) {
      dispatch(setSelectedSeason(selectedSeason));
      const season = seasons.find((x: any) => x.label === selectedSeason.value);
      season.checked = true;
      dispatch(setSeasons(seasons));
    }
  }, [selectedSeason]);

  useEffect(() => {
    if (doneStep) {
      signUpRequest({
        type: 'PUT',
        endpoint: `${apiEndpoints.signupData}/${saveId}?countryCode=${marketData.marketId.toUpperCase()}`,
        body: {
          additionalData: additionalData,
        },
      });
      window.localStorage.setItem('formData', JSON.stringify(form));
      if (review) {
        history.push(`/${params.lang}/signup/6`);
        dispatch(setStep(6));
        dispatch(setDynamicStep(null));
      } else if (dynamicStep < marketConfig?.form.pages.length - 1) {
        history.push(`/${params.lang}/signup/5/${marketConfig?.form.pages[dynamicStep + 1].pageStepName}`);
        dispatch(setStep(5));
        dispatch(setDynamicStep(dynamicStep + 1));
      } else if (marketConfig.ukSteps) {
        setShowLoader(true);
        setTimeout(() => {
          completeSignup({
            type: 'POST',
            endpoint: `${apiEndpoints.signup}/submit/${saveId}?countryCode=${marketData.marketId.toUpperCase()}`,
          });
        }, 3000);
      } else {
        history.push(`/${params.lang}/signup/6`);
        dispatch(setStep(6));
        dispatch(setDynamicStep(null));
      }
    }
  }, [doneStep]);

  useEffect(() => {
    scrollToError();
  }, [form.dynamicFormValidFields]);

  useEffect(() => {
    if (!isValidated && query && form && form.saveId.saveId && marketConfig) {
      const errorParam = query.get('error');
      if (errorParam && errorParam === 'validation') {
        validateAdditionalInfo(form, dynamicStep, setDynamicFormField, setValidDynamicFormField, t);
        setIsValidated(true);
        scrollToError();
      }
    }
  }, [query, form, marketConfig]);

  const validateStep = () => {
    const valid = validateAdditionalInfo(form, dynamicStep, setDynamicFormField, setValidDynamicFormField, t);

    if (valid) {
      setDoneStep(true);
    }

    scrollToError();
    return;
  };

  return (
    <React.Fragment>
      <div className={styles.formContainer}>
        <FormHeaderComponent changeStep={changeStep} />
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <div className={cn(styles.screenWrapper)}>
              <div className={cn(styles.wrapper)}>
                <div className={styles.stepperContainer}>
                  <Stepper
                    steps={marketConfig.steps}
                    currentStep={marketConfig.ukSteps ? dynamicStep + 3 : dynamicStep + 5}
                  />
                </div>
                <DynamicFormPage
                  configPage={dynamicStep}
                  validateField={(val: any, field: any, item?: any, regex?: string) => {
                    return validateField(val, field, setDynamicFormField, setValidDynamicFormField, item, regex);
                  }}
                  uploadRequest={uploadRequest}
                  getFilesRequest={getFilesRequest}
                  checkAccountNumberRequest={checkAccountNumberRequest}
                />
              </div>
            </div>
            {!isMobile && <SidebarWrapper />}
          </div>
        </div>
      </div>
      <FormFooterComponent validateStep={validateStep} showLoader={showLoader} disabled={false} />
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => {
  return {
    brands: getHeadlessBrandsContent(state),
    products: getHeadlessProductsContent(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    signUpRequest: (data: RequestData) => {
      dispatch(startRequest(data, 'saveId'));
    },
    elmaCheck: (data: RequestData) => {
      dispatch(startRequest(data, 'elmaInvoice'));
    },
    uploadRequest: (data: RequestData) => {
      dispatch(startRequest(data, 'attachments'));
    },
    getFilesRequest: (data: RequestData) => {
      dispatch(startRequest(data, 'attachments'));
    },
    checkAccountNumberRequest: (data: RequestData) => {
      dispatch(startRequest(data, 'accountNumberAPIValidation'));
    },
    setDynamicStep: (dynamicStep: boolean) => {
      dispatch(setDynamicStep(dynamicStep));
    },
    setDynamicFormField: (fieldName: string, fieldValue: any) => {
      dispatch(setDynamicFormField(fieldName, fieldValue));
    },
    setValidDynamicFormField: (fieldName: string, validField: boolean) => {
      dispatch(setValidDynamicFormField(fieldName, validField));
    },
    brandsRequest: () => {
      dispatch(startCategoriesRequest('BRANDS'));
    },
    productsRequest: () => {
      dispatch(startCategoriesRequest('PRODUCTS'));
    },
    completeSignup: (data: RequestData) => {
      dispatch(startRequest(data, 'responseSignup'));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(immutableToJS(DynamicFormWrapper));
