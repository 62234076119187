import React, { useEffect } from 'react';
import styles from './daysAndHours.module.scss';
import cn from 'classnames';
import Select from '../Select';
import { ReactComponent as Plus } from '../../assets/images/plus-line.svg';
import Text, { SIZES } from '../Text';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoCheck } from '../../assets/images/nocheck.svg';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../reducers';
import { setAvailableDays, setDynamicFormField } from '../../actions/form';
import { i18nKeyPrefix } from '../../utils/constants';

interface Props {
  hours: any;
  days: any;
  action: any;
  field: any;
  hoursTo?: any;
  defaultHourFrom?: any;
  defaultHourTo?: any;
}

const DaysAndHours: React.FC<Props> = ({
  hours,
  days,
  action,
  field,
  hoursTo,
  defaultHourFrom,
  defaultHourTo,
}: Props) => {
  const selectedTimes = useSelector((state: State) => state.form.additionalData[field.name]);
  const availableDays = useSelector((state: State) => state.form.availableDays);
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_Custom_BusinessHours';
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAvailableDays(days));
  }, []);

  const deleteDay = (day :any) => {
    const newItems = selectedTimes.filter((x: any) => x.label !== day.label);
    dispatch(setDynamicFormField(field.name, newItems));
  }

  const daySelection = (day) => {
    if (selectedTimes) {
      const selectedDay = selectedTimes.find((x: any) => x.label === day.label);
      return selectedDay;
    }

    return null;
  }

  const handleAddDay = (day) => {
    if (!selectedTimes || !selectedTimes.length) {
      const dataObject = [{
        label: day.label,
        day: day.value,
        dayTranslated: t(i18nKeyPrefix + day.label),
        from: defaultHourFrom ? defaultHourFrom : hours[0],
        to: defaultHourTo ? defaultHourTo : hoursTo ? hoursTo[0] : hours[0]
      }];

      action(dataObject);
      dispatch(setDynamicFormField(field.name, dataObject));
    } else {
      const dataObject = {
        label: day.label,
        day: day.value,
        dayTranslated: t(i18nKeyPrefix + day.label),
        from: defaultHourFrom ? defaultHourFrom : hours[0],
        to: defaultHourTo ? defaultHourTo : hoursTo ? hoursTo[0] : hours[0]
      };

      const newArray = [];
      newArray.push(dataObject);

      action(selectedTimes.concat(newArray));
      dispatch(setDynamicFormField(field.name, selectedTimes.concat(newArray)));
    }
  }

  const setDayTimeFrom = (day, time, timeType) => {
    const otherItems = selectedTimes.filter((x: any) => x.label !== day.label);
    const theItem = selectedTimes.filter((x: any) => x.label === day.label)[0];

    if (timeType === 'timeFrom') {
      theItem.from = time;
    } 
    
    else if (timeType === 'timeTo') {
      theItem.to = time;
    }

    const updatedItem = [];
    updatedItem.push(theItem);

    action(otherItems.concat(updatedItem));
    dispatch(setDynamicFormField(field.name, otherItems.concat(updatedItem)));
  }

  return (
    <div className={cn(styles.wrapper)}>
      <div>
        {availableDays && availableDays.map((day: any, i: any) => {
          const daySelected = daySelection(day);
          return (
          <div data-i18n-key={day.label} key={`days-${i}`}>
            <div className={styles.dayContainer}>
              <div>{t(i18nKeyPrefix + day.label)}</div>
              { !daySelected && 
                <div className={styles.buttonContainer} data-i18n-key={`${prefix}_add_label`} onClick={()=> handleAddDay(day)}>
                  <span className={styles.buttonText}>
                    <Text size={SIZES.SMALL} bold>{t(`${prefix}_add_label`)}</Text>
                  </span>
                  <span className={styles.buttonIcon}><Plus/></span>
                </div>
              }
              { daySelected &&
                <div className={styles.buttonContainer} data-i18n-key={`${prefix}_remove_label`} onClick={()=> deleteDay(day)}>
                  <span className={styles.buttonText}>
                    <Text size={SIZES.SMALL} bold>{t(`${prefix}_remove_label`)}</Text>
                  </span>
                  <span className={styles.buttonIcon}><NoCheck/></span>
                </div>
              }
            </div>
            { daySelected &&
              <div className={styles.deliveryInputs}>
                <div className={styles.deliveryInput}>
                  <Select value={daySelected.from} options={hours} onChange={(event) => setDayTimeFrom(day, event.target.value, "timeFrom")}/>
                </div>
                <div className={styles.deliveryInputSeparator}>{t(`${prefix}_hoursList_toLabel`)}</div>
                <div className={styles.deliveryInput}>
                  <Select value={daySelected.to} options={hoursTo ? hoursTo : hours} onChange={(event) => setDayTimeFrom(day, event.target.value, "timeTo")}/>
                </div>
              </div>
            }
          </div>
          )}
        )}
      </div>
    </div>
  );
}

export default DaysAndHours;