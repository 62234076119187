export const convertLanguageForUmbraco = (lang) => {
  switch (lang) {
    case 'nb-NO':
      return 'no';
    case 'en-NO':
      return 'en-us';
    case 'sv-SE':
      return 'sv';
    default:
      return lang;
  }
};

export const convertLanguageFromUmbraco = (lang) => {
  switch (lang) {
    case 'no':
      return 'nb-NO';
    case 'en-US':
      return 'en-NO';
    case 'sv':
      return 'sv-SE';
    default:
      return lang;
  }
};
