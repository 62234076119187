import React, { Dispatch, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './welcomeScreen.module.scss';
import cn from 'classnames';
import Button from '../../Button';
import TagManager from "react-gtm-module";
import { connect, useDispatch, useSelector } from 'react-redux';
import immutableToJS from 'with-immutable-props-to-js';
import { State } from '../../../reducers';
import { resetState } from '../../../actions/form';
import backupBackgroundImage from '../../../assets/images/welcome.png';
import { getGlobalContent } from '../../../utils/selectors/headless/selectors';
import { Action } from 'redux';
import { apiEndpoints } from '../../../utils/api/endpoints';
import { startRequest } from '../../../actions/form';
import { i18nKeyPrefix } from '../../../utils/constants';

const cookieConsent = window.localStorage.getItem('cookies') === 'accepted' ? 'yes' : 'no';

interface Props {
  global?: any;
}

const WelcomeScreenComponent: React.FC<Props> = ({
  global
}: Props) => {  
  const { t } = useTranslation();
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_Welcome';
  const saveId = useSelector((state: State) => state.form.saveId.saveId);
  const marketConfig = useSelector((state: State) => state.router.marketConfig);
  const marketData = useSelector((state: State) => state.router.marketData);
  const [umbracoBackgroundImage, setUmbracoBackgroundImage] = useState(backupBackgroundImage);
  const [umbracoSuccessUrl, setUmbracoSuccessUrl] = useState('https://www.carlsberg.com/');
  const [umbracoAdditionalUrl, setUmbracoAdditionalUrl] = useState([]);
  const salesRepData = useSelector((state: State) => state.form.salesRepData);
  const dispatch = useDispatch();
  const [salesRep, setSalesRep] = useState(salesRepData);

  const tagManagerDataLayerArgs = {
    dataLayer: {
      'event': 'submitSignUpConfirmation',
      'pageCountry': marketData.marketId,
      'pageLanguage': localStorage['i18nextLng'],
      'pageTitle': 'Welcome to the family',
      'pageStep': 9,
      'pageStepName': 'Welcome',
      'userAcceptCookies': cookieConsent
    }
  }

  useLayoutEffect(() => {
    if (global) {
      if(global?.welcomeScreen?.backgroundImage?.url) {
        setUmbracoBackgroundImage(global?.welcomeScreen?.backgroundImage?.url)
      }
      if(global?.welcomeScreen?.ctaSuccessUrl) {
        setUmbracoSuccessUrl(global?.welcomeScreen?.ctaSuccessUrl)
      }
      if(global?.welcomeScreen?.ctaAdditionalUrls) {
        setUmbracoAdditionalUrl(global?.welcomeScreen?.ctaAdditionalUrls)
      }
    }
  }, [global]);

  useEffect(() => {
    TagManager.dataLayer(tagManagerDataLayerArgs);
  }, []);

  useEffect(() => {
    if (marketConfig && marketConfig.isSalesRepActive) {
      dispatch(startRequest({
        type: 'GET',
        endpoint: `${apiEndpoints.signup}/salesRep/${saveId}`,
      }, 'salesRepData'));
    } else {
      dispatch(resetState());
      localStorage.removeItem("formData");
    }
  }, [marketConfig]);

  useEffect(() => {
    if (salesRepData) {
      setSalesRep(salesRepData);
      dispatch(resetState());
      localStorage.removeItem("formData");
    }
  }, [salesRepData])

  return (
    <div className={styles.wrapper}>
      <div className={styles.fullBackground}>
        <div className={cn(styles.fullImage)} style={{backgroundImage: `url(${umbracoBackgroundImage})`}}></div>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.text}>
          <div className={styles.header} data-i18n-key={`${prefix}_title`}>{t(`${prefix}_title`)}</div>
          <div className={styles.info} data-i18n-key={`${prefix}_registration`}>{t(`${prefix}_registration`)}</div>
          <div className={styles.info} data-i18n-key={`${prefix}_reachOut`}>{t(`${prefix}_reachOut`)}</div>
          { salesRep && 
            <>
              {(salesRep.avatarUrl || salesRep.name || salesRep.phone || salesRep.email) && 
                <div className={styles.subInfo} data-i18n-key={`${prefix}_saleRepInfo`}>{t(`${prefix}_saleRepInfo`)}</div>
              }
              <div className={styles.salesRepContainer}>
                { salesRep.avatarUrl && 
                  <div className={styles.avatarContainer}>
                    <img src={salesRep.avatarUrl}/>
                  </div>
                }
                {salesRep.name && <div className={cn(styles.subInfo, styles.boldInfo)}>{salesRep.name}</div>}
                {salesRep.phone && <div className={styles.subInfo} data-i18n-key={`${prefix}_saleRepPhone`}>{`${t(`${prefix}_saleRepPhone`)} ${salesRep.phone}`}</div>}
                {salesRep.email && <div className={styles.subInfo}>{salesRep.email}</div>}

              </div>
            </>
          }
          { (umbracoAdditionalUrl && umbracoAdditionalUrl.length > 0) &&
            <div className={styles.linksContainer}>
              <div className={styles.info} data-i18n-key={`${prefix}_check`}>{t(`${prefix}_check`)}</div>
              <div className={styles.links}>
                { umbracoAdditionalUrl.map((link: any, i: any) =>
                  <div>
                    <a href={link.url}>{link.name}</a>
                  </div>
                )}
              </div>
            </div>
          }
        </div>    
      </div>
      <div className={cn(styles.contactFooter)}>
        <div className={cn(styles.contactButton)} data-i18n-key={`${prefix}_button`}>
          <Button
            text={t(`${prefix}_button`)}
            color="black"
            size="medium"
            style="noBorder"
            action={() => window.location.replace(`${umbracoSuccessUrl}`)}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: State) => {
  return {
    global: getGlobalContent(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(WelcomeScreenComponent));