import React, { useState, useEffect, Dispatch, SyntheticEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './companyType.module.scss';
import cn from 'classnames';
import { getCurrentWidth } from '../../../../utils/custom-hooks/handleWindowResize';
import { app, i18nKeyPrefix } from '../../../../utils/constants';
import ScreenTitle from '../../ScreenTitle';
import FormHeaderComponent from '../../FormHeader';
import Input from '../../../Input';
import {
  setCompanyType,
  setStep,
  setValidCompanyType,
  startRequest,
  setCompanyTypeImage,
  setCategories,
  setSelectedCategories,
  setDynamicStep,
  setMainTradeChannel,
  setAdditionalTradeChannel,
  setDynamicValidation,
  setDynamicValue
} from '../../../../actions/form';
import { useSelector, useDispatch, connect } from 'react-redux';
import { State } from '../../../../reducers';
import immutableToJS from 'with-immutable-props-to-js';
import FormFooterComponent from '../../FormFooter';
import SidebarWrapper from '../../Sidebar/SidebarWrapper';
import { apiEndpoints } from '../../../../utils/api/endpoints';
import { RequestData } from '../../../../utils/interfaces/request';
import { Action } from 'redux';
import { getHeadlessCategoriesContent } from '../../../../utils/selectors/headless/selectors';
import { startCategoriesRequest } from '../../../../actions/api';
import Chip from '../../../Chip';
import Card from '../../../Card';
import Radio from '../../../Radio';
import { Stepper } from '@carlsberggroup/malty.molecules.stepper';
import { scrollToError, validateOutletValidation } from '../../../../utils/validation';
import xss from 'xss';

//this file is for both categories types, chipCategories or the old style

interface Props {
  types?: any;
  categories?: any;
  signUpRequest?: (data: RequestData) => void;
  categoriesRequest: () => void;
}

interface ParamTypes {
  lang: any;
}

const CompanyTypeComponent: React.FC<Props> = ({
  types,
  categories,
  signUpRequest,
  categoriesRequest
}: Props) => {
  const marketData = useSelector((state: State) => state.router.marketData);
  const marketConfig = useSelector((state: State) => state.router.marketConfig);
  const { t } = useTranslation();
  const el = React.createRef<HTMLDivElement>();
  const history = useHistory();
  const size = getCurrentWidth();
  const [windowSize, setWindowSize] = useState(size);
  const dispatch = useDispatch();

  useEffect(() => {
    setWindowSize(size);
  });
  const isMobile = windowSize <= app.higherBreakPoint;

  const [validStep, setValidStep] = useState(false);

  const companyType = useSelector((state: State) => state.form.companyType);
  const saveId = useSelector((state: State) => state.form.saveId.saveId);
  const validCompanyType = useSelector((state: State) => state.form.validCompanyType);

  const step = useSelector((state: State) => state.form.step);
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_CompanyType';
  const params = useParams<ParamTypes>();
  const form = useSelector((state: State) => state.form);

  const selectedCategories = useSelector((state: State) => state.form.additionalData.selectedCategories || []);
  const additionalData = useSelector((state: State) => state.form.additionalData);
  const tiles = useSelector((state: State) => state.form.categories);
  const review = useSelector((state: State) => state.form.review);

  const postalCodeValidation = marketConfig.postalCodeRegex;
  const outletZipCode = useSelector((state: State) => state.form.outletZipCode);
  const validOutletZipCode = useSelector((state: State) => state.form.validOutletZipCode);
  const outletName = useSelector((state: State) => state.form.outletName);
  const validOutletName = useSelector((state: State) => state.form.validOutletName);
  const query = new URLSearchParams(location.search);

  const changeStep = (step: number) => {
    history.push(`/${params.lang}/signup/${step}`);
    dispatch(setStep(step));
  }

  useEffect(() => {
    if (!categories) {
      categoriesRequest();
    }
    dispatch(setCategories(categories));
  }, [categories, categoriesRequest]);

  useEffect(() => {
    if (selectedCategories && selectedCategories.length > 0) {
      dispatch(setValidCompanyType(true));
    }
  }, [selectedCategories]);


  useEffect(() => {
    if (categories && categories.length) {
      for (let i = 0; i < categories.length; i++) {
        const category = categories[i];
        if (category.isSelected) {
          dispatch(setSelectedCategories([category]));
        }
      }
    }
  }, [categories]);

  useEffect(() => {
    if (selectedCategories && selectedCategories.length && tiles) {
      for (let i = 0; i < selectedCategories.length; i++) {
        const category = selectedCategories[i];
        if (category.isSelected && tiles && tiles.length > 0) {
          const tile = tiles.find((x: any) => x.name === category.name);
          tile.isSelected = true;
        }
      }
      dispatch(setCategories(tiles));
    }
  }, [selectedCategories, tiles]);

  useEffect(() => {
    dispatch(setStep(4));
  }, []);

  useEffect(() => {
    scrollToError();
  }, [validCompanyType, validOutletName, validOutletZipCode]);

  useEffect(() => {
    if (query) {
      const errorParam = query.get('error')
      if (errorParam && errorParam === 'validation') {
        scrollToError();
      }
    }
  }, [query]);

  const callSignUp = (additionalDataUpdated) => {
    const body = {
      additionalData: additionalDataUpdated
    }

    if (marketConfig.showOutletCompanyType) {
      body['mailingData'] = {
        name: outletName,
        zipCode: outletZipCode
      };

      body['basicData'] = {
        companyName: outletName,
        companyId: '00000'
      };
    }

    signUpRequest({
      type: 'PUT',
      endpoint: `${apiEndpoints.signupData}/${saveId}?countryCode=${marketData.marketId.toUpperCase()}`,
      body: body
    });
  }

  const validateStep = () => {
    if (marketConfig.showOutletCompanyType && !validateOutletValidation(dispatch, marketConfig, form)) {
      scrollToError();
      return;
    }
    if (categories && marketConfig?.chipCategories == false && companyType) {
      setValidStep(true);
      dispatch(setValidCompanyType(true));
      
      callSignUp(additionalData);

      window.localStorage.setItem('formData', JSON.stringify(form));
      history.push(`/${params.lang}/signup/6`);
      dispatch(setStep(6));
      return;
    } else if (selectedCategories && selectedCategories.length) {
      const mainCategory = selectedCategories.find((x: any) => x.isMain === true);
      const additionalCategories = selectedCategories.filter((x: any) => x.isMain !== true);
      const additionalDataUpdated = { ...additionalData, mainTradeChannel: mainCategory, additionalTradeChannel: additionalCategories }
      
      dispatch(setMainTradeChannel(mainCategory));
      dispatch(setCompanyTypeImage(mainCategory.image.url));
      dispatch(setAdditionalTradeChannel(additionalCategories));

      setValidStep(true);
      dispatch(setValidCompanyType(true));

      callSignUp(additionalDataUpdated);

      window.localStorage.setItem('formData', JSON.stringify(form));
      if (review) {
        history.push(`/${params.lang}/signup/6`);
        dispatch(setStep(6));
      } else if (marketConfig?.form !== null) {
        history.push(`/${params.lang}/signup/5`);
        dispatch(setDynamicStep(0));
        dispatch(setStep(5));
      } else {
        history.push(`/${params.lang}/signup/6`);
        dispatch(setStep(6));
      }
      return;
      
    } else {
      dispatch(setValidCompanyType(false));
      setValidStep(false);
      return;
    }
  }

  const getInsertionIndex = (index: number, row: number) => {
    return (Math.ceil((index + 1) / row) * row) - 1;
  }

  const openDescription = (bool: boolean, tile: any, index: number) => {
    if (!tile && !index) return;
    const child = document.getElementsByClassName(`${styles.tile}`);
    const alreadySelected = document.getElementsByClassName(`${styles.selected}`)[0];
    const rows = isMobile ? 2 : 4;

    if (alreadySelected && alreadySelected.isEqualNode(document.getElementById(`${tile.name}`))) {
      document.getElementById('description').remove();
      document.getElementById(`${tile.name}`).className = `${styles.tile}`;
      dispatch(setCompanyType(null));
      return;
    } else {
      const selectedItem = document.getElementById(`${tile.name}`);
      if (alreadySelected) {
        if (document.getElementById('description')) {
          document.getElementById('description').remove();
        }
        alreadySelected.className = `${styles.tile}`;
      }
      if (isMobile) {
        child[getInsertionIndex(index, rows)].insertAdjacentHTML('afterend',
        `<div id="description"style="width:100%;height:150px;margin-top:20px;padding-right:40px; padding-left:40px;margin-bottom:30px">
          <div style="font-size:18px;margin-bottom:20px;font-weight:900;text-transform:uppercase">${tile.name}</div>
          <div style="font-size:14px;font-weight:500;">${tile.description.replace(/<[^>]*>?/gm, '').replace(/\u00a0/g, " ")}</div>
        </div>`);
      } else {
        child[getInsertionIndex(index, rows)].insertAdjacentHTML('afterend',
        `<div id="description"style="width:100%;height:150px;margin-top:20px;padding-right:100px;">
          <div style="font-size:18px;margin-bottom:20px;font-weight:900;text-transform:uppercase">${tile.name}</div>
          <div style="font-size:14px;font-weight:500;">${filterXSS(tile.description.replace(/\u00a0/g, " "))}</div>
        </div>`);
      }
      selectedItem.className = `${styles.tile} ${styles.selected}`;
      dispatch(setCompanyType(tile.name));
      dispatch(setCompanyTypeImage(tile.image.url));
    }
  }

  const getTiles = () => {
    return (
      <div id="tileContainer" className={styles.tilesContainer}>
        {tiles && tiles?.length > 0 && tiles?.map((tile: any, i: any) =>
          <div data-id={`section${tile.tcNumber}`} className={cn(styles.tile, {[styles.selected]: companyType === tile.name})} key={tile.tcNumber} id={tile.name} onClick={() => openDescription(true, tile, i)}>
            <div className={styles.image} style={{ backgroundImage: `url(${tile.image.url}`}} ref={ el }></div>
            <span className={cn(styles.label, {[styles.noImage]: !tile.image})}>{tile.name}</span>
          </div>
        )}
        {!validCompanyType && <div className={styles.companyTypeMissing} data-i18n-key={`${prefix}_error`}>{t(`${prefix}_error`)}</div>}
      </div>
    )
  }

  const selectCategory = (id: any, value: boolean) => {
    const tile = tiles.find((x: any) => x.tcNumber === id);
    const newArray = [...selectedCategories];
    if (marketConfig.maxSelectedCategories === 1 && selectedCategories.length > 0 && !tile.isSelected) {
      const selectedTile = tiles.find((x: any) => x.isSelected);
      selectedTile.isSelected = false;
      selectedTile.isMain = false;

      const index = newArray.indexOf(selectedTile);
      newArray.splice(index, 1);

      tile.isSelected = true;
      tile.isMain = true;
      newArray.push(tile);
    } else if (tile && tile.isSelected && newArray) {
      tile.isSelected = !value;
      tile.isMain = false;
      const index = newArray.indexOf(tile);
      newArray.splice(index, 1);
      const mainCategory = newArray.find((x: any) => x.isMain);
      if (!mainCategory) {
        if (newArray[0]) {
          newArray[0].isMain = true;
        }
      }
    } else {
      if (!newArray || !newArray.find((x: any) => x.isMain)) {
        tile.isMain = true;
      }
      tile.isSelected = value;
      newArray.push(tile);
    }
    dispatch(setSelectedCategories(newArray));
    dispatch(setCategories(tiles));
  }

  const markAsMain = (id: any, value: boolean) => {
    const tile = tiles.find((x: any) => x.tcNumber === id);

    for (let i = 0; i < selectedCategories.length; i++) {
      if (selectedCategories[i]?.isMain) {
        selectedCategories[i].isMain = false;
      }
    }
    if (tile.isMain) {
      tile.isMain = !value;
    } else {
      if (selectedCategories.find((x:any) => x.isMain === true)) {
        for (let i = 0; i < selectedCategories.length; i++) {
          if (selectedCategories[i]?.isMain) {
            selectedCategories[i].isMain = false;
          }
        }
      }
      tile.isMain = value;
      selectedCategories.find((x:any) => x.tcNumber === id).isMain = value;
    }
    dispatch(setCategories(tiles));
    dispatch(setSelectedCategories(selectedCategories));
  }
  
  const validateInput = (e: SyntheticEvent, field: string, validField: string) => {
    const formatValue =  (e.target as HTMLInputElement).value;
    if (formatValue) {
      dispatch(setDynamicValidation(`valid${validField}`, true));
    }

    dispatch(setDynamicValue(field, formatValue));
  }

  const validateZipCode = (e: SyntheticEvent) => {
    const formatValue =  (e.target as HTMLInputElement).value;

    const regex = new RegExp(postalCodeValidation);
    const valid = formatValue.replace(/ /g, '').match(regex);

    if(valid) {
      dispatch(setDynamicValidation('validOutletZipCode', true));
    } else {
      dispatch(setDynamicValidation('validOutletZipCode$', false));
    }

    dispatch(setDynamicValue('outletZipCode', formatValue));
  }

  const OutletBlock = () => {
    return (
      <span key="name-block">
          <div className={styles.outletContainer}>
            <span>
              <Input
                label={t(`${prefix}_outletName`)}
                placeholder={t(`${prefix}_outletNamePlaceholder`)}
                onChange={(val: any) =>  validateInput(val, 'outletName', 'OutletName')}
                onBlur={(val: any) =>  validateInput(val, 'outletName', 'OutletName')}
                required
                maxLength={35}
                value={outletName}
                errorMessage={t(`${prefix}_outletNameError`)}
                valid={validOutletName}
                dataId="inputOutletName"
              />
            </span>
            <span
              data-i18n-key={`${prefix}_outletZipLabel`}
              data-i18n-key-error={`${prefix}_outletZipError`}>
              <Input
                label={t(`${prefix}_outletZipLabel`)}
                placeholder={t(`${prefix}_outletZipLabel`)}
                onChange={(val: any) => validateZipCode(val)}
                onBlur={(val: any) => validateZipCode(val)}
                value={outletZipCode}
                required
                valid={validOutletZipCode}
                errorMessage={t(`${prefix}_outletZipError`)}
                dataId={'inputOutletZip'}
              />
            </span>
          </div>
      </span>
    )
  }

  return (
    <React.Fragment>
        <div className={styles.formContainer}>
          {step !== 7 && <FormHeaderComponent changeStep={changeStep} />}
            <div className={styles.container}>
              <div className={styles.innerContainer}>
                <div className={cn(styles.tilesWrapper)}>
                  <div className={styles.wrapper} data-i18n-key-title={`${prefix}_title`}>
                    <div className={styles.stepperContainer}>
                      <Stepper steps={marketConfig.steps} currentStep={marketConfig.ukSteps ? 2 : 4}/>
                    </div>
                    { marketConfig.showOutletCompanyType &&
                      <>
                        <ScreenTitle title={t(`${prefix}_outletTitle`)}></ScreenTitle>
                        {OutletBlock()}
                      </>
                    }
                    <ScreenTitle title={t(`${prefix}_title`)}></ScreenTitle>
                    <div className={cn({[styles.alignCenter]: isMobile})}>
                    {categories && marketConfig?.chipCategories == false && getTiles()}
                    {categories && marketConfig?.chipCategories != false &&
                      <div id="tileContainer" className={styles.tilesContainer}>
                        {tiles && tiles?.length > 0 && tiles?.map((tile: any, i: any) =>
                          <Chip
                            label={tile.name}
                            selected={tile.isSelected}
                            key={tile.tcNumber}
                            action={() => selectCategory(tile.tcNumber, true)}
                            multiSelection={marketConfig?.maxSelectedCategories > 1}
                            disabled={tiles.filter((x: any) => x.isSelected).length >= marketConfig?.maxSelectedCategories}
                            data-id={`chip${tile.tcNumber}`}
                          />
                        )}
                        <div className={styles.info} data-i18n-key={`${prefix}_info`}>
                          {t(`${prefix}_info`)}
                        </div>
                        <div className={styles.cards}>
                          {selectedCategories && selectedCategories.length > 0 && selectedCategories.map((tile: any, i: any) =>
                            <div className={cn(styles.hiddenCard, {[styles.card]: tile.isSelected})} key={tile.tcNumber}>
                                <Card>
                                  <div className={styles.cardImage}>
                                    <div className={styles.image} style={{ backgroundImage: `url(${tile.image.url}`}} ref={ el }></div>
                                  </div>
                                  <div className={styles.cardDescription}>
                                    <div className={styles.cardTitle}>{tile.name}</div>
                                    {filterXSS(tile.description.replace(/\u00a0/g, " "))}
                                  </div>
                                </Card>
                                {selectedCategories.filter((x:any) => x.isSelected).length > 1 &&
                                  <div className={styles.radioControl} data-i18n-key={`${prefix}_radio`}>
                                    <Radio dataId={`radio${tile.tcNumber}`} label={t(`${prefix}_radio`)} checked={tile.isMain || false} action={() => markAsMain(tile.tcNumber, true)}></Radio>
                                  </div>
                                }
                            </div>
                          )}
                          {!validCompanyType && <div className={cn('formError', styles.companyTypeMissing)} data-i18n-key={`${prefix}_error`}>{t(`${prefix}_error`)}</div>}
                        </div>
                      </div>
                    }
                    </div>
                  </div>
                </div>
                {!isMobile && step !== 7 &&
                  <SidebarWrapper />
                }
              </div>
            </div>
        </div>
      {step !== 7 && <FormFooterComponent validateStep={validateStep} />}
    </React.Fragment>
  );
}

const mapStateToProps = (state: State) => {
  return {
    categories: getHeadlessCategoriesContent(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    signUpRequest: (data: RequestData) => {
      dispatch(startRequest(data, 'saveId'));
    },
    categoriesRequest: () => {
      dispatch(startCategoriesRequest("CATEGORIES"));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(immutableToJS(CompanyTypeComponent));