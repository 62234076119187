import React, { useState, useEffect, Dispatch } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './contactMeFooter.module.scss';
import cn from 'classnames';
import { getCurrentWidth } from '../../../../utils/custom-hooks/handleWindowResize';
import { app, i18nKeyPrefix } from '../../../../utils/constants';
import Button from '../../../Button';
import immutableToJS from 'with-immutable-props-to-js';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Action } from 'redux';
import { setInfoSent, startRequest } from '../../../../actions/form';
import { RequestData } from '../../../../utils/interfaces/request';
import { apiEndpoints } from '../../../../utils/api/endpoints';
import { State } from '../../../../reducers';

interface Props {
  sendContact?: (data: RequestData) => void;
}

const ContactMeFooterComponent: React.FC<Props> = ({
  sendContact,
}: Props) => {
  const marketData = useSelector((state: State) => state.router.marketData);
  const { t } = useTranslation();

  const size = getCurrentWidth();
  const [windowSize, setWindowSize] = useState(size);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setWindowSize(size);
  });
  const isMobile = windowSize <= app.breakPoint;

  const saveId = useSelector((state: State) => state.form.saveId.saveId);
  const [showLoader, setLoader] = useState(false);
  const infoSent = useSelector((state: State) => state.form.infoSent);
  const prefix = i18nKeyPrefix + 'LeanSignup_Form_ContactMe';
  const marketConfig = useSelector((state: State) => state.router.marketConfig);

  const contactMe = () => {
    if (!saveId) return;
    if (infoSent) {
      window.location.replace(`${marketConfig?.exitNoSuccessCTA}`)
    } else {
      setLoader(true);
      setTimeout(() => {
        sendContact({
          type: 'POST',
          noAuth: true,
          endpoint: `${apiEndpoints.sendContactInfo}/${saveId}?countryCode=${marketData.marketId.toUpperCase()}`,
        });
        setLoader(false);
        dispatch(setInfoSent(true));
      }, 3000);
    }
  }

  return (
    <div className={cn(styles.contactFooter)}>
      <div className={cn(styles.contactButton, {
          [styles.nextButtonMobile]: isMobile
        })}
        data-i18n-key-button={`${prefix}_button`}
        data-i18n-key-finished={`${prefix}_buttonFinished`}
      >
        <Button
          text={!infoSent ? t(`${prefix}_button`): t(`${prefix}_buttonFinished`)}
          color="black"
          size="medium"
          style="noBorder"
          cbLoader={showLoader}
          action={contactMe}
          disabled={showLoader}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    sendContact: (data: RequestData) => {
      dispatch(startRequest(data, 'sendContactInfo'));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(immutableToJS(ContactMeFooterComponent));