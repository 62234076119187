import React, { useState, useEffect, createRef, RefObject } from 'react';
import i18n from '../../i18n';
import cn from 'classnames';
import Text, { SIZES, TEXTCOLOR } from '../../components/Text';
import { Language, } from '../../utils/interfaces/language';
import Dropdown from '../../components/DropdownCard';
import handleOutsideClick from '../../utils/custom-hooks/handleOutsideClick';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ChevronDown } from '../../assets/images/chevron-down.svg';
import { ReactComponent as English } from '../../assets/images/flags/gb.svg';
import { ReactComponent as Lao } from '../../assets/images/flags/la.svg';
import { ReactComponent as Portuguese } from '../../assets/images/flags/pt.svg';
import { ReactComponent as LanguageIcon } from '../../assets/images/language.svg';

import styles from './languages.module.scss';
import { app } from '../../utils/constants';
import { getCurrentWidth } from '../../utils/custom-hooks/handleWindowResize';

interface Props {
  defaultLanguage?: Language;
  handleLanguageSelection?: (selectedLanguage: Language) => void;
  hasFlag?: boolean;
  isAbsolute?: boolean;
  isLogin?: boolean;
  languages: Language[];
  shouldClose?: boolean;
}

const Languages: React.FC<Props> = ({
  defaultLanguage,
  handleLanguageSelection,
  hasFlag = true,
  isAbsolute = true,
  isLogin,
  languages,
  shouldClose
}: Props) => {
  const { t } = useTranslation();
  const [isSelectLanguageOpen, toggleLanguage] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState({} as Language);
  const size = getCurrentWidth();
  const [windowSize, setWindowSize] = useState(size);
  useEffect(() => {
    setWindowSize(size);
  });
  const isMobile = windowSize <= app.breakPoint;
  const path = location.pathname;
  const isForm = path.includes('signup') || path.includes('contact-me');

  const laguageDropdownRef: RefObject<HTMLDivElement> = createRef();

  useEffect(() => {
    setCurrentLanguage(defaultLanguage);
  }, [defaultLanguage]);

  useEffect(() => {
    if (shouldClose) {
      toggleLanguage(false);
    }
  }, [shouldClose]);

  const handleToggleSelect = () => {
    toggleLanguage(!isSelectLanguageOpen);
  }

  handleOutsideClick(laguageDropdownRef, () => {
    if (isSelectLanguageOpen) {
      handleToggleSelect();
    }
  });

  const shortenIsoCode = () => {
    if (!currentLanguage || !Object.keys(currentLanguage).length) {

      return;
    }

    return currentLanguage.isoCode.substring(3, 5);
  }

  const getLanguageName = () => {
    if (!currentLanguage || !Object.keys(currentLanguage).length) {

      return;
    }

    return currentLanguage.name;
  }

  const selectLanguage = (language: Language) => {
    if (language && language.isoCode === currentLanguage.isoCode) {
      handleToggleSelect();

      return;
    }

    i18n.changeLanguage(language.isoCode.replace('_', '-'));

    if (handleLanguageSelection) {
      handleLanguageSelection(language);
    }

    setCurrentLanguage(language);

    handleToggleSelect();
  }

  const renderFlag = (name: string) => {
    switch(name) {
      case "Lao":
        return <Lao />;
      case "English":
        return <English />;
      case "Portuguese":
        return <Portuguese />;
      default:
        return null;
    }
  }

  const renderLanguages = () => {
    return languages && languages.map((language: Language, index: number) => {
      return  (
        <div
          key={`${index}-${language.name}`}
          className={styles.item}
          onClick={() => selectLanguage(language)}
        >
          <div className={cn(styles['language-option'])}>
            { language.name }
          </div>
        </div>
      );
    });
  }

  const renderLanguagesDropdown = () => {
    if (!isSelectLanguageOpen) {
      return null;
    }

    return (
      <div ref={laguageDropdownRef} className={cn(styles['dropdown-wrapper'], styles.languages)}>
        <Dropdown>
          {renderLanguages()}
        </Dropdown>
      </div>
    );
  }

  return (
    <div className={cn(styles['languages-wrapper'])}>
      <div className={cn(styles.wrapper, {
        [styles.absolute]: isAbsolute,
        [styles.relative]: !isAbsolute,
        [styles.login]: isLogin
      })}>
        <div className={cn(styles['languages-wrapper'], {[styles.mobile]: isMobile, [styles.isForm]: isForm})}>
          <div className={cn(styles.text, {[styles.mobile]: isMobile, [styles.isForm]: isForm})} onClick={handleToggleSelect}>
            <div>
              {getLanguageName()}
            </div>
            <div className={cn(styles.chevron, {[styles.mobile]: isMobile, [styles.isForm]: isForm})}>
              <ChevronDown />
            </div>
          </div>
          {isSelectLanguageOpen && renderLanguagesDropdown()}
        </div>
      </div>
    </div>
  );
}

export default Languages;