import { formatFullDate } from '../getToday';
import { markets } from './constants';

const version = '22';
const storagePeriodHours = 24;

export const getConfig = async (url: string, name: string, isUmbraco?: boolean, apiKey?: string) => {
  const versionNo = localStorage.getItem('version_' + name);
  const updatedOn = localStorage.getItem('updated_' + name);
  let isPeriodPast = true;
  const timestamp = Date.parse(updatedOn);
  if (isNaN(timestamp) === false) {
    var d = new Date(timestamp);
    d.setTime(d.getTime() + storagePeriodHours * 60 * 60 * 1000);

    if (d > new Date()) {
      isPeriodPast = false;
    }
  }

  if (versionNo === version && !isPeriodPast) {
    if (localStorage.getItem(name)) {
      return JSON.parse(localStorage.getItem(name));
    }
  } else {
    localStorage.setItem('version_' + name, version);
    localStorage.setItem('updated_' + name, formatFullDate(new Date()));
  }

  let options = {};
  const headers = {
    'Content-Type': 'application/json',
  };

  if (isUmbraco) {
    options = {
      method: 'GET',
      headers: { ...headers, 'X-Api-Key': apiKey },
    };
  } else {
    options = {
      mode: 'no-cors',
      headers: headers,
    };
  }

  const response = await fetch(url, options);

  const config = await response.json().then((result) => {
    if (isUmbraco) {
      return JSON.parse(result.route?.settings?.applicationSettings);
    } else {
      return result;
    }
  });

  localStorage.setItem(name, JSON.stringify(config));

  return config;
};

export const getOriginPath = (url?: string) => {
  if (process.env.NODE_ENV === 'development') {
    return url;
  } else {
    return `${window.location.origin}/`;
  }
};

export const getConfigPath = (url?: string) => {
  return `${getOriginPath(url)}__cx__conf__/lsu/`;
};

export const getConfigUrl = (config: configName) => {
  return `${configName[config]}.json`;
};

export enum configName {
  global = 'global',
  market = 'market',
}

export const getAWSConfig = async (isGlobalConfig?: boolean) => {
  const currentLocation = window.location.href.toLocaleLowerCase();

  const marketSuffixes = markets.reduce((suffixes, market) => {
    return suffixes.concat([`-${market}`, `.${market}`]);
  }, []);

  let env = currentLocation.includes('dev')
    ? 'dev'
    : currentLocation.includes('tst')
    ? 'tst'
    : currentLocation.includes('stg')
    ? 'stg'
    : 'prd';

  const baseURL = `https://api.${
    env === 'prd' ? 'live' : env
  }.carlsbergwebservices.com/configuration/handler?appConfigName=cx-lean-signup-fe&appConfigKey=pub_fe_`;

  let url: string;
  let market: string;
  let config: any;

  if (process.env.NODE_ENV === 'development') {
    market = isGlobalConfig
      ? configName.global
      : JSON.parse(localStorage.getItem('market'))?.marketId.replace(/[^a-z0-9]/gi, '');
    market = market === 'gb' ? 'uk' : market;
    config = require(`./appConfig/pub_fe_${market}_dev/config.json`);
  } else {
    market = isGlobalConfig
      ? configName.global
      : marketSuffixes.find((suffix) => currentLocation.includes(suffix)).replace(/[^a-z0-9]/gi, '');
    market = market === 'gb' ? 'uk' : market;
    url = `${baseURL}${market}_${env}`;

    const response = await fetch(url, {
      headers: { 'Content-Type': 'application/json' },
    });

    config = await response.json().then((result) => result.data);
  }

  localStorage.setItem(
    market === configName.global ? configName.global : market === 'uk' ? 'GB' : market?.toUpperCase(),
    JSON.stringify(config),
  );

  return config;
};
