import React, { ReactNode } from 'react';
import styles from './radioCard.module.scss';
import cn from 'classnames';
import { ReactComponent as Year }  from '../../assets/images/year.svg';
import { ReactComponent as Summer } from '../../assets/images/summer.svg';
import { ReactComponent as Winter } from '../../assets/images/winter.svg';
import { ReactComponent as Events } from '../../assets/images/events.svg';

interface Props {
  label: string;
  checked: boolean;
  icon?: any;
  action: any;
  genericClass?: string;
}

const RadioCard: React.FC<Props> = ({
  label,
  icon,
  checked,
  action,
  genericClass
}: Props) => {

const getIcon = (icon: string) => {
  switch(icon) {
    case "Year":
      return <Year />;
    case "Summer":
      return <Summer />;
    case "Winter":
      return <Winter />;
    case "Events":
      return <Events />;
    default:
      return null;
  }
}
  return (
    <div className={cn(styles.card, {[styles.checked]: checked, [styles[genericClass]]: genericClass})} onClick={action}>
        {icon && <div className={styles.icon}>{getIcon(icon)}</div>}
        <div className={styles.label}>{label}</div>
    </div>
  );
}

export default RadioCard;
