import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getConfig, getConfigPath, getConfigUrl, configName, getAWSConfig } from './utils/config/getConfig';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import LocizeBackend from 'i18next-locize-backend';

const translations = {
  projectId: 'd1cb5fe6-07f3-4427-9b65-7ca7f3cde298',
  defaultLang: 'en',
  supportedLangs: [
    'en',
    'en-DK',
    'en-US',
    'en-NO',
    'en-GB',
    'en-SE',
    'sv-SE',
    'fi-FI',
    'nb-NO',
    'da-DK',
    'en-FI',
    'en-LT',
    'lt-LT',
  ],
  fallbackLng: {
    no: ['nb', 'en'],
    nn: ['nb', 'en'],
    default: ['en'],
  },
  namespace: {
    defaultNamespace: 'default',
    namespaceList: ['default'],
  },
  version: {
    dev: 'latest',
    prod: 'production',
  },
};

const i18nInstance = i18n.use(ChainedBackend).use(LanguageDetector).use(initReactI18next);

const i18nConfig = async () => {
  const marketData = await getConfig(`${getConfigUrl(configName.market)}`, configName.market);
  const apiConfig = await getAWSConfig(true);

  const fallbackLanguage: string = marketData?.defaultLanguage;

  if (!localStorage.getItem('i18nextLng')) {
    localStorage.setItem('i18nextLng', fallbackLanguage);
    const lang = window.location.pathname.split('/')[1];

    if (lang && lang.length > 1) {
      marketData.supportedLanguages.forEach((langItem) => {
        if (langItem.toLowerCase() === lang.toLowerCase()) {
          localStorage.setItem('i18nextLng', langItem);
        }
      });
    }
  }

  const {
    projectId,
    supportedLangs,
    defaultLang,
    fallbackLng,
    namespace: { defaultNamespace, namespaceList },
  } = translations;

  const version = apiConfig.locizeVersion;

  await i18nInstance.init({
    defaultNS: defaultNamespace,
    ns: namespaceList,
    // Loading only `en` from `en-UK`
    load: 'all',
    // Allows us to fallback from unsupported languages
    fallbackLng,
    // Only saving missing keys in Dev environment
    saveMissing: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    supportedLngs: supportedLangs,
    contextSeparator: '.',
    backend: {
      backends: [LocalStorageBackend, LocizeBackend],
      backendOptions: [
        {
          expirationTime: 24 * 60 * 60 * 1000, // 1 day
        },
        {
          projectId,
          referenceLng: defaultLang,
          allowedAddOrUpdateHosts: ['localhost', '0.0.0.0'],
          version,
        },
      ],
    },
  });
};

export const startI18n = async () => {
  await i18nConfig();
  return i18nInstance;
};

export default i18nInstance;
