import React, { ChangeEvent, useState, createRef, RefObject, SyntheticEvent, useEffect } from 'react';
import styles from './codeVerify.module.scss';
import Text, { SIZES, TEXTCOLOR } from '../Text';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { getCurrentWidth } from '../../utils/custom-hooks/handleWindowResize';
import { app } from '../../utils/constants';

interface Props {
  onChange: (val: string) => void;
  label?: string;
  required?: boolean;
  valid?: boolean;
}

const CodeVerify: React.FC<Props> = ({
  onChange,
  label,
  required,
  valid
}: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [focused, setFocused] = useState(false);
  const CODE_LENGTH = new Array(4).fill(0);
  const size = getCurrentWidth();
  const [windowSize, setWindowSize] = useState(size);
  useEffect(() => {
    setWindowSize(size);
  });
  const isMobile = windowSize <= app.breakPoint;

  const inputRef: RefObject<HTMLInputElement> = createRef();

  const handleClick = () => {
    inputRef.current.focus();
  };
  const handleFocus = () => {
    setFocused(true);
  };
  const handleBlur = (e: SyntheticEvent) => {
    setFocused(false);
  };
  const handleKeyUp = (e: any) => {
    if (e.key === "Backspace") {
      setValue(value.slice(0, value.length - 1));
    }
  };

  const handleChange = (e: SyntheticEvent) => {
    const newVal = (e.target as HTMLInputElement).value;
    let concat = value;

    if (value.length > CODE_LENGTH.length) {
      return null;
    } else {
      setValue(value + newVal);
      concat += newVal;
    };
    if (concat.length === CODE_LENGTH.length) {
      setFocused(false);
    }
    return onChange(concat);
  };
    const values = value.split("");

    const selectedIndex = values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;

    const hideInput = !(values.length < CODE_LENGTH.length);

  return (
      <React.Fragment>
        {label &&
            <label
              className={cn(styles.label, {[styles.error]: required && !valid})}
            >
              <Text size={SIZES.SMALL} color={TEXTCOLOR.STEEL}>{label}{required && ' *'}</Text>
            </label>
        }
        <div className={styles.formWrap} onClick={handleClick}>
            <input
                value=""
                ref={inputRef}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyUp={handleKeyUp}
                onFocus={handleFocus}
                className={cn(styles.input)}
                data-id="inputCode"
                style={{
                width: 'calc(25% - 14px)',
                height: '66px',
                top: "0px",
                bottom: "0px",
                left: `calc(${selectedIndex} * 25%)`,
                opacity: hideInput ? 0 : 1,
                }}
            />
            {CODE_LENGTH.map((v, index) => {
                const selected = values.length === index;
                const filled = values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;

                return (
                <div className={cn(styles.display, {[styles.error]: required && !valid})} key={index}>
                    {values[index]}
                    {(selected || filled) && focused && <div className={styles.shadows} />}
                </div>
                );
            })}
        </div>
    </React.Fragment>
  );
}

export default CodeVerify;
